.p-impulse {
  .c-product-mv {
    padding-bottom: 6rem;
  }

  .c-product-spec-ttl {
    @media (--mobile) {
      font-size: 2.3rem;
    }
  }

  .c-product-spec {
    padding-top: 9rem;

    @media (--mobile) {
      padding-top: 4rem;
    }
  }

  .c-product-mv-ttl02 {
    @media (--mobile) {
      font-size: 4rem;

      span {
        font-size: 3rem;
      }
    }
  }

  .c-product-spec-img {
    width: 71.6rem;

    @media (--mobile) {
      width: 100%;
    }
  }
}
