.js-open {
  cursor: pointer;

  .arrow-bottom {
    transition: 0.3s;
  }

  &.is-open {
    .arrow-bottom {
      transform: translateY(-50%) rotate(-180deg) !important;
    }
  }
}

.js-acco__content {
  display: none;
}
