@media (--large) {
  .c-hover_bLine {
    position: relative;

    &::before {
      position: absolute;

      // bottom: -0.3rem;
      bottom: -1px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      content: "";
      background-color: #1a2e56;
      opacity: 0;
      transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
      transition-duration: 0.6s;
      transition-property: opacity, transform;
      transform: scale3d(0.8, 1, 1);
      transform-origin: left center;
    }

    &:hover {
      &::before {
        opacity: 1;
        transform: none;
      }
    }

    &--white {
      &::before {
        bottom: 0;
        background-color: #fff;
      }
    }
  }

  .c-hover01 {
    position: relative;
    display: inline-block;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      margin: auto;
      content: "";
      background-color: #1a2e56;
      transition: transform 0.5s;
      transform: scale(0, 1);
      transform-origin: right center;
    }

    &:hover {
      &::before {
        transform: scale(1, 1);
        transform-origin: left center;
      }
    }
  }
}
