.c-i-inner {
  width: 120rem;
  margin-right: auto;
  margin-left: auto;

  @media (--mobile) {
    width: 100%;
    padding: 0 3rem;
  }
}

.c-o-inner {
  width: 120rem;
  margin-right: auto;
  margin-left: auto;

  @media (--mobile) {
    width: 100%;
    padding: 0 2rem;
  }
}
