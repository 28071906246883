.p-exhibition {
  &-inner {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    margin-bottom: 14rem;

    @media (--mobile) {
      display: block;
      margin-top: 3rem;
      margin-bottom: 7rem;
    }

    &__side {
      width: 15rem;

      @media (--mobile) {
        width: 100%;
      }

      ul {
        li {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 0;
            font-size: 1.5rem;
            font-weight: bold;
            border: 0.1rem solid #1a2e56;
          }

          &:nth-child(n + 2) {
            margin-top: 2rem;
          }

          &.active {
            a {
              color: #fff;
              pointer-events: none;
              background-color: #1a2e56;
            }
          }
        }
      }
    }

    &__main {
      width: 100%;

      @media (--mobile) {
        width: 100%;
        margin-top: 3rem;
      }

      .text {
        margin-top: 3rem;
        font-size: 1.6rem;
      }
    }

    &__list {
      .p-exhibition-inner__cell:first-of-type {
        margin-top: 3rem;
      }

      .p-exhibition-inner__cell:nth-of-type(n + 2) {
        margin-top: 6rem;
      }
    }

    &__cell {
      .content {
        display: flex;
        margin-top: 2rem;

        @media (--mobile) {
          display: block;
        }
      }

      .left-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 29rem;
        min-height: 11rem;
        margin-right: 2rem;
        overflow: hidden;

        @media (--mobile) {
          width: 100%;
          min-height: 13rem;
        }

        img {
          display: block;
          width: 100%;
          min-width: 20rem;
          height: 100%;
          margin: 0 auto;
          object-fit: cover;
        }
      }

      .right-content {
        position: relative;
        width: calc(100% - 29rem - 2rem);

        @media (--mobile) {
          width: 100%;
        }

        dl {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          font-size: 1.6rem;

          @media (--large) {
            padding-right: 19rem;
          }

          @media (--mobile) {
            margin-top: 2rem;
          }

          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 9rem;
            background-color: #edeff4;

            &:nth-of-type(n + 2) {
              margin-top: 1rem;
            }

            @media (--mobile) {
              width: calc(100% - 70% - 1.5rem);
            }
          }

          dd {
            width: calc(100% - 9rem);
            padding-left: 1.5rem;
            letter-spacing: -0.01em;

            &:nth-of-type(n + 2) {
              margin-top: 1rem;
            }

            @media (--mobile) {
              width: 70%;
            }
          }
        }

        .btn {
          position: absolute;
          right: 0;
          bottom: 0;

          @media (--mobile) {
            position: inherit;
            margin-top: 3rem;

            .c-btn09 {
              a {
                height: 6rem !important;
                padding: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
