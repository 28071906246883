/* ==========================================================================
  fontsize
========================================================================== */

/* fontsize */
@for $value from 1 to 26 {
  .u-fs-#{$value} {
    font-size: $value / 10 + rem !important;
  }
}

@media (--mobile) {
  /* fontsize */
  @for $value from 1 to 26 {
    .u-fs-sp-#{$value} {
      font-size: $value / 10 + rem !important;
    }
  }
}
