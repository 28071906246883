.p-rental-user {
  .l-btnWrap {
    @media (--large) {
      display: flex;
      align-items: flex-end;
    }

    .c-btn12 {
      a {
        width: 40rem;
        height: 7rem;

        @media (--mobile) {
          width: 100%;
          height: 6rem;
          font-size: 1.8rem;
        }
      }
    }

    p {
      margin-left: 2rem;
      font-size: 1.5rem;
      color: #8491aa;

      @media (--mobile) {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  }

  .l-btnWrap02 {
    @media (--large) {
      display: flex;
    }

    .c-btn12 {
      a {
        @media (--large) {
          width: 35rem;
          height: 6rem;
        }

        @media (--mobile) {
          width: 100%;
          height: 6rem;
          font-size: 1.8rem;
        }
      }

      &:nth-of-type(n + 2) {
        @media (--large) {
          margin-left: 3rem;
        }

        @media (--mobile) {
          margin-top: 2rem;
        }
      }
    }
  }

  .l-product {
    dd {
      width: 27rem;

      @media (--mobile) {
        width: 100%;
      }

      input {
        width: 100%;
      }
    }

    &--separate {
      display: flex;
      flex-wrap: wrap;

      @media (--mobile) {
        justify-content: space-between;
      }

      @media (--large) {
        margin: 0 -2.2rem;

        .c-f-txt01 {
          margin: 0 2.2rem;

          &:nth-of-type(n + 4) {
            margin-top: 3rem;
          }
        }
      }

      @media (--mobile) {
        .c-f-txt01 {
          width: 46%;

          &:nth-of-type(n + 3) {
            margin-top: 2rem;
          }
        }
      }
    }
  }

  .c-btn01 {
    button,
    a {
      position: relative;
      height: 8rem;
      font-size: 1.8rem;

      &::after {
        position: absolute;
        top: 50%;
        right: 4rem;
        width: 4rem;
        height: 1.3rem;
        content: "";
        background: url(../images/common/ic_arrow02.svg) no-repeat center center/contain;
        transition: 0.3s;
        transform: translateY(-50%) translateY(-0.2rem);
      }

      @media (--large) {
        &:hover {
          &::after {
            right: 2.5rem;
          }
        }
      }
    }
  }
}
