.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 10rem;

  @at-root .h-hdn & {
    pointer-events: none;
  }

  @media (--mobile) {
    height: 8rem;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 3rem;
    border-top: 0.3rem solid $primary;
    transition: 0.5s transform, 0.2s border-color;

    @at-root .h-mv & {
      border-color: unquote("rgb(26 46 86 / 0%)");
    }

    @media (--mobile) {
      padding: 0 2rem;
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    width: 100%;
    height: 10rem;

    @media (--mobile) {
      height: 8rem;
    }

    @at-root .h-hdn & {
      pointer-events: none;
    }

    &__wrap {
      width: 100%;
      height: 100%;
      background-color: unquote("rgba(255, 255, 255, 0.6)");
      transition: 0.5s transform, 0.2s background-color, 0.2s backdrop-filter;
      backdrop-filter: blur(10px);

      @at-root .h-mv & {
        background: linear-gradient(to bottom, #00022b, unquote("rgb(0 2 43 / 0%)"));
        background-color: unquote("rgba(255, 255, 255, 0.0)");
        backdrop-filter: blur(0);
      }

      @at-root .h-mv-mega & {
        background: #00012b;
      }

      @media (--mobile) {
        height: 8rem;
      }
    }
  }

  &-logo {
    position: relative;
    z-index: 2;
    width: 18rem;

    @media (--mobile) {
      display: flex;
      width: 16rem;
    }

    svg {
      * {
        transition: 0.2s fill;
      }
    }

    @at-root .h-mv & {
      svg {
        * {
          fill: #fff;
        }
      }
    }

    @at-root .h-mv.is-hidden & {
      svg {
        * {
          fill: unquote("rgb(26 19 17)");
        }

        #pass_425,
        #pass_427 {
          fill: unquote("rgb(0, 88, 162)");
        }
      }
    }
  }

  &-trigger {
    position: absolute;
    top: 50%;
    right: 2rem;
    z-index: 2;
    width: 5rem;
    height: 5rem;
    background: #1a2e56;
    border-radius: 999rem;
    transform: translateY(-50%);

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2rem;
      height: 2px;
      background: #fff;
      transition: 0.3s transform, 0.3s width;

      @at-root .is-open & {
        width: 2.4rem;
      }

      &:first-of-type {
        transform: translate(-50%, -50%) translateY(-0.6rem);

        @at-root .is-open & {
          transform: translate(-50%, -50%) translateY(0) rotate(30deg);
        }
      }

      &:last-of-type {
        transform: translate(-50%, -50%) translateY(0.6rem);

        @at-root .is-open & {
          transform: translate(-50%, -50%) translateY(0) rotate(-30deg);
        }
      }
    }
  }

  @media (--mobile) {
    &-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: var(--firstViewHeight);
      padding-top: 8rem;
      pointer-events: none;
      background: #fff;
      opacity: 0;
      transition: 0.3s opacity;

      @at-root .is-open & {
        pointer-events: inherit;
        opacity: 1;
      }
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-search {
      width: calc(100% - 9rem);
      margin-top: 1rem;

      .gsc-control-cse {
        position: relative;
        width: 100%;
        padding: 0;
        background: none;
        border: none;
      }

      .gsc-control-cse,
      .gsc-input-box,
      .gsib_a {
        padding: 0;
        background: none;
        border: none;

        input {
          height: inherit !important;
          padding: 1.5rem 2rem !important;
          margin: 0 !important;
          font-size: 1.6rem !important;
          color: #000 !important;
          background: #fff !important;
          border: 1px solid #b2b2b2 !important;
          border-radius: 9999px !important;

          &::placeholder {
            color: #9b9b9b;
          }
        }
      }

      .gsib_b {
        display: none;
      }

      table.gsc-search-box {
        margin-bottom: 0;
      }

      table.gsc-search-box td.gsc-input {
        padding: 0;
      }

      .gsc-search-button {
        margin-left: 0;
      }

      form.gsc-search-box {
        margin-bottom: 0 !important;
      }

      button {
        position: absolute;
        top: 50%;
        right: 2.5rem;
        z-index: 2;
        padding: 0;
        background: none !important;
        border: none;
        outline: none;
        transform: translateY(-50%);

        svg {
          width: 2rem;
          height: 2rem;

          * {
            fill: #0f4b71;
          }
        }
      }
    }
  }

  @media (--large) {
    &-top {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-inner {
        display: flex;
        justify-content: flex-end;

        a {
          font-size: 1.3rem;
          transition: 0.2s color;

          @at-root .h-mv & {
            color: #fff;
          }

          &:nth-of-type(n + 2) {
            margin-left: 2.6rem;
          }
        }
      }
    }
  }

  &-lang {
    display: flex;
    justify-content: flex-end;
    font-size: 1.4rem;

    @media (--mobile) {
      margin-left: 1rem;
    }

    a {
      padding: 0 1.2rem;
      color: #b2b2b2 !important;
      transition: 0.2s color;

      @media (--mobile) {
        font-size: 1.9rem;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      &:last-of-type {
        position: relative;
        margin-left: 1rem;

        @media (--large) {
          &::before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 0.1rem;
            height: 70%;
            content: "";
            background-color: #8491aa;
            transform: translateY(-50%);
          }
        }
      }

      &.is-active {
        font-weight: bold;
        color: #222;
      }

      @at-root .h-mv & {
        color: #fff;
      }
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    margin-top: 1.2rem;

    @media (--mobile) {
      align-items: flex-start;
      width: 100%;
      height: calc(100% - 7rem - -3rem - 15rem);
      margin-top: 1rem;
      overflow: auto;
      border-top: 1px solid #1a2e56;
      border-bottom: 1px solid #1a2e56;

      &__gList {
        width: 100%;
      }
    }

    &__el {
      position: relative;

      @media (--large) {
        display: flex;
      }

      &:nth-of-type(n + 2) {
        @media (--large) {
          margin-left: 3rem;
        }

        @media (--mobile) {
          border-top: 1px solid #1a2e56;
        }
      }

      @at-root .h-mv-mega & {
        &.is-active {
          span {
            color: #1a2e56;
          }

          svg {
            path {
              stroke: #1a2e56 !important;
            }
          }
        }
      }

      @media (--large) {
        &.js-h-mega {
          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            width: calc(100% + 4rem);
            height: calc(100% + 3rem);
            content: "";
            transition: 0.3s all;
            transform: translate(-50%, -50%) translateY(0.15rem);
          }

          &.is-active {
            &::before {
              background-color: #edeff4;
            }
          }
        }
      }

      @media (--large) {
        &.is-active {
          &::after {
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% + 10rem);
            height: 5rem;
            content: "";
            transform: translateX(-50%);
          }
        }
      }
    }

    &__link {
      font-size: 1.6rem;
      color: #1a2e56;
      transition: 0.2s color;

      @media (--large) {
        @at-root .h-mv & {
          color: #fff;
        }
      }

      @media (--large) {
        cursor: pointer;

        &.js-h-aco {
          display: inline-block;
          padding-right: 2rem;
        }
      }

      @media (--mobile) {
        display: flex;
        align-items: center;
        width: 100%;
        height: 7.4rem;
        padding: 0 3.6rem;
        font-size: 1.8rem;
        font-weight: bold;
        color: #1a2e56;
      }
    }

    &__gList {
      display: flex;
      align-items: center;

      @media (--mobile) {
        display: block;
      }
    }

    &__search {
      position: relative;
      width: 3.2rem;
      height: 3.2rem;
      margin-left: 3rem;

      @media (--mobile) {
        margin-top: 3.2rem;
      }

      .gsc-control-cse {
        position: relative;

        // width: 28rem;
        padding: 0;
        background: none;
        border: none;

        @media (--mobile) {
          width: 100%;
        }
      }

      .gsc-input {
        pointer-events: none;
        opacity: 0;
        transition: 0.3s;

        &.is-active {
          pointer-events: inherit;
          opacity: 1;
        }
      }

      .gsc-control-cse,
      .gsc-input-box,
      .gsib_a {
        padding: 0;
        background: none;
        border: none;

        input {
          height: 3.2rem !important;
          padding: 0.5rem 1rem !important;
          margin: 0 !important;
          font-size: 1.3rem !important;
          color: #000 !important;
          background: #fff !important;
          border: 1px solid #1a2e56 !important;
          border-radius: 9999px !important;

          @at-root .h-mv & {
            color: #fff !important;
            background: #00012b !important;
          }

          &::placeholder {
            color: #9b9b9b;
          }
        }
      }

      .gsib_a {
        position: absolute;
        top: 0;
        right: 0;
        width: 20rem;
      }

      .gsib_b {
        display: none;
      }

      table.gsc-search-box {
        margin-bottom: 0;
      }

      table.gsc-search-box td.gsc-input {
        padding: 0;
      }

      .gsc-search-button {
        margin-left: 0;
      }

      form.gsc-search-box {
        margin-bottom: 0 !important;
      }

      .gsc-search-button {
        width: 3.2rem;
        height: 3.2rem;
        background: #1a2e56 !important;
        border-radius: 9999px;
        transition: 0.2s background;

        @at-root .h-mv & {
          background: #fff !important;
        }

        button {
          position: absolute;
          top: 50%;
          z-index: 2;
          padding: 0;
          border: none;
          outline: none;
          transform: translateY(-50%);

          svg {
            position: relative;
            z-index: 3;
            width: 1.2rem;
            height: 1.2rem;

            * {
              fill: #fff;
              transition: 0.2s fill;

              @at-root .h-mv & {
                fill: #1a2e56;
              }
            }
          }
        }
      }

      .gsc-modal-background-image {
        height: 100vh;
      }

      .gsc-results-wrapper-overlay {
        height: 80vh;
        margin-top: 10vh;
      }
    }

    &__trigger {
      @media (--large) {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        width: 0.8rem;
        transform: translateY(-50%) rotate(90deg);

        * {
          transition: 0.2s stroke;
        }

        @at-root .h-mv & {
          * {
            stroke: #fff;
          }
        }
      }

      @media (--mobile) {
        position: absolute;
        top: 1.5rem;
        right: 2.6rem;
        width: 4rem;
        height: 4rem;
        pointer-events: none;
        transition: 0.3s transform;
        transform: rotate(90deg);

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1.2rem;
          transition: 0.3s transform;
          transform: translate(-50%, -50%) translateX(25%);
        }

        &.is-active {
          transform: rotate(-90deg);

          svg {
            transform: translate(-50%, -50%) translateX(-25%);
          }
        }
      }
    }

    &__openList {
      display: none;
      padding-right: 3rem;
      padding-bottom: 2.4rem;
      padding-left: 5.5rem;
    }

    &__openEle {
      font-size: 1.5rem;

      &:nth-of-type(n + 2) {
        margin-top: 1.5rem;
      }
    }

    &__openLink {
      display: flex;
      align-items: center;
      font-weight: bold;

      svg {
        width: 0.8rem;
        margin-right: 1rem;
      }
    }
  }

  @media (--mobile) {
    &-cBtn {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 15rem;
      background: #edeff4;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35rem;
        height: 8rem;
        font-size: 2rem;
        color: #fff;
        text-align: center;
        background: #1a2e56;
      }
    }
  }

  &-mega {
    position: fixed;
    top: -5rem;
    left: 0;
    z-index: 98;
    width: 100%;
    padding-top: 15.5rem;
    padding-bottom: 6rem;
    overflow: auto;
    pointer-events: none;
    background: #edeff4;
    opacity: 0;
    transition: 0.3s opacity, 0.3s top;

    &.is-active {
      top: 0;
      pointer-events: inherit;
      opacity: 1;
    }

    &__ttl {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2.5rem;
      line-height: 1.4;
      color: #1a2e56;

      strong {
        display: block;
        font-family: $robot;
        font-size: 3.4rem;
        line-height: 1;
        color: #1a2e56;
      }

      small {
        position: relative;
        top: -0.2rem;
        margin-left: 1rem;
        font-size: 1.5rem;
      }
    }
  }

  &-products {
    &__wrap {
      display: flex;
      justify-content: space-between;

      & > div {
        width: 48rem;

        &:last-of-type {
          width: 54rem;
        }
      }
    }

    &__parent {
      position: relative;
      z-index: 10;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      &.tool {
        @media (--large) {
          align-items: center;
        }
      }

      img {
        width: 13rem;
      }

      p {
        position: relative;
        padding-right: 3.6rem;
        margin-top: 0.6rem;
        margin-left: 2.4rem;
        font-size: 2.2rem;
        font-weight: bold;
        color: #1a2e56;

        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 2.5rem;
          height: 2.5rem;
          content: "";
          background: url(../images/common/arrow_icon01.png) no-repeat center center / contain;
          transform: translateY(-50%);
        }

        &::before {
          position: absolute;
          bottom: -0.3rem;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          content: "";
          background-color: #1a2e56;
          opacity: 0;
          transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
          transition-duration: 0.6s;
          transition-property: opacity, transform;
          transform: scale3d(0.8, 1, 1);
          transform-origin: left center;
        }
      }

      @media (--large) {
        &:hover {
          p {
            &::before {
              opacity: 1;
              transform: none;
            }
          }
        }
      }
    }

    &__lineup {
      display: flex;
      align-items: center;

      a {
        display: flex;

        svg {
          width: 0.7rem;
          margin-right: 0.5rem;
        }
      }

      form {
        display: flex;
        align-items: center;
        height: 3.5rem;
        margin-left: 2rem;

        input {
          width: 16rem;
          height: 100%;
          padding: 0;
          padding: 1rem;
          font-size: 1.4rem;
          border: 1px solid unquote("rgb(0 0 0 / 20%)");

          &::placeholder {
            color: #b2b2b2;
          }
        }

        button {
          width: 9rem;
          height: 100%;
          padding: 0;
          font-size: 1.4rem;
          color: #fff;
          white-space: nowrap;
          background: #1a2e56;
        }
      }
    }

    &-side {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      &__2col {
        width: 22rem;
      }

      &__3col {
        width: 16rem;
      }

      &__2col,
      &__3col {
        p {
          padding-bottom: 0.5rem;
          margin-bottom: 1rem;
          font-size: 1.6rem;
          color: #1a2e56;
          border-bottom: 1px solid #1a2e56;

          @at-root .en & {
            display: flex;
            align-items: flex-end;
            min-height: 6.5rem;
          }
        }

        ul {
          li {
            &:nth-of-type(n + 2) {
              margin-top: 0.5rem;
            }

            a {
              position: relative;
              display: inline-block;
              padding-left: 1.2rem;
              font-size: 1.5rem;
              white-space: nowrap;

              svg {
                position: absolute;
                top: 50%;
                left: 0;
                width: 0.6rem;
                transform: translateY(-50%);
              }
            }
          }
        }
      }

      &__parts {
        margin-top: 2.8rem;

        a {
          position: relative;
          display: inline-block;
          padding-right: 3.5rem;
          font-size: 2.2rem;
          font-weight: bold;

          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 2.5rem;
            height: 2.5rem;
            content: "";
            background: url(../images/common/arrow_icon01.png) no-repeat center center / contain;
            transform: translateY(-50%);
          }

          &:nth-of-type(n + 2) {
            margin-left: 5rem;
          }
        }
      }
    }

    &__top {
      &-inner {
        position: relative;
      }
    }
  }

  &-download {
    &__list {
      display: flex;

      li {
        &:nth-of-type(n + 2) {
          margin-left: 6rem;
        }

        a {
          position: relative;
          display: inline-block;
          padding-right: 3.5rem;
          font-size: 2.2rem;
          font-weight: bold;

          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 2.5rem;
            height: 2.5rem;
            content: "";
            background: url(../images/common/arrow_icon01.png) no-repeat center center / contain;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &-support {
    &__top {
      display: flex;
      justify-content: space-between;
    }

    &__menu {
      display: flex;
      margin-top: 1rem;

      a {
        position: relative;
        display: flex;
        align-items: center;

        // width: 28.5rem;
        width: 25rem;
        height: 6rem;
        padding-left: 5.2rem;
        font-size: 1.6rem;
        border: 1px solid #1a2e56;
        transition: 0.3s;

        &:nth-of-type(n + 2) {
          margin-left: 2rem;
        }

        @media (--large) {
          &:hover {
            color: #fff;
            background: #1a2e56;
          }
        }

        .l-header-support__menu--logo svg,
        .l-header-support__menu--logo svg *,
        .l-header-support__menu--arrow,
        .l-header-support__menu--arrow svg * {
          transition: 0.3s;
        }

        @media (--large) {
          &:hover {
            .l-header-support__menu--logo svg * {
              fill: #fff;
            }

            .l-header-support__menu--arrow path {
              stroke: #fff;
            }
          }
        }
      }

      &--logo {
        position: absolute;
        top: 50%;
        left: 1.7rem;
        display: flex;
        width: 2.2rem;
        transform: translateY(-50%);
      }

      &--arrow {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        display: flex;
        width: 0.8rem;
        transform: translateY(-50%);
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 2rem -1rem 0;

      li {
        width: calc(100% / 4 - 2rem);
        margin: 0 1rem;

        &:nth-of-type(2) {
          svg {
            width: 4.3rem;
          }
        }

        &:nth-of-type(7) {
          svg {
            width: 4.4rem;
          }
        }

        &:nth-of-type(8) {
          svg {
            width: 3.2rem;
          }
        }

        &:nth-of-type(n + 5) {
          margin-top: 2rem;
        }

        a {
          display: block;
          padding: 3rem 0 2.5rem;
          font-size: 1.6rem;
          text-align: center;
          border: 1px solid #1a2e56;
          transition: 0.3s;

          figure {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5.3rem;
            height: 4.1rem;
            margin-right: auto;
            margin-bottom: 2rem;
            margin-left: auto;

            svg {
              max-width: 100%;
              max-height: 100%;

              // &,
              // * {
              //   transition: 0.3s;
              // }
            }
          }

          @media (--large) {
            &:hover {
              color: #fff;
              background: #1a2e56;

              svg {
                &,
                * {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
