.p-sitemap {
  margin-bottom: 14rem;

  @media (--mobile) {
    margin-bottom: 7rem;
  }

  &-inner {
    width: 117rem;
    margin: 0 auto;

    @media (--mobile) {
      width: 100%;
      padding: 0 3rem;
    }
  }

  &-list {
    display: flex;
    margin-top: 6rem;

    @media (--mobile) {
      display: block;
      margin-top: 3rem;
    }

    &__content {
      width: calc(100% / 3);
      margin-right: 6rem;

      @media (--mobile) {
        width: 100%;
        margin-right: 0;

        &:nth-of-type(n + 2) {
          margin-top: 3rem;
        }
      }

      ul {
        li {
          margin-top: 2rem;

          a {
            position: relative;
            padding-left: 2rem;
            font-size: 1.6rem;

            &::after {
              position: absolute;
              top: 50%;
              left: 0;
              width: 0.9rem;
              height: 0.9rem;
              content: "";
              border-top: 2px solid #1a2e56;
              border-right: 2px solid #1a2e56;
              transform: translateY(-50%) rotate(45deg);
            }

            @at-root .en & {
              white-space: nowrap;

              &::after {
                top: 0.8rem;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }

  &-middle {
    margin-top: 11rem;

    @media (--mobile) {
      margin-top: 5rem;
    }

    &__menu {
      margin-top: 4rem;
      font-size: 2.4rem;
      font-weight: bold;

      @media (--mobile) {
        margin-top: 2rem;
      }

      a {
        position: relative;

        &::after {
          position: absolute;
          top: 50%;
          right: -2rem;
          width: 0.9rem;
          height: 0.9rem;
          content: "";
          border-top: 2px solid #1a2e56;
          border-right: 2px solid #1a2e56;
          transform: translateY(-50%) rotate(45deg);
        }
      }

      &.btn {
        padding-bottom: 1rem;
        border-bottom: 0.1rem solid #8491aa;
      }
    }

    &__flex {
      display: flex;
      justify-content: space-between;

      &.wrap {
        flex-wrap: wrap;
      }

      @media (--mobile) {
        display: block;
      }
    }

    &__content {
      width: 44rem;
      margin-right: 6rem;

      @media (--mobile) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-of-type(2) {
        width: calc(100% - 44rem - 6rem);
        margin-right: 0;

        @media (--mobile) {
          width: 100%;
        }
      }
    }

    &__wrap {
      display: flex;
      flex-wrap: wrap;

      @media (--mobile) {
        flex-direction: column;
      }
    }

    &__list {
      margin-top: 2rem;

      .title {
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        font-size: 2rem;
        font-weight: bold;
        color: #1a2e56;
        background-color: #edeff4;

        @at-root .en & {
          padding-right: 1.5rem;
        }

        span {
          font-size: 1.4rem;

          @at-root .en & {
            @media (--large) {
              margin-top: 0.4rem;
            }
          }
        }

        @media (--mobile) {
          padding: 0 1rem;
        }
      }

      ul {
        width: 21rem;

        @media (--mobile) {
          width: 100%;
        }

        li {
          margin-top: 1rem;

          @at-root .en & {
            white-space: nowrap;
          }

          a {
            position: relative;
            display: inline-block;
            padding-left: 2rem;

            &::after {
              position: absolute;
              top: 50%;
              left: 0;
              width: 0.8rem;
              height: 0.8rem;
              content: "";
              border-top: 2px solid #1a2e56;
              border-right: 2px solid #1a2e56;
              transform: translateY(-50%) rotate(45deg);
            }
          }
        }
      }

      &:nth-of-type(1) {
        margin-right: 1.5rem;

        @media (--mobile) {
          margin-right: 0;
        }
      }

      &:nth-of-type(n + 2) {
        margin-right: 1.5rem;

        @media (--mobile) {
          margin-right: 0;
        }
      }

      &:nth-of-type(3) {
        margin-right: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &-bottom {
    display: flex;
    margin-top: 6rem;

    @media (--mobile) {
      display: block;
      margin-top: 3rem;
    }

    &__wrap {
      ul {
        margin-top: 2rem;

        li {
          a {
            position: relative;
            display: inline-block;
            padding-left: 2rem;
            font-size: 1.6rem;

            &::after {
              position: absolute;
              top: 50%;
              left: 0;
              width: 0.8rem;
              height: 0.8rem;
              content: "";
              border-top: 2px solid #1a2e56;
              border-right: 2px solid #1a2e56;
              transform: translateY(-50%) rotate(45deg);
            }
          }
        }
      }

      &:nth-of-type(1) {
        width: 56rem;
        margin-right: 6rem;

        @media (--mobile) {
          width: 100%;
          margin-right: 0;
        }
      }

      &:nth-of-type(n + 2) {
        width: 24rem;
        margin-right: 6rem;

        @media (--mobile) {
          width: 100%;
          margin-top: 3rem;
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      .title {
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        margin-top: 4rem;
        font-size: 2rem;
        font-weight: bold;
        color: #1a2e56;
        background-color: #edeff4;

        span {
          font-size: 1.4rem;
        }
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      @at-root .en & {
        margin-top: 0;
      }

      @media (--mobile) {
        display: block;
      }

      ul {
        margin-top: 0;
      }

      .subtitle {
        font-size: 1.6rem;
        font-weight: bold;
        color: #333;
      }
    }

    &__list {
      @media (--mobile) {
        &:nth-of-type(n + 2) {
          margin-top: 2rem;
        }
      }
    }
  }
}
