.c-table01 {
  @media (--large) {
    overflow: inherit !important;
  }

  @media (--mobile) {
    overflow: auto;
  }

  table {
    width: 100%;
    font-size: 1.5rem;
    border-spacing: 0;
    border-collapse: collapse;

    @media (--mobile) {
      width: 100rem;
      border-collapse: separate;
    }

    th {
      padding: 1.5rem 0;
      font-weight: bold;
      color: #1a2e56;
      background-color: #edeff4;
      border: 1px solid #c7ced3;

      // min-width: 9rem;
    }

    td {
      padding: 1.5rem 0;
      text-align: center;
      background-color: #fff;
      border: 1px solid #c7ced3;

      // &:nth-child(2){
      //   border-left: none;
      // }
      a {
        color: #0058a2;
        text-decoration: underline;
      }
    }

    @media (--mobile) {
      .fixed01 {
        position: sticky;
        left: 0;

        &::before {
          position: absolute;
          top: -1px;
          left: 1px;
          width: 100%;
          height: 100%;
          content: "";

          // border-right: 1px solid #C7CED3;
        }
      }
    }
  }
}

.c-table02 {
  border-right: 1px solid #c7ced3;
  border-left: 1px solid #c7ced3;

  dl {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-top: 1px solid #c7ced3;

    @media (--mobile) {
      display: block;
    }

    dt {
      width: 15%;
      padding: 2rem;
      font-size: 1.5rem;
      font-weight: bold;
      color: #1a2e56;
      background-color: #edeff4;
      border-right: 1px solid #c7ced3;
      border-bottom: 1px solid #c7ced3;

      @media (--mobile) {
        width: 100%;
        padding: 1rem 2rem;
      }
    }

    dd {
      width: 85%;
      padding: 2rem 3.5rem;
      font-size: 1.5rem;
      border-bottom: 1px solid #c7ced3;

      @media (--mobile) {
        width: 100%;
        padding: 1rem 2rem;
      }
    }
  }
}

.c-table03 {
  overflow: hidden;

  table {
    width: 100%;
    font-size: 1.5rem;
    border-spacing: 0;
    border-collapse: collapse;

    @media (--mobile) {
      width: 80rem;
      border-collapse: separate;
    }

    th {
      padding: 1.5rem 0;
      color: #fff;
      background-color: #2c529f;
      border: 1px solid #c7ced3;

      &:first-child {
        background-color: #fff;
        border: none;
      }
    }

    td {
      padding: 1rem 2rem;
      border: 1px solid #c7ced3;

      @media (--mobile) {
        min-width: 9rem;
      }

      &.first {
        text-align: center;
        background-color: #edeff4;
      }

      &.price {
        color: #2c529f;
      }
    }

    @media (--mobile) {
      .fixed01 {
        position: sticky;
        left: 0;
        width: 12rem;

        &::before {
          position: absolute;
          top: -1px;
          left: 1px;
          width: 100%;
          height: 100%;
          content: "";

          // border-right: 1px solid #C7CED3;
        }
      }
    }
  }
}
