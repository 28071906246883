.p-faq {
  font-size: 1.6rem;

  &-side {
    ul {
      li {
        &:nth-of-type(n + 2) {
          margin-top: 2rem;
        }

        a {
          position: relative;
          display: block;
          font-size: 1.2rem;

          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 0.8rem;
            height: 0.8rem;
            content: "";
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            transform: translateY(-50%) rotate(45deg);
          }
        }
      }
    }
  }

  .c-mv {
    &__top {
      display: flex;
      align-items: center;

      span {
        font-size: 1.8rem;
        color: #969faf;

        @media (--mobile) {
          font-size: 1.4rem;
        }
      }

      em {
        padding: 0 1.3rem;
        margin-left: 1rem;
        font-size: 1.2rem;
        font-style: inherit;
        color: #969faf;
        border: 1px solid #969faf;
        border-radius: 9999px;
      }
    }

    &__middle {
      padding-top: 0.8rem;
      font-size: 4rem;
      line-height: 1.2;
      color: #969faf;
      letter-spacing: 0.1em;

      @media (--mobile) {
        padding-top: 0.8rem;
        font-size: 3rem;
      }
    }

    &__bottom {
      padding-top: 1rem;
      font-size: 3.2rem;
      line-height: 1.2;

      @media (--mobile) {
        padding-top: 1.2rem;
        font-size: 3.3rem;
      }
    }

    @media (--mobile) {
      &__h {
        height: 22rem;
      }
    }
  }

  &__ttl {
    .ttl {
      &-it {
        padding: 1.2rem 0.9rem;
        background-color: #1a2e56;

        h2 {
          font-size: 2.2rem;
          color: #fff;
        }
      }

      &-text {
        margin-top: 3rem;
      }
    }
  }

  &__content {
    margin-top: 3rem;
    margin-bottom: 10rem;
  }

  &__inner {
    display: flex;

    @media (--mobile) {
      flex-direction: column;
    }
  }

  &__menu {
    width: 21rem;
    color: #1a2e56;

    @media (--mobile) {
      width: 100%;
      margin-bottom: 5rem;
    }

    .menu {
      &-inner {
        width: 15rem;

        @media (--mobile) {
          width: 100%;
        }
      }

      &-select {
        position: relative;

        select {
          width: 100%;
          padding: 1.2rem 3.2rem 1.2rem 1.2rem;
          font-size: 1.5rem;
          font-weight: bold;
          color: #1a2e56;
          letter-spacing: 0.03em;
          white-space: normal;
          border: 1px solid #1a2e56;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 1rem;
          width: 1.4rem;
          height: 1.4rem;
          transform: translateY(-50%);
        }
      }

      &-accoInner {
        margin-top: 1.7rem;
      }

      &-acco {
        &:nth-child(n + 2) {
          margin-top: 0.7rem;
        }

        &__ttl {
          position: relative;
          padding: 1.3rem 0;
          font-size: 1.3rem;
          font-weight: bold;
          border-bottom: 1px solid #c7ced3;

          svg {
            position: absolute;
            top: 50%;
            right: 1rem;
            width: 1.4rem;
            height: 1.4rem;
            transform: translateY(-50%);
          }
        }

        &__list {
          font-size: 1.3rem;

          @media (--mobile) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }

        &__item {
          &:first-child {
            margin-top: 2rem;

            @media (--mobile) {
              margin-top: 1.4rem;
            }
          }

          margin-top: 1.4rem;
          text-align: center;

          @media (--mobile) {
            width: 50%;
          }
        }
      }
    }
  }

  &__list,
  .c-faq {
    flex: 1;

    .list {
      &-ttl {
        padding: 0.9rem 1.2rem;
        background-color: #1a2e56;

        h3 {
          font-size: 2.2rem;
          color: #fff;
        }
      }

      &-content {
        margin-top: 3rem;
      }

      &-item {
        &:first-child {
          border-top: 1px solid #8491aa;
        }

        border-bottom: 1px solid #8491aa;
      }

      &-question,
      &-answer {
        display: flex;
        align-items: flex-start;
        padding: 3.2rem 6rem 3.2rem 2rem;

        @media (--mobile) {
          padding: 1.8rem 3.5rem 1.8rem 0.5rem;
        }

        p {
          flex: 1;
        }

        .list-icon {
          width: 3.2rem;
          height: 3.2rem;
          margin-right: 2rem;

          @media (--mobile) {
            margin-right: 1rem;
          }
        }
      }

      &-question {
        position: relative;
        transition: 0.3s;

        p {
          margin-top: 0.2rem;
        }

        .arrow-bottom {
          position: absolute;
          top: 50%;
          right: 2rem;
          width: 1.4rem;
          height: 1.4rem;
          transform: translateY(-50%);

          @media (--mobile) {
            right: 1rem;
          }
        }

        @media (--large) {
          &:hover {
            opacity: 0.5;
          }
        }
      }

      &-answer {
        margin-top: -3rem;

        @media (--mobile) {
          padding: 1.8rem 3.5rem 1.8rem 0.5rem;
          margin-top: -1rem;
        }

        &__content {
          flex: 1;
          margin-top: 0.2rem;
        }

        .caption {
          width: 71.6rem;
          font-size: 1.4rem;
          text-align: right;

          @media (--mobile) {
            width: 100%;
          }
        }

        &__img {
          height: 100%;
          margin-top: 3rem;

          &.img01 {
            width: 30rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img02 {
            width: 30rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img03 {
            width: 43rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img04 {
            width: 43rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img05 {
            width: 77rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img06 {
            width: 44rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img07 {
            width: 63rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img08 {
            width: 59rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img09 {
            width: 59rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img10 {
            width: 73.6rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img11 {
            width: 73.6rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          &.img12 {
            width: 73.6rem;

            @media (--mobile) {
              width: 100%;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        &__link {
          display: flex;
          margin-top: 3rem;

          a {
            text-decoration: underline;

            &:nth-child(n + 2) {
              margin-left: 3rem;
            }
          }
        }
      }
    }
  }
}
