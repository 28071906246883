.s-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 1rem;
    background: #0058a2;
    transition: 0.5s;
  }

  svg {
    width: 20rem;

    .second {
      opacity: 0;
      transform: translate(-1rem, -1rem);
    }

    .first {
      opacity: 0;
      transform: translate(1rem, 1rem);
    }

    .third {
      opacity: 0;
      transform: translate(1rem, -1rem);
    }
  }
}

.p-top {
  margin-top: -10rem;

  .c-topTtl {
    text-align: center;

    strong {
      display: block;
      font-size: 2.8rem;
      font-weight: bold;
      color: #1a2e56;
      letter-spacing: 0.1em;
    }

    small {
      font-family: $robot;
      font-size: 1.8rem;
    }
  }

  .s-mv {
    &-news {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: #edeff4;
      transition: 0.3s background;

      @media (--mobile) {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 9rem;
      }

      a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 1.4rem 2.7rem;

        &::after {
          position: absolute;
          top: 50%;
          right: 2.7rem;
          width: 0.9rem;
          height: 0.9rem;
          content: "";
          border-top: 0.2rem solid #1a2e56;
          border-right: 0.2rem solid #1a2e56;
          transform: translateY(-50%) rotate(45deg);

          @media (--mobile) {
            right: 2rem;
          }
        }

        @media (--mobile) {
          padding: 1rem 2rem;
        }
      }

      &__ttl {
        font-family: $robot;
        font-size: 1.6rem;
        color: #384eaa;
        transition: 0.3s;
      }

      &__b {
        display: flex;
        align-items: center;
      }

      &__year {
        margin-right: 0.8rem;
        font-family: $robot;
        font-size: 1.4rem;
        line-height: 1;
        letter-spacing: 0;
        writing-mode: tb;
        transition: 0.3s;

        @media (--mobile) {
          width: 1.2rem;
        }
      }

      // &__day {
      //   font-family: $robot;
      //   font-size: 4rem;
      //   font-weight: bold;
      //   line-height: 1.1;
      //   letter-spacing: normal;
      //   transition: 0.3s;

      //   @media (--mobile) {
      //     width: 11rem;
      //   }
      // }

      &__day {
        margin-left: 1.5rem;
        font-family: $robot;
        font-size: 1.4rem;
        font-weight: bold;
        color: #8491aa;
        letter-spacing: normal;
        transition: 0.3s;

        @media (--mobile) {
          width: 11rem;
        }
      }

      &__txt {
        position: relative;
        width: 38rem;
        margin-right: 2rem;
        overflow: hidden;
        font-size: 1.5rem;
        line-height: 1.5;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: 0.3s;

        @media (--mobile) {
          width: 95%;

          // width: calc(100% - 11rem - 1.2rem - 0.8rem - 1rem);
        }

        svg {
          position: absolute;
          top: 50%;
          right: 0;
          width: 0.9rem;
          transform: translateY(-50%);

          & * {
            transition: 0.3s;
          }
        }
      }

      @media (--large) {
        &:hover {
          background: #1a2e56;

          * {
            color: #fff;
          }

          a {
            &::after {
              border-color: #fff;
            }
          }

          svg {
            & * {
              stroke: #fff;
            }
          }
        }
      }
    }
  }

  .s-products {
    padding-top: 16.5rem;
    padding-bottom: 8rem;
    overflow: hidden;
    background: #edeff4;

    @media (--mobile) {
      padding: 0 3rem 5rem;
      padding-top: 9rem;
      overflow: hidden;
    }

    &__ttl {
      position: relative;
      width: calc(142rem - 6rem * 2);
      margin-right: auto;
      margin-left: auto;

      &::after {
        position: absolute;
        top: -5rem;
        left: -7rem;
        width: 116rem;
        height: 53rem;
        pointer-events: none;
        content: "";
        background: url(../images/page/top/products_txt.png) no-repeat center center / contain;

        @media (--mobile) {
          top: -6rem;
          left: -6rem;
          width: 83rem;
          height: 38rem;
        }
      }

      * {
        color: #1a2e56;
      }

      strong {
        display: block;
        font-family: $robot;
        font-size: 15.2rem;
        line-height: 1;

        @media (--mobile) {
          font-size: 6.5rem;
        }
      }

      small {
        display: inline-block;
        font-size: 3.6rem;

        @media (--mobile) {
          font-size: 3rem;
        }
      }
    }
  }

  .s-moter {
    width: 120rem;
    margin-top: 6.3rem;
    margin-right: auto;
    margin-left: auto;

    @media (--mobile) {
      position: relative;
      width: 100%;
    }

    &__wrap {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;

      @media (--mobile) {
        display: block;
      }
    }

    &__ttl {
      * {
        color: #1a2e56;
      }

      strong {
        display: block;
        font-family: $robot;
        font-size: 10.6rem;
        line-height: 1;

        @media (--mobile) {
          font-size: 4.7rem;
          line-height: 1.1;
        }
      }

      small {
        font-size: 4.2rem;

        @media (--mobile) {
          font-size: 2.2rem;
        }
      }
    }

    &__right {
      width: 52.8rem;
      padding-top: 5rem;

      @media (--mobile) {
        position: relative;
        z-index: 1;
        width: 100%;
        padding-top: 28rem;
      }
    }

    &__txt {
      font-size: 2rem;

      @media (--mobile) {
        font-size: 1.6rem;
      }
    }

    &__btnWrap {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;

      @media (--mobile) {
        justify-content: center;
      }

      @media (--mobile) {
        .c-btn01 {
          width: 70%;
        }
      }

      a {
        width: 25.5rem;
        height: 7rem;
        padding-left: 2rem;
        font-size: 1.6rem;

        @at-root .en & {
          width: 28.5rem;
        }

        @media (--mobile) {
          width: 100%;
          height: 6rem;
          padding-left: 1.5rem;
        }

        @media (--mobile) {
          svg {
            right: 1rem;
            width: 2.5rem;
            transform: translateY(-50%) translateY(-0.1rem);
          }
        }
      }
    }

    &__slider {
      width: 98rem;
      margin: 0 0 0 auto;
      pointer-events: none;

      @media (--large) {
        width: 85rem;
      }

      @media (--mobile) {
        position: absolute;
        top: -3rem;
        z-index: 2;
        width: calc(100% + 4rem);
        padding-top: 0;
        margin: 0 -2rem;
      }

      &-box {
        position: relative;
        width: 100rem;
        height: 60rem;

        @media (--large) {
          left: -3rem;
          width: 81rem;
          height: 46rem;
          margin: 0 0 0 auto;
        }

        @media (--mobile) {
          width: 100%;
        }

        &::after {
          position: absolute;
          bottom: 9rem;
          left: 50%;
          width: calc(100% - 8rem);
          height: calc(100% - 4rem);
          content: "";
          background: linear-gradient(135deg, #e4e6ec, #c5c7d0);
          transform: translateX(-50%);

          @media (--mobile) {
            top: 50%;
            bottom: inherit;
            width: 85%;
            height: 19rem;
            transform: translateX(-50%) translateY(-50%) translateY(-1rem);
          }
        }

        img {
          position: absolute;
          top: -9rem;
          left: 0;
          z-index: 2;
          width: 100%;

          // height: 100%;
          object-fit: contain;
          opacity: 0;
          transform: translate(15rem, -15rem);

          @media (--large) {
            top: -8rem;
            left: 3rem;
            width: 93%;
          }

          @media (--mobile) {
            top: 16rem;
            transform: translate(5rem, -5rem);
          }
        }

        &--mg {
          img {
            top: -1rem;

            @media (--large) {
              top: 0;
            }
          }
        }
      }

      &-num {
        position: absolute;
        right: 3rem;
        bottom: 4rem;
        display: flex;
        align-items: center;
        font-family: $robot;
        font-size: 2rem;

        @media (--large) {
          right: 7rem;
        }

        @media (--mobile) {
          bottom: 17rem;
        }

        span {
          position: relative;
          width: 2.4rem;
          height: 3.6rem;

          &:nth-of-type(2) {
            width: 3rem;
            height: 1px;
            margin: 0 0.8rem;
            background: #1a2e56;
          }

          em {
            position: absolute;
            top: 0;
            left: 0;
            font-style: inherit;
            opacity: 0;
          }
        }
      }
    }
  }

  .s-lineUp {
    position: relative;

    &__wrap {
      position: relative;
      display: flex;
      margin-top: 8rem;
      margin-left: calc(50vw - 144rem / 2 + 5rem);

      @at-root .en & {
        justify-content: flex-end;
        margin-top: 3rem;
        margin-left: calc(50vw - 144rem / 2);

        @media (--mobile) {
          margin: 0 -3rem;
          margin-top: 3rem;
        }
      }

      @media (--mobile) {
        display: block;
        margin: 0 -3rem;
        margin-top: 7rem;
      }
    }

    &__pagenation {
      @media (--large) {
        position: absolute;
        bottom: 6rem;
        left: 0;
        display: inline-block;
        width: auto;

        @at-root .en & {
          position: relative;
          bottom: inherit;
          left: inherit;
        }

        span {
          font-family: $robot;
          line-height: 0;

          &:nth-of-type(1) {
            position: absolute;
            top: -3rem;
            left: -2rem;
            width: 4rem;
            font-size: 3.6rem;
            font-weight: bold;
            text-align: right;
          }

          &:nth-of-type(2) {
            position: absolute;
            top: -0.7rem;
            left: 0.4rem;
            width: 3.5rem;
            height: 1px;
            background: #8491aa;
            transform: rotate(-40deg);
          }

          &:nth-of-type(3) {
            position: absolute;
            top: 1rem;
            left: 1.8rem;
            font-size: 2.3rem;
          }
        }
      }

      @media (--mobile) {
        display: none;
      }
    }

    &__next,
    &__prev {
      position: absolute;
      top: 50%;
      left: 10rem;
      z-index: 2;
      width: 8.8rem;
      height: 8.8rem;
      cursor: pointer;
      background: unquote("rgb(255 255 255 / 90%)");
      border-radius: 9999px;
      outline: none;
      transition: 0.3s;
      transform: translateY(-50%) translateX(-50%);

      @media (--large) {
        &:hover {
          transform: translateY(-50%) translateX(-50%) scale(1.1);
        }
      }

      @media (--mobile) {
        top: 0;
        right: 9.6rem;
        left: inherit;
        width: 4.6rem;
        height: 4.6rem;
        transform: translateY(-0.6rem);

        @at-root .en & {
          top: -6.5rem;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.9rem;
        height: 1.8rem;
        content: "";
        background: url(../images/common/ic_arrow01.svg) no-repeat center center / contain;
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    &__prev {
      @media (--large) {
        display: none;
      }

      @media (--mobile) {
        right: 3rem;
        transform: translateY(-0.6rem) rotate(180deg);
      }
    }

    &__ttl {
      display: flex;
      align-items: flex-end;
      width: 10rem;
      font-family: $robot;
      font-size: 3.6rem;
      line-height: 1;
      color: #1a2e56;
      writing-mode: tb;

      @media (--mobile) {
        width: auto;
        padding-left: 3rem;
        writing-mode: lr-tb;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 120rem;

      // align-items: flex-end;
      // width: 10rem;
      margin-top: 8rem;
      margin-left: calc(50vw - 144rem / 2 + 10rem);

      & > h3 {
        font-family: $robot;
        font-size: 3.6rem;
        line-height: 1;
        color: #1a2e56;
      }

      @media (--mobile) {
        width: 100%;
        margin: 0 auto;
        margin-top: 7rem;
      }
    }

    &__slider {
      position: relative;
      display: flex;
      width: calc(100% - 10rem);
      padding-bottom: 4rem;
      overflow: hidden;

      &::after,
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 116rem;
        height: 2px;
        content: "";
        background: #c7ced3;

        @media (--mobile) {
          width: calc(100vw - 6rem);
        }
      }

      &::before {
        z-index: 2;
        width: 56rem;
        background: #1a2e56;

        @media (--mobile) {
          width: 12rem;
        }
      }

      @media (--mobile) {
        width: calc(100% - 3rem);
        margin: 0 0 0 auto;
        margin-top: 2.3rem;
      }

      & > div {
        width: 30rem;
        height: 45rem;

        @media (--mobile) {
          width: inherit;
          height: 25.2rem;
        }

        a {
          position: relative;
          display: inline-block;
          width: 100%;
          height: 100%;
          padding-top: 27rem;
          background: linear-gradient(315deg, #000, #00012b);
          transition: 0.3s;

          @media (--mobile) {
            padding-top: 14rem;
          }

          @media (--large) {
            &:hover {
              h3 {
                transform: scale(1.05);
              }
            }
          }

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 13.8rem;
            text-align: center;

            @media (--mobile) {
              height: 8.8rem;
            }
          }

          span {
            display: inline-block;
            padding: 0 0.8rem;
            margin-top: 2rem;
            font-size: 1.4rem;
            color: #c7ced3;
            border: 1px solid #c7ced3;
            border-radius: 9999px;

            @media (--mobile) {
              margin-top: 1rem;
              font-size: 1.2rem;

              @at-root .en & {
                padding: 0.6rem 1.5rem;
                line-height: 1.5;
              }
            }
          }

          h3 {
            line-height: 1.7;
            text-align: center;
            transition: 0.3s;

            * {
              color: #fff;
            }

            strong {
              display: block;
              font-size: 3.2rem;
              font-weight: 500;

              @media (--mobile) {
                font-size: 1.8rem;
              }
            }

            small {
              display: block;
              font-size: 1.8rem;
              color: #c7ced3;

              @media (--mobile) {
                font-size: 1.4rem;
              }
            }
          }

          figure {
            position: absolute;
            top: 0;
            left: 50%;
            width: 30rem;
            transform: translateX(-50%);

            @media (--mobile) {
              width: 15rem;
            }
          }
        }
      }
    }
  }

  .s-dl {
    margin-top: 7rem;

    @media (--mobile) {
      padding: 0;
    }

    &__btnWrap {
      display: flex;
      justify-content: space-between;
      margin-top: 4.6rem;

      @media (--mobile) {
        display: block;
        margin-top: 2rem;
      }

      .c-btn08 {
        @media (--large) {
          width: calc(100% / 3 - 2rem);
        }

        @media (--mobile) {
          &:nth-of-type(n + 2) {
            margin-top: 2rem;
          }
        }

        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 8rem;
          font-size: 1.6rem;
          font-weight: bold;
          background: none;
          border: 1px solid #1a2e56;

          @media (--mobile) {
            width: 100%;

            &:nth-of-type(n + 2) {
              margin-top: 1.8rem;
            }
          }

          svg {
            position: absolute;
            top: 50%;
            right: 3rem;
            width: 2.1rem;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .s-support {
    padding-top: 8rem;
    padding-bottom: 8rem;

    @media (--mobile) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
      margin-top: 3.1rem;

      li {
        position: relative;
        width: calc(100% / 4);

        @media (--mobile) {
          width: calc(100% / 3);
        }

        @media (--large) {
          &::after {
            position: absolute;
            top: 50%;
            width: 1px;
            height: calc(100% - 1.3rem * 2);
            content: "";
            background: #c7ced3;
            transform: translateY(-50%);
          }
        }

        &:nth-of-type(1),
        &:nth-of-type(5) {
          &::after {
            display: none;
          }
        }

        &:nth-of-type(2) {
          svg {
            width: 4.5rem;
          }
        }

        &:nth-of-type(7) {
          svg {
            width: 4.4rem;
          }
        }

        &:nth-of-type(8) {
          svg {
            width: 3.2rem;

            @media (--mobile) {
              width: 4rem;
            }
          }
        }

        @media (--large) {
          &:nth-of-type(n + 5) {
            border-top: 1px solid #c7ced3;
          }
        }

        a {
          position: relative;
          display: block;
          padding: 5rem 0;
          font-size: 1.6rem;
          text-align: center;

          @media (--mobile) {
            padding: 0.5rem 0 2rem;
          }

          figure {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5.3rem;
            height: 4.1rem;
            margin-right: auto;
            margin-bottom: 2rem;
            margin-left: auto;

            @media (--mobile) {
              margin-bottom: 1rem;
            }

            svg {
              max-width: 100%;
              max-height: 100%;
            }
          }

          @media (--mobile) {
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 5rem;
              line-height: 1.5;
              text-align: center;
            }
          }

          @media (--large) {
            &::before {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: -1;
              width: 0;
              height: 0;
              content: "";
              background: #edeff4;
              opacity: 0;
              transition: 0.3s;
              transform: translate(-50%, -50%);
            }

            &:hover {
              &::before {
                width: calc(100% - 1.3rem * 2);
                height: calc(100% - 1.3rem * 2);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &__btn {
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;

      @media (--mobile) {
        display: block;
        margin-top: 2rem;
      }

      .c-btn10 {
        @media (--large) {
          width: calc(100% / 2 - 2rem);
        }

        @media (--mobile) {
          &:nth-of-type(n + 2) {
            margin-top: 2rem;
          }
        }

        a {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 11rem;
          padding-left: 10.8rem;
          font-size: 2.2rem;
          font-weight: 500;
          letter-spacing: 0.1em;
          border: 1px solid #1a2e56;

          @media (--mobile) {
            width: 100%;
            height: 8rem;
            padding-left: 8rem;
            font-size: 2rem;
          }

          svg {
            position: absolute;
            top: 50%;
            left: 4rem;
            width: 3.6rem;
            transform: translateY(-50%);

            @media (--mobile) {
              left: 2.5rem;
              width: 3.5rem;
            }

            * {
              stroke: none;
            }
          }

          @media (--large) {
            &:hover {
              svg {
                * {
                  fill: #fff;
                }
              }
            }
          }

          &::after {
            right: 3rem;
            width: 1.5rem;
            height: 1.5rem;
            content: "";

            @media (--mobile) {
              right: 2.5rem;
            }
          }
        }
      }
    }
  }

  .s-sideWrap {
    display: flex;
    justify-content: space-between;
    padding-top: 8rem;
    padding-bottom: 8rem;
    border-top: 1px solid #c7ced3;

    @media (--mobile) {
      display: block;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    & > section {
      width: calc(100% / 2 - 3rem);

      @media (--mobile) {
        width: 100%;
      }
    }
  }

  .s-news {
    &__list {
      margin-top: 4rem;
    }

    &__el {
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #c7ced3;

      @media (--large) {
        height: 9.6rem;
      }

      &:first-of-type {
        border-top: 1px solid #c7ced3;
      }

      &:nth-of-type(n + 2) {
        margin-top: -1px;
      }
    }

    &__mass {
      width: 100%;

      @media (--mobile) {
        padding: 1.5rem 0;
      }
    }

    &__link {
      position: absolute;
      width: 100%;
      height: 100%;

      &.nolink {
        pointer-events: none;
      }
    }

    &__top {
      display: flex;
      align-items: center;
    }

    &__tag {
      min-width: 12rem;
      font-size: 1.3rem;
      color: #fff;
      text-align: center;
      background: #384eaa;
    }

    &__date {
      margin-left: 1rem;
      font-family: $robot;
      font-size: 1.4rem;
    }

    &__txt {
      max-width: 100%;
      margin-top: 0.5rem;
      font-size: 1.5rem;

      @media (--large) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__more {
      position: relative;
      margin-top: 2.4rem;
      font-size: 1.5rem;
      text-align: right;

      @media (--mobile) {
        font-size: 1.8rem;
      }

      a {
        padding-right: 2rem;

        svg {
          position: absolute;
          top: 50%;
          right: 0;
          width: 0.8rem;
          transform: translateY(-50%);
        }
      }
    }
  }

  .s-exhibition {
    @media (--mobile) {
      padding-top: 6rem;
    }

    &__list {
      margin-top: 4rem;
    }

    &__el {
      border-bottom: 1px solid #c7ced3;

      &:first-of-type {
        border-top: 1px solid #c7ced3;
      }

      &:nth-of-type(n + 2) {
        margin-top: -1px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      padding: 2.4rem 0;

      @media (--mobile) {
        position: relative;
        display: block;
      }
    }

    &__thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22rem;
      height: 10rem;
      overflow: hidden;

      @media (--mobile) {
        position: absolute;
        top: 2.9rem;
        left: 0;
        width: 12.6rem;
        height: 5.5rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__mass {
      width: calc(100% - 22rem);
      padding-left: 2.4rem;

      @media (--mobile) {
        width: 100%;
        padding-left: 0;
      }
    }

    &__ttl {
      font-size: 1.7rem;

      @media (--large) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @media (--mobile) {
        min-height: 7rem;
        padding-left: 14.4rem;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;

      span {
        &:first-of-type {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 7.5rem;
          height: 2.4rem;
          font-size: 1.4rem;
          border: 1px solid #1a2e56;
        }

        &:last-of-type {
          width: calc(100% - 7.5rem);
          padding-left: 1rem;
          font-size: 1.4rem;
        }
      }
    }

    &__more {
      position: relative;
      margin-top: 2.4rem;
      font-size: 1.5rem;
      text-align: right;

      a {
        padding-right: 2rem;

        svg {
          position: absolute;
          top: 50%;
          right: 0;
          width: 0.8rem;
          transform: translateY(-50%);
        }
      }
    }
  }

  .s-column {
    padding-top: 8rem;
    padding-bottom: 8rem;
    background: #edeff4;

    @media (--mobile) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    &__el {
      @media (--large) {
        &:hover {
          .s-column__thumb {
            img {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    &__thumb {
      overflow: hidden;

      img {
        transition: 0.3s;
      }
    }

    &__txt {
      margin-top: 3rem;
      font-size: 1.6rem;
      text-align: center;

      @media (--mobile) {
        margin-top: 2rem;
      }
    }

    &__sliderWrap {
      position: relative;
      padding: 0 4.5rem;
      margin: 0 -4.5rem;
      margin-top: 4.6rem;

      @media (--mobile) {
        padding: 0;
        margin: 0;
        margin-top: 3rem;
      }
    }

    @media (--mobile) {
      &__list {
        display: block;
      }

      &__el {
        display: flex;

        &:nth-of-type(n + 2) {
          margin-top: 2.5rem;
        }
      }

      &__thumb {
        width: 12rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__spRight {
        width: calc(100% - 12rem);
        padding-left: 2rem;
      }
    }

    &__slider {
      overflow: hidden;

      &--next,
      &--prev {
        position: absolute;
        top: 4.4rem;
        left: 0;
        z-index: 2;
        width: 8.8rem;
        height: 8.8rem;
        cursor: pointer;
        background: #fff;
        border-radius: 9999px;
        outline: none;
        opacity: 0.9;
        transition: 0.3s;

        @media (--large) {
          &:hover {
            transform: scale(1.1);
          }
        }

        @media (--mobile) {
          display: none;
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0.9rem;
          height: 1.8rem;
          content: "";
          background: url(../images/common/ic_arrow01.svg) no-repeat center center / contain;
          transform: translate(-50%, -50%);
        }
      }

      &--next {
        &::after {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }

      &--prev {
        right: 0;
        left: inherit;
      }
    }

    &__ttl {
      margin-top: 2.2rem;
      font-size: 1.6rem;
      line-height: 1.5;
      color: #1a2e56;

      @media (--mobile) {
        margin-top: 0;
      }
    }

    &__client {
      margin-top: 0.7rem;
      line-height: 1.5;
      color: #8491aa;
    }
  }

  .s-mg {
    position: relative;

    &::after {
      position: absolute;
      top: -13rem;
      left: -12rem;
      width: 116rem;
      height: 53rem;
      pointer-events: none;
      content: "";
      background: url(../images/page/top/products_txt.png) no-repeat center center/contain;

      @media (--mobile) {
        display: none;
      }
    }
  }
}
