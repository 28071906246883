.p-aCase {
  .c-mv {
    &__top {
      display: flex;
      align-items: center;

      span {
        font-size: 1.8rem;
        color: #969faf;

        @media (--mobile) {
          font-size: 1.4rem;
        }
      }

      em {
        padding: 0 1.3rem;
        margin-left: 1rem;
        font-size: 1.2rem;
        font-style: inherit;
        color: #969faf;
        border: 1px solid #969faf;
        border-radius: 9999px;
      }
    }

    &__middle {
      padding-top: 0.8rem;
      font-size: 4rem;
      line-height: 1.2;
      color: #969faf;
      letter-spacing: 0.1em;

      @media (--mobile) {
        padding-top: 0.8rem;
        font-size: 3rem;
      }
    }

    &__bottom {
      padding-top: 1rem;
      font-size: 3.2rem;
      line-height: 1.2;

      @media (--mobile) {
        padding-top: 1.2rem;
        font-size: 3.3rem;
      }
    }

    @media (--mobile) {
      &__h {
        height: 22rem;
      }
    }
  }

  @media (--large) {
    .c-product-example-el h3 {
      margin-top: 1.8rem;
      font-size: 1.6rem;
    }

    .c-product-example-el h4 {
      font-size: 1.6rem;
    }

    .c-product-example-el:nth-of-type(n + 4) {
      margin-top: 5.5rem;
    }

    .c-pagenav .wp-pagenavi * {
      margin: 0 0.6rem;
    }
  }

  &__mv {
    position: relative;

    @media (--mobile) {
      height: 20rem;
    }

    .c-i-inner {
      padding: 0 0 0 3rem;
    }
  }

  &__kind {
    display: inline-block;
    padding: 0.3rem 0.8rem;
    margin-bottom: 1.8rem;
    font-size: 1.8rem;
    font-weight: bold;
    border: 1px solid #fff;

    @media (--mobile) {
      padding: 0.1rem 0.4rem;
      margin-bottom: 0;
      font-size: 1.4rem;
    }
  }

  &__type {
    display: block;
    font-size: 2rem;
  }
}
