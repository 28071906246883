.c-separate {
  display: flex;
  flex-wrap: wrap;

  &__left {
    width: 22rem;
    padding-right: 4rem;

    @media (--mobile) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 5rem;
    }
  }

  &__sticty {
    position: sticky;
    top: 0;
    left: 0;

    @media (--large) {
      padding-top: 12rem;
      margin-top: -12rem;
    }
  }

  &__right {
    width: calc(100% - 22rem);

    @media (--mobile) {
      width: 100%;
    }
  }
}
