.p-e3000 {
  @media (--large) {
    .c-product-spec-img {
      width: 64.1rem;
    }

    @at-root .en & {
      .c-product-list {
        & > ul {
          li:nth-child(2) {
            .c-link01 a {
              font-size: 1.5rem !important;
            }
          }
        }
      }
    }
  }

  &-list {
    ul {
      display: flex;

      @media (--mobile) {
        display: block;
      }

      li {
        margin-right: 4rem;

        @media (--mobile) {
          margin-top: 3rem;
          margin-right: 0;

          &:first-child {
            margin-top: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .title {
          margin-top: 2.4rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56;
        }

        .text {
          margin-top: 1.2rem;
          font-size: 1.6rem;
        }
      }
    }
  }
}

.c-modal.short {
  width: 40rem;

  @media (--mobile) {
    width: 90%;
  }
}

.c-modal-inner.long {
  height: 500px;
  overflow-y: scroll;

  @media (--large) {
    &.vertical {
      @at-root .en & {
        ul {
          flex-direction: column;
          margin: 2.4rem 0 0;

          li {
            display: flex;
            width: 100%;
            min-width: 80rem;
            margin: 0;

            &:nth-child(n + 2) {
              margin-top: 2rem;
            }

            & > .img {
              width: 40%;
              margin-right: 2rem;
            }

            & > div {
              width: calc(100% - 40% - 2rem);
            }
          }
        }
      }
    }
  }
}
