.c-product {
  &-mv {
    position: relative;
    padding: 7rem 0;
    overflow: hidden;
    background: linear-gradient(90deg, #00012b, #000);

    @media (--mobile) {
      padding: 4rem 0 12rem;
    }

    &__pImg {
      display: block;

      @media (--large) {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;

        // height: 100%;
      }

      img {
        display: block;
      }

      &.micro-grinder {
        @media (--large) {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 58rem;
          transform: translateY(-50%) translateX(-50%) translateX(34rem);
        }
      }

      &.e3000 {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 68.5rem;
          transform: translateX(-50%) translateX(100rem) translateY(-50%);
        }

        @media (--mobile) {
          width: calc(100% - 4rem);
          margin: 0 0 5rem auto;
        }
      }

      &.e4000 {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 72rem;
          transform: translateX(-50%) translateX(110rem) translateY(-50%);

          img {
            bottom: 0;
          }
        }

        @media (--mobile) {
          width: calc(100% - 3rem);
          margin: 0 0 5rem auto;
        }
      }

      &.e2000 {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 73rem;
          transform: translate(-50%, -50%) translateX(110rem);
        }

        @media (--mobile) {
          width: calc(100% - 3rem);
          margin: 0 0 5rem auto;
        }
      }

      &.ispeed3 {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 75rem;
          transform: translate(-50%, -50%) translateX(110rem);
        }

        @media (--mobile) {
          width: calc(100% - 3rem);
          margin: 0 0 5rem auto;
        }
      }

      &.hes {
        @media (--large) {
          position: absolute;
          right: 50%;
          bottom: 0;
          width: 67rem;
          transform: translateX(-50%) translateX(95rem);

          img {
            position: absolute;
            bottom: 0;
          }
        }

        @media (--mobile) {
          width: calc(100% - 3rem);
          margin: 0 0 3rem auto;
        }
      }

      &.moter_spindle {
        img {
          @media (--large) {
            // position: absolute;
            top: 50%;
            left: 0;
            width: 50rem;

            // transform: translateY(-50%);
          }

          @media (--mobile) {
            width: calc(100% - 6rem);
            margin: 0 auto 5rem;
          }
        }
      }

      &.airspeed {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 68rem;
          transform: translate(-50%, -50%) translateX(96rem);
        }

        @media (--mobile) {
          width: calc(100% - 3rem);
          margin: 0 0 2rem auto;
        }
      }

      &.xpeed {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 65rem;
          transform: translate(-50%, -50%) translateX(95rem);
        }

        img {
          @media (--mobile) {
            width: calc(100% - 3rem);
            margin: 0 0 2rem auto;
          }
        }
      }

      &.abt {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 68rem;
          transform: translate(-50%, -50%) translateX(98rem);
        }

        img {
          @media (--mobile) {
            width: calc(100% - 3rem);
            margin: 0 0 5rem auto;
          }
        }
      }

      &.hts {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 63rem;
          transform: translate(-50%, -50%) translateX(93rem);
        }

        img {
          @media (--mobile) {
            width: calc(100% - 3rem);
            margin: 0 0 2rem auto;
          }
        }
      }

      &.planet {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 47rem;
          transform: translate(-50%, -50%) translateX(76rem);
        }

        img {
          @media (--mobile) {
            width: calc(100% - 3rem);
            margin: 0 0 5rem auto;
          }
        }
      }

      &.emax_evolution {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 43.3rem;
          transform: translate(-50%, -50%) translateX(70rem);
        }

        @media (--mobile) {
          width: calc(100% - 10rem);
          margin: 0 auto 5rem;
        }
      }

      &.rotus {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 65rem;
          transform: translate(-50%, -50%) translateX(100rem);
        }

        @media (--mobile) {
          position: relative;
          width: calc(100% - 8rem);
          margin: 0 auto 2rem;
        }
      }

      &.espert500 {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 51.8rem;
          transform: translate(-50%, -50%) translateX(75rem);
        }

        @media (--mobile) {
          width: calc(100% - 7rem);
          margin: 0 auto;
        }
      }

      &.impulse {
        @media (--large) {
          position: absolute;
          right: 50%;
          bottom: 0;
          width: 44rem;
          transform: translateX(-50%) translateX(74rem);

          img {
            position: absolute;
            bottom: 0;
          }
        }

        @media (--mobile) {
          width: calc(100% - 10rem);
          margin: 0 auto 2rem;
          transform: translateX(-1rem);
        }
      }

      &.presto2 {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 56rem;
          transform: translate(-50%, -50%) translateX(83rem);
        }

        @media (--mobile) {
          width: calc(100% - 3rem);
          margin: 0 auto 5rem;
        }
      }

      &.zero {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 44rem;
          transform: translate(-50%, -50%) translateX(75rem);
        }

        @media (--mobile) {
          display: block;
          width: calc(100% - 6rem);
          margin: 0 auto 5rem;
        }
      }

      &.soniccutter_zero {
        @media (--large) {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 38rem;
          transform: translate(-50%, -50%) translateX(73rem);
        }

        @media (--mobile) {
          width: calc(100% - 9rem);
          margin: 0 auto 1rem;
        }
      }

      // &.e3000 {
      //   img {
      //     @media (--large) {
      //       position: absolute;
      //       bottom: 0;
      //       left: 0;
      //       width: 100%;
      //     }

      //     @media (--mobile) {
      //       width: calc(100% - 3rem);
      //       margin: 0 0 5rem auto;
      //     }
      //   }
      // }
    }

    * {
      color: #fff;
    }

    &__left {
      @media (--large) {
        width: 50rem;
      }
    }

    &-inner {
      display: flex;

      @media (--mobile) {
        flex-direction: column;
      }

      & > div {
        &:last-of-type {
          margin-left: 1rem;

          @media (--mobile) {
            margin-top: 1rem;
            margin-left: 0;
          }

          .c-btn09 a {
            @at-root .en & {
              width: 26rem;
            }
          }
        }
      }
    }

    &-ttl01 {
      font-size: 4.2rem;
      line-height: 1.5;
      letter-spacing: 0.1em;

      @media (--mobile) {
        font-size: 3.46rem;
      }

      small {
        display: block;
        font-family: $robot;
        font-size: 1.8rem;
        font-weight: lighter;
        color: #969faf;
        letter-spacing: 0.1em;
      }

      span {
        font-size: 3.3rem;
      }
    }

    &-ttl02 {
      font-size: 6rem;
      line-height: 1.5;
      letter-spacing: 0.1em;

      @media (--mobile) {
        font-size: 4.2rem;
      }

      small {
        display: flex;
        align-items: center;
        font-family: $robot;
        font-size: 1.8rem;
        font-weight: lighter;
        color: #969faf;
        letter-spacing: 0.1em;

        em {
          padding: 0.2rem 1.3rem;
          margin-left: 1rem;
          font-size: 1.2rem;
          font-style: inherit;
          color: #fff;
          border: 1px solid #fff;
          border-radius: 9999px;

          @media (--mobile) {
            font-size: 1.2rem;
          }
        }
      }

      span {
        font-size: 3.3rem;

        @media (--mobile) {
          font-size: 3rem;
        }
      }
    }

    &-mainTtl {
      display: flex;
      align-items: baseline;

      & > * {
        &:first-child {
          margin-right: 0.4rem;
          font-size: 6rem !important;

          span {
            font-size: 6rem !important;
            line-height: 1;

            @media (--mobile) {
              font-size: 4.2rem !important;
            }
          }

          @media (--mobile) {
            font-size: 4.2rem !important;
          }
        }

        &:nth-child(2) {
          line-height: 1.3;

          @media (--mobile) {
            line-height: 1.5;
          }

          span {
            @media (--mobile) {
              line-height: 1.1;
            }
          }
        }
      }

      &_rev {
        display: flex;
        align-items: baseline;

        & > * {
          &:first-child {
            margin-right: 0.4rem;
            line-height: 1.2;

            @media (--mobile) {
              line-height: 1.5;
            }

            span {
              @media (--mobile) {
                line-height: 1.1;
              }
            }
          }

          &:nth-child(2) {
            font-size: 6rem !important;

            span {
              font-size: 6rem !important;
              line-height: 1;

              @media (--mobile) {
                font-size: 4.2rem !important;
              }
            }

            @media (--mobile) {
              font-size: 4.2rem !important;
            }
          }
        }

        &.sp-column {
          @media (--mobile) {
            flex-direction: column;
            margin-top: 1rem;
          }
        }
      }

      &_kind {
        display: flex;
        align-items: baseline;

        & > * {
          &:first-child {
            font-size: 4.2rem !important;

            span {
              font-size: 4.2rem !important;
              line-height: 1;

              @media (--mobile) {
                font-size: 3.46rem !important;
              }
            }

            @media (--mobile) {
              font-size: 3.46rem !important;
            }
          }

          // &:nth-child(2) {
          //   line-height: 1.3;

          //   @media (--mobile) {
          //     line-height: 1.5;
          //   }

          //   span {
          //     @media (--mobile) {
          //       line-height: 1.1;
          //     }
          //   }
          // }
        }
      }
    }

    &-mTxt {
      margin-top: 4rem;
      font-size: 2rem;

      @media (--mobile) {
        margin-top: 3rem;
      }
    }

    &-sTxt {
      margin-top: 4.5rem;
      font-size: 1.8rem;

      @media (--mobile) {
        margin-top: 3rem;
      }
    }

    &-sTtl {
      font-size: 3.6rem;

      @media (--mobile) {
        margin-top: 1rem;
        font-size: 2.2rem;
      }
    }

    &-feature {
      margin-top: 0.5rem;
      font-size: 1.6rem;

      @media (--mobile) {
        margin-top: 1rem;
        font-size: 1.5rem;
      }
    }

    &-connection {
      display: flex;
      align-items: center;
      margin-top: 3.5rem;

      span {
        width: 8rem;
        color: #1a2e56;
        text-align: center;
        background: #969faf;

        @media (--mobile) {
          text-align: center;
          letter-spacing: normal;
          white-space: nowrap;
        }
      }

      a {
        position: relative;
        max-width: calc(100% - 8rem - 1.5rem);
        padding-right: 1.5rem;
        margin-left: 1.5rem;
        overflow: hidden;
        font-size: 1.6rem;
        color: #969faf;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 1px solid #969faf;
        transition: 0.5s opacity;

        @at-root .en & {
          margin-left: 0;
        }

        @media (--large) {
          &:hover {
            opacity: 0.6;
          }
        }

        @media (--mobile) {
          max-width: calc(100% - 8rem - 1.5rem);
        }

        &::after {
          position: absolute;
          top: 50%;
          right: 0.3rem;
          width: 0.8rem;
          height: 0.8rem;
          content: "";
          border-top: 1px solid #969faf;
          border-right: 1px solid #969faf;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }

    &-btn {
      margin-top: 3rem;

      .c-btn09 {
        a {
          width: 20rem;
          background-color: transparent;
          border: 0.1rem solid #fff;

          @media (--mobile) {
            height: 6rem;
          }

          span {
            color: #fff;

            &::after {
              border-top: 2px solid #fff;
              border-right: 2px solid #fff;
            }
          }
        }
      }
    }
  }

  &_mv-img {
    position: absolute;
    top: 50%;
    left: 50%;

    @media (--large) {
      height: 20.8rem;
    }

    @media (--mobile) {
      position: initial;
      width: 100%;
      height: auto;
      margin-left: 1rem;
    }

    &[data-obj="E4000"] {
      @media (--large) {
        width: 33.5rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(53rem);
      }
    }

    &[data-obj="E3000"] {
      @media (--large) {
        width: 40rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(51rem);
      }
    }

    &[data-obj="E2000"] {
      @media (--large) {
        width: 35.4rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(53rem);
      }
    }

    &[data-obj="iSpeed3"] {
      @media (--large) {
        width: 37rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem);
      }
    }

    &[data-obj="HES"] {
      @media (--large) {
        width: 34rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem);
      }
    }

    &[data-obj="Air-Speed"] {
      @media (--large) {
        width: 42rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem);
      }
    }

    &[data-obj="Emax EVOlution"] {
      @media (--large) {
        width: 20rem;
        height: 18.8rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem);
      }

      @media (--mobile) {
        margin-right: 1rem;
      }
    }

    &[data-obj="Espert500"] {
      @media (--large) {
        width: 28rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem);
      }

      @media (--mobile) {
        margin-right: 0.5rem;
      }
    }

    &[data-obj="シーナスZERO"] {
      @media (--large) {
        width: 24rem;
        height: 18rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem);
      }
    }

    &[data-obj="ソニックカッターZERO"] {
      @media (--large) {
        width: 20rem;
        height: 19.5rem;
        transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-navArea {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9rem;
    background: #edeff4;

    @media (--mobile) {
      position: absolute;
      left: 50%;
      z-index: 1;
      width: calc(100% - 8rem);
      height: auto;
      margin-top: -8rem;
      margin-right: auto;
      margin-left: auto;
      background: #fff;
      box-shadow: 0 0 2rem unquote("rgb(0 0 0 / 10%)");
      transform: translateX(-50%);

      &.mt {
        margin-top: -2rem;
      }

      .c-i-inner {
        padding: 0.5rem 2rem 1.4rem;
      }
    }

    &-list {
      display: flex;
      justify-content: center;

      @media (--mobile) {
        display: block;
        border: none;
      }
    }

    &-el {
      position: relative;
      width: calc(100% / 4);
      height: 4.5rem;
      border-left: 1px solid #8491aa;

      &:last-child {
        border-right: 1px solid #8491aa;

        @media (--mobile) {
          border-right: 0;
        }
      }

      @media (--large) {
        text-align: center;
      }

      @media (--mobile) {
        width: 100%;
        height: 5rem;
        border: none;

        &:nth-child(1) {
          .c-product-navArea-link {
            &::after {
              content: "01";
            }
          }
        }

        &:nth-child(2) {
          .c-product-navArea-link {
            &::after {
              content: "02";
            }
          }
        }

        &:nth-child(3) {
          .c-product-navArea-link {
            &::after {
              content: "03";
            }
          }
        }

        &:nth-child(4) {
          .c-product-navArea-link {
            &::after {
              content: "04";
            }
          }
        }

        &:nth-child(5) {
          .c-product-navArea-link {
            &::after {
              content: "05";
            }
          }
        }
      }
    }

    &-link {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      font-size: 1.5rem;

      &.is-selected {
        color: #8491aa;
        pointer-events: none;

        svg {
          path {
            stroke: #8491aa;
          }
        }
      }

      span {
        transition: 0.5s all;
      }

      // &::after {
      //   position: absolute;
      //   bottom: 0;
      //   left: 50%;
      //   width: 1.5rem;
      //   height: 0.8rem;
      //   content: "";
      //   background: #000;
      //   transform: translateX(-50%);
      // }
      @media (--mobile) {
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        border-bottom: 1px solid #000;

        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          font-family: $robot;
          font-size: 1rem;
          color: #969faf;
          letter-spacing: normal;
          content: "";
          transform: translateY(-50%);
        }
      }

      svg {
        transition: 0.5s stroke;

        @media (--large) {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0.8rem;
          transform: translateX(-50%) rotate(90deg);
        }

        @media (--mobile) {
          position: absolute;
          top: 50%;
          right: 1rem;
          width: 0.7rem;
          transform: rotate(90deg) translateX(-25%);
        }
      }

      transition: 0.5s all;

      @media (--large) {
        &:hover {
          color: #384eaa;

          span {
            margin: 0 0.2rem;
          }

          svg {
            path {
              stroke: #384eaa;
            }
          }
        }
      }
    }
  }

  &-list01 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    &-el {
      position: relative;
      width: calc(100% / 2 - 2rem);
      height: 27rem;
      padding: 2rem;
      margin: 0 1rem;
      background: linear-gradient(to left, #000, #00012b);

      * {
        padding-left: 0.8rem;
        color: #fff;
      }

      img {
        position: absolute;

        &.e4000 {
          right: 0;
          bottom: 0;
          width: 28rem;
          padding-left: 0;
        }

        &.e3000 {
          right: 0;
          bottom: 0;
          width: 31rem;
          padding-left: 0;
        }
      }

      span {
        display: inline-block;
        padding: 0 1rem;
        font-size: 1.1rem;
        color: #c7ced3;
        border: 1px solid #c7ced3;
        border-radius: 9999px;
      }

      h3 {
        margin-top: 1rem;
        font-size: 3.6rem;
        font-weight: normal;
        color: #fff;
      }

      h4 {
        margin-top: 0.5rem;
        font-size: 1.6rem;
        font-weight: lighter;
        opacity: 0.9;
      }

      h5 {
        margin-top: 0.4rem;
        font-size: 1.2rem;
        font-weight: lighter;
        opacity: 0.9;
      }

      p {
        margin-top: 1.6rem;
        font-size: 1.1rem;
        opacity: 0.7;
      }
    }
  }

  &-spec {
    padding: 9rem 0;
    background: linear-gradient(-30deg, #edf0fa, #dee1eb);

    @media (--mobile) {
      padding: 22rem 0 5rem;
    }

    &-ttl {
      font-size: 3rem;
      color: #1a2e56;
      text-align: center;

      @media (--mobile) {
        font-size: 2.4rem;
      }
    }

    &-list {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      p {
        text-align: center;
      }

      ul {
        li {
          padding-left: 1.5em;
          text-indent: -1.5em;
        }
      }
    }

    &-img {
      width: 58.5rem;
      margin-top: 5rem;
      margin-right: auto;
      margin-left: auto;

      @media (--mobile) {
        width: 100%;
      }
    }
  }

  &-side {
    display: flex;
    justify-content: space-between;

    @media (--mobile) {
      display: block;
    }

    &__left {
      font-size: 1.6rem;

      @media (--large) {
        width: 59rem;
      }

      strong {
        display: block;
        margin-bottom: 1.5rem;
        font-size: 2rem;
        color: #1a2e56;

        &:nth-of-type(n + 2) {
          margin-top: 4rem;
        }
      }

      img {
        width: 35rem;
        margin-top: 2.1rem;
      }
    }

    &__right {
      @media (--large) {
        width: 55rem;
      }
    }

    &-graph {
      display: none;
      background: #f8faff;

      &.is-active {
        display: block;
      }

      figcaption {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 1.6rem;
        color: #fff;
        text-align: center;
        background: #384eaa;
      }

      & > div {
        padding: 1.5rem 7.3rem 1rem;

        @media (--mobile) {
          padding: 1.5rem;
        }
      }

      &__btn {
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 14rem;
          height: 3.8rem;
          padding: 0 1.5rem;
          font-size: 1.3rem;
          color: #1a2e56;
          text-align: center;
          cursor: pointer;
          border: 1px solid #1a2e56;
          border-radius: 999rem;

          @media (--mobile) {
            min-width: 11rem;
          }

          &.is-active {
            color: #fff;
            background: #1a2e56;
          }

          small {
            font-size: 1rem;
          }

          &:nth-of-type(n + 2) {
            @media (--large) {
              margin-left: 2rem;
            }

            @media (--mobile) {
              margin-left: 1rem;
            }
          }
        }
      }
    }

    &__spec {
      display: flex;

      @media (--mobile) {
        margin: 0 -0.5rem;
      }

      img {
        width: 9rem;

        @media (--mobile) {
          width: calc(100% / 5 - 1rem);
          margin: 0 0.5rem;
        }

        @media (--large) {
          &:nth-of-type(n + 2) {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  &-example {
    &-list {
      @media (--large) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2rem;
      }

      @media (--mobile) {
        &-case {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
    }

    &-el {
      position: relative;

      @media (--large) {
        width: calc(100% / 3 - 4rem);
        margin: 0 2rem;

        &:nth-of-type(n + 4) {
          margin-top: 4rem;
        }
      }

      @media (--mobile) {
        display: flex;

        &:nth-of-type(n + 2) {
          padding-top: 2rem;
          margin-top: 2rem;
          border-top: 1px solid #8491aa;
        }

        &-case {
          display: block;

          &:nth-of-type(n + 2) {
            padding-top: 0;
            margin-top: 0;
            border-top: none;
          }

          &:nth-of-type(n + 3) {
            margin-top: 2rem;
          }
        }
      }

      h3 {
        font-size: 2rem;
        color: #1a2e56;

        @media (--large) {
          margin-top: 2.7rem;
        }

        @media (--mobile) {
          overflow: hidden;
          font-size: 1.5rem;
          line-height: 1.5;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.overFlow-inherit {
            overflow: inherit;
            white-space: inherit;
          }
        }
      }

      h4 {
        margin-top: 0.9rem;
        font-size: 1.5rem;

        @media (--mobile) {
          margin-top: 0.5rem;
          overflow: hidden;
          font-size: 1.3rem;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      @media (--large) {
        &:hover {
          .c-product-example-cover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    &-cover,
    &-contain {
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        transition: 0.3s;
      }
    }

    &-cover {
      position: relative;
      width: 100%;
      height: 22rem;

      &.is-movie {
        &::after {
          position: absolute;
          right: 1rem;
          bottom: 1rem;
          width: 4rem;
          height: 4rem;
          content: "";
          background: url(../images/common/ic_case_movie.png);
          background-size: cover;

          @media (--mobile) {
            width: 2rem;
            height: 2rem;
          }
        }
      }

      @media (--mobile) {
        width: 12rem;
        height: 8rem;
      }

      img {
        object-fit: cover;
      }
    }

    &-contain {
      height: 34rem;
      background: #f7f7f7;

      @media (--mobile) {
        width: 12rem;
        height: 12rem;

        &-case {
          width: calc(100vw / 2 - 4rem);
          height: calc(100vw / 2 - 4rem);
        }
      }

      img {
        object-fit: contain;
      }
    }

    &-border {
      border: 1px solid #1a2e56;
    }

    &-txtWrap {
      @media (--mobile) {
        width: calc(100% - 12rem);
        padding-left: 2rem;

        &-case {
          width: calc(100vw / 2 - 4rem);
          padding-left: 0;
          margin-top: 2rem;
        }
      }

      div {
        position: relative;
        z-index: 1;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.7rem;
        margin-top: 1.2rem;

        @media (--mobile) {
          margin-top: 0.5rem;
        }

        a {
          margin: 0 0.7rem;
          color: #8491aa;

          @media (--mobile) {
            font-size: 1.2rem;
          }
        }
      }
    }

    &-link {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }

  &-table {
    display: flex;
    justify-content: space-between;

    @media (--mobile) {
      display: block;
    }

    &-left {
      width: 55rem;

      @media (--mobile) {
        width: 100%;
      }
    }

    &-right {
      width: 59rem;

      @media (--mobile) {
        width: 100%;
      }

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          td {
            padding: 1.5rem;
            font-size: 1.5rem;
            border: 1px solid #c7ced3;

            &:first-of-type {
              width: 18rem;
              background: #edeff4;

              @at-root .en & {
                width: 21rem;
              }
            }

            @media (--mobile) {
              display: block;
              width: 100% !important;
              padding: 1rem 1.5rem;
            }
          }
        }
      }
    }

    &-annotation {
      span {
        display: block;
      }
    }
  }

  &-feature {
    &-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-mass {
      width: 52rem;
      font-size: 1.6rem;

      @media (--large) {
        &:nth-of-type(n + 3) {
          margin-top: 8rem;
        }
      }

      @media (--mobile) {
        &:nth-of-type(n + 2) {
          margin-top: 5rem;
        }
      }

      strong {
        display: block;
        margin-bottom: 1.6rem;
        font-size: 2rem;

        @media (--mobile) {
          font-size: 1.8rem;
        }

        &:nth-of-type(n + 2) {
          margin-top: 2rem;
        }
      }
    }
  }

  &-lineup {
    &__img {
      width: 90rem;
      margin: 3rem auto 0;

      @media (--mobile) {
        width: 100%;
      }
    }

    &-txt {
      width: 100%;
      font-size: 1.6rem;
    }

    &-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-mass {
      width: 53rem;
      padding: 2.6rem 0;
      border-bottom: 1px solid #e2e2e2;

      @media (--mobile) {
        width: 100%;
        padding: 1.6rem 0;
      }

      figure {
        display: flex;

        @media (--mobile) {
          align-items: center;
        }

        div {
          width: 13rem;

          @media (--mobile) {
            width: 10rem;
          }
        }

        figcaption {
          width: calc(100% - 13rem);
          padding-left: 3.2rem;

          @media (--mobile) {
            width: calc(100% - 10rem);
          }

          h4 {
            font-size: 2rem;
            color: #1a2e56;

            @media (--mobile) {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }

  &-control {
    &__txt {
      font-size: 1.6rem;
    }

    &__img {
      display: block;
      max-width: 87.8rem;
      margin-right: auto;
      margin-left: auto;

      &--w100 {
        max-width: 100%;
      }
    }
  }

  &-graph {
    &__text {
      margin-top: 3rem;
    }

    &__inner {
      width: 100%;
      padding-bottom: 4rem;
      margin-top: 3rem;
      background-color: #f8faff;
      border-top: 0.6rem solid #384eaa;

      ul {
        display: flex;
        justify-content: center;
        margin-top: 3rem;

        @media (--mobile) {
          display: block;
        }

        li {
          width: 31rem;
          margin-right: 3rem;

          .title {
            font-size: 1.6rem;
            font-weight: bold;
            text-align: center;
          }

          .img {
            margin-top: 3rem;

            img {
              display: block;
              width: auto;
              height: 28rem;
              margin: 0 auto;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          @media (--mobile) {
            position: relative;
            width: 100%;
            padding: 0 2rem;
            padding-bottom: 3rem;
            margin-bottom: 3rem;

            &::after {
              position: absolute;
              bottom: 0;
              left: 50%;
              width: calc(100% - 4rem);
              height: 0.1rem;
              content: "";
              background-color: #c1c1c1;
              transform: translateX(-50%);
            }

            &:last-child {
              padding-bottom: 0;
              margin-bottom: 0;
              border-bottom: none;

              &::after {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  .l-lineup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__el {
      display: flex;
      width: calc(100% / 2 - 2rem);
      padding: 2.5rem 0;
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;

      @media (--mobile) {
        width: 100%;

        &:nth-of-type(n + 2) {
          margin-top: -1px;
        }
      }

      &:nth-of-type(n + 3) {
        margin-top: -1px;
      }
    }

    &__img {
      width: 13rem;
      overflow: hidden;

      @media (--mobile) {
        width: 12rem;
      }

      img {
        transition: 0.3s;
      }
    }

    &__info {
      width: calc(100% - 13rem);
      padding-left: 3rem;

      @media (--mobile) {
        width: calc(100% - 12rem);
        padding-left: 2rem;
      }

      h5 {
        font-size: 2rem;
        line-height: 1.5;
      }

      p {
        font-size: 1.4rem;
      }
    }

    &__series {
      display: inline-block;
      color: #8491aa;
    }

    &__el {
      position: relative;

      &::after,
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 1px;
        content: "";
        background-color: #1a2e56;
        opacity: 0;
        transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
        transition-duration: 0.6s;
        transition-property: opacity, transform;
        transform: scale3d(0.8, 1, 1);
        transform-origin: left center;
      }

      &::after {
        top: 0;
        bottom: inherit;
      }

      @media (--large) {
        &:hover {
          img {
            transform: scale(1.05);
          }

          &::after,
          &::before {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }

  &-list {
    ul {
      display: flex;

      @media (--mobile) {
        display: block;
      }

      li {
        width: calc(100% / 2 - 4rem);
        margin-right: 4rem;

        @media (--mobile) {
          width: 100%;
          margin-top: 3rem;
          margin-right: 0;

          &:first-child {
            margin-top: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .title {
          margin-top: 2.4rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56;
        }

        .text {
          margin-top: 1.2rem;
          font-size: 1.6rem;
        }
      }
    }
  }
}
