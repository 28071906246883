.p-aProduct {
  .c-mv {
    &__top {
      display: flex;
      align-items: center;

      span {
        font-size: 1.8rem;
        color: #969faf;

        @media (--mobile) {
          font-size: 1.4rem;
        }
      }

      em {
        padding: 0 1.3rem;
        margin-left: 1rem;
        font-size: 1.2rem;
        font-style: inherit;
        color: #969faf;
        border: 1px solid #969faf;
        border-radius: 9999px;
      }
    }

    &__middle {
      padding-top: 0.8rem;
      font-size: 4rem;
      line-height: 1.2;
      color: #969faf;
      letter-spacing: 0.1em;

      @media (--mobile) {
        padding-top: 0.8rem;
        font-size: 3rem;
      }
    }

    &__bottom {
      padding-top: 1.5rem;
      font-size: 3.2rem;
      line-height: 1.2;

      @media (--mobile) {
        padding-top: 1.2rem;
        font-size: 3.3rem;
      }
    }

    @media (--mobile) {
      &__h {
        height: 22rem;
      }
    }
  }

  .c-s-sort {
    h4 {
      padding: 0 2.5rem 2rem;

      @media (--mobile) {
        padding: 0 1.5rem 2rem;
      }

      span {
        position: relative;
        display: block;
        padding: 0.8rem 1.5rem;
        font-size: 1.8rem;
        background: #edeff4;
      }
    }
  }

  &__kind {
    display: inline-block;
    padding: 0.3rem 0.8rem;
    margin-bottom: 1.8rem;
    font-size: 1.8rem;
    font-weight: bold;
    border: 1px solid #fff;
  }

  .l-sort {
    &:nth-of-type(n + 2) {
      margin-top: 5.5rem;

      @media (--mobile) {
        margin-top: 3rem;
      }
    }

    h4 {
      padding-bottom: 1rem;
      font-weight: bold;
      color: #1a2e56;
      border-bottom: 1px solid #c7ced3;

      span {
        position: relative;
        display: block;
        font-size: 1.3rem;
        font-weight: 500;

        @media (--mobile) {
          font-size: 2rem;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 0;
          width: 1.5rem;
          height: 1.1rem;
          transition: 0.3s;
          transform: translateY(-50%) rotate(90deg);

          @media (--mobile) {
            right: 1rem;
            width: 1.4rem;
            height: 1.8rem;
          }
        }
      }

      &.is-open {
        svg {
          transform: translateY(-50%) rotate(-90deg);
        }
      }
    }

    &__inner {
      padding: 2.4rem 1rem 0;

      @media (--mobile) {
        padding-top: 2rem;
      }

      div {
        a,
        span {
          display: inline-block;
          font-size: 1.3rem;
          cursor: pointer;

          @media (--mobile) {
            font-size: 1.6rem;
          }

          &.is-active {
            font-weight: bold;
            pointer-events: none;
          }
        }
      }
    }
  }

  .l-lineup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__el {
      display: flex;
      width: calc(100% / 2 - 2rem);
      padding: 2.5rem 0;
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;

      @media (--mobile) {
        width: 100%;

        &:nth-of-type(n + 2) {
          margin-top: -1px;
        }
      }

      &:nth-of-type(n + 3) {
        margin-top: -1px;
      }
    }

    &__img {
      width: 13rem;
      overflow: hidden;

      @media (--mobile) {
        width: 12rem;
      }

      img {
        transition: 0.3s;
      }
    }

    &__info {
      width: calc(100% - 13rem);
      padding-left: 3rem;

      @media (--mobile) {
        width: calc(100% - 12rem);
        padding-left: 2rem;
      }

      h5 {
        font-size: 2rem;
        line-height: 1.5;
      }

      p {
        font-size: 1.4rem;
      }
    }

    &__series {
      display: inline-block;
      color: #8491aa;
    }

    &__el {
      position: relative;

      &::after,
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 1px;
        content: "";
        background-color: #1a2e56;
        opacity: 0;
        transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
        transition-duration: 0.6s;
        transition-property: opacity, transform;
        transform: scale3d(0.8, 1, 1);
        transform-origin: left center;
      }

      &::after {
        top: 0;
        bottom: inherit;
      }

      @media (--large) {
        &:hover {
          img {
            transform: scale(1.05);
          }

          &::after,
          &::before {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }

  .c-s-sort {
    margin-top: -1px;
    border-top: 1px solid #c7ced3;

    &-wrap {
      flex-wrap: wrap;
      padding: 0 2.5rem 3rem;

      @media (--mobile) {
        display: flex;
        padding: 0 1.5rem 3rem;
      }

      .c-s-check {
        width: calc(100% / 4);

        @media (--mobile) {
          width: calc(100% / 2);
        }

        @media (--large) {
          &:nth-of-type(n + 5) {
            margin-top: 1rem;
          }
        }

        @media (--mobile) {
          &:nth-of-type(n + 3) {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
