.p-company {
  &-overview {
    margin-top: 6rem;

    @media (--mobile) {
      margin-top: 3rem;
    }

    &__img {
      width: 86rem;
      margin: 5rem auto 0;

      @media (--mobile) {
        width: 100%;
        margin-top: 3rem;
      }
    }

    dl {
      margin-top: 5rem;

      @media (--mobile) {
        margin-top: 3rem;
      }

      dt {
        @at-root .en & {
          width: 25%;
        }
      }

      dd {
        @at-root .en & {
          width: 75%;
        }
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      margin-top: 6rem;

      @media (--mobile) {
        display: block;
        margin-top: 0;
      }
    }

    &__content {
      width: 50rem;

      @media (--mobile) {
        width: 100%;
        margin-top: 3rem;
      }

      .text {
        margin-top: 3rem;
        font-size: 1.6rem;
      }

      .img {
        margin-top: 2rem;
        overflow: hidden;
      }

      .btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 3rem;
        cursor: pointer;

        .prev01,
        .prev02 {
          width: 3rem;
          margin-right: 2rem;
        }

        .next01,
        .next02 {
          width: 3rem;
        }
      }
    }
  }

  &-area {
    margin-top: 11rem;
    margin-bottom: 16rem;

    @media (--mobile) {
      margin-top: 5rem;
      margin-bottom: 8rem;
    }

    &__content {
      display: flex;
      margin-top: 3rem;

      @media (--mobile) {
        display: block;
      }

      .left-content {
        width: 48rem;
        margin-right: 7rem;

        @media (--mobile) {
          width: 100%;
          margin-right: 0;
        }

        .text {
          font-size: 1.6rem;
        }

        .btn {
          a {
            width: 24rem;
            height: 6.2rem;
            margin-top: 3rem;
            font-size: 1.6rem;
          }
        }
      }

      .right-img {
        @media (--mobile) {
          margin-top: 3rem;
        }

        .btn {
          display: flex;
          justify-content: flex-end;

          .prev03,
          .prev04,
          .prev05 {
            width: 3rem;
            margin-right: 2rem;
          }

          .next03,
          .next04,
          .next05 {
            width: 3rem;
          }
        }

        .img {
          width: 55rem;

          // margin-top: 2rem;
          overflow: hidden;

          @media (--mobile) {
            width: 100%;
          }
        }
      }
    }
  }
}
