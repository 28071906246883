.c-caption {
  width: 100%;
  padding: 2rem 2.5rem 2rem 3rem;
  background-color: #edeff4;

  @media (--mobile) {
    width: 100%;
    padding: 2rem;
  }

  &-inner {
    font-size: 1.6rem;

    .title {
      position: relative;
      padding-left: 1.5rem;
      font-weight: bold;
      color: #1a2e56;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 0.9rem;
        height: 0.9rem;
        content: "";
        background-color: #1a2e56;
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }

    .text {
      margin-top: 0.6rem;

      b {
        text-decoration: underline;
      }
    }

    .caution{
      font-weight: bold;
      text-decoration:underline;
      text-decoration-color:#222222;
    }
  }
}
