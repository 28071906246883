/**
 * BrowserSync
 */
#__bs_notify__ {
  display: none !important;
  max-height: 50px;
  font-size: 8px !important;
  opacity: 0.25;
}

.__debug_mihon__ {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  pointer-events: none;
  cursor: none;
  animation: __debug_mihon__ 1s infinite;
}

.__debug_mihon__ {
  padding-top: 0;
  margin-top: 0;
}

@keyframes __debug_mihon__ {
  0% { opacity: 0; }
  60% { opacity: 0.5; }
  100% { opacity: 0.5; }
}

/*
@example
 <div class="mihon">
  <img src="./images/sample1.png" alt="" class="type_pc">
  <img src="./images/sample2.png" alt="" class="type_sp">
</div>
*/
