.p-sExhibition {
  @media (--large) {
    .l-inner {
      width: 90rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .s-ttlArea {
    padding-bottom: 1rem;
    border-bottom: 1px solid #8491aa;

    &__date {
      font-size: 1.6rem;
    }

    &__ttl {
      margin-top: 1rem;
      font-size: 3rem;

      @media (--mobile) {
        margin-top: 0.5rem;
        font-size: 2.4rem;
      }
    }
  }

  .s-source {
    &__inner {
      display: flex;

      @media (--mobile) {
        display: block;
      }
    }

    &__img {
      padding-right: 2rem;

      @media (--mobile) {
        width: 100%;
        padding-right: 0;
      }

      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40rem;
        min-height: 14rem;

        @media (--mobile) {
          width: 100%;
        }
      }

      figure {
        // @media (--mobile) {
        //   width: 70%;
        // }

        img {
          width: 100%;
          min-width: 20rem;
          height: 100%;
          object-fit: cover;
        }
      }

      .caption {
        margin-top: 1rem;
        font-size: 1.4rem;
      }
    }

    &__right {
      width: calc(100% - 40rem);

      @media (--mobile) {
        width: 100%;
        margin-top: 2rem;
      }

      .title-img {
        margin-bottom: 2rem;
      }

      dl {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.6rem;

        // @media (--large) {
        //   padding-right: 19rem;
        // }

        @media (--mobile) {
          margin-top: 2rem;
        }

        dt {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 9rem;
          font-weight: bold;
          color: #1a2e56;
          background-color: #edeff4;

          &:nth-of-type(n + 2) {
            margin-top: 1rem;
          }

          @media (--mobile) {
            width: calc(100% - 70% - 1.5rem);
          }
        }

        dd {
          width: calc(100% - 9rem);
          padding-left: 1.5rem;
          letter-spacing: -0.01em;

          &:nth-of-type(n + 2) {
            margin-top: 1rem;
          }

          @media (--mobile) {
            width: 70%;
          }
        }
      }

      .link {
        margin-top: 1rem;
      }

      .captionTxt {
        margin-top: 2rem;
      }
    }
  }

  .s-btn {
    border-top: 1px solid #8491aa;

    .c-btn03 {
      a {
        width: 26rem;
        height: 7rem;
        font-size: 1.6rem;
      }

      @at-root .en & {
        a {
          width: 35rem;
        }
      }
    }
  }
}
