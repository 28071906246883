html {
  @media (--vw_outside) {
    font-size: 62.5%;
  }

  @media (--vw_inside) {
    font-size: calc(var(--vw) / 144);
  }

  @media (--mobile) {
    font-size: calc(10vw / 414 * 100);
  }

  &.is-hidden {
    position: fixed;
    right: 0;
    left: 0;
    overflow: hidden;
  }
}

body {
  font-family: $fontfamily;
  font-size: 1.6rem;
  font-weight: 500;
  font-feature-settings: "palt";
  line-height: 1.8;
  color: $txtColer;
  letter-spacing: 0.05em;
}

a {
  color: #1a2e56;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

//text
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
p {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

img {
  vertical-align: bottom;
}

figure {
  padding: 0;
  margin: 0;
}

input,
button,
select,
textarea {
  font: inherit;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

textarea {
  resize: vertical;
}

// input[type="checkbox"],
// input[type="radio"] {
//   display: none;
// }

input[type="submit"],
input[type="button"],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.l-main {
  padding-top: 10rem;

  // overflow: hidden;

  @media (--mobile) {
    padding-top: 8rem;
  }
}

small {
  font-size: 80%;
  vertical-align: top;
}

svg,
img {
  max-width: 100%;
  height: auto;
}

@media (--mobile) {
  html,
  body {
    width: 100%;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  dl,
  dt,
  dd,
  p {
    padding: 0;
    margin: 0;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

@media (--mobile) {
  .pc {
    display: none !important;
  }
}

@media (--large) {
  [href*="tel:"] {
    pointer-events: none;
  }

  .sp {
    display: none !important;
  }
}

.gsc-adBlock,
.gssb_a {
  display: none !important;
}
