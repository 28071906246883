.e2000 {
}

.p-planet {
  .c-product-mv {
    padding-bottom: 6rem;
  }

  .c-product-spec {
    padding-top: 9rem;

    @media (--mobile) {
      padding-top: 4rem;
    }
  }

  .c-product-spec-img {
    width: 42.9rem;

    @media (--mobile) {
      width: 100%;
    }
  }
}
