.p-emax_evolution {
  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3rem;

    @media (--mobile) {
      display: block;
    }
  }

  &__left {
    width: 51.7rem;

    @media (--mobile) {
      width: 100%;
    }
  }

  &__right {
    margin-left: 6rem;

    @media (--mobile) {
      margin-top: 2rem;
      margin-left: 0;
    }

    p {
      font-size: 1.6rem;
    }

    span {
      display: block;
      margin-top: 2rem;
      font-size: 2rem;
      font-weight: bold;
      color: #1a2e56;
    }

    .img {
      width: 35.1rem;
      margin-top: 2rem;

      @media (--mobile) {
        width: 100%;
      }
    }
  }

  &__list {
    ul {
      display: flex;

      @media (--mobile) {
        display: block;
      }

      li {
        &:first-child {
          margin-right: 4rem;

          @media (--mobile) {
            margin-right: 0;
            margin-bottom: 2rem;
          }
        }

        .pic {
          margin-bottom: 2rem;

          @media (--mobile) {
            margin-bottom: 1rem;
          }
        }

        .text {
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56;
        }
      }
    }
  }

  .c-ttl04 {
    span {
      display: inline-block;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      border: 0.1rem solid #1a2e56;

      @media (--mobile) {
        margin-bottom: 0.5rem;
      }
    }
  }

  @media (--large) {
    .no-break {
      @at-root .en & {
        white-space: nowrap;
      }
    }
  }
}
