.c-pagenav {
  .wp-pagenavi {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 6rem;

    @media (--mobile) {
      margin-top: 4rem;
    }

    * {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 3rem;
      height: 3.5rem;
      margin: 0 0.3rem;

      // font-family: Outfit, sans-serif;
      font-size: 1.8rem;
      cursor: pointer;
      border: 1px solid #c7ced3;
      transition: 0.3s;

      @media (--mobile) {
        width: 2.3rem;
      }

      @media (--large) {
        &:hover:not(.extend, .nextpostslink, .previouspostslink) {
          color: #fff;
          background: #1a2e56;
        }
      }
    }

    .previouspostslink,
    .nextpostslink {
      background: none;
      border: none;

      @media (--large) {
        &:hover {
          color: #1a2e56;
        }
      }
    }

    .current {
      color: #fff;
      background: #1a2e56;
    }
  }
}
