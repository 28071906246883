/* ==========================================================================
 width
========================================================================== */

.u-w-all {
  width: 100% !important;
}

@for $value from 0 to 50 {
  .u-w-#{$value} {
    width: $value + rem !important;
  }
}

@media (--mobile) {
  .u-w-sp-all {
    width: 100% !important;
  }

  @for $value from 0 to 50 {
    .u-w-sp-#{$value} {
      width: $value + rem !important;
    }
  }
}
