.p-catalogue {
  .all-ele {
    position: relative;
    display: block;
    padding: 1.5rem 1.5rem 1rem;
  }

  .all-ele__link {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: -1.5rem -1.5rem -1rem !important;

    &:hover {
      color: #8491aa;
    }
  }

  &__inner {
    .caption {
      margin-bottom: 3rem;
      font-size: 1.6rem;

      a {
        text-decoration: underline;
      }
    }
  }

  &__heading {
    display: flex;
    align-items: center;

    &:nth-child(1) {
      .u-m-c {
        color: #fff;
        background-color: #1a2e56;
      }

      svg {
        width: 1.1rem;

        path {
          stroke: #fff;
        }
      }
    }

    @media (--mobile) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .heading {
      &-text {
        @media (--mobile) {
          margin-left: 0 !important;
        }
      }
    }

    .u-m-c {
      @media (--mobile) {
        height: 5.6rem;
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .c-btn08 {
      @media (--mobile) {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }

  &__search {
    .search-content {
      display: flex;

      input {
        width: 26rem;
        padding: 0 1rem;
        background-color: #fff;
        border: 1px solid #c7ced3;
      }

      .c-btn09 {
        a {
          background-color: #1a2e56;

          span {
            color: #fff;

            &::after {
              border-top: 2px solid #fff;
              border-right: 2px solid #fff;
            }
          }
        }
      }
    }
  }

  &__list {

  }

  &__item {
    &:nth-child(n + 2) {
      margin-top: 8rem;
    }

    .item {
      &-ttl {
        margin-bottom: 3rem;
      }

      &-subttl {
        margin-bottom: 3rem;
      }

      &-list {
        display: flex;
        flex-wrap: wrap;

        @media (--mobile) {
          justify-content: space-between;
        }
      }

      &-this {
        position: relative;
        width: 27.7rem;
        border: 1px solid #1a2e56;
        transition: 0.3s;

        @media (--large) {
          &:hover {
            background-color: #1a2e56;

            .this-name {
              p {
                color: #8491aa;
              }
            }

            .light {
              color: #8491aa;

              a {
                color: #8491aa;
              }
            }
          }
        }

        a {
          display: block;
          height: 100%;
          padding: 1.5rem 1.5rem 1rem;
        }

        .ic_pdf {
          position: absolute;
          top: 1rem;
          right: 1.7rem;
          width: 2.9rem;
          height: 2.9rem;

          @media (--mobile) {
            top: 0.5rem;
            right: 0.7rem;
            width: 2rem;
            height: 2rem;
          }
        }

        @media (--mobile) {
          width: 48%;

          &:nth-child(n + 3) {
            margin-top: 3rem !important;
          }
        }

        &:not(:nth-child(4n)) {
          margin-right: 3rem;

          @media (--mobile) {
            margin-right: 0;
          }
        }

        &:nth-child(n + 5) {
          margin-top: 3rem;

          @media (--mobile) {
            margin-top: 0;
          }
        }

        &.item01 {
          min-height: 10rem;

          .this-name {
            p {
              @media (--mobile) {

              }
            }
          }
        }

        &.item02 {
          // min-height: 15rem;
          .this-name {
            line-height: 1.4;

            p {
              display: flex;
              align-items: center;

              @media (--mobile) {

              }
            }

            // align-items: flex-start;
          }
        }

        .this {
          &-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1.2rem;
            font-size: 1.8rem;
            letter-spacing: 0.1em;
            letter-spacing: 0;
            border-bottom: 1px solid #c7ced3;

            @media (--mobile) {
              flex-direction: column-reverse;
              align-items: flex-start;
              letter-spacing: 0;
            }

            p {
              font-weight: bold;

              @media (--mobile) {
                margin-top: 1rem;
                font-size: 1.4rem;
                line-height: 1.4;
              }
            }

            span {
              display: block;
              font-size: 1.3rem;
              font-weight: 500;
            }
          }

          &-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 0.8rem;

            @media (--mobile) {
              flex-direction: column-reverse;
              align-items: flex-start;
            }

            p {
              font-size: 1.3rem;
              letter-spacing: 0;
            }

            time {
              font-size: 1rem;
              color: #8491aa;
              letter-spacing: 0;

              &.new {
                color: #c41818;
              }
            }
          }
        }
      }
    }
  }
}

.p-catalogue.detail {
  .this-text {
    justify-content: right;

    &.desc {
      justify-content: space-between;

      .light {
        font-size: 1.3rem;

        a {
          position: relative;
          z-index: 2;
          padding: 0;
        }
      }
    }
  }
}
