.c-step {
  &-order {
    li {
      position: relative;
      padding-left: 3rem;
      margin-bottom: 5rem;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1;
      color: #c7ced3;
      transition: 0.3s;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 2;
        width: 1.8rem;
        height: 1.8rem;
        content: "";
        background: #c7ced3;
        border-radius: 9999px;
        transition: 0.3s;
        transform: translateY(-50%) translateY(0.1rem);
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: calc(1.8rem / 2 - 0.1rem);
        z-index: 1;
        width: 0.2rem;
        height: 6.5rem;
        content: "";
        background: #c7ced3;
        border-radius: 9999px;
        transition: 0.3s;
        transform: translateY(0);
      }

      &:first-of-type {
        &::after {
          display: none;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &.is-active {
        color: #1a2e56;

        &::after {
          background: #1a2e56;
        }

        &::before {
          background: #1a2e56;
        }
      }
    }
  }
}

.c-f-txt01,
.c-f-txtarea {
  dt {
    span {
      position: relative;

      b {
        font-size: 1.6rem;
        color: #1a2e56;
      }

      small {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.5rem;
        height: 2rem;
        font-size: 1.5rem;
        color: #fff;
        text-align: center;
        background: #1a2e56;
        transform: translateY(-50%) translateX(100%) translateX(0.8rem);

        @at-root .en & {
          width: 10rem;
          height: 2.5rem;
        }
      }
    }
  }

  dd {
    span {
      margin-top: 0.5rem;
      color: #1a2e56;
    }
  }

  dd,
  div {
    margin-top: 1.5rem;

    input,
    textarea {
      width: 100%;
      padding: 1rem 2rem;
      font-size: 1.6rem;
      border: 1px solid #c7ced3;

      &::placeholder {
        color: #c7ced3;
      }
    }

    @media (--large) {
      .c-f-txt01__short {
        width: 27rem;
      }
    }
  }
}

.c-f-txtarea {
  dd,
  div {
    textarea {
      height: 18.4rem;
    }
  }
}

.c-f-pp {
  p {
    font-size: 1.6rem;

    a {
      text-decoration: underline;

      @media (--large) {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  div {
    margin-top: 1rem;

    label {
      position: relative;
      display: inline-block;
      padding-left: 3.5rem;

      input {
        position: absolute;
        top: 50%;
        left: 0;
        width: 2.5rem;
        height: 2.5rem;
        transform: translateY(-50%);

        &::before,
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 100%;
          content: "";
          border: 1px solid #c7ced3;
        }

        &::after {
          display: none;
          background: url(../images/common/ic_check.png) no-repeat center center / contain;
          border: none;
        }

        &:checked {
          &::after {
            display: block;
          }
        }
      }

      .mwform-radio-field-text {
        font-size: 1.6rem;
      }
    }
  }
}

.c-f-radio {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;

  @media (--mobile) {
    justify-content: space-between;
    width: 100%;
  }

  & > span {
    @media (--mobile) {
      display: inline-block;
      width: 47%;
    }

    label {
      display: flex;
      align-items: center;
      width: 34rem;
      height: 7rem;
      padding-left: 2.5rem;
      border: 1px solid #c7ced3;

      @media (--mobile) {
        width: 100%;
        height: 5rem;
        padding: 0 1.5rem;
      }

      input {
        position: relative;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
        appearance: inherit;

        &::before,
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 100%;
          content: "";
          border: 1px solid #222;
          border-radius: 9999px;
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          display: none;
          width: 60%;
          height: 60%;
          background: #222;
          transform: translate(-50%, -50%);
        }

        &:checked {
          &::after {
            display: block;
          }
        }
      }
    }

    @media (--large) {
      &:nth-of-type(n + 2) {
        margin-left: 3rem;
      }
    }
  }

  .error {
    width: 100%;
    margin-left: 0 !important;
  }
}

.c-f_btn {
  @media screen and (max-width: 959px) {
    font-size: 1.5rem;
  }
}

/* ラジオボタンしたへのテキスト */
.c-f-radio {
  @media (--mobile) {
    display: block;

    & > span {
      display: block;
      width: 100%;
    }

    .horizontal-item + .horizontal-item {
      margin-top: 1rem;
      margin-left: 0 !important;
    }
  }

  &.c-f-radio-u_text {
    position: relative;

    .horizontal-item {
      &:last-of-type {
        &::after {
          display: block;
          margin-top: 0.3rem;
          font-size: 1.6rem;
          color: #8491aa;
          content: "※下記項目のご入力をお願いいたします。";
        }
      }
    }

    &.aNone {
      .horizontal-item {
        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

/* 選択制が3つの場合  */
.c-f-radio-3 {
  span {
    label {
      width: 28rem;
    }
  }
}

.c-f-comfirm {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--mobile) {
    flex-direction: column;
  }

  & > p {
    position: relative;

    @media (--mobile) {
      margin-bottom: 1rem;
    }

    small {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 5.5rem;
      height: 2rem;
      font-size: 1.5rem;
      color: #fff;
      text-align: center;
      background: #1a2e56;

      @media (--large) {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) translateX(100%) translateX(0.8rem);
      }

      @media (--mobile) {
        position: relative;
        top: 0.4rem;
        margin-left: 1rem;
      }
    }
  }

  .c-f-radio {
    span {
      label {
        width: 20rem;
      }
    }
  }
}

.c-form-magazine__btn {
  button {
    margin-left: 0 !important;

    &:nth-child(n + 2) {
      margin-left: 2rem !important;
    }
  }
}

.c-f-cation {
  margin-top: 1.5rem;

  .cation-text {
    display: block;
    font-size: 1.5rem;
    color: #8491aa;

    @media (--mobile) {
      padding-left: 1em;
      text-indent: -1em;
    }

    &__flex {
      display: flex;
    }
  }
}

.c-f-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;

  .c-btn12 {
    .u-m-c {
      justify-content: center;
      width: 58rem;

      @media (--mobile) {
        width: 100%;
      }
    }

    &:nth-child(n + 2) {
      @media (--mobile) {
        margin-top: 4rem;
      }
    }
  }

  @media (--mobile) {
    flex-direction: column;
    margin-bottom: 6rem;
  }
}

.c-f-conform {
  height: initial !important;
}

.js-step01 {
  .c-btn01 {
    button,
    a {
      position: relative;
      height: 8rem;
      font-size: 1.8rem;

      &::after {
        position: absolute;
        top: 50%;
        right: 4rem;
        width: 4rem;
        height: 1.3rem;
        content: "";
        background: url(../images/common/ic_arrow02.svg) no-repeat center center/contain;
        transition: 0.3s;
        transform: translateY(-50%) translateY(-0.2rem);
      }

      @media (--large) {
        &:hover {
          &::after {
            right: 2.5rem;
          }
        }
      }
    }
  }

  .c-ttl04 {
    position: relative;

    small {
      position: absolute;
      top: 50%;
      left: 9.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5.5rem;
      height: 2rem;
      font-size: 1.5rem;
      color: #fff;
      text-align: center;
      background: #1a2e56;
      transform: translateY(-75%);
    }
  }

  .l-btnWrap {
    display: flex;
    align-items: flex-end;

    @media (--mobile) {
      flex-direction: column;
      align-items: flex-start;
    }

    .c-btn12 {
      @media (--mobile) {
        width: 100%;
      }

      a {
        width: 40rem;
        height: 7rem;

        @media (--mobile) {
          width: 100%;
        }
      }
    }

    p {
      margin-left: 2rem;
      font-size: 1.5rem;
      color: #8491aa;
    }
  }
}

.js-step {
  .c-btn01 {
    button,
    a {
      position: relative;
      height: 8rem;
      font-size: 1.8rem;

      &::after {
        position: absolute;
        top: 50%;
        right: 4rem;
        width: 4rem;
        height: 1.3rem;
        content: "";
        background: url(../images/common/ic_arrow02.svg) no-repeat center center/contain;
        transition: 0.3s;
        transform: translateY(-50%) translateY(-0.2rem);
      }

      @media (--large) {
        &:hover {
          &::after {
            right: 2.5rem;
          }
        }
      }

      &.js-step-btn:first-of-type {
        justify-content: flex-end;

        &::before {
          right: -100%;
          left: inherit;
        }

        &::after {
          right: inherit;
          left: 4rem;
          transform: translateY(-50%) translateY(-0.2rem) scale(-1, 1);
        }

        @media (--large) {
          &:hover::before {
            right: 0;
            opacity: 1;
          }

          &:hover::after {
            left: 2.5rem;
          }
        }
      }
    }
  }
}

.error {
  margin-top: 0.5rem !important;
}

.mw_wp_form_input {
  .c-btn01.c-btn01-double {
    display: flex !important;

    @media (--mobile) {
      justify-content: space-between !important;
    }

    button,
    a {
      width: 34rem !important;

      @media (--mobile) {
        width: 47% !important;
        height: 6rem !important;
      }

      &[value="back"] {
        background: #c2c2c2 !important;

        &::before {
          background-color: #8b8b8b !important;
        }
      }

      @media (--large) {
        &:nth-of-type(n + 2) {
          margin-left: 2rem !important;
        }
      }

      @media (--mobile) {
        &::after {
          right: 2rem !important;
        }
      }
    }

    @media (--large) {
      button {
        margin-left: 2rem;
      }
    }
  }
}

.mw_wp_form_input {
  .c-d-confirm,
  .c-d-complete,
  .c-d-error {
    display: none !important;
  }

  .c-d-input {
    display: block !important;
  }

  .c-d-input_flex {
    display: flex !important;

    @media (--mobile) {
      flex-direction: column;
    }
  }

  .c-d-input.js-step-btn-back {
    display: flex !important;
  }
}

.mw_wp_form_error {
  .c-d-confirm,
  .c-d-complete,
  .c-d-input {
    display: none !important;
  }

  .c-d-input_flex {
    display: none !important;
  }

  .c-d-error {
    display: block !important;
  }

  .js-step {
    display: block !important;
  }

  .js-step-btn {
    display: none;
  }

  .c-step-order li:nth-last-of-type(n + 3) {
    color: #1a2e56 !important;

    &::after {
      background: #1a2e56 !important;
    }

    &::before {
      background: #1a2e56 !important;
    }
  }
}

.mw_wp_form_confirm {
  .c-d-input,
  .c-d-input_flex,
  .c-d-complete,
  .c-d-error {
    display: none !important;
  }

  .c-d-confirm {
    display: block !important;
  }

  .js-step {
    display: block !important;
  }

  .js-step-btn {
    display: none;
  }

  .c-f-txt01 dd,
  .c-f-radio,
  .c-f-pp div,
  .c-f-txtarea div {
    padding: 1rem;
    background: #fafafa;
  }

  .c-btn01 {
    display: flex;

    @media (--mobile) {
      justify-content: space-between;
    }

    button,
    a {
      width: 34rem;

      @media (--mobile) {
        width: 47%;
        height: 6rem;
      }

      &[value="back"] {
        justify-content: flex-end;
        background: #c2c2c2;

        &::before {
          right: -100%;
          left: inherit;
          background-color: #8b8b8b;
        }

        &::after {
          right: inherit;
          left: 4rem;
          transform: translateY(-50%) translateY(-0.2rem) scale(-1, 1);
        }

        @media (--large) {
          &:hover::before {
            right: 0;
            opacity: 1;
          }

          &:hover::after {
            left: 2.5rem;
          }
        }
      }

      @media (--large) {
        &:nth-of-type(n + 2) {
          margin-left: 2rem;
        }
      }

      @media (--mobile) {
        &::after {
          right: 2rem;
        }
      }
    }
  }

  .c-step-order li:nth-last-of-type(n + 2) {
    color: #1a2e56 !important;

    &::after {
      background: #1a2e56 !important;
    }

    &::before {
      background: #1a2e56 !important;
    }
  }
}

.mw_wp_form_complete {
  .c-d-input,
  .c-d-confirm,
  .c-d-input_flex,
  .c-d-error {
    display: none !important;
  }

  .c-d-complete {
    display: block !important;
  }

  .c-step-order li {
    color: #1a2e56 !important;

    &::after {
      background: #1a2e56 !important;
    }

    &::before {
      background: #1a2e56 !important;
    }
  }
}

.mw_wp_form_error {
  .c-f-conform {
    height: 8rem !important;
  }
}
