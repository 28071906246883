.p-sProduct {
  .l-top {
    display: flex;

    @media (--large) {
      justify-content: space-between;
    }

    @media (--mobile) {
      display: block;
    }

    &__left {
      width: 54rem;

      @media (--mobile) {
        width: 100%;
      }
    }

    &__right {
      width: calc(100% - 54rem);
      padding-left: 6rem;

      @media (--mobile) {
        width: 100%;
        padding-left: 0;
      }

      img {
        width: 100%;
      }
    }

    &__tag {
      display: flex;

      span {
        padding: 0 0.8rem;
        font-size: 1.4rem;
        color: #fff;
        background: #1a2e56;
      }

      a {
        margin-left: 1rem;
        font-size: 1.4rem;
        font-weight: bold;
      }
    }

    &__ttl {
      padding-bottom: 1.5rem;
      margin-top: 0.2rem;
      border-bottom: 3px solid #1a2e56;

      @media (--mobile) {
        border-bottom-width: 1px;
      }

      * {
        display: block;
      }

      b {
        font-size: 4rem;
        line-height: 1.5;

        @media (--mobile) {
          font-size: 3.2rem;
        }
      }

      small {
        font-size: 2rem;
        font-weight: 500;

        @media (--mobile) {
          font-size: 1.6rem;
        }
      }
    }

    &__code {
      &-inner {
        display: flex;

        @media (--mobile) {
          flex-direction: column;
        }
      }

      margin-top: 2.5rem;

      @media (--mobile) {
        margin-top: 1.5rem;
      }

      * {
        display: block;
      }

      small {
        font-size: 1.2rem;
      }

      b {
        font-size: 2.8rem;
        line-height: 1.5;
      }
    }
  }

  .l-anchor {
    a {
      position: relative;
      display: block;
      font-size: 1.3rem;
      font-weight: bold;

      svg {
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.7rem;
        height: 1.4rem;
        transform: translateY(-50%);
      }

      &:nth-of-type(n + 2) {
        margin-top: 3rem;
      }
    }
  }

  .l-btnArea {
    display: flex;
    flex-wrap: wrap;

    @media (--large) {
      margin: 0 -1.5rem;
    }

    @media (--mobile) {
      display: block;
    }

    .c-btn08 {
      width: calc(100% / 3 - 3rem);
      margin: 0 1.5rem;

      @media (--mobile) {
        width: 100%;
        margin: 0;

        &:nth-of-type(n + 2) {
          margin-top: 3rem;
        }
      }

      @media (--large) {
        &:nth-of-type(n + 4) {
          margin-top: 3rem;
        }
      }

      a {
        width: 100%;

        @media (--mobile) {
          height: 6rem;
        }
      }
    }
  }

  .l-btHalf {
    @media (--large) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .c-btn12 {
      @media (--large) {
        width: calc(100% / 2 - 1.5rem) !important;
      }

      &,
      a {
        width: 100%;
        font-size: 2.4rem;

        @media (--large) {
          height: 8rem;
        }

        @media (--mobile) {
          height: 6rem;
        }
      }

      a {
        @media (--mobile) {
          padding: 2rem;
          padding-left: 3.4rem !important;
          font-size: 2rem;
          font-weight: bold;
        }
      }
    }
  }

  .l-btnAll {
    .c-btn12 {
      &,
      a {
        width: 100%;
        font-size: 2.4rem;
      }
    }
  }

  @media (--large) {
    .c-table02 {
      dl {
        dt {
          width: 25%;
        }
      }

      dd {
        width: 75%;
      }
    }
  }

  .c-table01 {
    table {
      @media (--mobile) {
        width: 50rem;
      }
    }
  }
}

.leaflet-container {
  z-index: 2 !important;
  outline: none !important;
}
