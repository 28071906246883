.p-inquiry {
  margin-bottom: 14rem;

  @media (--mobile) {
    margin-bottom: 7rem;
  }

  &-box {
    width: 100%;
    padding: 5rem 9rem;
    margin-top: 6rem;
    border: 0.1rem solid #1a2e56;

    @media (--mobile) {
      padding: 2rem 3rem;
      margin-top: 3rem;
    }

    &__title {
      font-size: 2.4rem;
      text-align: center;
    }

    &__inner {
      margin-top: 2rem;

      @media (--mobile) {
        margin-top: 2rem;
      }
    }

    &__content {
      .img {
        text-align: center;

        img {
          width: 8.2rem;
        }
      }

      .btn {
        margin-top: 2rem;

        @media (--mobile) {
          margin-top: 4rem;
        }
      }
    }
  }

  &-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4rem;

    @media (--mobile) {
      display: block;
      margin-top: 3rem;
    }

    &__cell {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5rem 0;
      border: 0.1rem solid #1a2e56;

      @media (--mobile) {
        padding: 3rem 0;
      }

      &:first-of-type {
        width: 58rem;

        @media (--mobile) {
          width: 100%;
          margin-right: 0;
        }
      }

      &:nth-of-type(2) {
        width: 58rem;

        @media (--mobile) {
          width: 100%;
          margin-top: 2rem;
        }
      }

      &:nth-of-type(n + 3) {
        width: 58rem;
        margin-top: 4rem;

        @media (--mobile) {
          width: 100%;
          margin-top: 2rem;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      .title {
        font-size: 2.4rem;
        color: #1a2e56;

        @media (--mobile) {
          font-size: 2rem;
        }
      }

      .img {
        margin-top: 4rem;

        @media (--mobile) {
          margin-top: 2rem;
        }

        img {
          width: 8.2rem;
        }
      }

      .btn-content {
        display: flex;
        margin-top: 2rem;

        @media (--mobile) {
          display: block;
          margin-top: 0;
        }

        .btn {
          &:first-of-type {
            margin-right: 3rem;

            @media (--mobile) {
              margin-right: 0;
            }
          }
        }
      }

      .btn {
        margin-top: 4rem;

        @media (--mobile) {
          &:first-of-type {
            margin-top: 4rem;
          }

          margin-top: 2rem;
        }
      }
    }
  }

  .c-btn07 a {
    @media (--mobile) {
      width: 23rem;
      height: 5rem;
    }
  }

  .u-m-c {
    @media (--mobile) {
      font-size: 1.4rem;
    }
  }
}
