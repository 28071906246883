.p-beginner {
  margin-bottom: 16rem;

  .about {
    figure {
      width: 100%;
      height: 34rem;
      margin-top: 4rem;

      @media (--mobile) {
        height: 22rem;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      letter-spacing: 0.1em;
    }
  }

  .feature {
    .c-product-side {
      display: flex;
      height: 27rem;
      background: linear-gradient(to right, #00012b, #000);

      @media (--mobile) {
        display: block;
        width: 100vw;
        height: 100%;
        margin: 0 calc(50% - 50vw);
        margin-bottom: 0.2rem;
      }

      @media (--large) {
        @at-root .en & {
          position: relative;
          display: block;
        }
      }

      &__left {
        color: #fff;

        @media (--mobile) {
          width: 33rem;
          padding-top: 5rem;
          margin: 0 auto;
        }

        @media (--large) {
          display: flex;
          align-items: center;
          width: 50%;
          height: 23rem;
          padding-left: 8rem;

          @at-root .en & {
            position: absolute;
            top: 50%;
            left: 4rem;
            display: block;
            width: 70%;
            height: auto;
            padding-left: 0;
            transform: translateY(-50%);
          }
        }

        h4 {
          font-size: 3.2rem;
          font-weight: 500;
          color: #fff;
          letter-spacing: 0.1em;

          @media (--mobile) {
            font-size: 2.6rem;
          }
        }

        ul {
          li {
            position: relative;
            padding-left: 1rem;

            &::before {
              position: absolute;
              top: 0;
              left: 0;
              content: "・";
            }
          }
        }

        p {
          line-height: 1.8;
        }

        .c-btn09 {
          @media (--mobile) {
            margin-bottom: 2rem;
          }

          a {
            width: 20rem;
            height: 5rem;
            border: 1px solid #1a2e56;

            @media (--large) {
              padding-right: 0;
            }

            span {
              &::after {
                right: -3rem;
              }
            }
          }
        }
      }

      &__right {
        @media (--large) {
          width: 50%;

          @at-root .en & {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 40%;
          }
        }

        @media (--mobile) {
          margin-top: 4rem;
        }

        picture {
          // width: 55rem;
          // height: 32rem;

          @media (--mobile) {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;

    // margin-top: 6rem;

    @media (--mobile) {
      display: block;
      margin-top: 3rem;
    }
  }

  &-content {
    @media (--mobile) {
      width: 100%;
    }
  }

  &-side {
    ul {
      li {
        &:nth-of-type(n + 2) {
          margin-top: 2rem;
        }

        a {
          position: relative;
          display: block;
          font-size: 1.2rem;

          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 0.8rem;
            height: 0.8rem;
            content: "";
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            transform: translateY(-50%) rotate(45deg);
          }
        }
      }
    }
  }

  // &-side {
  //   ul {
  //     position: sticky;
  //     top: 12rem;

  //     @media (--mobile) {
  //       display: none;
  //     }

  //     li {
  //       font-size: 1.3rem;
  //       font-weight: bold;

  //       a {
  //         position: relative;
  //         display: block;

  //         &::after {
  //           position: absolute;
  //           top: 50%;
  //           right: -2rem;
  //           width: 1rem;
  //           height: 1rem;
  //           content: "";
  //           border-top: 2px solid #384eaa;
  //           border-right: 2px solid #384eaa;
  //           transform: translateY(-50%) rotate(45deg);
  //         }
  //       }

  //       &:nth-child(n + 2) {
  //         margin-top: 3rem;
  //       }
  //     }
  //   }
  // }

  &-flex {
    display: flex;

    @media (--mobile) {
      display: block;
    }

    .left-content {
      width: calc(100% - 28rem - 6rem);
      margin-right: 6rem;

      @media (--mobile) {
        width: 100%;
        margin-right: 0;
      }

      p {
        font-size: 1.6rem;

        &:nth-child(n + 2) {
          margin-top: 3rem;

          @media (--mobile) {
            margin-top: 1.5rem;
          }
        }
      }
    }

    .right-img {
      width: 28rem;

      @media (--mobile) {
        width: 100%;
        margin-top: 4rem;
      }

      .img {
        &:nth-child(n + 2) {
          margin-top: 4rem;
        }
      }
    }
  }

  &-inner {
    .img-content {
      width: 65rem;
      margin: 0 auto;

      @media (--mobile) {
        width: 100%;
      }
    }

    &__text {
      p {
        font-size: 1.6rem;

        &:first-of-type {
          margin-top: 5rem;

          @media (--mobile) {
            margin-top: 2rem;
          }
        }

        &:nth-child(n + 2) {
          margin-top: 3rem;

          @media (--mobile) {
            margin-top: 1.5rem;
          }
        }
      }
    }

    &__content {
      display: flex;

      @media (--mobile) {
        display: block;
      }

      .left-content {
        width: calc(100% - 38rem - 3rem);
        margin-right: 3rem;

        @media (--mobile) {
          width: 100%;
          margin-right: 0;
        }

        p {
          font-size: 1.6rem;
        }
      }

      .right-img {
        width: 38rem;

        @media (--large) {
          padding-left: 4rem;
        }

        @media (--mobile) {
          width: 100%;
          margin-top: 4rem;
        }

        .img {
          @media (--mobile) {
            text-align: center;
          }
        }

        .text {
          margin-top: 1rem;
          font-size: 1.6rem;
          text-align: right;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 5rem;

      li {
        width: 48%;

        @media (--mobile) {
          width: 100%;
        }

        @media (--large) {
          &:nth-child(n + 3) {
            margin-top: 8rem;
          }
        }

        @media (--mobile) {
          &:nth-child(n + 2) {
            margin-top: 4rem;
          }
        }
        .img{}

        .text {
          margin-top: 1.4rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56;

          @media (--mobile) {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
