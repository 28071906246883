.c-btn01,
.c-btn02,
.c-btn03,
.c-btn04,
.c-btn05,
.c-btn06,
.c-btn07,
.c-btn08,
.c-btn09,
.c-btn10,
.c-btn11,
.c-btn12,
.c-btn13 {
  a,
  button {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    width: 38rem;
    height: 10rem;
    padding: 0 4rem;
    font-size: 2.2rem;
    color: #fff;
    background: #1a2e56;

    @media (--mobile) {
      width: 80%;
      height: 8rem;
      padding: 2rem;
      font-size: 2rem;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 3rem;
      width: 3.5rem;
      height: 1rem;
      transform: translateY(-50%) translateY(-0.2rem);

      @media (--mobile) {
        right: 2rem;
        transform: translateY(-50%) translateY(-0.3rem);
      }
    }
  }
}

[class*="c-btn-hover"] {
  position: relative;
  overflow: hidden;
}

.c-btn-hover01 {
  a,
  button {
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #0058a2;
      opacity: 0;
      transition: 0.3s;
    }

    svg {
      transition: 0.3s all;
    }

    @media (--large) {
      &:hover {
        svg {
          right: 2rem;
        }
      }

      &:hover::before {
        left: 0;
        opacity: 1;
      }
    }
  }
}

.c-btn-hover01--right {
  a,
  button {
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #0058a2;
      opacity: 0;
      transition: 0.3s;
    }

    svg {
      transition: 0.3s all;
    }

    @media (--large) {
      &:hover {
        svg {
          right: 1rem;
        }
      }

      &:hover::before {
        left: 0;
        opacity: 1;
      }
    }
  }
}

.c-btn-hover02 {
  a {
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      right: -100%;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #0058a2;
      opacity: 0;
      transition: 0.3s;
    }

    svg {
      transition: 0.3s all;
    }

    @media (--large) {
      &:hover {
        svg {
          right: 0;
          left: 2rem;
        }
      }

      &:hover::before {
        right: 0;
        opacity: 1;
      }
    }
  }
}

.c-btn-hover03 {
  a {
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      right: -100%;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #0058a2;
      opacity: 0;
      transition: 0.3s;
    }

    svg {
      transition: 0.3s all;
    }

    @media (--large) {
      &:hover {
        color: #fff;

        svg {
          * {
            stroke: #fff;
          }
        }
      }

      &:hover::before {
        right: 0;
        opacity: 1;
      }
    }
  }
}

.c-btn-hover04 {
  a {
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #0058a2;
      opacity: 0;
      transition: 0.3s;
    }

    svg {
      transition: 0.3s all;
    }

    @media (--large) {
      &:hover {
        color: #fff;

        span {
          color: #fff;

          &::after {
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
          }
        }

        svg {
          * {
            stroke: #fff;
          }
        }
      }

      &:hover::before {
        left: 0;
        opacity: 1;
      }

      &:hover::after {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }
}

.c-btn-hover05 {
  a {
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #0058a2;
      opacity: 0;
      transition: 0.3s;
    }

    svg {
      transition: 0.3s all;
    }

    @media (--large) {
      &:hover {
        color: #fff;

        span {
          color: #fff;

          &::after {
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
          }
        }

        svg {
          * {
            fill: #fff;
            stroke: #fff;
          }
        }
      }

      &:hover::before {
        left: 0;
        opacity: 1;
      }

      &:hover::after {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }
}

.c-btn02 {
  a {
    justify-content: flex-end;

    svg {
      right: inherit;
      left: 3rem;
      transform: translateY(-50%) translateY(-0.3rem) scale(-1, 1) !important;
    }
  }
}

.c-btn03 {
  a {
    justify-content: center;

    svg {
      display: none;
    }
  }
}

.c-btn04 {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    padding: 0;
    font-size: 1.6rem;

    svg {
      position: static;
      width: 1rem;
      height: inherit;
      margin-left: 1rem;
      transform: inherit;

      @media (--mobile) {
        position: absolute;
        right: 2rem;
      }

      * {
        stroke: #fff;
      }
    }
  }
}

.c-btn05 {
  a {
    justify-content: center;
    width: 12rem;
    padding: 0;
    font-size: 1.6rem;

    svg {
      position: static;
      width: 1rem;
      height: inherit;
      margin-right: 1rem;
      transform: inherit;

      @media (--mobile) {
        position: absolute;
        left: 2rem;
      }

      * {
        stroke: #fff;
      }
    }
  }
}

.c-btn06 {
  a {
    width: 46rem;
    font-size: 1.9rem;
    color: #1a2e56;
    background-color: #fff;
    border: 0.1rem solid #1a2e56;

    @media (--mobile) {
      padding-left: 6rem;
    }

    svg {
      position: static;
      width: 1rem;
      height: inherit;
      margin-right: 4rem;
      transform: inherit;

      @media (--mobile) {
        position: absolute;
        left: 2rem;
      }

      * {
        stroke: #1a2e56;
      }
    }
  }
}

.c-btn07 {
  a {
    position: relative;
    justify-content: center;
    width: 23rem;
    height: 5rem;
    padding: 0;
    margin: 0 auto;
    font-size: 1.6rem;
    color: #1a2e56;
    background-color: #fff;
    border: 0.1rem solid #1a2e56;
    border-radius: 9999px;

    &::after {
      position: absolute;
      top: 50%;
      right: 2rem;
      width: 1rem;
      height: 1rem;
      content: "";
      border-top: 2px solid #1a2e56;
      border-right: 2px solid #1a2e56;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.c-btn08 {
  a {
    justify-content: left;
    width: 25rem;
    height: 6rem;
    padding-left: 3.4rem !important;
    font-size: 1.6rem;
    font-weight: bold;
    color: #1a2e56;
    background-color: #fff;
    border: 0.1rem solid #1a2e56;

    svg {
      position: absolute;
      top: 50%;
      right: 2.7rem !important;
      width: 2rem;
      height: inherit;
      transform: translateY(-50%) !important;

      @media (--mobile) {
        position: absolute;
      }

      * {
        stroke: #1a2e56;
      }
    }
  }
}

.c-btn09 {
  a {
    justify-content: center;
    width: 17rem;
    height: 5rem;
    padding: 0;
    padding-right: 2rem;
    background-color: #fff;
    border: 0.1rem solid #1a2e56;

    span {
      position: relative;
      font-size: 1.6rem;
      color: #1a2e56;

      &::after {
        position: absolute;
        top: 50%;
        right: -2rem;
        width: 1rem;
        height: 1rem;
        content: "";
        border-top: 2px solid #1a2e56;
        border-right: 2px solid #1a2e56;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}

.c-btn10 {
  a {
    justify-content: flex-start;
    width: 38rem;
    height: 8rem;
    padding: 0 0 0 3rem;
    font-size: 1.6rem;
    font-weight: bold;
    color: #1a2e56;
    background-color: #fff;
    border: 0.1rem solid #1a2e56;

    @media (--mobile) {
      width: 100%;
      padding-left: 5rem;
      font-size: 1.6rem;
    }

    svg {
      position: static;
      width: 2rem;
      height: inherit;
      margin-right: 1.5rem;
      transform: inherit;

      @media (--mobile) {
        position: absolute;
        left: 2rem;
        transform: translateY(-50%);
      }

      * {
        stroke: #1a2e56;
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 2rem;
      width: 1rem;
      height: 1rem;
      content: "";
      border-top: 2px solid #1a2e56;
      border-right: 2px solid #1a2e56;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.c-btn11 {
  a {
    justify-content: flex-start;
    width: 38rem;
    height: 8rem;
    padding: 0 0 0 3rem;
    font-size: 1.6rem;
    font-weight: bold;
    color: #1a2e56;
    background-color: #fff;
    border: 0.1rem solid #1a2e56;

    @media (--mobile) {
      width: 100%;
      padding-left: 5rem;
      font-size: 1.6rem;
    }

    svg {
      position: static;
      width: 2rem;
      height: inherit;
      margin-right: 1.5rem;
      transform: inherit;

      @media (--mobile) {
        position: absolute;
        left: 2rem;
        transform: translateY(-50%);
      }

      * {
        stroke: #1a2e56;
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 2rem;
      width: 1rem;
      height: 1rem;
      content: "";
      border-top: 2px solid #1a2e56;
      border-right: 2px solid #1a2e56;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.c-btn12 {
  a {
    position: relative;
    width: 46rem;
    font-size: 1.9rem;
    color: #1a2e56;
    background-color: #fff;
    border: 0.1rem solid #1a2e56;

    svg {
      position: absolute;
      top: 50%;
      width: 1rem;
      height: inherit;
      transform: translateY(-50%) rotate(180deg);

      @media (--mobile) {
        transform: translateY(-50%) rotate(180deg);
      }

      * {
        stroke: #1a2e56;
      }
    }
  }
}

.c-btn13 {
  a {
    position: relative;
    width: 32rem;
    height: 7rem;
    font-size: 1.6rem;
    color: #1a2e56;
    background-color: #fff;
    border: 0.2rem solid #1a2e56;

    @media (--mobile) {
      width: 100%;
    }

    svg {
      position: absolute;
      top: 50%;
      width: 2rem;
      height: inherit;

      * {
        stroke: #1a2e56;
      }
    }
  }
}
