.c-mv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22rem;
  margin-bottom: 6rem;
  overflow: hidden;
  color: #fff;
  background: #00012b;

  @media (--mobile) {
    height: 16rem;
    margin-bottom: 4rem;
  }

  h1,
  h2 {
    font-size: 3.2rem;
    letter-spacing: 0.1em;

    @media (--mobile) {
      font-size: 2.4rem;
    }
  }

  &--mini {
    height: 6rem;

    h1,
    h2 {
      font-size: 2rem;
    }
  }
}
