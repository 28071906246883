.p-espert500 {
  .c-product-spec-img {
    width: 74.1rem;
    max-width: 100%;
  }

  &__list {
    ul {
      display: flex;

      @media (--mobile) {
        display: block;
      }

      li {
        &:first-child {
          margin-right: 4rem;

          @media (--mobile) {
            margin-right: 0;
            margin-bottom: 2rem;
          }
        }

        .pic {
          margin-bottom: 2rem;

          @media (--mobile) {
            margin-bottom: 1rem;
          }
        }

        .text {
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56;
        }
      }
    }
  }

  .c-ttl04 {
    span {
      display: inline-block;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      border: 0.1rem solid #1a2e56;

      @media (--mobile) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
