.p-sManual {
  .s-wrap {
    display: flex;
    justify-content: space-between;

    @media (--mobile) {
      display: block;
    }

    &__left {
      width: 100%;

      dl {
        display: flex;
        flex-wrap: wrap;

        *:nth-child(n + 3) {
          margin-top: 1rem;
        }

        dt {
          width: 12rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56;
        }

        dd {
          width: calc(100% - 12rem);
        }
      }
    }

    &__right {
      flex-shrink: 0;
      width: calc(28rem + 5.5rem);
      padding-left: 5.5rem;

      @media (--mobile) {
        width: 100%;
        padding-left: 0;
        margin-top: 3rem;
      }

      figure {
        border: 1px solid #dedede;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .s-btn {
    display: flex;

    @media (--mobile) {
      justify-content: space-between;
    }

    .c-btn02,
    .c-btn03 {
      @media (--mobile) {
        width: 48%;
      }

      a {
        width: 20rem;
        height: 6rem;
        padding: 0 2rem;
        font-size: 1.6rem;

        @media (--mobile) {
          width: 100%;
          padding: 0;
        }

        svg {
          left: 2rem;
        }
      }
    }

    @media (--mobile) {
      .c-btn02 {
        a {
          padding-right: 1.5rem !important;

          svg {
            width: 2.4rem;
          }
        }
      }
    }
  }

  .s-contact {
    padding-top: 5.6rem;
    border-top: 1px solid #8491aa;

    @media (--mobile) {
      padding-top: 4rem;
    }

    &-ttl {
      text-align: center;
    }

    &-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--mobile) {
        display: block;
      }
    }

    &-left {
      padding-right: 4.7rem;
      border-right: 1px solid #c7ced3;

      @media (--mobile) {
        padding-right: 0;
        background-repeat: 0;
      }

      .c-btn10 {
        a {
          width: 33rem;
          font-size: 2rem;
          font-weight: 500;

          @media (--mobile) {
            width: 100%;
            height: 6rem;
            padding: 1.5rem 0 1.5rem 7rem;
          }

          svg {
            width: 2.8rem;
          }

          &:hover {
            svg {
              * {
                fill: #fff;
                stroke: none;
              }
            }
          }
        }
      }
    }

    &-right {
      padding-left: 4.7rem;

      @media (--mobile) {
        padding-left: 0;
      }

      h4 {
        display: flex;
        align-items: center;
        font-size: 2.2rem;
        font-weight: bold;
        color: #1a2e56;

        @media (--mobile) {
          justify-content: center;
          padding-right: 1rem;
        }

        svg {
          width: 4rem;
          margin-right: 1rem;
        }
      }

      &__tel {
        display: flex;
        align-items: center;

        @media (--mobile) {
          display: block;
        }

        & > div {
          display: flex;
          align-items: center;

          @media (--large) {
            &:nth-of-type(n + 2) {
              margin-left: 2.4rem;
            }
          }

          @media (--mobile) {
            justify-content: center;
          }

          span {
            flex-shrink: 0;
            padding: 0 0.8rem;
            margin-right: 1rem;
            font-size: 1.6rem;
            color: #fff;
            background: #1a2e56;
          }

          b,
          a {
            font-size: 2.2rem;
            color: #222;
          }
        }
      }
    }
  }
}
