.l-footer {
  padding-top: 12rem;
  padding-bottom: 5.6rem;
  background: #222;

  @media (--mobile) {
    padding: 5rem 0 2.5rem;
  }

  * {
    color: #fff;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (--mobile) {
      display: block;
    }
  }

  &-left {
    width: 28rem;

    @media (--mobile) {
      width: 100%;
    }
  }

  &-right {
    width: 80rem;

    @media (--mobile) {
      width: 100%;
    }
  }

  &-logo {
    width: 22rem;

    @media (--mobile) {
      margin-right: auto;
      margin-left: auto;
    }

    svg {
      * {
        fill: #fff;
      }
    }
  }

  &-btnArea {
    margin-top: 3.7rem;

    @media (--mobile) {
      width: 28rem;
      margin-top: 4rem;
      margin-right: auto;
      margin-left: auto;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      width: 100% !important;
      height: 6rem !important;
      padding: 0 1.6rem !important;
      font-size: 1.6rem !important;
      color: #fff !important;
      background: none !important;
      border: 1px solid #fff !important;

      svg {
        position: absolute;
        top: 50% !important;
        right: 1.5rem !important;
        width: 0.5rem !important;
        width: 0.7rem !important;
        transform: translateY(-50%) rotate(180deg) !important;

        @media (--mobile) {
          transform: translateY(-50%) rotate(0) !important;
        }

        * {
          stroke: #fff !important;
        }
      }

      @media (--mobile) {
        &:nth-of-type(n + 2) {
          margin-top: 2rem;
        }
      }
    }

    div {
      &:nth-of-type(n + 2) {
        margin-top: 2rem;
      }
    }
  }

  &-bnrArea {
    margin-top: 3.5rem;

    @media (--mobile) {
      width: 28rem;
      margin-top: 4.8rem;
      margin-right: auto;
      margin-left: auto;
    }

    a {
      display: inline-block;
      margin-top: 1.5rem;
      transition: 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 1.4rem;
    }
  }

  &-search {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (--mobile) {
      justify-content: center;
    }

    * {
      color: inherit;
    }

    @media (--mobile) {
      margin-top: 3.2rem;
    }

    .gsc-control-cse {
      position: relative;
      width: 28rem;
      padding: 0;
      background: none;
      border: none;

      @media (--mobile) {
        width: 100%;
      }
    }

    .gsc-control-cse,
    .gsc-input-box,
    .gsib_a {
      padding: 0;
      background: none;
      border: none;

      input {
        height: inherit !important;
        padding: 1rem 2rem !important;
        margin: 0 !important;
        font-size: 1.3rem !important;
        color: #000 !important;
        background: #fff !important;
        border-radius: 9999px !important;

        @media (--mobile) {
          padding: 1.5rem 2rem !important;
        }

        &::placeholder {
          color: #9b9b9b;
        }
      }
    }

    .gsib_b {
      display: none;
    }

    table.gsc-search-box {
      margin-bottom: 0;
    }

    table.gsc-search-box td.gsc-input {
      padding: 0;
    }

    .gsc-search-button {
      margin-left: 0;
    }

    form.gsc-search-box {
      margin-bottom: 0 !important;
    }

    button {
      position: absolute;
      top: 50%;
      right: 2.5rem;
      z-index: 2;
      padding: 0;
      background: none !important;
      border: none;
      outline: none;
      transform: translateY(-50%);

      svg {
        width: 2rem;
        height: 2rem;

        * {
          fill: #0f4b71;
        }
      }
    }

    // @media (--mobile) {
    //   margin-top: 3.2rem;
    // }

    // label {
    //   position: relative;
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-start;
    //   width: 28rem;
    //   height: 4.7rem;
    //   padding-left: 2rem;
    //   background: #fff;
    //   border-radius: 999rem;

    //   @media (--mobile) {
    //     width: calc(100vw - 6rem);
    //     margin-right: auto;
    //     margin-left: auto;
    //   }

    //   input {
    //     width: 20rem;
    //     font-size: 1.3rem;
    //     color: #222;

    //     &::placeholder {
    //       color: #9b9b9b;

    //       @media (--mobile) {
    //         font-size: 1.5rem;
    //       }
    //     }
    //   }
    // }

    // &__button {
    //   position: absolute;
    //   top: 50%;
    //   right: 2.2rem;
    //   display: flex;
    //   padding: 0;
    //   transform: translateY(-50%);

    //   svg {
    //     width: 1.6rem;

    //     * {
    //       fill: #1a2e56;
    //     }
    //   }
    // }
  }

  &-sns {
    display: flex;
    align-items: center;

    @media (--mobile) {
      justify-content: flex-end;
    }

    p {
      font-family: $robot;
      font-size: 1.4rem;
      color: #b2b2b2;
    }

    ul {
      display: flex;
      margin-left: 1.5rem;

      li {
        height: 2.8rem;

        &:nth-child(n + 2) {
          margin-left: 1rem;
        }

        @media (--mobile) {
          svg {
            height: 2.8rem;
          }
        }
      }
    }
  }

  &-topNav {
    margin-top: 4rem;
  }

  &-topList {
    display: flex;
    flex-wrap: wrap;

    @at-root .en & {
      margin-right: -2.5rem;
      margin-left: -2.5rem;
    }
  }

  &-topEle {
    width: calc(100% / 4);

    @at-root .en & {
      width: inherit;
      margin: 0 2.5rem;
    }

    @media (--large) {
      &:nth-of-type(n + 5) {
        margin-top: 1.5rem;

        @at-root .en & {
          margin-top: 0;
        }
      }
    }

    @media (--mobile) {
      width: calc(100% / 2);

      &:nth-of-type(n + 3) {
        margin-top: 1rem;
      }
    }

    @media (--tablet) {
      width: calc(100% / 3);

      &:nth-of-type(n + 5) {
        margin-top: 0;
      }

      &:nth-of-type(n + 4) {
        margin-top: 1.5rem;
      }
    }
  }

  &-topLink {
    display: inline-block;
    padding-bottom: 0.5rem;
    font-size: 1.3rem;

    @media (--mobile) {
      font-size: 1.4rem;
      letter-spacing: 0;
      white-space: nowrap;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  &-icon {
    width: 2.2rem;
    margin-right: 0.5rem;

    img {
      width: 100%;
    }
  }

  &-bArea {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2.5rem;
    margin-top: 5rem;

    @media (--mobile) {
      margin: 0;
      margin-top: 4.2rem;
      border-top: 1px solid unquote("rgb(255 255 255 / 20%)");
      border-bottom: 1px solid unquote("rgb(255 255 255 / 20%)");
    }

    &__nav {
      width: 23.2rem;
      margin: 0 2.5rem;

      @media (--large) {
        &:nth-of-type(n + 4) {
          margin-top: 5.1rem;
        }
      }

      @media (--mobile) {
        width: 100%;
        padding: 0 1rem;
        margin: 0;

        &:nth-of-type(n + 2) {
          border-top: 1px solid unquote("rgb(255 255 255 / 20%)");
        }
      }

      & > a {
        @media (--mobile) {
          position: relative;
          display: block;

          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &__sTtl {
      position: relative;
      display: block;
      padding-bottom: 1.5rem;
      margin-bottom: 1.2rem;
      font-size: 1.6rem;
      font-weight: 500;
      border-bottom: 1px solid unquote("rgb(255 255 255 / 20%)");

      @media (--large) {
        &.pc-pn {
          pointer-events: none;
        }
      }

      @media (--mobile) {
        padding: 2.4rem 0;
        margin-bottom: 0;
        font-size: 1.6rem;
        border-bottom: none;

        &.is-active {
          .l-footer-bArea__trigger {
            transform: translateY(-50%) rotate(270deg);
          }
        }
      }

      @media (--large) {
        svg {
          position: absolute;
          top: 0.8rem;
          right: 0;
          width: 0.8rem;

          * {
            stroke: #fff;
          }
        }
      }
    }

    @media (--mobile) {
      &__trigger {
        position: absolute;
        top: 50%;
        right: 1rem;
        display: inline-block;
        width: 1rem;
        transition: 0.3s transform;
        transform: translateY(-50%) rotate(90deg);

        &--link {
          transform: translateY(-50%);
        }

        * {
          stroke: #fff;
        }
      }

      &__list {
        display: none;
        padding-right: 0.5rem;
        padding-bottom: 2.4rem;
        padding-left: 0.5rem;
      }
    }

    &__el {
      &:nth-of-type(n + 2) {
        margin-top: 0.2rem;

        @media (--mobile) {
          margin-top: 1rem;
        }
      }
    }

    &__link {
      display: inline-block;
      padding-bottom: 0.3rem;

      &,
      * {
        font-size: 1.4rem;
        color: #b2b2b2;
      }
    }
  }

  &__pr {
    width: 100%;
    margin-top: 4.6rem;
    font-size: 1.2rem;
    color: #b2b2b2;
    text-align: right;

    @media (--mobile) {
      margin-top: 4.8rem;
      text-align: center;
    }
  }
}
