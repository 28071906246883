.p-moter_spindle {
  @media (--mobile) {
    .c-product-mv {
      padding-bottom: 7rem;
    }
  }

  .c-product-mv__left {
    width: 56rem;

    @media (--mobile) {
      width: inherit;
    }
  }

  .c-product-mv-mTxt {
    font-size: 1.6rem;
  }

  .c-product-mv-sTxt {
    font-size: 1.6rem;
  }

  @media (--large) {
    .c-product-mv__pImg {
      position: absolute;
      top: 50%;
      right: 0;
      width: 50%;
      transform: translateY(-50%);
    }
  }

  &-list01 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    @media (--mobile) {
      display: block;
      margin: 0;
    }

    &-el {
      position: relative;
      width: calc(100% / 2 - 2rem);
      min-height: 27rem;
      margin: 0 1rem;
      background: linear-gradient(to left, #000, #00012b);

      @media (--mobile) {
        width: 100%;
        height: 19rem;
        min-height: initial;
        margin: 0 0 2rem;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 2rem;

        * {
          position: relative;
          z-index: 2;
          padding-left: 0.8rem;
          color: #fff;

          @media (--mobile) {
            padding-left: 0;
          }
        }

        img {
          position: absolute;
          z-index: 1;
          padding-left: 0;

          &.e4000 {
            top: 2.3rem;
            right: -2.2rem;
            width: 26rem;
            transform: rotate(-90deg);

            @media (--mobile) {
              top: 1.5rem;
              right: -1.4rem;
              width: 16rem;
            }
          }

          &.e3000 {
            top: 2.9rem;
            right: -2.9rem;
            width: 26rem;
            transform: rotate(-90deg);

            @media (--mobile) {
              top: 1.8rem;
              right: -1.7rem;
              width: 16rem;
            }
          }

          &.e2000 {
            top: 0;
            right: 1rem;
            width: 14.5rem;
            transform: rotate(180deg);

            @media (--mobile) {
              width: 12.6rem;
            }
          }

          &.ispeed3 {
            top: 2.8rem;
            right: -1rem;
            width: 18rem;
            transform: rotate(-90deg);

            @media (--mobile) {
              top: 2rem;
              right: -0.4rem;
              width: 14rem;
            }
          }

          &.hes {
            top: 3.1rem;
            right: 0;
            width: 17rem;
            transform: rotate(-90deg);

            @media (--large) {
              @at-root .en & {
                right: -2rem;
              }
            }

            @media (--mobile) {
              top: 2.8rem;
              width: 15rem;
            }
          }
        }

        & > span {
          display: inline-block;
          padding: 0 1rem;
          font-size: 1.1rem;
          color: #c7ced3;
          border: 1px solid #c7ced3;
          border-radius: 9999px;

          @at-root .en & {
            padding: 0.5rem 1rem;
            line-height: 1.2;
          }

          @media (--mobile) {
            top: -0.8rem;
            width: 61%;
            font-size: 1rem;
          }
        }

        h3 {
          margin-top: 1rem;
          font-size: 3.6rem;
          font-weight: normal;
          color: #fff;

          @media (--mobile) {
            margin-top: -1rem;
            font-size: 2.8rem;
          }
        }

        h4 {
          margin-top: 0.5rem;
          font-size: 1.6rem;
          font-weight: lighter;
          opacity: 0.9;

          @media (--mobile) {
            margin-top: -0.5rem;
          }
        }

        h5 {
          margin-top: 0.4rem;
          font-size: 1.2rem;
          font-weight: lighter;
          opacity: 0.9;

          @media (--mobile) {
            margin-top: 0;
          }
        }

        p {
          margin-top: 1.6rem;
          font-size: 1.1rem;
          letter-spacing: 0;
          opacity: 0.7;

          @media (--mobile) {
            margin-top: 0;
            font-size: 1rem;
          }

          & > span {
            padding-left: 0;
            font-size: 70%;
            vertical-align: top;
          }
        }
      }

      &:nth-of-type(n + 3) {
        width: calc(100% / 3 - 2rem);
        min-height: 23rem;
        margin-top: 2rem;

        @media (--mobile) {
          width: 100%;
          height: 18rem;
          min-height: initial;
          margin-bottom: 2rem;
        }

        @at-root .en & {
          min-height: 24rem;

          span {
            width: 55%;

            @media (--mobile) {
              width: 61%;
            }
          }
        }

        h3 {
          margin-top: 0.5rem;
          font-size: 2.6rem;

          @media (--mobile) {
            margin-top: -1rem;
          }
        }

        h4 {
          margin-top: 0;
          font-size: 1.6rem;
          font-weight: lighter;
          opacity: 0.9;

          @media (--mobile) {
            margin-top: -0.5rem;
          }

          @at-root .en & {
            width: 65%;
            line-height: 1.3;
          }
        }

        h5 {
          margin-top: 0.4rem;
          font-size: 1.2rem;
          font-weight: lighter;
          opacity: 0.9;

          @media (--mobile) {
            margin-top: 0;
          }

          @at-root .en & {
            width: 65%;
            line-height: 1.3;
          }

          @media (--large) {
            &.hes {
              @at-root .en & {
                width: 70%;
              }
            }
          }
        }

        p {
          margin-top: 0.5rem;
          font-size: 1.1rem;
          opacity: 0.7;

          @media (--mobile) {
            margin-top: 0;
            font-size: 1rem;
          }
        }
      }

      &:nth-of-type(4) {
        @at-root .en & {
          span {
            width: 41%;

            @media (--mobile) {
              width: 46%;
            }
          }
        }
      }
    }
  }

  .h-16 {
    @media (--mobile) {
      height: 100%;
      min-height: 16rem;
    }
  }

  .h-20 {
    @media (--mobile) {
      height: 100%;
      min-height: 20rem;
    }
  }

  &-list02 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    @media (--mobile) {
      margin: 0;
    }

    &-el {
      position: relative;
      width: calc(100% / 3 - 2rem);
      min-height: 18rem;
      margin: 0 1rem;
      background: linear-gradient(to left, #000, #00012b);

      @media (--mobile) {
        width: 100%;
        margin: 0 0 2rem;
      }

      a {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        padding: 2rem;

        * {
          position: relative;
          z-index: 2;
          padding-left: 0.8rem;
          color: #fff;
        }

        img {
          position: absolute;
          z-index: 1;
          padding-left: 0;

          &.air-speed {
            top: 0;
            right: 0;
            width: 14rem;
            transform: rotate(-180deg);

            @media (--mobile) {
              right: 1.5rem;
              width: 12rem;
            }
          }

          &.xpeed {
            top: 0;
            right: 2.4rem;
            width: 9.8rem;
            transform: rotate(-180deg);

            @media (--mobile) {
              top: 0;
              right: 3.2rem;
              width: 8.8rem;
            }
          }

          &.abt {
            top: 0;
            right: 2.8rem;
            width: 7.5rem;
            transform: rotate(-180deg);

            @media (--mobile) {
              top: 0;
              right: 4rem;
              width: 6.8rem;
            }
          }

          &.hts {
            top: 0;
            right: 2rem;
            width: 10.7rem;
            transform: rotate(-180deg);

            @at-root .en & {
              width: 9.7rem;
            }

            @media (--mobile) {
              top: 0;
              right: 2.5rem;
              width: 9.7rem;
            }
          }

          &.planet {
            top: 0;
            right: 1.5rem;
            width: 10.4rem;
            transform: rotate(-180deg);

            @at-root .en & {
              width: 9.4rem;
            }

            @media (--mobile) {
              top: 0;
              right: 2.4rem;
              width: 8.4rem;
            }
          }
        }

        & > span {
          display: inline-block;
          padding: 0 1rem;
          font-size: 1.1rem;
          color: #c7ced3;
          border: 1px solid #c7ced3;
          border-radius: 9999px;

          @at-root .en & {
            width: 55%;
            padding: 0.5rem 1rem;
            line-height: 1.2;

            @media (--mobile) {
              position: absolute;
              top: 1.5rem;
            }
          }
        }

        h3 {
          position: absolute;
          top: 50%;
          font-size: 2.6rem;
          font-weight: normal;
          line-height: 1.5;
          color: #fff;
          transform: translateY(-50%) translateY(-1rem);
        }

        h5 {
          margin-top: 1rem;
          font-size: 1.2rem;
          font-weight: lighter;
          opacity: 0.9;

          @media (--mobile) {
            font-size: 1.6rem;
          }
        }

        p {
          margin-top: 1.2rem;
          font-size: 1.1rem;
          letter-spacing: 0;
          opacity: 0.7;

          &.desc {
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateY(3rem);

            & > span {
              padding-left: 0;
              font-size: 70%;
              vertical-align: top;
            }
          }
        }
      }

      &:nth-of-type(n + 4) {
        margin-top: 2rem;

        @media (--mobile) {
          margin-top: 0;
        }

        @at-root .en & {
          span {
            width: 70%;

            @media (--mobile) {
              width: 55%;
            }
          }
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        @at-root .en & {
          a {
            span {
              width: 25rem;

              @media (--mobile) {
                width: 52%;
              }
            }
          }
        }
      }

      &:nth-of-type(5) {
        @at-root .en & {
          a {
            span {
              width: 21rem;

              @media (--mobile) {
                width: 60%;
              }
            }
          }
        }
      }
    }
  }

  .about {
    figure {
      width: 100%;
      height: 34rem;
      margin-top: 4rem;

      @media (--mobile) {
        height: 22rem;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      letter-spacing: 0.1em;
    }
  }

  .feature {
    .c-product-side {
      display: flex;
      height: 27rem;
      background: linear-gradient(to right, #00012b, #000);

      @media (--mobile) {
        display: block;
        width: 100vw;
        height: 100%;
        margin: 0 calc(50% - 50vw);
        margin-bottom: 0.2rem;
      }

      @media (--large) {
        @at-root .en & {
          position: relative;
          display: block;
        }
      }

      &__left {
        color: #fff;

        @media (--mobile) {
          width: 33rem;
          padding-top: 5rem;
          margin: 0 auto;
        }

        @media (--large) {
          display: flex;
          align-items: center;
          width: 50%;
          height: 23rem;
          padding-left: 8rem;

          @at-root .en & {
            position: absolute;
            top: 50%;
            left: 4rem;
            display: block;
            width: 70%;
            height: auto;
            padding-left: 0;
            transform: translateY(-50%);
          }
        }

        h4 {
          font-size: 3.2rem;
          font-weight: 500;
          color: #fff;
          letter-spacing: 0.1em;

          @media (--mobile) {
            font-size: 2.6rem;
          }
        }

        ul {
          li {
            position: relative;
            padding-left: 1rem;

            &::before {
              position: absolute;
              top: 0;
              left: 0;
              content: "・";
            }
          }
        }

        p {
          line-height: 1.8;
        }

        .c-btn09 {
          @media (--mobile) {
            margin-bottom: 2rem;
          }

          a {
            width: 20rem;
            height: 5rem;
            border: 1px solid #1a2e56;

            @media (--large) {
              padding-right: 0;
            }

            span {
              &::after {
                right: -3rem;
              }
            }
          }
        }
      }

      &__right {
        @media (--large) {
          width: 50%;

          @at-root .en & {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 40%;
          }
        }

        @media (--mobile) {
          margin-top: 4rem;
        }

        picture {
          // width: 55rem;
          // height: 32rem;

          @media (--mobile) {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .comparison {
    .c-table03 {
      table {
        @media (--mobile) {
          width: 60rem;
        }

        tr {
          width: 80%;
          color: #fff;

          @media (--mobile) {
            width: 100%;
          }
        }

        th {
          font-size: 1.5rem;
          font-weight: 700;
        }

        td {
          position: relative;
          padding: 2.7rem 1.6rem;
          font-size: 1.3rem;
          color: #333;
          text-align: left;

          @media (--tablet) {
            padding: 2.7rem 1.4rem;
          }

          @media (--mobile) {
            padding: 2rem 1rem 1.7rem;
          }

          span {
            position: absolute;
            top: 50%;
            left: 8%;
            width: 3.8rem;
            height: 3.3rem;
            transform: translateY(-50%);

            @media (--mobile) {
              left: 5%;
              width: 3.8rem;
              height: 3.3rem;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .cross {
            left: 6%;
            width: 5rem;
            height: 5rem;

            @media (--mobile) {
              left: 3%;
            }
          }
        }

        .fixed01 {
          width: 10%;
        }

        .first {
          position: sticky;
          z-index: 1;
          width: 16rem;
          font-size: 1.5rem;
          font-weight: 700;
          color: #1a2e56;

          @media (--mobile) {
            width: 10%;
          }
        }

        .left,
        .right {
          width: 47rem;
        }

        .left {
          position: relative;

          @media (--mobile) {
            width: 10rem;
          }

          &::after {
            position: absolute;
            top: 50%;
            right: 8%;
            display: inline-block;
            width: 3.8rem;
            height: 3.8rem;
            vertical-align: middle;
            content: "";
            background-image: url(../images/page/moter_spindle/ms-circle.svg);
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);
          }
        }

        .right {
          position: relative;
          padding: 2.7rem 6rem 2.7rem 9.5rem;

          @media (--mobile) {
            width: 10rem;
            padding: 1rem 2rem 1rem 6.5rem;
          }
        }

        .p-2 {
          padding: 2rem 8rem 2rem 2.8rem;

          @media (--mobile) {
            padding: 1rem 6.5rem 1rem 1rem;
          }
        }

        .p-3 {
          padding: 2rem 7rem 2rem 9.5rem;

          @media (--mobile) {
            padding: 1rem 2rem 1rem 6.5rem;
          }
        }
      }
    }
  }
}

.c-modal.moter_spindle {
  width: 100rem;

  @media (--mobile) {
    width: 90%;
  }
}
