.c-dl {
  padding-top: 6rem;
  padding-bottom: 7.5rem;
  margin-top: 10rem;
  text-align: center;
  border-top: 1px solid #8491aa;

  @media (--mobile) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 5rem;
  }

  &-txt {
    margin-top: 2.4rem;
    font-size: 2rem;

    @media (--mobile) {
      font-size: 1.8rem;
    }
  }

  &-search {
    margin-top: 3.7rem;

    label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43.3rem;
      height: 6.8rem;
      margin-right: auto;
      margin-left: auto;
      border: 1px solid #c7ced3;
      border-radius: 999rem;

      @media (--mobile) {
        max-width: 100%;
        height: 6rem;
      }

      input {
        width: 30rem;
        font-size: 2rem;
      }
    }

    &__button {
      position: absolute;
      top: 50%;
      left: 2rem;
      display: flex;
      padding: 0;
      transform: translateY(-50%);

      svg {
        width: 2.7rem;
        transition: 0.3s;

        * {
          fill: #1a2e56;
          transition: 0.3s;
        }
      }

      @media (--large) {
        &:hover {
          svg {
            opacity: 0.7;
          }
        }
      }
    }
  }

  &-caution {
    margin-top: 2.5rem;
    font-size: 1.6rem;
    color: #8491aa;

    @media (--mobile) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-list {
    display: flex;
    justify-content: space-between;
    margin-top: 5.5rem;

    @media (--mobile) {
      margin: 3rem -3rem 0;
    }

    &-el {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 3);

      @at-root .en & {
        width: calc(100% / 2);
      }

      &:nth-of-type(n + 2) {
        border-left: 1px solid #c7ced3;
      }
    }

    &-link {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 15rem;

      @media (--large) {
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          width: 0;
          height: 0;
          content: "";
          background: #edeff4;
          opacity: 0;
          transition: 0.3s;
          transform: translate(-50%, -50%);
        }

        &:hover {
          &::after {
            width: calc(100% - 1.3rem * 2);
            height: 100%;
            opacity: 1;
          }
        }
      }

      @media (--mobile) {
        height: 14rem;
      }

      &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4.7rem;

        @media (--mobile) {
          height: 3.2rem;
        }

        svg {
          height: 100%;
        }
      }

      &__txt {
        margin-top: 2.5rem;
        font-size: 1.8rem;
        color: #1a2e56;

        @media (--mobile) {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 5rem;
          margin-top: 1.5rem;
          font-size: 1.6rem;
          line-height: 1.4;
        }
      }
    }
  }
}
