.p-zero {
  @media (--large) {
    .c-product-fontSize {
      span {
        font-size: 4.2rem;
      }
    }
  }

  @media (--large) {
    .c-product-spec-img {
      position: relative;
      left: 11rem;
    }
  }
}
