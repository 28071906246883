.p-sample-shop {
  .l-sample {
    &__flex {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 0 !important;

      @media (--mobile) {
        flex-direction: column;
        align-items: flex-start;
      }

      .error {
        position: absolute;
        bottom: -2.4rem;

        @media (--mobile) {
          right: 0;
        }
      }
    }

    &__content {
      justify-content: space-between;

      &:nth-child(n + 2) {
        margin-top: 2.4rem !important;

        @media (--mobile) {
          margin-top: 3rem !important;
        }
      }

      &.is-hidden {
        margin-top: 0 !important;

        @media (--mobile) {
          margin-top: 0 !important;
        }
      }
    }

    &__code,
    &__item {
      @media (--mobile) {
        margin-top: 0.5rem !important;

        input {
          height: 4rem;
        }
      }

      span {
        margin-top: 0 !important;
        margin-right: 2rem;
      }
    }

    &__code {
      input {
        width: 19rem !important;

        @media (--mobile) {
          width: 100% !important;
        }
      }
    }

    &__item {
      margin-left: 4rem;

      @media (--mobile) {
        margin-left: 0;
      }

      input {
        width: 35rem !important;
      }
    }

    &__add {
      margin-top: 5rem;

      .c-btn10 {
        .sample-add__btn {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33rem;
          height: 6rem;
          font-size: 2.2rem;
          color: #1a2e56;
          cursor: pointer;
          background-color: #fff;
          border: 1px solid #1a2e56;
          transition: 0.3s;

          &.u-m-c {
            justify-content: center !important;
            padding: 0 !important;
          }

          svg {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            transform: translateY(-50%);

            path {
              transition: 0.3s;
            }
          }
        }
      }

      // .c-btn10{

      //   .u-m-c{
      //     justify-content: center !important;
      //     padding: 0 !important;
      //   }
      // }
    }
  }

  .c-btn-hover04 {
    .sample-add__btn {
      position: relative;
      z-index: 10;
      overflow: hidden;

      &::before {
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #0058a2;
        opacity: 0;
        transition: 0.3s;
      }

      svg {
        transition: 0.3s all;
      }

      @media (--large) {
        &:hover {
          color: #fff;

          span {
            color: #fff;

            &::after {
              border-top: 2px solid #fff;
              border-right: 2px solid #fff;
            }
          }

          svg {
            * {
              stroke: #fff;
            }
          }
        }
      }

      @media (--large) {
        &:hover::before {
          left: 0;
          opacity: 1;
        }

        &:hover::after {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
        }
      }
    }
  }
}

.js-sample {
  .is-hidden {
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;

    &.js-appear {
      display: flex;
      height: inherit;
      margin-top: 2.4rem !important;
      visibility: visible;
      opacity: 1;
    }
  }
}

.mw_wp_form_confirm {
  .c-f-cation {
    display: none;
  }

  .c-caption {
    display: none;
  }

  .l-sample__add {
    display: none;
  }

  .l-sample {
    &__code,
    &__item {
      @media (--mobile) {
        flex-direction: row;

        span {
          width: 8rem;
        }
      }
    }
  }

  .l-sample__flex {
    @media (--mobile) {
      width: 100%;
    }
  }
}
