/* ==========================================================================
 height
========================================================================== */

@for $value from 0 to 26 {
  .u-h-#{$value} {
    height: $value + rem !important;
  }
}

@media (--mobile) {
  @for $value from 0 to 26 {
    .u-h-sp-#{$value} {
      height: $value + rem !important;
    }
  }
}
