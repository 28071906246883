.p-sProduct {
  .c-article {
    li {
      a {
        span {
          position: relative;

          &.is-movie {
            &::after {
              position: absolute;
              top: 50%;
              right: -4rem;
              width: 2.5rem;
              height: 1.9rem;
              content: "";
              background: url(../images/common/ic_movie.png);
              background-size: cover;
              transform: translateY(-50%);

              @media (--mobile) {
                top: inherit;
                bottom: -1rem;
              }
            }
          }
        }
      }
    }
  }
}
