.p-top {
  .s-mv {
    position: relative;

    @media (--mobile) {
      padding-bottom: 9rem;
    }

    &__inner {
      position: relative;
      width: 100%;
      height: var(--firstViewHeight);
      overflow: hidden;
      background: linear-gradient(310deg, #191919 40%, #00012b);

      @media (--mobile) {
        height: calc(var(--firstViewHeight) + 2.2rem);
      }
    }

    &__circle-bottom {
      position: absolute;
      top: -20rem;
      left: 50%;
      width: 200rem;
      height: 200rem;
      opacity: 1;
      transform: translateX(-20rem) rotate(180deg);

      @media (--mobile) {
        top: -25rem;
        left: 50%;
        width: 100rem;
        height: 100rem;
        transform: translateX(-6rem) rotate(180deg);
      }

      img {
        width: 100%;
      }

      &.last {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 93%;

          @media (--mobile) {
            width: 90%;
          }
        }
      }
    }

    &__circle-top {
      position: absolute;
      top: -27rem;
      left: 0;
      width: 50rem;
      height: 50rem;
      opacity: 1;
      transform: translateX(-20rem) rotate(50deg);

      @media (--mobile) {
        top: -29rem !important;
        transform: translateX(-25rem) rotate(50deg);
      }

      img {
        width: 100%;
      }

      &.last {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 80%;
        }
      }
    }

    &__spindle,
    &__mg {
      position: absolute;
      top: 50%;
      right: 50%;
      width: 110rem;
      height: 50rem;
      transform: translateY(-50%) translateY(-30rem) translateX(108rem);

      @media (--mobile) {
        top: 50%;
        right: 50%;
        width: 60rem;
        height: 47rem;
        transform: translate(50%, -50%) translateX(13rem) translateY(-15rem);
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-120rem) translateX(120rem);

        @media (--mobile) {
          width: 100%;
          height: 100%;
          transform: translateY(-50rem) translateX(50rem);
          object-fit: contain;
        }
      }

      &-mov {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        transform: translate(-50%, -50%);

        @media (--mobile) {
          top: inherit;
          bottom: 27rem;
          left: inherit;
          height: 30rem;
          transform: inherit;
        }

        video {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          object-fit: cover;
          object-position: center center;
        }
      }

      &-txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) translateX(-38rem);

        @media (--mobile) {
          top: auto;
          bottom: 4rem;
          left: 0;
          padding-left: 3rem;
          transform: initial;
        }

        * {
          color: #fff;
          letter-spacing: 0.1em;

          @media (--mobile) {
            letter-spacing: 0.05em;
          }
        }

        &-wrap {
          div {
            display: flex;
            align-items: center;
            opacity: 0;
            transform: translateX(-15rem);

            @media (--mobile) {
              transform: translateX(-8rem);
            }

            span {
              font-size: 2.2rem;
              opacity: 0.6;

              @media (--mobile) {
                font-size: 1.5rem;
              }
            }

            small {
              font-size: 1.5rem;
              opacity: 0.6;

              @media (--mobile) {
                font-size: 1.2rem;
              }
            }

            hr {
              width: 20rem;
              height: 1px;
              margin: 0 1rem;
              background: #fff;
              border: none;
              opacity: 0.6;

              @media (--mobile) {
                width: 3rem;
              }
            }
          }
        }
      }

      &-ttl {
        margin-top: 4rem;
        font-family: Roboto, sans-serif;
        font-size: 10rem;
        font-style: italic;
        line-height: 1.1;
        opacity: 0;
        transform: translateX(-15rem);

        @media (--mobile) {
          margin-top: 2rem;
          font-size: 6.4rem;
          transform: translateX(-5rem);
        }
      }
    }

    &__mg {
      top: 50% !important;
      right: 50% !important;

      // left: 50%;
      width: 98rem !important;
      height: 55rem !important;
      transform: translate(50%, -50%) translateX(40rem) translateY(3rem) !important;

      @media (--mobile) {
        width: 48rem !important;
        height: 31rem !important;
        transform: translate(50%, -50%) translateY(-6rem) translateX(4rem) !important;
      }

      img {
        opacity: 0;
        transform: translate(5rem, -5rem);

        @media (--mobile) {
          width: 100%;
          height: 100%;

          // opacity: 1;
          object-fit: contain;
          object-position: center center;
          transform: translate(5rem, -5rem);
        }

        &.last {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
