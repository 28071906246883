@charset "utf-8";

/**
 * error message
 */
.mw_wp_form .error {
  display: block;
  font-size: 93%;
  color: #b70000;
}

/**
 * error message of akismet
 */
.mw_wp_form .akismet_error {
  display: block;
}

/**
 * vertical style of radio and checkbox
 */
.mw_wp_form .vertical-item {
  display: block;
}

.mw_wp_form .vertical-item + .vertical-item {
  margin-top: 5px;
}

/**
 * horizontal style of radio and checkbox
 */
.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 1rem;
}

/**
 * radio and checkbox
 */
.mwform-checkbox-field label,
.mwform-radio-field label {
  margin-bottom: 0;
  font-weight: normal;
}

.mwform-checkbox-field input,
.mwform-radio-field input {
  margin-right: 5px;
}

/**
 * datepicker
 */
.mw_wp_form .ui-datepicker .ui-datepicker-title select.ui-datepicker-month,
.mw_wp_form .ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
  width: 40%;
  margin: 0 5px;
}

/**
 * deprecated message
 */
.mw-wp-form-deprecated-message {
  padding: 1em;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.6;
  color: #af4040;
  background: #fdeeee;
  border: 1px solid #f7bdb8;
  border-radius: 5px;
}

/**
 * file
 */
.mw_wp_form input[type="file"] {
  display: inline-block;
}

/**
 * file delete button
 */
.mwform-file-delete {
  cursor: pointer;
  visibility: hidden;
}

/**
 * tel and zip
 */
.mwform-tel-field input[type="text"],
.mwform-zip-field input[type="text"] {
  display: inline-block;
  width: auto;
}

.mwform-tel-field input[type="text"] {
  @media (--mobile) {
    width: 28% !important;
  }
}
