.p-micro-grinder {
  @media (--mobile) {
    .c-product-mv {
      padding: 7rem 0;

      &__pImg {
        display: block;
        width: 37rem;
        margin: 0 auto 5rem;
      }
    }
  }

  .c-product-mv-mTxt {
    font-size: 1.6rem;
  }

  .c-product-mv-sTxt {
    font-size: 1.6rem;
  }

  &-list01 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    @media (--mobile) {
      display: block;
      margin: 0;
    }

    &-el {
      position: relative;
      width: calc(100% / 2 - 2rem);
      min-height: 27rem;
      margin: 0 1rem;
      background: linear-gradient(to left, #000, #00012b);

      @at-root .en & {
        &:nth-child(2) {
          h4 {
            @media (--large) {
              margin-top: 4.4rem;
            }
          }
        }

        &.single-type {
          h4 {
            margin-top: 3rem !important;
          }

          h5 {
            @media (--large) {
              margin-top: 2.4rem;
            }
          }
        }
      }

      @media (--mobile) {
        width: 100%;
        height: 100%;
        min-height: 18rem;
        margin: 0 0 2rem;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 2rem;

        * {
          position: relative;
          z-index: 2;
          padding-left: 0.8rem;
          color: #fff;
        }

        img {
          position: absolute;
          z-index: 1;
          padding-left: 0;

          &.evolution {
            right: 4.5rem;
            bottom: 1.7rem;
            width: 25rem;
            height: auto;

            @media (--mobile) {
              right: 2rem;
              bottom: 1.7rem;
              width: 15rem;
              height: auto;
            }
          }

          &.espert {
            right: 2.9rem;
            bottom: 3.1rem;
            width: 27rem;

            @media (--mobile) {
              right: 1.2rem;
              bottom: 2.1rem;
              width: 18rem;
            }
          }

          &.sheenus {
            right: 3rem;
            bottom: 2.4rem;
            width: 24rem;

            @media (--large) {
              @at-root .en & {
                top: 5.5rem;
                right: 2.5rem;
                width: 22rem;
              }
            }

            @media (--mobile) {
              right: 2rem;
              width: 15.4rem;

              @at-root .en & {
                bottom: 4rem;
                width: 13.5rem;
              }
            }
          }

          &.soniccutter {
            top: 0.8rem;
            right: 3.8rem;
            width: 23rem;

            @media (--large) {
              @at-root .en & {
                top: 1.5rem;
                right: 2.5rem;
                width: 20rem;
              }
            }

            @media (--mobile) {
              right: 2rem;
              width: 14.8rem;

              @at-root .en & {
                top: 1.5rem;
                width: 13rem;
              }
            }
          }
        }

        span {
          display: inline-block;
          padding: 0 1rem;
          font-size: 1.1rem;
          color: #c7ced3;
          border: 1px solid #c7ced3;
          border-radius: 9999px;
        }

        h3 {
          font-size: 3.6rem;
          font-weight: normal;
          color: #fff;

          @media (--mobile) {
            font-size: 2.6rem;
          }
        }

        h4 {
          margin-top: 3rem;
          font-size: 1.8rem;
          font-weight: lighter;
          opacity: 0.9;

          @media (--mobile) {
            font-size: 1.6rem;
          }
        }

        h5 {
          margin-top: 0.4rem;
          font-size: 1.4rem;
          font-weight: lighter;
          opacity: 0.9;

          @media (--mobile) {
            font-size: 1.2rem;
          }
        }

        p {
          margin-top: 3rem;
          margin-bottom: -1.5rem;
          font-size: 3rem;
          line-height: 1.3;

          @media (--mobile) {
            margin-top: 1rem;
            font-size: 1.8rem;
          }
        }
      }

      &:nth-of-type(n + 3) {
        width: calc(100% / 3 - 2rem);
        min-height: 22rem;
        margin-top: 2rem;

        @media (--mobile) {
          width: 100%;
          margin-bottom: 2rem;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 2rem;
        }

        h3 {
          margin-top: 0.5rem;
          font-size: 2.6rem;

          @media (--mobile) {
            font-size: 2.8rem;
          }
        }

        h4 {
          margin-top: 0;
          font-size: 1.6rem;
          font-weight: lighter;
          opacity: 0.9;
        }

        h5 {
          margin-top: 0.4rem;
          font-size: 1.2rem;
          font-weight: lighter;
          opacity: 0.9;
        }

        p {
          margin-top: 0.5rem;
          font-size: 1.1rem;
          opacity: 0.7;
        }
      }
    }
  }

  &-list02 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    @media (--mobile) {
      margin: 0;
    }

    &-el {
      position: relative;
      width: calc(100% / 3 - 2rem);
      min-height: 18rem;
      margin: 0 1rem;
      background: linear-gradient(to left, #000, #00012b);

      @media (--mobile) {
        width: 100%;
        height: 100%;
        min-height: inherit;
        margin: 0 0 2rem;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;

        @media (--large) {
          padding-top: 4.5rem !important;
          padding-left: 1.6rem !important;
        }

        @media (--mobile) {
          padding: 3rem 1.5rem;
        }

        * {
          position: relative;
          z-index: 2;
          padding-left: 0.8rem;
          color: #fff;
        }

        img {
          position: absolute;
          z-index: 1;
          padding-left: 0;

          &.lotas {
            right: 0.9rem;
            bottom: 2.1rem;
            width: 16rem;

            @media (--mobile) {
              bottom: 0.9rem;
            }
          }

          &.impulse {
            right: 0.9rem;
            bottom: 0;
            width: 17.2rem;
          }

          &.presto {
            right: 1.4rem;
            bottom: 2.3rem;
            width: 22.5rem;

            @at-root .en & {
              @media (--large) {
                right: 0.4rem;
                bottom: 3.3rem;
                width: 20.5rem;
              }
            }

            @media (--mobile) {
              right: 0;
              width: 17.6rem;
            }
          }
        }

        span {
          display: inline-block;
          padding: 0 1rem;
          font-size: 1.1rem;
          color: #c7ced3;
          border: 1px solid #c7ced3;
          border-radius: 9999px;
        }

        h3 {
          font-size: 2.6rem;
          font-weight: normal;
          line-height: 1.5;
          color: #fff;
        }

        @media (--large) {
          padding-top: 2.5rem;
        }

        h5 {
          margin-top: 1rem;
          font-size: 1.2rem;
          font-weight: lighter;
          opacity: 0.9;

          @media (--mobile) {
            font-size: 1.6rem;
          }
        }

        p {
          margin-top: 1.2rem;
          font-size: 1.1rem;
          letter-spacing: 0;
          opacity: 0.7;
        }
      }

      &:nth-of-type(n + 4) {
        margin-top: 2rem;

        @media (--mobile) {
          margin-top: 0;
        }
      }
    }
  }

  .about {
    figure {
      width: 100%;
      height: 34rem;
      margin-top: 4rem;

      @media (--mobile) {
        height: 22rem;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      letter-spacing: 0.1em;
    }
  }

  .feature {
    .c-product-side {
      display: flex;

      @media (--mobile) {
        display: block;
        margin-top: 10rem;
      }

      &__left {
        h4 {
          font-size: 2.4rem;
          font-weight: 500;
          color: #1a2e56;
          letter-spacing: 0.1em;
        }

        p {
          line-height: 1.8;
        }

        .c-btn09 {
          @media (--mobile) {
            margin-bottom: 2rem;
          }

          a {
            width: 20rem;
            height: 5rem;
            border: 1px solid #1a2e56;

            @media (--large) {
              padding-right: 0;
            }

            span {
              &::after {
                right: -3rem;
              }
            }
          }
        }
      }

      &__right {
        figure {
          width: 55rem;
          height: 32rem;

          @media (--mobile) {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .comparison {
    .c-table03 {
      table {
        width: 100%;
        font-size: 1.5rem;
        border-spacing: 0;
        border-collapse: collapse;

        @media (--mobile) {
          width: 80rem;
          border-collapse: separate;
        }

        th {
          padding: 1.5rem 0;
          color: #fff;
          background-color: #2c529f;
          border: 1px solid #c7ced3;

          &:first-child {
            background-color: #fff;
            border: none;
          }
        }

        td {
          padding: 1rem 2rem;
          border: 1px solid #c7ced3;

          @media (--large) {
            &.nonbreaking {
              @at-root .en & {
                white-space: nowrap;
              }
            }
          }

          @media (--mobile) {
            min-width: 9rem;
          }

          &.first {
            text-align: center;
            background-color: #edeff4;
          }

          &.price {
            color: #2c529f;
          }
        }

        .p-2 {
          padding: 2.5rem 2rem;

          @media (--mobile) {
            padding: 0.5rem 2rem;
          }
        }

        @media (--mobile) {
          .fixed01 {
            position: sticky;
            left: 0;
            width: 12rem;

            &::before {
              position: absolute;
              top: -1px;
              left: 1px;
              width: 100%;
              height: 100%;
              content: "";
            }
          }
        }
      }
    }
  }

  .model {
    &__menu {
      display: flex;
      justify-content: space-between;

      li {
        position: relative;
        width: 54rem;
        text-align: center;
        border-bottom: 3px solid #b2b2b2;

        @media (--mobile) {
          width: 16.5rem;
        }

        &.is-active {
          border-bottom: 3px solid #1a2e56;

          div {
            color: #1a2e56;
          }

          svg {
            * {
              fill: #1a2e56;
            }
          }
        }

        div {
          display: block;
          width: 100%;
          padding: 0.8rem 0;
          color: #b2b2b2;
          text-align: center;
          cursor: pointer;

          @media (--mobile) {
            padding: 0.8rem 0.5rem 0.8rem 2.3rem;
          }
        }

        svg {
          position: absolute;
          bottom: -1.7rem;
          left: 50%;
          width: 1.5rem;
          height: 1rem;
          transform: translateY(-50%);

          * {
            fill: #b2b2b2;
          }

          @media (--mobile) {
            bottom: -1.6rem;
          }
        }
      }
    }

    @media (--large) {
      .c-table03 {
        display: none;

        &.is-active {
          display: block;
        }

        th {
          color: #fff;
        }

        td {
          min-width: 9rem;
          padding: 2rem;
          border: 1px solid #c7ced3;

          figure {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              margin: 0 auto;

              &.model_basic {
                width: 15.4rem;
              }

              &.model_highend {
                width: 20.8rem;
              }

              &.model_sheenus {
                width: 18.2rem;
              }

              &.model_sonic {
                width: 18.5rem;
              }

              &.model_detail01 {
                width: 28.3rem;
              }

              &.model_detail02 {
                width: 30.4rem;
              }

              &.enk-410s {
                width: 14.5rem;
              }

              &.enk-250t {
                width: 14.5rem;
              }

              &.enk-500c {
                width: 14.5rem;
              }

              &.enk-500t {
                width: 14.5rem;
              }
            }
          }

          span {
            position: relative;

            figure {
              position: absolute;
              top: -0.2rem;
              left: -2.5rem;
              width: 2rem;
              height: 3rem;
            }

            &.item {
              display: block;
              padding-left: 2rem;

              @at-root .en & {
                padding-left: 1.5rem;
              }

              &::before {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 1.6rem;
                color: #1a2e56;
                content: "■";

                @at-root .en & {
                  content: "•";
                }
              }
            }
          }
        }

        .p-2 {
          padding: 1.5rem 3rem;
        }

        .first {
          width: 16rem;
          padding: 1.8rem 1rem 1.8rem 2rem;
          text-align: left;

          &.el_02 {
            @at-root .en & {
              width: 23rem;
            }
          }
        }

        .p-l-5 {
          padding-left: 5rem;
        }

        .w-50 {
          width: 50rem;
        }
      }
    }

    //  sp  //
    .c-table03-sp {
      display: none;

      &.is-active {
        display: block;
      }

      table {
        width: 100%;
        margin: 6rem auto;
        font-size: 1.5rem;
        border-spacing: 0;
        border-collapse: separate;
      }

      th {
        width: 100%;
        color: #fff;
        background-color: #2c529f;
        border: 1px solid #c7ced3;

        &:first-child {
          background-color: #fff;
          border: none;
        }
      }

      .c-f {
        color: #fff;
        background-color: #2c529f;
      }

      td {
        min-width: 9rem;
        padding: 2rem;
        text-align: center;
        border: 1px solid #c7ced3;

        figure {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            margin: 0 auto;

            &.model_basic {
              width: 15.4rem;
            }

            &.model_highend {
              width: 20.8rem;
            }

            &.model_sheenus {
              width: 18.2rem;
            }

            &.model_sonic {
              width: 18.5rem;
            }

            &.model_detail01 {
              width: 28.3rem;
            }

            &.model_detail02 {
              width: 30.4rem;
            }

            &.enk-410s {
              width: 14.5rem;
            }

            &.enk-250t {
              width: 14.5rem;
            }

            &.enk-500c {
              width: 14.5rem;
            }

            &.enk-500t {
              width: 14.5rem;
            }
          }
        }

        &.price {
          color: #2c529f;
        }

        span {
          position: relative;

          figure {
            position: absolute;
            top: -0.2rem;
            left: -2.5rem;
            width: 2rem;
            height: 3rem;
          }

          &.item {
            display: block;
            padding-left: 2rem;

            &::before {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 1.6rem;
              color: #1a2e56;
              content: "■";

              @at-root .en & {
                content: "●";
              }
            }
          }
        }
      }

      .p-2 {
        padding: 1.5rem 3rem;
      }

      .first {
        width: 100%;
        padding: 1.1rem 1rem 0.6rem 2rem;
        text-align: center;
        background-color: #edeff4;
      }

      .p-l-5 {
        padding-left: 4rem;
        text-align: left;
      }

      .w-50 {
        width: 50rem;
      }
    }

    .model-btn {
      display: flex;
      width: 70rem;
      margin: 5rem auto 0;

      @media (--mobile) {
        display: block;
        width: 100%;
        margin: 3rem auto 0;
      }

      .u-m-c {
        width: 32rem;
        height: 7rem;
        font-size: 1.6rem;

        @media (--mobile) {
          width: 100%;
          margin: 2rem auto 0;
        }
      }
    }

    .c-btn12 {
      a,
      button {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        width: 38rem;
        height: 10rem;
        padding: 0 4rem 0 2rem;
        margin-right: 2rem;
        font-size: 2.2rem;

        @media (--mobile) {
          width: 80%;
          height: 8rem;
          padding: 2rem;
          font-size: 2rem;
        }

        svg {
          right: 2rem;

          @media (--mobile) {
            right: 2rem;
          }
        }
      }

      .c-btn-hover04 {
        a,
        button {
          overflow: hidden;

          &::before {
            position: absolute;
            top: 0;
            left: -100%;
            z-index: -1;
            width: 100%;
            height: 100%;
            color: #fff;
            content: "";
            background-color: #0058a2;
            opacity: 0;
            transition: 0.3s;
          }

          svg {
            transition: 0.3s all;
          }

          @media (--large) {
            &:hover {
              svg {
                right: 2rem;
              }
            }
          }

          @media (--large) {
            &:hover::before {
              left: 0;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .price {
    color: #1a2e56 !important;
  }
}
