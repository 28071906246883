.c-article {
  &__nav {
    & > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.5rem;

      @media (--mobile) {
        margin: 0 -0.5rem;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.7rem 1.5rem;
        font-size: 1.6rem;
        border: 0.1rem solid #1a2e56;
        border-radius: 9999px;

        @media (--mobile) {
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 0 2.5rem;
          border-radius: 1.5rem;
          transition: 0.3s all;

          @media (--large) {
            &:hover {
              color: #fff;
              background-color: #1a2e56;
            }
          }

          @media (--mobile) {
            padding: 0 1rem;
          }
        }

        @media (--mobile) {
          margin-right: 1rem;
        }

        &.active {
          color: #fff;
          background-color: #1a2e56;

          a {
            color: #fff;
            pointer-events: none;
          }
        }
      }
    }
  }

  & > ul {
    li {
      border-bottom: 0.1rem solid #c7ced3;

      &:first-child {
        border-top: 0.1rem solid #c7ced3;
      }

      a {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 3rem;

        &::after {
          position: absolute;
          bottom: -1px;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          content: "";
          background-color: #1a2e56;
          opacity: 0;
          transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
          transition-duration: 0.6s;
          transition-property: opacity, transform;
          transform: scale3d(0.8, 1, 1);
          transform-origin: left center;
        }

        @media (--large) {
          &:hover {
            &::after {
              opacity: 1;
              transform: none;
            }
          }
        }

        @media (--mobile) {
          display: block;
          padding: 2rem 1rem;
        }

        .desc {
          display: flex;
        }

        .date {
          min-width: 9rem;
          margin-right: 3rem;
          font-family: $robot;
          font-size: 1.6rem;
          font-weight: bold;

          @at-root .en & {
            flex-shrink: 0;
            min-width: 16rem;
          }

          @media (--mobile) {
            margin-right: 1.5rem;
          }
        }

        .cat {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12rem;
          padding: 0 1rem;
          margin-right: 2.4rem;
          overflow: hidden;
          font-size: 1.3rem;
          color: #fff;
          text-overflow: ellipsis;
          white-space: nowrap;
          background-color: #1a2e56;
        }

        .title {
          font-size: 1.6rem;

          @media (--large) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          @media (--mobile) {
            margin-top: 1rem;
          }
        }

        &.nolink {
          pointer-events: none;
        }
      }
    }
  }
}
