@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@500;700&family=Zen+Kaku+Gothic+New:wght@500;700&display=swap");
/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */
/**
 * for mobile viewoport
 */
@import url(normalize.css);
@import url(swiper/swiper-bundle.min.css);
@import url(scroll-hint/css/scroll-hint.css);
@import url(leaflet/dist/leaflet.css);
@custom-media --mobile screen and (max-width: 767px);
@custom-media --large screen and (min-width: 768px);
@custom-media --tablet screen and (max-width: 1023px) and (min-width: 768px);
@custom-media --vw_outside screen and (min-width: 1600px);
@custom-media --vw_inside screen and (min-width: 768px) and (max-width: 1599px);
/** http://bourbon.io/docs/#timing-functions */
/** example @include transition(all 5s $ease-in-circ);*/
/**
transition timing-function:
ease - cubic-bezier(0.25, 0.1, 0.25, 1.0) を指定したのと同じ（開始と完了を滑らかにする）（初期値）
linear - cubic-bezier(0.0, 0.0, 1.0, 1.0) を指定したのと同じ（一定）
ease-in - cubic-bezier(0.42, 0, 1.0, 1.0) を指定したのと同じ（ゆっくり始まる）
ease-out - cubic-bezier(0, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり終わる）
ease-in-out - cubic-bezier(0.42, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり始まってゆっくり終わる）
*/
/**
 * text ellipsis for block multiline
 */
/*
 unsupport IE, Edge, Fx

@mixin text-ellipsis-block($rows) {
  display: box;
  box-orient: vertical;
  line-clamp: $rows;
  overflow: hidden;
}
*/
/**
 * element size
 */
/**
 * position layout
 */
/**
 * images
 */
/**
* Variables
*/
/** extend */
@media (--large) {
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear; }
    .ex-link:hover {
      opacity: .5; } }

.js-inview[data-inview="fade"] {
  opacity: 0;
  transition: opacity 1s; }
  .js-inview[data-inview="fade"].is-inview {
    opacity: 1; }

.js-inview[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: translateY(30px); }
  .js-inview[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview[data-inview="fade-left"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(-30px); }
  .js-inview[data-inview="fade-left"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(30px); }
  .js-inview[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview[data-inview="btn"] a::before,
.js-inview[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn"] i {
  opacity: 0;
  transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn"].is-inview a::before,
.js-inview[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="btn"].is-inview i {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="btn-border"] a::after,
.js-inview[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn-border"].is-inview a::after,
.js-inview[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="bg"]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: transform 0.4s;
  transform: scale(1, 1);
  transform-origin: right top; }

.js-inview[data-inview="bg"].is-inview::after {
  transform: scale(0, 1); }

.js-inview[data-inview="bg-white"]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  transition: transform 0.4s;
  transform: scale(1, 1);
  transform-origin: right top; }

.js-inview[data-inview="bg-white"].is-inview::after {
  transform: scale(0, 1); }

.js-inview[data-inview="bg-scale"]::before {
  transition: transform 0.8s;
  transform: translate(-50%, -50%) scale(0.5);
  transform-origin: center center; }

.js-inview[data-inview="bg-scale"].is-inview::before {
  transform: translate(-50%, -50%) scale(1); }

.js-inview-photo[data-inview="scale-small"] {
  transition: transform 1s;
  transform: scale(1.5); }
  .is-inview .js-inview-photo[data-inview="scale-small"] {
    transform: scale(1); }

.js-inview-photo-img[data-inview="scale-small"] {
  transition: transform 1s;
  transform: translateX(-50%) scale(1.5); }
  .is-inview .js-inview-photo-img[data-inview="scale-small"] {
    transform: translateX(-50%) scale(1); }

.js-inview-eachItem[data-inview="from-side"] span {
  display: inline-block;
  overflow: hidden; }
  .js-inview-eachItem[data-inview="from-side"] span em {
    display: inline-block;
    transition: transform 1.25s cubic-bezier(0.48, 0.325, 0.03, 1);
    transform: translateX(-100%); }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(1) em {
    transition-delay: 50ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(2) em {
    transition-delay: 100ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(3) em {
    transition-delay: 150ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(4) em {
    transition-delay: 200ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(5) em {
    transition-delay: 250ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(6) em {
    transition-delay: 300ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(7) em {
    transition-delay: 350ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(8) em {
    transition-delay: 400ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(9) em {
    transition-delay: 450ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(10) em {
    transition-delay: 500ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(11) em {
    transition-delay: 550ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(12) em {
    transition-delay: 600ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(13) em {
    transition-delay: 650ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(14) em {
    transition-delay: 700ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(15) em {
    transition-delay: 750ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(16) em {
    transition-delay: 800ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(17) em {
    transition-delay: 850ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(18) em {
    transition-delay: 900ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(19) em {
    transition-delay: 950ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(20) em {
    transition-delay: 1000ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(21) em {
    transition-delay: 1050ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(22) em {
    transition-delay: 1100ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(23) em {
    transition-delay: 1150ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(24) em {
    transition-delay: 1200ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(25) em {
    transition-delay: 1250ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(26) em {
    transition-delay: 1300ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(27) em {
    transition-delay: 1350ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(28) em {
    transition-delay: 1400ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(29) em {
    transition-delay: 1450ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(30) em {
    transition-delay: 1500ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(31) em {
    transition-delay: 1550ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(32) em {
    transition-delay: 1600ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(33) em {
    transition-delay: 1650ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(34) em {
    transition-delay: 1700ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(35) em {
    transition-delay: 1750ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(36) em {
    transition-delay: 1800ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(37) em {
    transition-delay: 1850ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(38) em {
    transition-delay: 1900ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(39) em {
    transition-delay: 1950ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(40) em {
    transition-delay: 2000ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(41) em {
    transition-delay: 2050ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(42) em {
    transition-delay: 2100ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(43) em {
    transition-delay: 2150ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(44) em {
    transition-delay: 2200ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(45) em {
    transition-delay: 2250ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(46) em {
    transition-delay: 2300ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(47) em {
    transition-delay: 2350ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(48) em {
    transition-delay: 2400ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(49) em {
    transition-delay: 2450ms; }
  .js-inview-eachItem[data-inview="from-side"] span:nth-of-type(50) em {
    transition-delay: 2500ms; }

.js-inview-eachItem[data-inview="from-side"].is-inview span em {
  transform: translateX(0); }

.js-inview-eachItem[data-inview="fade"] {
  opacity: 0;
  transition: opacity 0.8s; }
  .js-inview-eachItem[data-inview="fade"].is-inview {
    opacity: 1; }

.js-inview-eachItem[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }
  .js-inview-eachItem[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview-eachItem[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(30px); }
  .js-inview-eachItem[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview-eachItem[data-inview="btn"] a::before,
.js-inview-eachItem[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-eachItem[data-inview="btn"].is-inview a::before,
.js-inview-eachItem[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview-eachItem[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="btn-border"] a::after,
.js-inview-eachItem[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-eachItem[data-inview="btn-border"].is-inview a::after,
.js-inview-eachItem[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview-eachItem[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="list-border"]::before {
  transition: transform 0.8s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="list-border"] span,
.js-inview-eachItem[data-inview="list-border"] figure,
.js-inview-eachItem[data-inview="list-border"] a {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }

.js-inview-eachItem[data-inview="list-border"] i {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(-20px) rotate(45deg); }

.js-inview-eachItem[data-inview="list-border"].is-inview::before {
  transform: scale(1); }

.js-inview-eachItem[data-inview="list-border"].is-inview span,
.js-inview-eachItem[data-inview="list-border"].is-inview figure,
.js-inview-eachItem[data-inview="list-border"].is-inview a {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="list-border"].is-inview i {
  opacity: 1;
  transform: translateX(-10px) rotate(45deg); }

.js-inview-eachItem-fast[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateY(10px); }
  .js-inview-eachItem-fast[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade"] {
  opacity: 0;
  transition: transform 0.8s; }
  .js-inview-eachItem-delay[data-inview="fade"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }
  .js-inview-eachItem-delay[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(30px); }
  .js-inview-eachItem-delay[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview-eachItem-delay[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-all[data-inview="btn"] a::before,
.js-inview-all[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-all[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-all[data-inview="btn"].is-inview a::before,
.js-inview-all[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview-all[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-all[data-inview="btn-border"] a::after,
.js-inview-all[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-all[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-all[data-inview="btn-border"].is-inview a::after,
.js-inview-all[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview-all[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo[data-inview="scale-small"] {
  transform: scale(1); }

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo-img[data-inview="scale-small"] {
  transform: translateX(-50%) scale(1); }

:root {
  --vw: 100vw;
  --firstViewHeight: 100vh; }

/* == @import "../node_modules/normalize.css/normalize.css"; */
[v-cloak] {
  display: none; }

@media (--vw_outside) {
  html {
    font-size: 62.5%; } }

@media (--vw_inside) {
  html {
    font-size: calc(var(--vw) / 144); } }

@media (--mobile) {
  html {
    font-size: calc(10vw / 414 * 100); } }

html.is-hidden {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden; }

body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  font-feature-settings: "palt";
  line-height: 1.8;
  color: #222222;
  letter-spacing: 0.05em; }

a {
  color: #1a2e56;
  text-decoration: none; }

*,
*::before,
*::after {
  box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
p {
  padding: 0;
  margin: 0; }

ul,
ol,
li {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

img {
  vertical-align: bottom; }

figure {
  padding: 0;
  margin: 0; }

input,
button,
select,
textarea {
  font: inherit;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none; }

textarea {
  resize: vertical; }

input[type="submit"],
input[type="button"],
label,
button,
select {
  cursor: pointer; }

select::-ms-expand {
  display: none; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0; }

input[type="number"] {
  appearance: textfield; }

.l-main {
  padding-top: 10rem; }
  @media (--mobile) {
    .l-main {
      padding-top: 8rem; } }

small {
  font-size: 80%;
  vertical-align: top; }

svg,
img {
  max-width: 100%;
  height: auto; }

@media (--mobile) {
  html,
  body {
    width: 100%;
    height: 100%; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  dl,
  dt,
  dd,
  p {
    padding: 0;
    margin: 0; }
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%; } }

@media (--mobile) {
  .pc {
    display: none !important; } }

@media (--large) {
  [href*="tel:"] {
    pointer-events: none; }
  .sp {
    display: none !important; } }

.gsc-adBlock,
.gssb_a {
  display: none !important; }

@tailwind components;
@tailwind utilities;
.l-footer {
  padding-top: 12rem;
  padding-bottom: 5.6rem;
  background: #222; }
  @media (--mobile) {
    .l-footer {
      padding: 5rem 0 2.5rem; } }
  .l-footer * {
    color: #fff; }
  .l-footer-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (--mobile) {
      .l-footer-inner {
        display: block; } }
  .l-footer-left {
    width: 28rem; }
    @media (--mobile) {
      .l-footer-left {
        width: 100%; } }
  .l-footer-right {
    width: 80rem; }
    @media (--mobile) {
      .l-footer-right {
        width: 100%; } }
  .l-footer-logo {
    width: 22rem; }
    @media (--mobile) {
      .l-footer-logo {
        margin-right: auto;
        margin-left: auto; } }
    .l-footer-logo svg * {
      fill: #fff; }
  .l-footer-btnArea {
    margin-top: 3.7rem; }
    @media (--mobile) {
      .l-footer-btnArea {
        width: 28rem;
        margin-top: 4rem;
        margin-right: auto;
        margin-left: auto; } }
    .l-footer-btnArea a {
      position: relative;
      display: flex;
      align-items: center;
      width: 100% !important;
      height: 6rem !important;
      padding: 0 1.6rem !important;
      font-size: 1.6rem !important;
      color: #fff !important;
      background: none !important;
      border: 1px solid #fff !important; }
      .l-footer-btnArea a svg {
        position: absolute;
        top: 50% !important;
        right: 1.5rem !important;
        width: 0.5rem !important;
        width: 0.7rem !important;
        transform: translateY(-50%) rotate(180deg) !important; }
        @media (--mobile) {
          .l-footer-btnArea a svg {
            transform: translateY(-50%) rotate(0) !important; } }
        .l-footer-btnArea a svg * {
          stroke: #fff !important; }
      @media (--mobile) {
        .l-footer-btnArea a:nth-of-type(n + 2) {
          margin-top: 2rem; } }
    .l-footer-btnArea div:nth-of-type(n + 2) {
      margin-top: 2rem; }
  .l-footer-bnrArea {
    margin-top: 3.5rem; }
    @media (--mobile) {
      .l-footer-bnrArea {
        width: 28rem;
        margin-top: 4.8rem;
        margin-right: auto;
        margin-left: auto; } }
    .l-footer-bnrArea a {
      display: inline-block;
      margin-top: 1.5rem;
      transition: 0.3s; }
      .l-footer-bnrArea a:hover {
        opacity: 0.7; }
    .l-footer-bnrArea p {
      margin-top: 1rem;
      font-size: 1.4rem; }
  .l-footer-search {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    @media (--mobile) {
      .l-footer-search {
        justify-content: center; } }
    .l-footer-search * {
      color: inherit; }
    @media (--mobile) {
      .l-footer-search {
        margin-top: 3.2rem; } }
    .l-footer-search .gsc-control-cse {
      position: relative;
      width: 28rem;
      padding: 0;
      background: none;
      border: none; }
      @media (--mobile) {
        .l-footer-search .gsc-control-cse {
          width: 100%; } }
    .l-footer-search .gsc-control-cse,
    .l-footer-search .gsc-input-box,
    .l-footer-search .gsib_a {
      padding: 0;
      background: none;
      border: none; }
      .l-footer-search .gsc-control-cse input,
      .l-footer-search .gsc-input-box input,
      .l-footer-search .gsib_a input {
        height: inherit !important;
        padding: 1rem 2rem !important;
        margin: 0 !important;
        font-size: 1.3rem !important;
        color: #000 !important;
        background: #fff !important;
        border-radius: 9999px !important; }
        @media (--mobile) {
          .l-footer-search .gsc-control-cse input,
          .l-footer-search .gsc-input-box input,
          .l-footer-search .gsib_a input {
            padding: 1.5rem 2rem !important; } }
        .l-footer-search .gsc-control-cse input::placeholder,
        .l-footer-search .gsc-input-box input::placeholder,
        .l-footer-search .gsib_a input::placeholder {
          color: #9b9b9b; }
    .l-footer-search .gsib_b {
      display: none; }
    .l-footer-search table.gsc-search-box {
      margin-bottom: 0; }
    .l-footer-search table.gsc-search-box td.gsc-input {
      padding: 0; }
    .l-footer-search .gsc-search-button {
      margin-left: 0; }
    .l-footer-search form.gsc-search-box {
      margin-bottom: 0 !important; }
    .l-footer-search button {
      position: absolute;
      top: 50%;
      right: 2.5rem;
      z-index: 2;
      padding: 0;
      background: none !important;
      border: none;
      outline: none;
      transform: translateY(-50%); }
      .l-footer-search button svg {
        width: 2rem;
        height: 2rem; }
        .l-footer-search button svg * {
          fill: #0f4b71; }
  .l-footer-sns {
    display: flex;
    align-items: center; }
    @media (--mobile) {
      .l-footer-sns {
        justify-content: flex-end; } }
    .l-footer-sns p {
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem;
      color: #b2b2b2; }
    .l-footer-sns ul {
      display: flex;
      margin-left: 1.5rem; }
      .l-footer-sns ul li {
        height: 2.8rem; }
        .l-footer-sns ul li:nth-child(n + 2) {
          margin-left: 1rem; }
        @media (--mobile) {
          .l-footer-sns ul li svg {
            height: 2.8rem; } }
  .l-footer-topNav {
    margin-top: 4rem; }
  .l-footer-topList {
    display: flex;
    flex-wrap: wrap; }
    .en .l-footer-topList {
      margin-right: -2.5rem;
      margin-left: -2.5rem; }
  .l-footer-topEle {
    width: calc(100% / 4); }
    .en .l-footer-topEle {
      width: inherit;
      margin: 0 2.5rem; }
    @media (--large) {
      .l-footer-topEle:nth-of-type(n + 5) {
        margin-top: 1.5rem; }
        .en .l-footer-topEle:nth-of-type(n + 5) {
          margin-top: 0; } }
    @media (--mobile) {
      .l-footer-topEle {
        width: calc(100% / 2); }
        .l-footer-topEle:nth-of-type(n + 3) {
          margin-top: 1rem; } }
    @media (--tablet) {
      .l-footer-topEle {
        width: calc(100% / 3); }
        .l-footer-topEle:nth-of-type(n + 5) {
          margin-top: 0; }
        .l-footer-topEle:nth-of-type(n + 4) {
          margin-top: 1.5rem; } }
  .l-footer-topLink {
    display: inline-block;
    padding-bottom: 0.5rem;
    font-size: 1.3rem; }
    @media (--mobile) {
      .l-footer-topLink {
        font-size: 1.4rem;
        letter-spacing: 0;
        white-space: nowrap; } }
    .l-footer-topLink span {
      display: flex;
      align-items: center; }
  .l-footer-icon {
    width: 2.2rem;
    margin-right: 0.5rem; }
    .l-footer-icon img {
      width: 100%; }
  .l-footer-bArea {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2.5rem;
    margin-top: 5rem; }
    @media (--mobile) {
      .l-footer-bArea {
        margin: 0;
        margin-top: 4.2rem;
        border-top: 1px solid rgb(255 255 255 / 20%);
        border-bottom: 1px solid rgb(255 255 255 / 20%); } }
    .l-footer-bArea__nav {
      width: 23.2rem;
      margin: 0 2.5rem; }
      @media (--large) {
        .l-footer-bArea__nav:nth-of-type(n + 4) {
          margin-top: 5.1rem; } }
      @media (--mobile) {
        .l-footer-bArea__nav {
          width: 100%;
          padding: 0 1rem;
          margin: 0; }
          .l-footer-bArea__nav:nth-of-type(n + 2) {
            border-top: 1px solid rgb(255 255 255 / 20%); } }
      @media (--mobile) {
        .l-footer-bArea__nav > a {
          position: relative;
          display: block; }
          .l-footer-bArea__nav > a svg {
            transform: rotate(-90deg); } }
    .l-footer-bArea__sTtl {
      position: relative;
      display: block;
      padding-bottom: 1.5rem;
      margin-bottom: 1.2rem;
      font-size: 1.6rem;
      font-weight: 500;
      border-bottom: 1px solid rgb(255 255 255 / 20%); }
      @media (--large) {
        .l-footer-bArea__sTtl.pc-pn {
          pointer-events: none; } }
      @media (--mobile) {
        .l-footer-bArea__sTtl {
          padding: 2.4rem 0;
          margin-bottom: 0;
          font-size: 1.6rem;
          border-bottom: none; }
          .l-footer-bArea__sTtl.is-active .l-footer-bArea__trigger {
            transform: translateY(-50%) rotate(270deg); } }
      @media (--large) {
        .l-footer-bArea__sTtl svg {
          position: absolute;
          top: 0.8rem;
          right: 0;
          width: 0.8rem; }
          .l-footer-bArea__sTtl svg * {
            stroke: #fff; } }
    @media (--mobile) {
      .l-footer-bArea__trigger {
        position: absolute;
        top: 50%;
        right: 1rem;
        display: inline-block;
        width: 1rem;
        transition: 0.3s transform;
        transform: translateY(-50%) rotate(90deg); }
        .l-footer-bArea__trigger--link {
          transform: translateY(-50%); }
        .l-footer-bArea__trigger * {
          stroke: #fff; }
      .l-footer-bArea__list {
        display: none;
        padding-right: 0.5rem;
        padding-bottom: 2.4rem;
        padding-left: 0.5rem; } }
    .l-footer-bArea__el:nth-of-type(n + 2) {
      margin-top: 0.2rem; }
      @media (--mobile) {
        .l-footer-bArea__el:nth-of-type(n + 2) {
          margin-top: 1rem; } }
    .l-footer-bArea__link {
      display: inline-block;
      padding-bottom: 0.3rem; }
      .l-footer-bArea__link,
      .l-footer-bArea__link * {
        font-size: 1.4rem;
        color: #b2b2b2; }
  .l-footer__pr {
    width: 100%;
    margin-top: 4.6rem;
    font-size: 1.2rem;
    color: #b2b2b2;
    text-align: right; }
    @media (--mobile) {
      .l-footer__pr {
        margin-top: 4.8rem;
        text-align: center; } }

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 10rem; }
  .h-hdn .l-header {
    pointer-events: none; }
  @media (--mobile) {
    .l-header {
      height: 8rem; } }
  .l-header__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 3rem;
    border-top: 0.3rem solid #1A2E56;
    transition: 0.5s transform, 0.2s border-color; }
    .h-mv .l-header__wrap {
      border-color: rgb(26 46 86 / 0%); }
    @media (--mobile) {
      .l-header__wrap {
        padding: 0 2rem; } }
  .l-header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    width: 100%;
    height: 10rem; }
    @media (--mobile) {
      .l-header-overlay {
        height: 8rem; } }
    .h-hdn .l-header-overlay {
      pointer-events: none; }
    .l-header-overlay__wrap {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      transition: 0.5s transform, 0.2s background-color, 0.2s backdrop-filter;
      backdrop-filter: blur(10px); }
      .h-mv .l-header-overlay__wrap {
        background: linear-gradient(to bottom, #00022b, rgb(0 2 43 / 0%));
        background-color: rgba(255, 255, 255, 0.0);
        backdrop-filter: blur(0); }
      .h-mv-mega .l-header-overlay__wrap {
        background: #00012b; }
      @media (--mobile) {
        .l-header-overlay__wrap {
          height: 8rem; } }
  .l-header-logo {
    position: relative;
    z-index: 2;
    width: 18rem; }
    @media (--mobile) {
      .l-header-logo {
        display: flex;
        width: 16rem; } }
    .l-header-logo svg * {
      transition: 0.2s fill; }
    .h-mv .l-header-logo svg * {
      fill: #fff; }
    .h-mv.is-hidden .l-header-logo svg * {
      fill: rgb(26 19 17); }
    .h-mv.is-hidden .l-header-logo svg #pass_425,
    .h-mv.is-hidden .l-header-logo svg #pass_427 {
      fill: rgb(0, 88, 162); }
  .l-header-trigger {
    position: absolute;
    top: 50%;
    right: 2rem;
    z-index: 2;
    width: 5rem;
    height: 5rem;
    background: #1a2e56;
    border-radius: 999rem;
    transform: translateY(-50%); }
    .l-header-trigger span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2rem;
      height: 2px;
      background: #fff;
      transition: 0.3s transform, 0.3s width; }
      .is-open .l-header-trigger span {
        width: 2.4rem; }
      .l-header-trigger span:first-of-type {
        transform: translate(-50%, -50%) translateY(-0.6rem); }
        .is-open .l-header-trigger span:first-of-type {
          transform: translate(-50%, -50%) translateY(0) rotate(30deg); }
      .l-header-trigger span:last-of-type {
        transform: translate(-50%, -50%) translateY(0.6rem); }
        .is-open .l-header-trigger span:last-of-type {
          transform: translate(-50%, -50%) translateY(0) rotate(-30deg); }
  @media (--mobile) {
    .l-header-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: var(--firstViewHeight);
      padding-top: 8rem;
      pointer-events: none;
      background: #fff;
      opacity: 0;
      transition: 0.3s opacity; }
      .is-open .l-header-right {
        pointer-events: inherit;
        opacity: 1; }
    .l-header-top {
      display: flex;
      align-items: center;
      justify-content: center; }
    .l-header-search {
      width: calc(100% - 9rem);
      margin-top: 1rem; }
      .l-header-search .gsc-control-cse {
        position: relative;
        width: 100%;
        padding: 0;
        background: none;
        border: none; }
      .l-header-search .gsc-control-cse,
      .l-header-search .gsc-input-box,
      .l-header-search .gsib_a {
        padding: 0;
        background: none;
        border: none; }
        .l-header-search .gsc-control-cse input,
        .l-header-search .gsc-input-box input,
        .l-header-search .gsib_a input {
          height: inherit !important;
          padding: 1.5rem 2rem !important;
          margin: 0 !important;
          font-size: 1.6rem !important;
          color: #000 !important;
          background: #fff !important;
          border: 1px solid #b2b2b2 !important;
          border-radius: 9999px !important; }
          .l-header-search .gsc-control-cse input::placeholder,
          .l-header-search .gsc-input-box input::placeholder,
          .l-header-search .gsib_a input::placeholder {
            color: #9b9b9b; }
      .l-header-search .gsib_b {
        display: none; }
      .l-header-search table.gsc-search-box {
        margin-bottom: 0; }
      .l-header-search table.gsc-search-box td.gsc-input {
        padding: 0; }
      .l-header-search .gsc-search-button {
        margin-left: 0; }
      .l-header-search form.gsc-search-box {
        margin-bottom: 0 !important; }
      .l-header-search button {
        position: absolute;
        top: 50%;
        right: 2.5rem;
        z-index: 2;
        padding: 0;
        background: none !important;
        border: none;
        outline: none;
        transform: translateY(-50%); }
        .l-header-search button svg {
          width: 2rem;
          height: 2rem; }
          .l-header-search button svg * {
            fill: #0f4b71; } }
  @media (--large) {
    .l-header-top {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .l-header-top-inner {
        display: flex;
        justify-content: flex-end; }
        .l-header-top-inner a {
          font-size: 1.3rem;
          transition: 0.2s color; }
          .h-mv .l-header-top-inner a {
            color: #fff; }
          .l-header-top-inner a:nth-of-type(n + 2) {
            margin-left: 2.6rem; } }
  .l-header-lang {
    display: flex;
    justify-content: flex-end;
    font-size: 1.4rem; }
    @media (--mobile) {
      .l-header-lang {
        margin-left: 1rem; } }
    .l-header-lang a {
      padding: 0 1.2rem;
      color: #b2b2b2 !important;
      transition: 0.2s color; }
      @media (--mobile) {
        .l-header-lang a {
          font-size: 1.9rem; }
          .l-header-lang a:first-of-type {
            padding-left: 0; }
          .l-header-lang a:last-of-type {
            padding-right: 0; } }
      .l-header-lang a:last-of-type {
        position: relative;
        margin-left: 1rem; }
        @media (--large) {
          .l-header-lang a:last-of-type::before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 0.1rem;
            height: 70%;
            content: "";
            background-color: #8491aa;
            transform: translateY(-50%); } }
      .l-header-lang a.is-active {
        font-weight: bold;
        color: #222; }
      .h-mv .l-header-lang a {
        color: #fff; }
  .l-header-nav {
    display: flex;
    align-items: center;
    margin-top: 1.2rem; }
    @media (--mobile) {
      .l-header-nav {
        align-items: flex-start;
        width: 100%;
        height: calc(100% - 7rem - -3rem - 15rem);
        margin-top: 1rem;
        overflow: auto;
        border-top: 1px solid #1a2e56;
        border-bottom: 1px solid #1a2e56; }
        .l-header-nav__gList {
          width: 100%; } }
    .l-header-nav__el {
      position: relative; }
      @media (--large) {
        .l-header-nav__el {
          display: flex; } }
      @media (--large) {
        .l-header-nav__el:nth-of-type(n + 2) {
          margin-left: 3rem; } }
      @media (--mobile) {
        .l-header-nav__el:nth-of-type(n + 2) {
          border-top: 1px solid #1a2e56; } }
      .h-mv-mega .l-header-nav__el.is-active span {
        color: #1a2e56; }
      .h-mv-mega .l-header-nav__el.is-active svg path {
        stroke: #1a2e56 !important; }
      @media (--large) {
        .l-header-nav__el.js-h-mega::before {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          width: calc(100% + 4rem);
          height: calc(100% + 3rem);
          content: "";
          transition: 0.3s all;
          transform: translate(-50%, -50%) translateY(0.15rem); }
        .l-header-nav__el.js-h-mega.is-active::before {
          background-color: #edeff4; } }
      @media (--large) {
        .l-header-nav__el.is-active::after {
          position: absolute;
          top: 0;
          left: 50%;
          width: calc(100% + 10rem);
          height: 5rem;
          content: "";
          transform: translateX(-50%); } }
    .l-header-nav__link {
      font-size: 1.6rem;
      color: #1a2e56;
      transition: 0.2s color; }
      @media (--large) {
        .h-mv .l-header-nav__link {
          color: #fff; } }
      @media (--large) {
        .l-header-nav__link {
          cursor: pointer; }
          .l-header-nav__link.js-h-aco {
            display: inline-block;
            padding-right: 2rem; } }
      @media (--mobile) {
        .l-header-nav__link {
          display: flex;
          align-items: center;
          width: 100%;
          height: 7.4rem;
          padding: 0 3.6rem;
          font-size: 1.8rem;
          font-weight: bold;
          color: #1a2e56; } }
    .l-header-nav__gList {
      display: flex;
      align-items: center; }
      @media (--mobile) {
        .l-header-nav__gList {
          display: block; } }
    .l-header-nav__search {
      position: relative;
      width: 3.2rem;
      height: 3.2rem;
      margin-left: 3rem; }
      @media (--mobile) {
        .l-header-nav__search {
          margin-top: 3.2rem; } }
      .l-header-nav__search .gsc-control-cse {
        position: relative;
        padding: 0;
        background: none;
        border: none; }
        @media (--mobile) {
          .l-header-nav__search .gsc-control-cse {
            width: 100%; } }
      .l-header-nav__search .gsc-input {
        pointer-events: none;
        opacity: 0;
        transition: 0.3s; }
        .l-header-nav__search .gsc-input.is-active {
          pointer-events: inherit;
          opacity: 1; }
      .l-header-nav__search .gsc-control-cse,
      .l-header-nav__search .gsc-input-box,
      .l-header-nav__search .gsib_a {
        padding: 0;
        background: none;
        border: none; }
        .l-header-nav__search .gsc-control-cse input,
        .l-header-nav__search .gsc-input-box input,
        .l-header-nav__search .gsib_a input {
          height: 3.2rem !important;
          padding: 0.5rem 1rem !important;
          margin: 0 !important;
          font-size: 1.3rem !important;
          color: #000 !important;
          background: #fff !important;
          border: 1px solid #1a2e56 !important;
          border-radius: 9999px !important; }
          .h-mv .l-header-nav__search .gsc-control-cse input, .h-mv
          .l-header-nav__search .gsc-input-box input, .h-mv
          .l-header-nav__search .gsib_a input {
            color: #fff !important;
            background: #00012b !important; }
          .l-header-nav__search .gsc-control-cse input::placeholder,
          .l-header-nav__search .gsc-input-box input::placeholder,
          .l-header-nav__search .gsib_a input::placeholder {
            color: #9b9b9b; }
      .l-header-nav__search .gsib_a {
        position: absolute;
        top: 0;
        right: 0;
        width: 20rem; }
      .l-header-nav__search .gsib_b {
        display: none; }
      .l-header-nav__search table.gsc-search-box {
        margin-bottom: 0; }
      .l-header-nav__search table.gsc-search-box td.gsc-input {
        padding: 0; }
      .l-header-nav__search .gsc-search-button {
        margin-left: 0; }
      .l-header-nav__search form.gsc-search-box {
        margin-bottom: 0 !important; }
      .l-header-nav__search .gsc-search-button {
        width: 3.2rem;
        height: 3.2rem;
        background: #1a2e56 !important;
        border-radius: 9999px;
        transition: 0.2s background; }
        .h-mv .l-header-nav__search .gsc-search-button {
          background: #fff !important; }
        .l-header-nav__search .gsc-search-button button {
          position: absolute;
          top: 50%;
          z-index: 2;
          padding: 0;
          border: none;
          outline: none;
          transform: translateY(-50%); }
          .l-header-nav__search .gsc-search-button button svg {
            position: relative;
            z-index: 3;
            width: 1.2rem;
            height: 1.2rem; }
            .l-header-nav__search .gsc-search-button button svg * {
              fill: #fff;
              transition: 0.2s fill; }
              .h-mv .l-header-nav__search .gsc-search-button button svg * {
                fill: #1a2e56; }
      .l-header-nav__search .gsc-modal-background-image {
        height: 100vh; }
      .l-header-nav__search .gsc-results-wrapper-overlay {
        height: 80vh;
        margin-top: 10vh; }
    @media (--large) {
      .l-header-nav__trigger {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        width: 0.8rem;
        transform: translateY(-50%) rotate(90deg); }
        .l-header-nav__trigger * {
          transition: 0.2s stroke; }
        .h-mv .l-header-nav__trigger * {
          stroke: #fff; } }
    @media (--mobile) {
      .l-header-nav__trigger {
        position: absolute;
        top: 1.5rem;
        right: 2.6rem;
        width: 4rem;
        height: 4rem;
        pointer-events: none;
        transition: 0.3s transform;
        transform: rotate(90deg); }
        .l-header-nav__trigger svg {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1.2rem;
          transition: 0.3s transform;
          transform: translate(-50%, -50%) translateX(25%); }
        .l-header-nav__trigger.is-active {
          transform: rotate(-90deg); }
          .l-header-nav__trigger.is-active svg {
            transform: translate(-50%, -50%) translateX(-25%); } }
    .l-header-nav__openList {
      display: none;
      padding-right: 3rem;
      padding-bottom: 2.4rem;
      padding-left: 5.5rem; }
    .l-header-nav__openEle {
      font-size: 1.5rem; }
      .l-header-nav__openEle:nth-of-type(n + 2) {
        margin-top: 1.5rem; }
    .l-header-nav__openLink {
      display: flex;
      align-items: center;
      font-weight: bold; }
      .l-header-nav__openLink svg {
        width: 0.8rem;
        margin-right: 1rem; }
  @media (--mobile) {
    .l-header-cBtn {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 15rem;
      background: #edeff4; }
      .l-header-cBtn a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35rem;
        height: 8rem;
        font-size: 2rem;
        color: #fff;
        text-align: center;
        background: #1a2e56; } }
  .l-header-mega {
    position: fixed;
    top: -5rem;
    left: 0;
    z-index: 98;
    width: 100%;
    padding-top: 15.5rem;
    padding-bottom: 6rem;
    overflow: auto;
    pointer-events: none;
    background: #edeff4;
    opacity: 0;
    transition: 0.3s opacity, 0.3s top; }
    .l-header-mega.is-active {
      top: 0;
      pointer-events: inherit;
      opacity: 1; }
    .l-header-mega__ttl {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2.5rem;
      line-height: 1.4;
      color: #1a2e56; }
      .l-header-mega__ttl strong {
        display: block;
        font-family: "Roboto", sans-serif;
        font-size: 3.4rem;
        line-height: 1;
        color: #1a2e56; }
      .l-header-mega__ttl small {
        position: relative;
        top: -0.2rem;
        margin-left: 1rem;
        font-size: 1.5rem; }
  .l-header-products__wrap {
    display: flex;
    justify-content: space-between; }
    .l-header-products__wrap > div {
      width: 48rem; }
      .l-header-products__wrap > div:last-of-type {
        width: 54rem; }
  .l-header-products__parent {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    margin-bottom: 2rem; }
    @media (--large) {
      .l-header-products__parent.tool {
        align-items: center; } }
    .l-header-products__parent img {
      width: 13rem; }
    .l-header-products__parent p {
      position: relative;
      padding-right: 3.6rem;
      margin-top: 0.6rem;
      margin-left: 2.4rem;
      font-size: 2.2rem;
      font-weight: bold;
      color: #1a2e56; }
      .l-header-products__parent p::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 2.5rem;
        height: 2.5rem;
        content: "";
        background: url(../images/common/arrow_icon01.png) no-repeat center center/contain;
        transform: translateY(-50%); }
      .l-header-products__parent p::before {
        position: absolute;
        bottom: -0.3rem;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        content: "";
        background-color: #1a2e56;
        opacity: 0;
        transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
        transition-duration: 0.6s;
        transition-property: opacity, transform;
        transform: scale3d(0.8, 1, 1);
        transform-origin: left center; }
    @media (--large) {
      .l-header-products__parent:hover p::before {
        opacity: 1;
        transform: none; } }
  .l-header-products__lineup {
    display: flex;
    align-items: center; }
    .l-header-products__lineup a {
      display: flex; }
      .l-header-products__lineup a svg {
        width: 0.7rem;
        margin-right: 0.5rem; }
    .l-header-products__lineup form {
      display: flex;
      align-items: center;
      height: 3.5rem;
      margin-left: 2rem; }
      .l-header-products__lineup form input {
        width: 16rem;
        height: 100%;
        padding: 0;
        padding: 1rem;
        font-size: 1.4rem;
        border: 1px solid rgb(0 0 0 / 20%); }
        .l-header-products__lineup form input::placeholder {
          color: #b2b2b2; }
      .l-header-products__lineup form button {
        width: 9rem;
        height: 100%;
        padding: 0;
        font-size: 1.4rem;
        color: #fff;
        white-space: nowrap;
        background: #1a2e56; }
  .l-header-products-side {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem; }
    .l-header-products-side__2col {
      width: 22rem; }
    .l-header-products-side__3col {
      width: 16rem; }
    .l-header-products-side__2col p, .l-header-products-side__3col p {
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;
      font-size: 1.6rem;
      color: #1a2e56;
      border-bottom: 1px solid #1a2e56; }
      .en .l-header-products-side__2col p, .en .l-header-products-side__3col p {
        display: flex;
        align-items: flex-end;
        min-height: 6.5rem; }
    .l-header-products-side__2col ul li:nth-of-type(n + 2), .l-header-products-side__3col ul li:nth-of-type(n + 2) {
      margin-top: 0.5rem; }
    .l-header-products-side__2col ul li a, .l-header-products-side__3col ul li a {
      position: relative;
      display: inline-block;
      padding-left: 1.2rem;
      font-size: 1.5rem;
      white-space: nowrap; }
      .l-header-products-side__2col ul li a svg, .l-header-products-side__3col ul li a svg {
        position: absolute;
        top: 50%;
        left: 0;
        width: 0.6rem;
        transform: translateY(-50%); }
    .l-header-products-side__parts {
      margin-top: 2.8rem; }
      .l-header-products-side__parts a {
        position: relative;
        display: inline-block;
        padding-right: 3.5rem;
        font-size: 2.2rem;
        font-weight: bold; }
        .l-header-products-side__parts a::after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 2.5rem;
          height: 2.5rem;
          content: "";
          background: url(../images/common/arrow_icon01.png) no-repeat center center/contain;
          transform: translateY(-50%); }
        .l-header-products-side__parts a:nth-of-type(n + 2) {
          margin-left: 5rem; }
  .l-header-products__top-inner {
    position: relative; }
  .l-header-download__list {
    display: flex; }
    .l-header-download__list li:nth-of-type(n + 2) {
      margin-left: 6rem; }
    .l-header-download__list li a {
      position: relative;
      display: inline-block;
      padding-right: 3.5rem;
      font-size: 2.2rem;
      font-weight: bold; }
      .l-header-download__list li a::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 2.5rem;
        height: 2.5rem;
        content: "";
        background: url(../images/common/arrow_icon01.png) no-repeat center center/contain;
        transform: translateY(-50%); }
  .l-header-support__top {
    display: flex;
    justify-content: space-between; }
  .l-header-support__menu {
    display: flex;
    margin-top: 1rem; }
    .l-header-support__menu a {
      position: relative;
      display: flex;
      align-items: center;
      width: 25rem;
      height: 6rem;
      padding-left: 5.2rem;
      font-size: 1.6rem;
      border: 1px solid #1a2e56;
      transition: 0.3s; }
      .l-header-support__menu a:nth-of-type(n + 2) {
        margin-left: 2rem; }
      @media (--large) {
        .l-header-support__menu a:hover {
          color: #fff;
          background: #1a2e56; } }
      .l-header-support__menu a .l-header-support__menu--logo svg,
      .l-header-support__menu a .l-header-support__menu--logo svg *,
      .l-header-support__menu a .l-header-support__menu--arrow,
      .l-header-support__menu a .l-header-support__menu--arrow svg * {
        transition: 0.3s; }
      @media (--large) {
        .l-header-support__menu a:hover .l-header-support__menu--logo svg * {
          fill: #fff; }
        .l-header-support__menu a:hover .l-header-support__menu--arrow path {
          stroke: #fff; } }
    .l-header-support__menu--logo {
      position: absolute;
      top: 50%;
      left: 1.7rem;
      display: flex;
      width: 2.2rem;
      transform: translateY(-50%); }
    .l-header-support__menu--arrow {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      display: flex;
      width: 0.8rem;
      transform: translateY(-50%); }
  .l-header-support__list {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem -1rem 0; }
    .l-header-support__list li {
      width: calc(100% / 4 - 2rem);
      margin: 0 1rem; }
      .l-header-support__list li:nth-of-type(2) svg {
        width: 4.3rem; }
      .l-header-support__list li:nth-of-type(7) svg {
        width: 4.4rem; }
      .l-header-support__list li:nth-of-type(8) svg {
        width: 3.2rem; }
      .l-header-support__list li:nth-of-type(n + 5) {
        margin-top: 2rem; }
      .l-header-support__list li a {
        display: block;
        padding: 3rem 0 2.5rem;
        font-size: 1.6rem;
        text-align: center;
        border: 1px solid #1a2e56;
        transition: 0.3s; }
        .l-header-support__list li a figure {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.3rem;
          height: 4.1rem;
          margin-right: auto;
          margin-bottom: 2rem;
          margin-left: auto; }
          .l-header-support__list li a figure svg {
            max-width: 100%;
            max-height: 100%; }
        @media (--large) {
          .l-header-support__list li a:hover {
            color: #fff;
            background: #1a2e56; }
            .l-header-support__list li a:hover svg,
            .l-header-support__list li a:hover svg * {
              fill: #fff; } }

/**
 * main.scss
 */
.js-open {
  cursor: pointer; }
  .js-open .arrow-bottom {
    transition: 0.3s; }
  .js-open.is-open .arrow-bottom {
    transform: translateY(-50%) rotate(-180deg) !important; }

.js-acco__content {
  display: none; }

@media (--large) {
  .c-articleDetail {
    width: 90rem;
    margin-right: auto;
    margin-left: auto; } }

.c-articleDetail-ttl__h1 {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  font-size: 3rem;
  border-bottom: 1px solid #8491aa; }
  @media (--mobile) {
    .c-articleDetail-ttl__h1 {
      font-size: 2.2rem; } }

.c-articleDetail-ttl__btm {
  display: flex;
  align-items: center; }
  .c-articleDetail-ttl__btm--date {
    font-weight: bold;
    color: #1a2e56;
    letter-spacing: 0; }
  .c-articleDetail-ttl__btm--tag {
    display: flex;
    justify-content: center;
    width: 12rem;
    padding: 0.2rem 1rem;
    margin-right: 2.4rem;
    margin-left: 2rem;
    overflow: hidden;
    font-size: 1.3rem;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #1a2e56; }

.c-articleDetail-btn {
  display: flex;
  justify-content: center;
  padding-top: 6rem;
  margin-top: 8rem;
  border-top: 1px solid #8491aa; }
  @media (--mobile) {
    .c-articleDetail-btn {
      padding-top: 3rem;
      margin-top: 5rem; } }
  @media (--large) {
    .c-articleDetail-btn__wrap {
      width: 12rem; } }
  @media (--mobile) {
    .c-articleDetail-btn__wrap {
      width: 10.5rem; } }
  @media (--mobile) {
    .c-articleDetail-btn > div:nth-of-type(1), .c-articleDetail-btn > div:nth-of-type(3) {
      width: 10.5rem; }
    .c-articleDetail-btn > div:nth-of-type(1) a {
      justify-content: flex-end !important; }
      .c-articleDetail-btn > div:nth-of-type(1) a svg {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%); }
    .c-articleDetail-btn > div:nth-of-type(3) a {
      justify-content: flex-start !important; }
      .c-articleDetail-btn > div:nth-of-type(3) a svg {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%); }
    .c-articleDetail-btn > div:nth-of-type(2) a {
      width: 12rem !important; } }
  .c-articleDetail-btn a {
    height: 7rem !important;
    font-size: 1.6rem !important; }
    @media (--mobile) {
      .c-articleDetail-btn a {
        width: 100% !important;
        height: 6rem !important;
        padding: 1rem !important; } }
  .c-articleDetail-btn .c-btn03 a {
    width: 26rem; }
    @media (--mobile) {
      .c-articleDetail-btn .c-btn03 a {
        width: 13rem; } }

.c-breadcrumb {
  display: flex;
  align-items: center;
  height: 4rem;
  padding-right: 3rem;
  background: #1a2e56; }
  @media (--mobile) {
    .c-breadcrumb {
      overflow: auto; }
      .c-breadcrumb .c-i-inner {
        width: auto;
        min-width: 100%; } }
    @media (--mobile) and (--mobile) {
      .c-breadcrumb .c-i-inner {
        padding-right: 0; } }
  .c-breadcrumb__list {
    display: flex;
    align-items: center; }
  .c-breadcrumb__el {
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap; }
    @media (--mobile) {
      .c-breadcrumb__el {
        text-overflow: inherit; } }
    .c-breadcrumb__el,
    .c-breadcrumb__el * {
      font-size: 1.3rem; }
    .c-breadcrumb__el:nth-of-type(n + 2) {
      padding-left: 1.5rem;
      margin-left: 0.5rem; }
      .c-breadcrumb__el:nth-of-type(n + 2)::before {
        position: absolute;
        top: 50%;
        left: 0.5rem;
        width: 0.1rem;
        height: 60%;
        content: "";
        background: #fff;
        transform: translateY(-50%) rotate(14deg); }
    .c-breadcrumb__el,
    .c-breadcrumb__el * {
      font-weight: lighter;
      color: #fff; }

.c-btn01 a,
.c-btn01 button,
.c-btn02 a,
.c-btn02 button,
.c-btn03 a,
.c-btn03 button,
.c-btn04 a,
.c-btn04 button,
.c-btn05 a,
.c-btn05 button,
.c-btn06 a,
.c-btn06 button,
.c-btn07 a,
.c-btn07 button,
.c-btn08 a,
.c-btn08 button,
.c-btn09 a,
.c-btn09 button,
.c-btn10 a,
.c-btn10 button,
.c-btn11 a,
.c-btn11 button,
.c-btn12 a,
.c-btn12 button,
.c-btn13 a,
.c-btn13 button {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  width: 38rem;
  height: 10rem;
  padding: 0 4rem;
  font-size: 2.2rem;
  color: #fff;
  background: #1a2e56; }
  @media (--mobile) {
    .c-btn01 a,
    .c-btn01 button,
    .c-btn02 a,
    .c-btn02 button,
    .c-btn03 a,
    .c-btn03 button,
    .c-btn04 a,
    .c-btn04 button,
    .c-btn05 a,
    .c-btn05 button,
    .c-btn06 a,
    .c-btn06 button,
    .c-btn07 a,
    .c-btn07 button,
    .c-btn08 a,
    .c-btn08 button,
    .c-btn09 a,
    .c-btn09 button,
    .c-btn10 a,
    .c-btn10 button,
    .c-btn11 a,
    .c-btn11 button,
    .c-btn12 a,
    .c-btn12 button,
    .c-btn13 a,
    .c-btn13 button {
      width: 80%;
      height: 8rem;
      padding: 2rem;
      font-size: 2rem; } }
  .c-btn01 a svg,
  .c-btn01 button svg,
  .c-btn02 a svg,
  .c-btn02 button svg,
  .c-btn03 a svg,
  .c-btn03 button svg,
  .c-btn04 a svg,
  .c-btn04 button svg,
  .c-btn05 a svg,
  .c-btn05 button svg,
  .c-btn06 a svg,
  .c-btn06 button svg,
  .c-btn07 a svg,
  .c-btn07 button svg,
  .c-btn08 a svg,
  .c-btn08 button svg,
  .c-btn09 a svg,
  .c-btn09 button svg,
  .c-btn10 a svg,
  .c-btn10 button svg,
  .c-btn11 a svg,
  .c-btn11 button svg,
  .c-btn12 a svg,
  .c-btn12 button svg,
  .c-btn13 a svg,
  .c-btn13 button svg {
    position: absolute;
    top: 50%;
    right: 3rem;
    width: 3.5rem;
    height: 1rem;
    transform: translateY(-50%) translateY(-0.2rem); }
    @media (--mobile) {
      .c-btn01 a svg,
      .c-btn01 button svg,
      .c-btn02 a svg,
      .c-btn02 button svg,
      .c-btn03 a svg,
      .c-btn03 button svg,
      .c-btn04 a svg,
      .c-btn04 button svg,
      .c-btn05 a svg,
      .c-btn05 button svg,
      .c-btn06 a svg,
      .c-btn06 button svg,
      .c-btn07 a svg,
      .c-btn07 button svg,
      .c-btn08 a svg,
      .c-btn08 button svg,
      .c-btn09 a svg,
      .c-btn09 button svg,
      .c-btn10 a svg,
      .c-btn10 button svg,
      .c-btn11 a svg,
      .c-btn11 button svg,
      .c-btn12 a svg,
      .c-btn12 button svg,
      .c-btn13 a svg,
      .c-btn13 button svg {
        right: 2rem;
        transform: translateY(-50%) translateY(-0.3rem); } }

[class*="c-btn-hover"] {
  position: relative;
  overflow: hidden; }

.c-btn-hover01 a,
.c-btn-hover01 button {
  overflow: hidden; }
  .c-btn-hover01 a::before,
  .c-btn-hover01 button::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #0058a2;
    opacity: 0;
    transition: 0.3s; }
  .c-btn-hover01 a svg,
  .c-btn-hover01 button svg {
    transition: 0.3s all; }
  @media (--large) {
    .c-btn-hover01 a:hover svg,
    .c-btn-hover01 button:hover svg {
      right: 2rem; }
    .c-btn-hover01 a:hover::before,
    .c-btn-hover01 button:hover::before {
      left: 0;
      opacity: 1; } }

.c-btn-hover01--right a,
.c-btn-hover01--right button {
  overflow: hidden; }
  .c-btn-hover01--right a::before,
  .c-btn-hover01--right button::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #0058a2;
    opacity: 0;
    transition: 0.3s; }
  .c-btn-hover01--right a svg,
  .c-btn-hover01--right button svg {
    transition: 0.3s all; }
  @media (--large) {
    .c-btn-hover01--right a:hover svg,
    .c-btn-hover01--right button:hover svg {
      right: 1rem; }
    .c-btn-hover01--right a:hover::before,
    .c-btn-hover01--right button:hover::before {
      left: 0;
      opacity: 1; } }

.c-btn-hover02 a {
  overflow: hidden; }
  .c-btn-hover02 a::before {
    position: absolute;
    top: 0;
    right: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #0058a2;
    opacity: 0;
    transition: 0.3s; }
  .c-btn-hover02 a svg {
    transition: 0.3s all; }
  @media (--large) {
    .c-btn-hover02 a:hover svg {
      right: 0;
      left: 2rem; }
    .c-btn-hover02 a:hover::before {
      right: 0;
      opacity: 1; } }

.c-btn-hover03 a {
  overflow: hidden; }
  .c-btn-hover03 a::before {
    position: absolute;
    top: 0;
    right: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #0058a2;
    opacity: 0;
    transition: 0.3s; }
  .c-btn-hover03 a svg {
    transition: 0.3s all; }
  @media (--large) {
    .c-btn-hover03 a:hover {
      color: #fff; }
      .c-btn-hover03 a:hover svg * {
        stroke: #fff; }
    .c-btn-hover03 a:hover::before {
      right: 0;
      opacity: 1; } }

.c-btn-hover04 a {
  overflow: hidden; }
  .c-btn-hover04 a::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #0058a2;
    opacity: 0;
    transition: 0.3s; }
  .c-btn-hover04 a svg {
    transition: 0.3s all; }
  @media (--large) {
    .c-btn-hover04 a:hover {
      color: #fff; }
      .c-btn-hover04 a:hover span {
        color: #fff; }
        .c-btn-hover04 a:hover span::after {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff; }
      .c-btn-hover04 a:hover svg * {
        stroke: #fff; }
    .c-btn-hover04 a:hover::before {
      left: 0;
      opacity: 1; }
    .c-btn-hover04 a:hover::after {
      border-top: 2px solid #fff;
      border-right: 2px solid #fff; } }

.c-btn-hover05 a {
  overflow: hidden; }
  .c-btn-hover05 a::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #0058a2;
    opacity: 0;
    transition: 0.3s; }
  .c-btn-hover05 a svg {
    transition: 0.3s all; }
  @media (--large) {
    .c-btn-hover05 a:hover {
      color: #fff; }
      .c-btn-hover05 a:hover span {
        color: #fff; }
        .c-btn-hover05 a:hover span::after {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff; }
      .c-btn-hover05 a:hover svg * {
        fill: #fff;
        stroke: #fff; }
    .c-btn-hover05 a:hover::before {
      left: 0;
      opacity: 1; }
    .c-btn-hover05 a:hover::after {
      border-top: 2px solid #fff;
      border-right: 2px solid #fff; } }

.c-btn02 a {
  justify-content: flex-end; }
  .c-btn02 a svg {
    right: inherit;
    left: 3rem;
    transform: translateY(-50%) translateY(-0.3rem) scale(-1, 1) !important; }

.c-btn03 a {
  justify-content: center; }
  .c-btn03 a svg {
    display: none; }

.c-btn04 a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  padding: 0;
  font-size: 1.6rem; }
  .c-btn04 a svg {
    position: static;
    width: 1rem;
    height: inherit;
    margin-left: 1rem;
    transform: inherit; }
    @media (--mobile) {
      .c-btn04 a svg {
        position: absolute;
        right: 2rem; } }
    .c-btn04 a svg * {
      stroke: #fff; }

.c-btn05 a {
  justify-content: center;
  width: 12rem;
  padding: 0;
  font-size: 1.6rem; }
  .c-btn05 a svg {
    position: static;
    width: 1rem;
    height: inherit;
    margin-right: 1rem;
    transform: inherit; }
    @media (--mobile) {
      .c-btn05 a svg {
        position: absolute;
        left: 2rem; } }
    .c-btn05 a svg * {
      stroke: #fff; }

.c-btn06 a {
  width: 46rem;
  font-size: 1.9rem;
  color: #1a2e56;
  background-color: #fff;
  border: 0.1rem solid #1a2e56; }
  @media (--mobile) {
    .c-btn06 a {
      padding-left: 6rem; } }
  .c-btn06 a svg {
    position: static;
    width: 1rem;
    height: inherit;
    margin-right: 4rem;
    transform: inherit; }
    @media (--mobile) {
      .c-btn06 a svg {
        position: absolute;
        left: 2rem; } }
    .c-btn06 a svg * {
      stroke: #1a2e56; }

.c-btn07 a {
  position: relative;
  justify-content: center;
  width: 23rem;
  height: 5rem;
  padding: 0;
  margin: 0 auto;
  font-size: 1.6rem;
  color: #1a2e56;
  background-color: #fff;
  border: 0.1rem solid #1a2e56;
  border-radius: 9999px; }
  .c-btn07 a::after {
    position: absolute;
    top: 50%;
    right: 2rem;
    width: 1rem;
    height: 1rem;
    content: "";
    border-top: 2px solid #1a2e56;
    border-right: 2px solid #1a2e56;
    transform: translateY(-50%) rotate(45deg); }

.c-btn08 a {
  justify-content: left;
  width: 25rem;
  height: 6rem;
  padding-left: 3.4rem !important;
  font-size: 1.6rem;
  font-weight: bold;
  color: #1a2e56;
  background-color: #fff;
  border: 0.1rem solid #1a2e56; }
  .c-btn08 a svg {
    position: absolute;
    top: 50%;
    right: 2.7rem !important;
    width: 2rem;
    height: inherit;
    transform: translateY(-50%) !important; }
    @media (--mobile) {
      .c-btn08 a svg {
        position: absolute; } }
    .c-btn08 a svg * {
      stroke: #1a2e56; }

.c-btn09 a {
  justify-content: center;
  width: 17rem;
  height: 5rem;
  padding: 0;
  padding-right: 2rem;
  background-color: #fff;
  border: 0.1rem solid #1a2e56; }
  .c-btn09 a span {
    position: relative;
    font-size: 1.6rem;
    color: #1a2e56; }
    .c-btn09 a span::after {
      position: absolute;
      top: 50%;
      right: -2rem;
      width: 1rem;
      height: 1rem;
      content: "";
      border-top: 2px solid #1a2e56;
      border-right: 2px solid #1a2e56;
      transform: translateY(-50%) rotate(45deg); }

.c-btn10 a {
  justify-content: flex-start;
  width: 38rem;
  height: 8rem;
  padding: 0 0 0 3rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: #1a2e56;
  background-color: #fff;
  border: 0.1rem solid #1a2e56; }
  @media (--mobile) {
    .c-btn10 a {
      width: 100%;
      padding-left: 5rem;
      font-size: 1.6rem; } }
  .c-btn10 a svg {
    position: static;
    width: 2rem;
    height: inherit;
    margin-right: 1.5rem;
    transform: inherit; }
    @media (--mobile) {
      .c-btn10 a svg {
        position: absolute;
        left: 2rem;
        transform: translateY(-50%); } }
    .c-btn10 a svg * {
      stroke: #1a2e56; }
  .c-btn10 a::after {
    position: absolute;
    top: 50%;
    right: 2rem;
    width: 1rem;
    height: 1rem;
    content: "";
    border-top: 2px solid #1a2e56;
    border-right: 2px solid #1a2e56;
    transform: translateY(-50%) rotate(45deg); }

.c-btn11 a {
  justify-content: flex-start;
  width: 38rem;
  height: 8rem;
  padding: 0 0 0 3rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: #1a2e56;
  background-color: #fff;
  border: 0.1rem solid #1a2e56; }
  @media (--mobile) {
    .c-btn11 a {
      width: 100%;
      padding-left: 5rem;
      font-size: 1.6rem; } }
  .c-btn11 a svg {
    position: static;
    width: 2rem;
    height: inherit;
    margin-right: 1.5rem;
    transform: inherit; }
    @media (--mobile) {
      .c-btn11 a svg {
        position: absolute;
        left: 2rem;
        transform: translateY(-50%); } }
    .c-btn11 a svg * {
      stroke: #1a2e56; }
  .c-btn11 a::after {
    position: absolute;
    top: 50%;
    right: 2rem;
    width: 1rem;
    height: 1rem;
    content: "";
    border-top: 2px solid #1a2e56;
    border-right: 2px solid #1a2e56;
    transform: translateY(-50%) rotate(45deg); }

.c-btn12 a {
  position: relative;
  width: 46rem;
  font-size: 1.9rem;
  color: #1a2e56;
  background-color: #fff;
  border: 0.1rem solid #1a2e56; }
  .c-btn12 a svg {
    position: absolute;
    top: 50%;
    width: 1rem;
    height: inherit;
    transform: translateY(-50%) rotate(180deg); }
    @media (--mobile) {
      .c-btn12 a svg {
        transform: translateY(-50%) rotate(180deg); } }
    .c-btn12 a svg * {
      stroke: #1a2e56; }

.c-btn13 a {
  position: relative;
  width: 32rem;
  height: 7rem;
  font-size: 1.6rem;
  color: #1a2e56;
  background-color: #fff;
  border: 0.2rem solid #1a2e56; }
  @media (--mobile) {
    .c-btn13 a {
      width: 100%; } }
  .c-btn13 a svg {
    position: absolute;
    top: 50%;
    width: 2rem;
    height: inherit; }
    .c-btn13 a svg * {
      stroke: #1a2e56; }

.c-caption {
  width: 100%;
  padding: 2rem 2.5rem 2rem 3rem;
  background-color: #edeff4; }
  @media (--mobile) {
    .c-caption {
      width: 100%;
      padding: 2rem; } }
  .c-caption-inner {
    font-size: 1.6rem; }
    .c-caption-inner .title {
      position: relative;
      padding-left: 1.5rem;
      font-weight: bold;
      color: #1a2e56; }
      .c-caption-inner .title::before {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 0.9rem;
        height: 0.9rem;
        content: "";
        background-color: #1a2e56;
        border-radius: 50%;
        transform: translateY(-50%); }
    .c-caption-inner .text {
      margin-top: 0.6rem; }
      .c-caption-inner .text b {
        text-decoration: underline; }
    .c-caption-inner .caution {
      font-weight: bold;
      text-decoration: underline;
      text-decoration-color: #222222; }

.cursor {
  position: fixed;
  top: -4rem;
  left: -4rem;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  font-size: 1.3rem;
  pointer-events: none;
  background: rgb(247 247 247 / 0%);
  border-radius: 50%;
  transition: 0.3s top, 0.3s left, 0.3s width, 0.3s height, 0.3s background; }
  .cursor.is-active {
    top: -6.5rem;
    left: -6.5rem;
    width: 13rem;
    height: 13rem;
    background: rgb(247 247 247 / 80%); }
  .cursor_wrap {
    overflow: hidden; }
    .cursor_wrap span {
      display: inline-block;
      opacity: 0;
      transition: 0.3s;
      transform: translateY(100%); }
      .cursor_wrap span:nth-of-type(1) {
        transition-delay: 0.03333s; }
      .cursor_wrap span:nth-of-type(2) {
        transition-delay: 0.06667s; }
      .cursor_wrap span:nth-of-type(3) {
        transition-delay: 0.1s; }
      .cursor_wrap span:nth-of-type(4) {
        transition-delay: 0.13333s; }
      .cursor_wrap span:nth-of-type(5) {
        transition-delay: 0.16667s; }
      .cursor_wrap span:nth-of-type(6) {
        transition-delay: 0.2s; }
  .cursor.is-active span {
    opacity: 1 !important;
    transform: translateY(0%); }
  .cursor.is-active.black {
    background: rgb(0 0 0 / 70%); }
    .cursor.is-active.black span {
      color: #fff; }

.c-dl {
  padding-top: 6rem;
  padding-bottom: 7.5rem;
  margin-top: 10rem;
  text-align: center;
  border-top: 1px solid #8491aa; }
  @media (--mobile) {
    .c-dl {
      padding-top: 4rem;
      padding-bottom: 4rem;
      margin-top: 5rem; } }
  .c-dl-txt {
    margin-top: 2.4rem;
    font-size: 2rem; }
    @media (--mobile) {
      .c-dl-txt {
        font-size: 1.8rem; } }
  .c-dl-search {
    margin-top: 3.7rem; }
    .c-dl-search label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43.3rem;
      height: 6.8rem;
      margin-right: auto;
      margin-left: auto;
      border: 1px solid #c7ced3;
      border-radius: 999rem; }
      @media (--mobile) {
        .c-dl-search label {
          max-width: 100%;
          height: 6rem; } }
      .c-dl-search label input {
        width: 30rem;
        font-size: 2rem; }
    .c-dl-search__button {
      position: absolute;
      top: 50%;
      left: 2rem;
      display: flex;
      padding: 0;
      transform: translateY(-50%); }
      .c-dl-search__button svg {
        width: 2.7rem;
        transition: 0.3s; }
        .c-dl-search__button svg * {
          fill: #1a2e56;
          transition: 0.3s; }
      @media (--large) {
        .c-dl-search__button:hover svg {
          opacity: 0.7; } }
  .c-dl-caution {
    margin-top: 2.5rem;
    font-size: 1.6rem;
    color: #8491aa; }
    @media (--mobile) {
      .c-dl-caution {
        display: flex;
        align-items: center;
        justify-content: center; } }
  .c-dl-list {
    display: flex;
    justify-content: space-between;
    margin-top: 5.5rem; }
    @media (--mobile) {
      .c-dl-list {
        margin: 3rem -3rem 0; } }
    .c-dl-list-el {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 3); }
      .en .c-dl-list-el {
        width: calc(100% / 2); }
      .c-dl-list-el:nth-of-type(n + 2) {
        border-left: 1px solid #c7ced3; }
    .c-dl-list-link {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 15rem; }
      @media (--large) {
        .c-dl-list-link::after {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          width: 0;
          height: 0;
          content: "";
          background: #edeff4;
          opacity: 0;
          transition: 0.3s;
          transform: translate(-50%, -50%); }
        .c-dl-list-link:hover::after {
          width: calc(100% - 1.3rem * 2);
          height: 100%;
          opacity: 1; } }
      @media (--mobile) {
        .c-dl-list-link {
          height: 14rem; } }
      .c-dl-list-link__img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4.7rem; }
        @media (--mobile) {
          .c-dl-list-link__img {
            height: 3.2rem; } }
        .c-dl-list-link__img svg {
          height: 100%; }
      .c-dl-list-link__txt {
        margin-top: 2.5rem;
        font-size: 1.8rem;
        color: #1a2e56; }
        @media (--mobile) {
          .c-dl-list-link__txt {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 5rem;
            margin-top: 1.5rem;
            font-size: 1.6rem;
            line-height: 1.4; } }

.c-step-order li {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 5rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #c7ced3;
  transition: 0.3s; }
  .c-step-order li::before {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    width: 1.8rem;
    height: 1.8rem;
    content: "";
    background: #c7ced3;
    border-radius: 9999px;
    transition: 0.3s;
    transform: translateY(-50%) translateY(0.1rem); }
  .c-step-order li::after {
    position: absolute;
    bottom: 0;
    left: calc(1.8rem / 2 - 0.1rem);
    z-index: 1;
    width: 0.2rem;
    height: 6.5rem;
    content: "";
    background: #c7ced3;
    border-radius: 9999px;
    transition: 0.3s;
    transform: translateY(0); }
  .c-step-order li:first-of-type::after {
    display: none; }
  .c-step-order li:last-of-type {
    margin-bottom: 0; }
  .c-step-order li.is-active {
    color: #1a2e56; }
    .c-step-order li.is-active::after {
      background: #1a2e56; }
    .c-step-order li.is-active::before {
      background: #1a2e56; }

.c-f-txt01 dt span,
.c-f-txtarea dt span {
  position: relative; }
  .c-f-txt01 dt span b,
  .c-f-txtarea dt span b {
    font-size: 1.6rem;
    color: #1a2e56; }
  .c-f-txt01 dt span small,
  .c-f-txtarea dt span small {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    height: 2rem;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    background: #1a2e56;
    transform: translateY(-50%) translateX(100%) translateX(0.8rem); }
    .en .c-f-txt01 dt span small, .en
    .c-f-txtarea dt span small {
      width: 10rem;
      height: 2.5rem; }

.c-f-txt01 dd span,
.c-f-txtarea dd span {
  margin-top: 0.5rem;
  color: #1a2e56; }

.c-f-txt01 dd,
.c-f-txt01 div,
.c-f-txtarea dd,
.c-f-txtarea div {
  margin-top: 1.5rem; }
  .c-f-txt01 dd input,
  .c-f-txt01 dd textarea,
  .c-f-txt01 div input,
  .c-f-txt01 div textarea,
  .c-f-txtarea dd input,
  .c-f-txtarea dd textarea,
  .c-f-txtarea div input,
  .c-f-txtarea div textarea {
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    border: 1px solid #c7ced3; }
    .c-f-txt01 dd input::placeholder,
    .c-f-txt01 dd textarea::placeholder,
    .c-f-txt01 div input::placeholder,
    .c-f-txt01 div textarea::placeholder,
    .c-f-txtarea dd input::placeholder,
    .c-f-txtarea dd textarea::placeholder,
    .c-f-txtarea div input::placeholder,
    .c-f-txtarea div textarea::placeholder {
      color: #c7ced3; }
  @media (--large) {
    .c-f-txt01 dd .c-f-txt01__short,
    .c-f-txt01 div .c-f-txt01__short,
    .c-f-txtarea dd .c-f-txt01__short,
    .c-f-txtarea div .c-f-txt01__short {
      width: 27rem; } }

.c-f-txtarea dd textarea,
.c-f-txtarea div textarea {
  height: 18.4rem; }

.c-f-pp p {
  font-size: 1.6rem; }
  .c-f-pp p a {
    text-decoration: underline; }
    @media (--large) {
      .c-f-pp p a:hover {
        opacity: 0.7; } }

.c-f-pp div {
  margin-top: 1rem; }
  .c-f-pp div label {
    position: relative;
    display: inline-block;
    padding-left: 3.5rem; }
    .c-f-pp div label input {
      position: absolute;
      top: 50%;
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
      transform: translateY(-50%); }
      .c-f-pp div label input::before, .c-f-pp div label input::after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        content: "";
        border: 1px solid #c7ced3; }
      .c-f-pp div label input::after {
        display: none;
        background: url(../images/common/ic_check.png) no-repeat center center/contain;
        border: none; }
      .c-f-pp div label input:checked::after {
        display: block; }
    .c-f-pp div label .mwform-radio-field-text {
      font-size: 1.6rem; }

.c-f-radio {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem; }
  @media (--mobile) {
    .c-f-radio {
      justify-content: space-between;
      width: 100%; } }
  @media (--mobile) {
    .c-f-radio > span {
      display: inline-block;
      width: 47%; } }
  .c-f-radio > span label {
    display: flex;
    align-items: center;
    width: 34rem;
    height: 7rem;
    padding-left: 2.5rem;
    border: 1px solid #c7ced3; }
    @media (--mobile) {
      .c-f-radio > span label {
        width: 100%;
        height: 5rem;
        padding: 0 1.5rem; } }
    .c-f-radio > span label input {
      position: relative;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
      appearance: inherit; }
      .c-f-radio > span label input::before, .c-f-radio > span label input::after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        content: "";
        border: 1px solid #222;
        border-radius: 9999px; }
      .c-f-radio > span label input::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: none;
        width: 60%;
        height: 60%;
        background: #222;
        transform: translate(-50%, -50%); }
      .c-f-radio > span label input:checked::after {
        display: block; }
  @media (--large) {
    .c-f-radio > span:nth-of-type(n + 2) {
      margin-left: 3rem; } }
  .c-f-radio .error {
    width: 100%;
    margin-left: 0 !important; }

@media screen and (max-width: 959px) {
  .c-f_btn {
    font-size: 1.5rem; } }

/* ラジオボタンしたへのテキスト */
@media (--mobile) {
  .c-f-radio {
    display: block; }
    .c-f-radio > span {
      display: block;
      width: 100%; }
    .c-f-radio .horizontal-item + .horizontal-item {
      margin-top: 1rem;
      margin-left: 0 !important; } }

.c-f-radio.c-f-radio-u_text {
  position: relative; }
  .c-f-radio.c-f-radio-u_text .horizontal-item:last-of-type::after {
    display: block;
    margin-top: 0.3rem;
    font-size: 1.6rem;
    color: #8491aa;
    content: "※下記項目のご入力をお願いいたします。"; }
  .c-f-radio.c-f-radio-u_text.aNone .horizontal-item:last-of-type::after {
    display: none; }

/* 選択制が3つの場合  */
.c-f-radio-3 span label {
  width: 28rem; }

.c-f-comfirm {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (--mobile) {
    .c-f-comfirm {
      flex-direction: column; } }
  .c-f-comfirm > p {
    position: relative; }
    @media (--mobile) {
      .c-f-comfirm > p {
        margin-bottom: 1rem; } }
    .c-f-comfirm > p small {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 5.5rem;
      height: 2rem;
      font-size: 1.5rem;
      color: #fff;
      text-align: center;
      background: #1a2e56; }
      @media (--large) {
        .c-f-comfirm > p small {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%) translateX(100%) translateX(0.8rem); } }
      @media (--mobile) {
        .c-f-comfirm > p small {
          position: relative;
          top: 0.4rem;
          margin-left: 1rem; } }
  .c-f-comfirm .c-f-radio span label {
    width: 20rem; }

.c-form-magazine__btn button {
  margin-left: 0 !important; }
  .c-form-magazine__btn button:nth-child(n + 2) {
    margin-left: 2rem !important; }

.c-f-cation {
  margin-top: 1.5rem; }
  .c-f-cation .cation-text {
    display: block;
    font-size: 1.5rem;
    color: #8491aa; }
    @media (--mobile) {
      .c-f-cation .cation-text {
        padding-left: 1em;
        text-indent: -1em; } }
    .c-f-cation .cation-text__flex {
      display: flex; }

.c-f-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem; }
  .c-f-select .c-btn12 .u-m-c {
    justify-content: center;
    width: 58rem; }
    @media (--mobile) {
      .c-f-select .c-btn12 .u-m-c {
        width: 100%; } }
  @media (--mobile) {
    .c-f-select .c-btn12:nth-child(n + 2) {
      margin-top: 4rem; } }
  @media (--mobile) {
    .c-f-select {
      flex-direction: column;
      margin-bottom: 6rem; } }

.c-f-conform {
  height: initial !important; }

.js-step01 .c-btn01 button,
.js-step01 .c-btn01 a {
  position: relative;
  height: 8rem;
  font-size: 1.8rem; }
  .js-step01 .c-btn01 button::after,
  .js-step01 .c-btn01 a::after {
    position: absolute;
    top: 50%;
    right: 4rem;
    width: 4rem;
    height: 1.3rem;
    content: "";
    background: url(../images/common/ic_arrow02.svg) no-repeat center center/contain;
    transition: 0.3s;
    transform: translateY(-50%) translateY(-0.2rem); }
  @media (--large) {
    .js-step01 .c-btn01 button:hover::after,
    .js-step01 .c-btn01 a:hover::after {
      right: 2.5rem; } }

.js-step01 .c-ttl04 {
  position: relative; }
  .js-step01 .c-ttl04 small {
    position: absolute;
    top: 50%;
    left: 9.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    height: 2rem;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    background: #1a2e56;
    transform: translateY(-75%); }

.js-step01 .l-btnWrap {
  display: flex;
  align-items: flex-end; }
  @media (--mobile) {
    .js-step01 .l-btnWrap {
      flex-direction: column;
      align-items: flex-start; } }
  @media (--mobile) {
    .js-step01 .l-btnWrap .c-btn12 {
      width: 100%; } }
  .js-step01 .l-btnWrap .c-btn12 a {
    width: 40rem;
    height: 7rem; }
    @media (--mobile) {
      .js-step01 .l-btnWrap .c-btn12 a {
        width: 100%; } }
  .js-step01 .l-btnWrap p {
    margin-left: 2rem;
    font-size: 1.5rem;
    color: #8491aa; }

.js-step .c-btn01 button,
.js-step .c-btn01 a {
  position: relative;
  height: 8rem;
  font-size: 1.8rem; }
  .js-step .c-btn01 button::after,
  .js-step .c-btn01 a::after {
    position: absolute;
    top: 50%;
    right: 4rem;
    width: 4rem;
    height: 1.3rem;
    content: "";
    background: url(../images/common/ic_arrow02.svg) no-repeat center center/contain;
    transition: 0.3s;
    transform: translateY(-50%) translateY(-0.2rem); }
  @media (--large) {
    .js-step .c-btn01 button:hover::after,
    .js-step .c-btn01 a:hover::after {
      right: 2.5rem; } }
  .js-step .c-btn01 button.js-step-btn:first-of-type,
  .js-step .c-btn01 a.js-step-btn:first-of-type {
    justify-content: flex-end; }
    .js-step .c-btn01 button.js-step-btn:first-of-type::before,
    .js-step .c-btn01 a.js-step-btn:first-of-type::before {
      right: -100%;
      left: inherit; }
    .js-step .c-btn01 button.js-step-btn:first-of-type::after,
    .js-step .c-btn01 a.js-step-btn:first-of-type::after {
      right: inherit;
      left: 4rem;
      transform: translateY(-50%) translateY(-0.2rem) scale(-1, 1); }
    @media (--large) {
      .js-step .c-btn01 button.js-step-btn:first-of-type:hover::before,
      .js-step .c-btn01 a.js-step-btn:first-of-type:hover::before {
        right: 0;
        opacity: 1; }
      .js-step .c-btn01 button.js-step-btn:first-of-type:hover::after,
      .js-step .c-btn01 a.js-step-btn:first-of-type:hover::after {
        left: 2.5rem; } }

.error {
  margin-top: 0.5rem !important; }

.mw_wp_form_input .c-btn01.c-btn01-double {
  display: flex !important; }
  @media (--mobile) {
    .mw_wp_form_input .c-btn01.c-btn01-double {
      justify-content: space-between !important; } }
  .mw_wp_form_input .c-btn01.c-btn01-double button,
  .mw_wp_form_input .c-btn01.c-btn01-double a {
    width: 34rem !important; }
    @media (--mobile) {
      .mw_wp_form_input .c-btn01.c-btn01-double button,
      .mw_wp_form_input .c-btn01.c-btn01-double a {
        width: 47% !important;
        height: 6rem !important; } }
    .mw_wp_form_input .c-btn01.c-btn01-double button[value="back"],
    .mw_wp_form_input .c-btn01.c-btn01-double a[value="back"] {
      background: #c2c2c2 !important; }
      .mw_wp_form_input .c-btn01.c-btn01-double button[value="back"]::before,
      .mw_wp_form_input .c-btn01.c-btn01-double a[value="back"]::before {
        background-color: #8b8b8b !important; }
    @media (--large) {
      .mw_wp_form_input .c-btn01.c-btn01-double button:nth-of-type(n + 2),
      .mw_wp_form_input .c-btn01.c-btn01-double a:nth-of-type(n + 2) {
        margin-left: 2rem !important; } }
    @media (--mobile) {
      .mw_wp_form_input .c-btn01.c-btn01-double button::after,
      .mw_wp_form_input .c-btn01.c-btn01-double a::after {
        right: 2rem !important; } }
  @media (--large) {
    .mw_wp_form_input .c-btn01.c-btn01-double button {
      margin-left: 2rem; } }

.mw_wp_form_input .c-d-confirm,
.mw_wp_form_input .c-d-complete,
.mw_wp_form_input .c-d-error {
  display: none !important; }

.mw_wp_form_input .c-d-input {
  display: block !important; }

.mw_wp_form_input .c-d-input_flex {
  display: flex !important; }
  @media (--mobile) {
    .mw_wp_form_input .c-d-input_flex {
      flex-direction: column; } }

.mw_wp_form_input .c-d-input.js-step-btn-back {
  display: flex !important; }

.mw_wp_form_error .c-d-confirm,
.mw_wp_form_error .c-d-complete,
.mw_wp_form_error .c-d-input {
  display: none !important; }

.mw_wp_form_error .c-d-input_flex {
  display: none !important; }

.mw_wp_form_error .c-d-error {
  display: block !important; }

.mw_wp_form_error .js-step {
  display: block !important; }

.mw_wp_form_error .js-step-btn {
  display: none; }

.mw_wp_form_error .c-step-order li:nth-last-of-type(n + 3) {
  color: #1a2e56 !important; }
  .mw_wp_form_error .c-step-order li:nth-last-of-type(n + 3)::after {
    background: #1a2e56 !important; }
  .mw_wp_form_error .c-step-order li:nth-last-of-type(n + 3)::before {
    background: #1a2e56 !important; }

.mw_wp_form_confirm .c-d-input,
.mw_wp_form_confirm .c-d-input_flex,
.mw_wp_form_confirm .c-d-complete,
.mw_wp_form_confirm .c-d-error {
  display: none !important; }

.mw_wp_form_confirm .c-d-confirm {
  display: block !important; }

.mw_wp_form_confirm .js-step {
  display: block !important; }

.mw_wp_form_confirm .js-step-btn {
  display: none; }

.mw_wp_form_confirm .c-f-txt01 dd,
.mw_wp_form_confirm .c-f-radio,
.mw_wp_form_confirm .c-f-pp div,
.mw_wp_form_confirm .c-f-txtarea div {
  padding: 1rem;
  background: #fafafa; }

.mw_wp_form_confirm .c-btn01 {
  display: flex; }
  @media (--mobile) {
    .mw_wp_form_confirm .c-btn01 {
      justify-content: space-between; } }
  .mw_wp_form_confirm .c-btn01 button,
  .mw_wp_form_confirm .c-btn01 a {
    width: 34rem; }
    @media (--mobile) {
      .mw_wp_form_confirm .c-btn01 button,
      .mw_wp_form_confirm .c-btn01 a {
        width: 47%;
        height: 6rem; } }
    .mw_wp_form_confirm .c-btn01 button[value="back"],
    .mw_wp_form_confirm .c-btn01 a[value="back"] {
      justify-content: flex-end;
      background: #c2c2c2; }
      .mw_wp_form_confirm .c-btn01 button[value="back"]::before,
      .mw_wp_form_confirm .c-btn01 a[value="back"]::before {
        right: -100%;
        left: inherit;
        background-color: #8b8b8b; }
      .mw_wp_form_confirm .c-btn01 button[value="back"]::after,
      .mw_wp_form_confirm .c-btn01 a[value="back"]::after {
        right: inherit;
        left: 4rem;
        transform: translateY(-50%) translateY(-0.2rem) scale(-1, 1); }
      @media (--large) {
        .mw_wp_form_confirm .c-btn01 button[value="back"]:hover::before,
        .mw_wp_form_confirm .c-btn01 a[value="back"]:hover::before {
          right: 0;
          opacity: 1; }
        .mw_wp_form_confirm .c-btn01 button[value="back"]:hover::after,
        .mw_wp_form_confirm .c-btn01 a[value="back"]:hover::after {
          left: 2.5rem; } }
    @media (--large) {
      .mw_wp_form_confirm .c-btn01 button:nth-of-type(n + 2),
      .mw_wp_form_confirm .c-btn01 a:nth-of-type(n + 2) {
        margin-left: 2rem; } }
    @media (--mobile) {
      .mw_wp_form_confirm .c-btn01 button::after,
      .mw_wp_form_confirm .c-btn01 a::after {
        right: 2rem; } }

.mw_wp_form_confirm .c-step-order li:nth-last-of-type(n + 2) {
  color: #1a2e56 !important; }
  .mw_wp_form_confirm .c-step-order li:nth-last-of-type(n + 2)::after {
    background: #1a2e56 !important; }
  .mw_wp_form_confirm .c-step-order li:nth-last-of-type(n + 2)::before {
    background: #1a2e56 !important; }

.mw_wp_form_complete .c-d-input,
.mw_wp_form_complete .c-d-confirm,
.mw_wp_form_complete .c-d-input_flex,
.mw_wp_form_complete .c-d-error {
  display: none !important; }

.mw_wp_form_complete .c-d-complete {
  display: block !important; }

.mw_wp_form_complete .c-step-order li {
  color: #1a2e56 !important; }
  .mw_wp_form_complete .c-step-order li::after {
    background: #1a2e56 !important; }
  .mw_wp_form_complete .c-step-order li::before {
    background: #1a2e56 !important; }

.mw_wp_form_error .c-f-conform {
  height: 8rem !important; }

.c-heading {
  height: 14rem;
  background-color: #00012B;
  display: flex;
  align-items: center; }
  .c-heading h1 {
    color: #fff; }

@media (--large) {
  .c-hover_bLine {
    position: relative; }
    .c-hover_bLine::before {
      position: absolute;
      bottom: -1px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      content: "";
      background-color: #1a2e56;
      opacity: 0;
      transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
      transition-duration: 0.6s;
      transition-property: opacity, transform;
      transform: scale3d(0.8, 1, 1);
      transform-origin: left center; }
    .c-hover_bLine:hover::before {
      opacity: 1;
      transform: none; }
    .c-hover_bLine--white::before {
      bottom: 0;
      background-color: #fff; }
  .c-hover01 {
    position: relative;
    display: inline-block; }
    .c-hover01::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      margin: auto;
      content: "";
      background-color: #1a2e56;
      transition: transform 0.5s;
      transform: scale(0, 1);
      transform-origin: right center; }
    .c-hover01:hover::before {
      transform: scale(1, 1);
      transform-origin: left center; } }

.c-i-inner {
  width: 120rem;
  margin-right: auto;
  margin-left: auto; }
  @media (--mobile) {
    .c-i-inner {
      width: 100%;
      padding: 0 3rem; } }

.c-o-inner {
  width: 120rem;
  margin-right: auto;
  margin-left: auto; }
  @media (--mobile) {
    .c-o-inner {
      width: 100%;
      padding: 0 2rem; } }

.js-span-wrap-text em {
  font-style: inherit; }

.c-link01 a {
  position: relative;
  display: inline-block;
  padding-right: 1rem;
  color: #384eaa;
  border-bottom: 1px solid #384eaa;
  transition: 0.3s opacity; }
  @media (--large) {
    .c-link01 a:hover {
      opacity: 0.6; } }

.c-link01__arrow a {
  padding-right: 1rem; }
  .c-link01__arrow a::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0.7rem;
    height: 0.7rem;
    content: "";
    border-top: 2px solid #384eaa;
    border-right: 2px solid #384eaa;
    transform: translateY(-50%) rotate(45deg); }

.c-txtLink {
  color: #384eaa;
  text-decoration: underline;
  transition: 0.3s opacity; }
  @media (--large) {
    .c-txtLink:hover {
      opacity: 0.6; } }

.c-article__nav > ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem; }
  @media (--mobile) {
    .c-article__nav > ul {
      margin: 0 -0.5rem; } }
  .c-article__nav > ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.7rem 1.5rem;
    font-size: 1.6rem;
    border: 0.1rem solid #1a2e56;
    border-radius: 9999px; }
    @media (--mobile) {
      .c-article__nav > ul li {
        margin-right: 0.5rem;
        margin-left: 0.5rem; } }
    .c-article__nav > ul li a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 2.5rem;
      border-radius: 1.5rem;
      transition: 0.3s all; }
      @media (--large) {
        .c-article__nav > ul li a:hover {
          color: #fff;
          background-color: #1a2e56; } }
      @media (--mobile) {
        .c-article__nav > ul li a {
          padding: 0 1rem; } }
    @media (--mobile) {
      .c-article__nav > ul li {
        margin-right: 1rem; } }
    .c-article__nav > ul li.active {
      color: #fff;
      background-color: #1a2e56; }
      .c-article__nav > ul li.active a {
        color: #fff;
        pointer-events: none; }

.c-article > ul li {
  border-bottom: 0.1rem solid #c7ced3; }
  .c-article > ul li:first-child {
    border-top: 0.1rem solid #c7ced3; }
  .c-article > ul li a {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 3rem; }
    .c-article > ul li a::after {
      position: absolute;
      bottom: -1px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      content: "";
      background-color: #1a2e56;
      opacity: 0;
      transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
      transition-duration: 0.6s;
      transition-property: opacity, transform;
      transform: scale3d(0.8, 1, 1);
      transform-origin: left center; }
    @media (--large) {
      .c-article > ul li a:hover::after {
        opacity: 1;
        transform: none; } }
    @media (--mobile) {
      .c-article > ul li a {
        display: block;
        padding: 2rem 1rem; } }
    .c-article > ul li a .desc {
      display: flex; }
    .c-article > ul li a .date {
      min-width: 9rem;
      margin-right: 3rem;
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      font-weight: bold; }
      .en .c-article > ul li a .date {
        flex-shrink: 0;
        min-width: 16rem; }
      @media (--mobile) {
        .c-article > ul li a .date {
          margin-right: 1.5rem; } }
    .c-article > ul li a .cat {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12rem;
      padding: 0 1rem;
      margin-right: 2.4rem;
      overflow: hidden;
      font-size: 1.3rem;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #1a2e56; }
    .c-article > ul li a .title {
      font-size: 1.6rem; }
      @media (--large) {
        .c-article > ul li a .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; } }
      @media (--mobile) {
        .c-article > ul li a .title {
          margin-top: 1rem; } }
    .c-article > ul li a.nolink {
      pointer-events: none; }

.c-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
  transform: translate(-50%, -50%); }
  @media (--mobile) {
    .c-modal {
      width: 90%; } }
  .c-modal .img-inner {
    overflow-y: scroll; }
    @media (--large) {
      .c-modal .img-inner {
        height: 100%;
        overflow: hidden; } }
    @media (--mobile) {
      .c-modal .img-inner {
        height: 60%; } }
  .c-modal.is-active {
    pointer-events: inherit;
    opacity: 1; }
  .c-modal-inner {
    width: 100%;
    padding: 2rem 4rem 4rem;
    background-color: #fff; }
    @media (--mobile) {
      .c-modal-inner {
        padding: 2rem; }
        .c-modal-inner.long {
          height: 80vh;
          overflow: auto; } }
    .c-modal-inner__title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.5rem;
      margin: 0 auto;
      font-size: 2rem;
      color: #fff;
      background-color: #384eaa; }
      .en .c-modal-inner__title.long {
        font-size: 1.8rem; }
      @media (--mobile) {
        .c-modal-inner__title.long {
          height: auto;
          padding: 1rem; } }
    .c-modal-inner ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.5rem;
      margin-top: 2.4rem; }
      @media (--mobile) {
        .c-modal-inner ul {
          flex-direction: column;
          margin: 2.4rem 0 0; } }
      .c-modal-inner ul li {
        width: calc(100% / 3 - 3rem);
        margin: 0 1.5rem; }
        @media (--mobile) {
          .c-modal-inner ul li {
            width: 100%;
            margin: 0; } }
        .c-modal-inner ul li:nth-of-type(3) {
          margin-right: 0; }
        .c-modal-inner ul li:nth-of-type(n + 4) {
          margin-top: 5rem; }
        @media (--mobile) {
          .c-modal-inner ul li:nth-of-type(n + 2) {
            margin-top: 4rem; } }
        .c-modal-inner ul li .title {
          margin-top: 2.4rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56; }
          @media (--mobile) {
            .c-modal-inner ul li .title {
              margin-top: 1rem; } }
        .c-modal-inner ul li .text {
          margin-top: 1.2rem;
          font-size: 1.6rem; }
    .c-modal-inner__list {
      display: flex;
      margin-top: 2.4rem; }
      @media (--mobile) {
        .c-modal-inner__list {
          flex-direction: column; } }
      .c-modal-inner__list > div {
        width: calc(100% / 2); }
        @media (--mobile) {
          .c-modal-inner__list > div {
            width: 100%; }
            .c-modal-inner__list > div:nth-of-type(n + 2) {
              margin-top: 4rem; } }
        .c-modal-inner__list > div:first-of-type {
          margin-right: 4rem; }
          @media (--mobile) {
            .c-modal-inner__list > div:first-of-type {
              margin-right: 0; } }
      .c-modal-inner__list .title {
        margin-top: 2.4rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #1a2e56; }
        @media (--mobile) {
          .c-modal-inner__list .title {
            margin-top: 1rem; } }
      .c-modal-inner__list .text {
        margin-top: 1.2rem;
        font-size: 1.6rem; }
        .en .c-modal-inner__list .text.long {
          white-space: nowrap; }
    .c-modal-inner__block {
      margin-top: 2.4rem; }
      .c-modal-inner__block .title {
        margin-top: 2.4rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #1a2e56; }
        @media (--mobile) {
          .c-modal-inner__block .title {
            margin-top: 1rem; } }
      .c-modal-inner__block .text {
        margin-top: 1.2rem;
        font-size: 1.6rem; }
  .c-modal__inner {
    position: absolute; }
  .c-modal__close {
    position: absolute;
    top: -1rem;
    right: 0;
    padding-left: 1.5rem;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
    transform: translateY(-100%); }
    .c-modal__close span {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      transform: translateY(-50%) translateX(-0.5rem); }
      .c-modal__close span::after, .c-modal__close span::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 1px;
        content: "";
        background: #fff;
        transform: translate(-50%, -50%) rotate(45deg); }
      .c-modal__close span::before {
        transform: translate(-50%, -50%) rotate(-45deg); }
  .c-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: rgb(26 46 86 / 90%);
    opacity: 0;
    transition: 0.3s; }
    .c-modal__overlay.is-active {
      pointer-events: inherit;
      opacity: 1; }

.c-mv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22rem;
  margin-bottom: 6rem;
  overflow: hidden;
  color: #fff;
  background: #00012b; }
  @media (--mobile) {
    .c-mv {
      height: 16rem;
      margin-bottom: 4rem; } }
  .c-mv h1,
  .c-mv h2 {
    font-size: 3.2rem;
    letter-spacing: 0.1em; }
    @media (--mobile) {
      .c-mv h1,
      .c-mv h2 {
        font-size: 2.4rem; } }
  .c-mv--mini {
    height: 6rem; }
    .c-mv--mini h1,
    .c-mv--mini h2 {
      font-size: 2rem; }

.c-pagenav .wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 6rem; }
  @media (--mobile) {
    .c-pagenav .wp-pagenavi {
      margin-top: 4rem; } }
  .c-pagenav .wp-pagenavi * {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    height: 3.5rem;
    margin: 0 0.3rem;
    font-size: 1.8rem;
    cursor: pointer;
    border: 1px solid #c7ced3;
    transition: 0.3s; }
    @media (--mobile) {
      .c-pagenav .wp-pagenavi * {
        width: 2.3rem; } }
    @media (--large) {
      .c-pagenav .wp-pagenavi *:hover {
        color: #fff;
        background: #1a2e56; } }
  .c-pagenav .wp-pagenavi .previouspostslink,
  .c-pagenav .wp-pagenavi .nextpostslink {
    background: none;
    border: none; }
    @media (--large) {
      .c-pagenav .wp-pagenavi .previouspostslink:hover,
      .c-pagenav .wp-pagenavi .nextpostslink:hover {
        color: #1a2e56; } }
  .c-pagenav .wp-pagenavi .current {
    color: #fff;
    background: #1a2e56; }

.c-product-map {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: #edeff4; }
  @media (--mobile) {
    .c-product-map {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  .c-product-map .c-i-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (--mobile) {
      .c-product-map .c-i-inner {
        display: block; } }
  .c-product-map__left, .c-product-map__right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 48%; }
    @media (--mobile) {
      .c-product-map__left, .c-product-map__right {
        width: 100%; } }
  @media (--mobile) {
    .c-product-map__right {
      margin-top: 3rem; } }
  .c-product-map__right > nav {
    width: 31%; }
    @media (--mobile) {
      .c-product-map__right > nav {
        width: 100%; } }
    @media (--large) {
      .c-product-map__right > nav:nth-of-type(n + 4) {
        margin-top: 3.7rem; } }
    @media (--mobile) {
      .c-product-map__right > nav:nth-of-type(n + 2) {
        margin-top: 0; } }
  .c-product-map__ttl {
    width: 100%;
    margin-bottom: 3rem; }
    @media (--mobile) {
      .c-product-map__ttl {
        margin-bottom: 2rem; } }
    .c-product-map__ttl a {
      position: relative;
      display: inline-block;
      padding-right: 2.5rem;
      font-size: 2.2rem;
      font-weight: bold;
      color: #1a2e56; }
      .c-product-map__ttl a svg {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1rem;
        transform: translateY(-50%); }
  .c-product-map__nav {
    width: 48%; }
    @media (--mobile) {
      .c-product-map__nav {
        width: 100%; } }
  .c-product-map__sTtl {
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #1a2e56;
    border-bottom: 1px solid #8491aa; }
    @media (--large) {
      .c-product-map__sTtl {
        pointer-events: none; }
        .en .c-product-map__sTtl {
          display: flex;
          align-items: flex-end;
          min-height: 7rem; } }
    @media (--mobile) {
      .c-product-map__sTtl {
        font-size: 1.8rem; }
        .c-product-map__sTtl.is-active .c-product-map__trigger {
          transform: translateY(-50%) rotate(-180deg); } }
  @media (--mobile) {
    .c-product-map__trigger {
      position: absolute;
      top: 50%;
      right: 0;
      display: inline-block;
      width: 3rem;
      height: 3rem;
      transition: 0.3s transform;
      transform: translateY(-50%); }
      .c-product-map__trigger svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1rem;
        transform: translate(-50%, -50%) rotate(90deg); } }
  @media (--mobile) {
    .c-product-map__list {
      display: none;
      padding-bottom: 2rem; } }
  .c-product-map__el:nth-of-type(n + 2) {
    margin-top: 0.5rem; }
  @media (--mobile) {
    .c-product-map__el {
      font-size: 1.6rem; } }

.c-product-mv {
  position: relative;
  padding: 7rem 0;
  overflow: hidden;
  background: linear-gradient(90deg, #00012b, #000); }
  @media (--mobile) {
    .c-product-mv {
      padding: 4rem 0 12rem; } }
  .c-product-mv__pImg {
    display: block; }
    @media (--large) {
      .c-product-mv__pImg {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%; } }
    .c-product-mv__pImg img {
      display: block; }
    @media (--large) {
      .c-product-mv__pImg.micro-grinder {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 58rem;
        transform: translateY(-50%) translateX(-50%) translateX(34rem); } }
    @media (--large) {
      .c-product-mv__pImg.e3000 {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 68.5rem;
        transform: translateX(-50%) translateX(100rem) translateY(-50%); } }
    @media (--mobile) {
      .c-product-mv__pImg.e3000 {
        width: calc(100% - 4rem);
        margin: 0 0 5rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.e4000 {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 72rem;
        transform: translateX(-50%) translateX(110rem) translateY(-50%); }
        .c-product-mv__pImg.e4000 img {
          bottom: 0; } }
    @media (--mobile) {
      .c-product-mv__pImg.e4000 {
        width: calc(100% - 3rem);
        margin: 0 0 5rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.e2000 {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 73rem;
        transform: translate(-50%, -50%) translateX(110rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.e2000 {
        width: calc(100% - 3rem);
        margin: 0 0 5rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.ispeed3 {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 75rem;
        transform: translate(-50%, -50%) translateX(110rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.ispeed3 {
        width: calc(100% - 3rem);
        margin: 0 0 5rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.hes {
        position: absolute;
        right: 50%;
        bottom: 0;
        width: 67rem;
        transform: translateX(-50%) translateX(95rem); }
        .c-product-mv__pImg.hes img {
          position: absolute;
          bottom: 0; } }
    @media (--mobile) {
      .c-product-mv__pImg.hes {
        width: calc(100% - 3rem);
        margin: 0 0 3rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.moter_spindle img {
        top: 50%;
        left: 0;
        width: 50rem; } }
    @media (--mobile) {
      .c-product-mv__pImg.moter_spindle img {
        width: calc(100% - 6rem);
        margin: 0 auto 5rem; } }
    @media (--large) {
      .c-product-mv__pImg.airspeed {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 68rem;
        transform: translate(-50%, -50%) translateX(96rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.airspeed {
        width: calc(100% - 3rem);
        margin: 0 0 2rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.xpeed {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 65rem;
        transform: translate(-50%, -50%) translateX(95rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.xpeed img {
        width: calc(100% - 3rem);
        margin: 0 0 2rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.abt {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 68rem;
        transform: translate(-50%, -50%) translateX(98rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.abt img {
        width: calc(100% - 3rem);
        margin: 0 0 5rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.hts {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 63rem;
        transform: translate(-50%, -50%) translateX(93rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.hts img {
        width: calc(100% - 3rem);
        margin: 0 0 2rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.planet {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 47rem;
        transform: translate(-50%, -50%) translateX(76rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.planet img {
        width: calc(100% - 3rem);
        margin: 0 0 5rem auto; } }
    @media (--large) {
      .c-product-mv__pImg.emax_evolution {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 43.3rem;
        transform: translate(-50%, -50%) translateX(70rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.emax_evolution {
        width: calc(100% - 10rem);
        margin: 0 auto 5rem; } }
    @media (--large) {
      .c-product-mv__pImg.rotus {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 65rem;
        transform: translate(-50%, -50%) translateX(100rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.rotus {
        position: relative;
        width: calc(100% - 8rem);
        margin: 0 auto 2rem; } }
    @media (--large) {
      .c-product-mv__pImg.espert500 {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 51.8rem;
        transform: translate(-50%, -50%) translateX(75rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.espert500 {
        width: calc(100% - 7rem);
        margin: 0 auto; } }
    @media (--large) {
      .c-product-mv__pImg.impulse {
        position: absolute;
        right: 50%;
        bottom: 0;
        width: 44rem;
        transform: translateX(-50%) translateX(74rem); }
        .c-product-mv__pImg.impulse img {
          position: absolute;
          bottom: 0; } }
    @media (--mobile) {
      .c-product-mv__pImg.impulse {
        width: calc(100% - 10rem);
        margin: 0 auto 2rem;
        transform: translateX(-1rem); } }
    @media (--large) {
      .c-product-mv__pImg.presto2 {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 56rem;
        transform: translate(-50%, -50%) translateX(83rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.presto2 {
        width: calc(100% - 3rem);
        margin: 0 auto 5rem; } }
    @media (--large) {
      .c-product-mv__pImg.zero {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 44rem;
        transform: translate(-50%, -50%) translateX(75rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.zero {
        display: block;
        width: calc(100% - 6rem);
        margin: 0 auto 5rem; } }
    @media (--large) {
      .c-product-mv__pImg.soniccutter_zero {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 38rem;
        transform: translate(-50%, -50%) translateX(73rem); } }
    @media (--mobile) {
      .c-product-mv__pImg.soniccutter_zero {
        width: calc(100% - 9rem);
        margin: 0 auto 1rem; } }
  .c-product-mv * {
    color: #fff; }
  @media (--large) {
    .c-product-mv__left {
      width: 50rem; } }
  .c-product-mv-inner {
    display: flex; }
    @media (--mobile) {
      .c-product-mv-inner {
        flex-direction: column; } }
    .c-product-mv-inner > div:last-of-type {
      margin-left: 1rem; }
      @media (--mobile) {
        .c-product-mv-inner > div:last-of-type {
          margin-top: 1rem;
          margin-left: 0; } }
      .en .c-product-mv-inner > div:last-of-type .c-btn09 a {
        width: 26rem; }
  .c-product-mv-ttl01 {
    font-size: 4.2rem;
    line-height: 1.5;
    letter-spacing: 0.1em; }
    @media (--mobile) {
      .c-product-mv-ttl01 {
        font-size: 3.46rem; } }
    .c-product-mv-ttl01 small {
      display: block;
      font-family: "Roboto", sans-serif;
      font-size: 1.8rem;
      font-weight: lighter;
      color: #969faf;
      letter-spacing: 0.1em; }
    .c-product-mv-ttl01 span {
      font-size: 3.3rem; }
  .c-product-mv-ttl02 {
    font-size: 6rem;
    line-height: 1.5;
    letter-spacing: 0.1em; }
    @media (--mobile) {
      .c-product-mv-ttl02 {
        font-size: 4.2rem; } }
    .c-product-mv-ttl02 small {
      display: flex;
      align-items: center;
      font-family: "Roboto", sans-serif;
      font-size: 1.8rem;
      font-weight: lighter;
      color: #969faf;
      letter-spacing: 0.1em; }
      .c-product-mv-ttl02 small em {
        padding: 0.2rem 1.3rem;
        margin-left: 1rem;
        font-size: 1.2rem;
        font-style: inherit;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 9999px; }
        @media (--mobile) {
          .c-product-mv-ttl02 small em {
            font-size: 1.2rem; } }
    .c-product-mv-ttl02 span {
      font-size: 3.3rem; }
      @media (--mobile) {
        .c-product-mv-ttl02 span {
          font-size: 3rem; } }
  .c-product-mv-mainTtl {
    display: flex;
    align-items: baseline; }
    .c-product-mv-mainTtl > *:first-child {
      margin-right: 0.4rem;
      font-size: 6rem !important; }
      .c-product-mv-mainTtl > *:first-child span {
        font-size: 6rem !important;
        line-height: 1; }
        @media (--mobile) {
          .c-product-mv-mainTtl > *:first-child span {
            font-size: 4.2rem !important; } }
      @media (--mobile) {
        .c-product-mv-mainTtl > *:first-child {
          font-size: 4.2rem !important; } }
    .c-product-mv-mainTtl > *:nth-child(2) {
      line-height: 1.3; }
      @media (--mobile) {
        .c-product-mv-mainTtl > *:nth-child(2) {
          line-height: 1.5; } }
      @media (--mobile) {
        .c-product-mv-mainTtl > *:nth-child(2) span {
          line-height: 1.1; } }
    .c-product-mv-mainTtl_rev {
      display: flex;
      align-items: baseline; }
      .c-product-mv-mainTtl_rev > *:first-child {
        margin-right: 0.4rem;
        line-height: 1.2; }
        @media (--mobile) {
          .c-product-mv-mainTtl_rev > *:first-child {
            line-height: 1.5; } }
        @media (--mobile) {
          .c-product-mv-mainTtl_rev > *:first-child span {
            line-height: 1.1; } }
      .c-product-mv-mainTtl_rev > *:nth-child(2) {
        font-size: 6rem !important; }
        .c-product-mv-mainTtl_rev > *:nth-child(2) span {
          font-size: 6rem !important;
          line-height: 1; }
          @media (--mobile) {
            .c-product-mv-mainTtl_rev > *:nth-child(2) span {
              font-size: 4.2rem !important; } }
        @media (--mobile) {
          .c-product-mv-mainTtl_rev > *:nth-child(2) {
            font-size: 4.2rem !important; } }
      @media (--mobile) {
        .c-product-mv-mainTtl_rev.sp-column {
          flex-direction: column;
          margin-top: 1rem; } }
    .c-product-mv-mainTtl_kind {
      display: flex;
      align-items: baseline; }
      .c-product-mv-mainTtl_kind > *:first-child {
        font-size: 4.2rem !important; }
        .c-product-mv-mainTtl_kind > *:first-child span {
          font-size: 4.2rem !important;
          line-height: 1; }
          @media (--mobile) {
            .c-product-mv-mainTtl_kind > *:first-child span {
              font-size: 3.46rem !important; } }
        @media (--mobile) {
          .c-product-mv-mainTtl_kind > *:first-child {
            font-size: 3.46rem !important; } }
  .c-product-mv-mTxt {
    margin-top: 4rem;
    font-size: 2rem; }
    @media (--mobile) {
      .c-product-mv-mTxt {
        margin-top: 3rem; } }
  .c-product-mv-sTxt {
    margin-top: 4.5rem;
    font-size: 1.8rem; }
    @media (--mobile) {
      .c-product-mv-sTxt {
        margin-top: 3rem; } }
  .c-product-mv-sTtl {
    font-size: 3.6rem; }
    @media (--mobile) {
      .c-product-mv-sTtl {
        margin-top: 1rem;
        font-size: 2.2rem; } }
  .c-product-mv-feature {
    margin-top: 0.5rem;
    font-size: 1.6rem; }
    @media (--mobile) {
      .c-product-mv-feature {
        margin-top: 1rem;
        font-size: 1.5rem; } }
  .c-product-mv-connection {
    display: flex;
    align-items: center;
    margin-top: 3.5rem; }
    .c-product-mv-connection span {
      width: 8rem;
      color: #1a2e56;
      text-align: center;
      background: #969faf; }
      @media (--mobile) {
        .c-product-mv-connection span {
          text-align: center;
          letter-spacing: normal;
          white-space: nowrap; } }
    .c-product-mv-connection a {
      position: relative;
      max-width: calc(100% - 8rem - 1.5rem);
      padding-right: 1.5rem;
      margin-left: 1.5rem;
      overflow: hidden;
      font-size: 1.6rem;
      color: #969faf;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid #969faf;
      transition: 0.5s opacity; }
      .en .c-product-mv-connection a {
        margin-left: 0; }
      @media (--large) {
        .c-product-mv-connection a:hover {
          opacity: 0.6; } }
      @media (--mobile) {
        .c-product-mv-connection a {
          max-width: calc(100% - 8rem - 1.5rem); } }
      .c-product-mv-connection a::after {
        position: absolute;
        top: 50%;
        right: 0.3rem;
        width: 0.8rem;
        height: 0.8rem;
        content: "";
        border-top: 1px solid #969faf;
        border-right: 1px solid #969faf;
        transform: translateY(-50%) rotate(45deg); }
  .c-product-mv-btn {
    margin-top: 3rem; }
    .c-product-mv-btn .c-btn09 a {
      width: 20rem;
      background-color: transparent;
      border: 0.1rem solid #fff; }
      @media (--mobile) {
        .c-product-mv-btn .c-btn09 a {
          height: 6rem; } }
      .c-product-mv-btn .c-btn09 a span {
        color: #fff; }
        .c-product-mv-btn .c-btn09 a span::after {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff; }

.c-product_mv-img {
  position: absolute;
  top: 50%;
  left: 50%; }
  @media (--large) {
    .c-product_mv-img {
      height: 20.8rem; } }
  @media (--mobile) {
    .c-product_mv-img {
      position: initial;
      width: 100%;
      height: auto;
      margin-left: 1rem; } }
  @media (--large) {
    .c-product_mv-img[data-obj="E4000"] {
      width: 33.5rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(53rem); } }
  @media (--large) {
    .c-product_mv-img[data-obj="E3000"] {
      width: 40rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(51rem); } }
  @media (--large) {
    .c-product_mv-img[data-obj="E2000"] {
      width: 35.4rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(53rem); } }
  @media (--large) {
    .c-product_mv-img[data-obj="iSpeed3"] {
      width: 37rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem); } }
  @media (--large) {
    .c-product_mv-img[data-obj="HES"] {
      width: 34rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem); } }
  @media (--large) {
    .c-product_mv-img[data-obj="Air-Speed"] {
      width: 42rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem); } }
  @media (--large) {
    .c-product_mv-img[data-obj="Emax EVOlution"] {
      width: 20rem;
      height: 18.8rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem); } }
  @media (--mobile) {
    .c-product_mv-img[data-obj="Emax EVOlution"] {
      margin-right: 1rem; } }
  @media (--large) {
    .c-product_mv-img[data-obj="Espert500"] {
      width: 28rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem); } }
  @media (--mobile) {
    .c-product_mv-img[data-obj="Espert500"] {
      margin-right: 0.5rem; } }
  @media (--large) {
    .c-product_mv-img[data-obj="シーナスZERO"] {
      width: 24rem;
      height: 18rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem); } }
  @media (--large) {
    .c-product_mv-img[data-obj="ソニックカッターZERO"] {
      width: 20rem;
      height: 19.5rem;
      transform: translateY(-50%) translateX(-50%) translateY(0.5rem) translateX(46rem); } }
  .c-product_mv-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.c-product-navArea {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  background: #edeff4; }
  @media (--mobile) {
    .c-product-navArea {
      position: absolute;
      left: 50%;
      z-index: 1;
      width: calc(100% - 8rem);
      height: auto;
      margin-top: -8rem;
      margin-right: auto;
      margin-left: auto;
      background: #fff;
      box-shadow: 0 0 2rem rgb(0 0 0 / 10%);
      transform: translateX(-50%); }
      .c-product-navArea.mt {
        margin-top: -2rem; }
      .c-product-navArea .c-i-inner {
        padding: 0.5rem 2rem 1.4rem; } }
  .c-product-navArea-list {
    display: flex;
    justify-content: center; }
    @media (--mobile) {
      .c-product-navArea-list {
        display: block;
        border: none; } }
  .c-product-navArea-el {
    position: relative;
    width: calc(100% / 4);
    height: 4.5rem;
    border-left: 1px solid #8491aa; }
    .c-product-navArea-el:last-child {
      border-right: 1px solid #8491aa; }
      @media (--mobile) {
        .c-product-navArea-el:last-child {
          border-right: 0; } }
    @media (--large) {
      .c-product-navArea-el {
        text-align: center; } }
    @media (--mobile) {
      .c-product-navArea-el {
        width: 100%;
        height: 5rem;
        border: none; }
        .c-product-navArea-el:nth-child(1) .c-product-navArea-link::after {
          content: "01"; }
        .c-product-navArea-el:nth-child(2) .c-product-navArea-link::after {
          content: "02"; }
        .c-product-navArea-el:nth-child(3) .c-product-navArea-link::after {
          content: "03"; }
        .c-product-navArea-el:nth-child(4) .c-product-navArea-link::after {
          content: "04"; }
        .c-product-navArea-el:nth-child(5) .c-product-navArea-link::after {
          content: "05"; } }
  .c-product-navArea-link {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    transition: 0.5s all; }
    .c-product-navArea-link.is-selected {
      color: #8491aa;
      pointer-events: none; }
      .c-product-navArea-link.is-selected svg path {
        stroke: #8491aa; }
    .c-product-navArea-link span {
      transition: 0.5s all; }
    @media (--mobile) {
      .c-product-navArea-link {
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        border-bottom: 1px solid #000; }
        .c-product-navArea-link::after {
          position: absolute;
          top: 50%;
          left: 0;
          font-family: "Roboto", sans-serif;
          font-size: 1rem;
          color: #969faf;
          letter-spacing: normal;
          content: "";
          transform: translateY(-50%); } }
    .c-product-navArea-link svg {
      transition: 0.5s stroke; }
      @media (--large) {
        .c-product-navArea-link svg {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0.8rem;
          transform: translateX(-50%) rotate(90deg); } }
      @media (--mobile) {
        .c-product-navArea-link svg {
          position: absolute;
          top: 50%;
          right: 1rem;
          width: 0.7rem;
          transform: rotate(90deg) translateX(-25%); } }
    @media (--large) {
      .c-product-navArea-link:hover {
        color: #384eaa; }
        .c-product-navArea-link:hover span {
          margin: 0 0.2rem; }
        .c-product-navArea-link:hover svg path {
          stroke: #384eaa; } }

.c-product-list01 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; }
  .c-product-list01-el {
    position: relative;
    width: calc(100% / 2 - 2rem);
    height: 27rem;
    padding: 2rem;
    margin: 0 1rem;
    background: linear-gradient(to left, #000, #00012b); }
    .c-product-list01-el * {
      padding-left: 0.8rem;
      color: #fff; }
    .c-product-list01-el img {
      position: absolute; }
      .c-product-list01-el img.e4000 {
        right: 0;
        bottom: 0;
        width: 28rem;
        padding-left: 0; }
      .c-product-list01-el img.e3000 {
        right: 0;
        bottom: 0;
        width: 31rem;
        padding-left: 0; }
    .c-product-list01-el span {
      display: inline-block;
      padding: 0 1rem;
      font-size: 1.1rem;
      color: #c7ced3;
      border: 1px solid #c7ced3;
      border-radius: 9999px; }
    .c-product-list01-el h3 {
      margin-top: 1rem;
      font-size: 3.6rem;
      font-weight: normal;
      color: #fff; }
    .c-product-list01-el h4 {
      margin-top: 0.5rem;
      font-size: 1.6rem;
      font-weight: lighter;
      opacity: 0.9; }
    .c-product-list01-el h5 {
      margin-top: 0.4rem;
      font-size: 1.2rem;
      font-weight: lighter;
      opacity: 0.9; }
    .c-product-list01-el p {
      margin-top: 1.6rem;
      font-size: 1.1rem;
      opacity: 0.7; }

.c-product-spec {
  padding: 9rem 0;
  background: linear-gradient(-30deg, #edf0fa, #dee1eb); }
  @media (--mobile) {
    .c-product-spec {
      padding: 22rem 0 5rem; } }
  .c-product-spec-ttl {
    font-size: 3rem;
    color: #1a2e56;
    text-align: center; }
    @media (--mobile) {
      .c-product-spec-ttl {
        font-size: 2.4rem; } }
  .c-product-spec-list {
    display: flex;
    justify-content: center;
    margin-top: 2rem; }
    .c-product-spec-list p {
      text-align: center; }
    .c-product-spec-list ul li {
      padding-left: 1.5em;
      text-indent: -1.5em; }
  .c-product-spec-img {
    width: 58.5rem;
    margin-top: 5rem;
    margin-right: auto;
    margin-left: auto; }
    @media (--mobile) {
      .c-product-spec-img {
        width: 100%; } }

.c-product-side {
  display: flex;
  justify-content: space-between; }
  @media (--mobile) {
    .c-product-side {
      display: block; } }
  .c-product-side__left {
    font-size: 1.6rem; }
    @media (--large) {
      .c-product-side__left {
        width: 59rem; } }
    .c-product-side__left strong {
      display: block;
      margin-bottom: 1.5rem;
      font-size: 2rem;
      color: #1a2e56; }
      .c-product-side__left strong:nth-of-type(n + 2) {
        margin-top: 4rem; }
    .c-product-side__left img {
      width: 35rem;
      margin-top: 2.1rem; }
  @media (--large) {
    .c-product-side__right {
      width: 55rem; } }
  .c-product-side-graph {
    display: none;
    background: #f8faff; }
    .c-product-side-graph.is-active {
      display: block; }
    .c-product-side-graph figcaption {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 1.6rem;
      color: #fff;
      text-align: center;
      background: #384eaa; }
    .c-product-side-graph > div {
      padding: 1.5rem 7.3rem 1rem; }
      @media (--mobile) {
        .c-product-side-graph > div {
          padding: 1.5rem; } }
    .c-product-side-graph__btn {
      display: flex;
      justify-content: center;
      margin-top: 2rem; }
      .c-product-side-graph__btn div {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 14rem;
        height: 3.8rem;
        padding: 0 1.5rem;
        font-size: 1.3rem;
        color: #1a2e56;
        text-align: center;
        cursor: pointer;
        border: 1px solid #1a2e56;
        border-radius: 999rem; }
        @media (--mobile) {
          .c-product-side-graph__btn div {
            min-width: 11rem; } }
        .c-product-side-graph__btn div.is-active {
          color: #fff;
          background: #1a2e56; }
        .c-product-side-graph__btn div small {
          font-size: 1rem; }
        @media (--large) {
          .c-product-side-graph__btn div:nth-of-type(n + 2) {
            margin-left: 2rem; } }
        @media (--mobile) {
          .c-product-side-graph__btn div:nth-of-type(n + 2) {
            margin-left: 1rem; } }
  .c-product-side__spec {
    display: flex; }
    @media (--mobile) {
      .c-product-side__spec {
        margin: 0 -0.5rem; } }
    .c-product-side__spec img {
      width: 9rem; }
      @media (--mobile) {
        .c-product-side__spec img {
          width: calc(100% / 5 - 1rem);
          margin: 0 0.5rem; } }
      @media (--large) {
        .c-product-side__spec img:nth-of-type(n + 2) {
          margin-left: 1rem; } }

@media (--large) {
  .c-product-example-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem; } }

@media (--mobile) {
  .c-product-example-list-case {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

.c-product-example-el {
  position: relative; }
  @media (--large) {
    .c-product-example-el {
      width: calc(100% / 3 - 4rem);
      margin: 0 2rem; }
      .c-product-example-el:nth-of-type(n + 4) {
        margin-top: 4rem; } }
  @media (--mobile) {
    .c-product-example-el {
      display: flex; }
      .c-product-example-el:nth-of-type(n + 2) {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: 1px solid #8491aa; }
      .c-product-example-el-case {
        display: block; }
        .c-product-example-el-case:nth-of-type(n + 2) {
          padding-top: 0;
          margin-top: 0;
          border-top: none; }
        .c-product-example-el-case:nth-of-type(n + 3) {
          margin-top: 2rem; } }
  .c-product-example-el h3 {
    font-size: 2rem;
    color: #1a2e56; }
    @media (--large) {
      .c-product-example-el h3 {
        margin-top: 2.7rem; } }
    @media (--mobile) {
      .c-product-example-el h3 {
        overflow: hidden;
        font-size: 1.5rem;
        line-height: 1.5;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .c-product-example-el h3.overFlow-inherit {
          overflow: inherit;
          white-space: inherit; } }
  .c-product-example-el h4 {
    margin-top: 0.9rem;
    font-size: 1.5rem; }
    @media (--mobile) {
      .c-product-example-el h4 {
        margin-top: 0.5rem;
        overflow: hidden;
        font-size: 1.3rem;
        text-overflow: ellipsis;
        white-space: nowrap; } }
  @media (--large) {
    .c-product-example-el:hover .c-product-example-cover img {
      transform: scale(1.1); } }

.c-product-example-cover, .c-product-example-contain {
  overflow: hidden; }
  .c-product-example-cover img, .c-product-example-contain img {
    width: 100%;
    height: 100%;
    transition: 0.3s; }

.c-product-example-cover {
  position: relative;
  width: 100%;
  height: 22rem; }
  .c-product-example-cover.is-movie::after {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 4rem;
    height: 4rem;
    content: "";
    background: url(../images/common/ic_case_movie.png);
    background-size: cover; }
    @media (--mobile) {
      .c-product-example-cover.is-movie::after {
        width: 2rem;
        height: 2rem; } }
  @media (--mobile) {
    .c-product-example-cover {
      width: 12rem;
      height: 8rem; } }
  .c-product-example-cover img {
    object-fit: cover; }

.c-product-example-contain {
  height: 34rem;
  background: #f7f7f7; }
  @media (--mobile) {
    .c-product-example-contain {
      width: 12rem;
      height: 12rem; }
      .c-product-example-contain-case {
        width: calc(100vw / 2 - 4rem);
        height: calc(100vw / 2 - 4rem); } }
  .c-product-example-contain img {
    object-fit: contain; }

.c-product-example-border {
  border: 1px solid #1a2e56; }

@media (--mobile) {
  .c-product-example-txtWrap {
    width: calc(100% - 12rem);
    padding-left: 2rem; }
    .c-product-example-txtWrap-case {
      width: calc(100vw / 2 - 4rem);
      padding-left: 0;
      margin-top: 2rem; } }

.c-product-example-txtWrap div {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.7rem;
  margin-top: 1.2rem; }
  @media (--mobile) {
    .c-product-example-txtWrap div {
      margin-top: 0.5rem; } }
  .c-product-example-txtWrap div a {
    margin: 0 0.7rem;
    color: #8491aa; }
    @media (--mobile) {
      .c-product-example-txtWrap div a {
        font-size: 1.2rem; } }

.c-product-example-link {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%; }

.c-product-table {
  display: flex;
  justify-content: space-between; }
  @media (--mobile) {
    .c-product-table {
      display: block; } }
  .c-product-table-left {
    width: 55rem; }
    @media (--mobile) {
      .c-product-table-left {
        width: 100%; } }
  .c-product-table-right {
    width: 59rem; }
    @media (--mobile) {
      .c-product-table-right {
        width: 100%; } }
    .c-product-table-right table {
      width: 100%;
      border-collapse: collapse; }
      .c-product-table-right table tr td {
        padding: 1.5rem;
        font-size: 1.5rem;
        border: 1px solid #c7ced3; }
        .c-product-table-right table tr td:first-of-type {
          width: 18rem;
          background: #edeff4; }
          .en .c-product-table-right table tr td:first-of-type {
            width: 21rem; }
        @media (--mobile) {
          .c-product-table-right table tr td {
            display: block;
            width: 100% !important;
            padding: 1rem 1.5rem; } }
  .c-product-table-annotation span {
    display: block; }

.c-product-feature-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.c-product-feature-mass {
  width: 52rem;
  font-size: 1.6rem; }
  @media (--large) {
    .c-product-feature-mass:nth-of-type(n + 3) {
      margin-top: 8rem; } }
  @media (--mobile) {
    .c-product-feature-mass:nth-of-type(n + 2) {
      margin-top: 5rem; } }
  .c-product-feature-mass strong {
    display: block;
    margin-bottom: 1.6rem;
    font-size: 2rem; }
    @media (--mobile) {
      .c-product-feature-mass strong {
        font-size: 1.8rem; } }
    .c-product-feature-mass strong:nth-of-type(n + 2) {
      margin-top: 2rem; }

.c-product-lineup__img {
  width: 90rem;
  margin: 3rem auto 0; }
  @media (--mobile) {
    .c-product-lineup__img {
      width: 100%; } }

.c-product-lineup-txt {
  width: 100%;
  font-size: 1.6rem; }

.c-product-lineup-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.c-product-lineup-mass {
  width: 53rem;
  padding: 2.6rem 0;
  border-bottom: 1px solid #e2e2e2; }
  @media (--mobile) {
    .c-product-lineup-mass {
      width: 100%;
      padding: 1.6rem 0; } }
  .c-product-lineup-mass figure {
    display: flex; }
    @media (--mobile) {
      .c-product-lineup-mass figure {
        align-items: center; } }
    .c-product-lineup-mass figure div {
      width: 13rem; }
      @media (--mobile) {
        .c-product-lineup-mass figure div {
          width: 10rem; } }
    .c-product-lineup-mass figure figcaption {
      width: calc(100% - 13rem);
      padding-left: 3.2rem; }
      @media (--mobile) {
        .c-product-lineup-mass figure figcaption {
          width: calc(100% - 10rem); } }
      .c-product-lineup-mass figure figcaption h4 {
        font-size: 2rem;
        color: #1a2e56; }
        @media (--mobile) {
          .c-product-lineup-mass figure figcaption h4 {
            font-size: 1.8rem; } }

.c-product-control__txt {
  font-size: 1.6rem; }

.c-product-control__img {
  display: block;
  max-width: 87.8rem;
  margin-right: auto;
  margin-left: auto; }
  .c-product-control__img--w100 {
    max-width: 100%; }

.c-product-graph__text {
  margin-top: 3rem; }

.c-product-graph__inner {
  width: 100%;
  padding-bottom: 4rem;
  margin-top: 3rem;
  background-color: #f8faff;
  border-top: 0.6rem solid #384eaa; }
  .c-product-graph__inner ul {
    display: flex;
    justify-content: center;
    margin-top: 3rem; }
    @media (--mobile) {
      .c-product-graph__inner ul {
        display: block; } }
    .c-product-graph__inner ul li {
      width: 31rem;
      margin-right: 3rem; }
      .c-product-graph__inner ul li .title {
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center; }
      .c-product-graph__inner ul li .img {
        margin-top: 3rem; }
        .c-product-graph__inner ul li .img img {
          display: block;
          width: auto;
          height: 28rem;
          margin: 0 auto; }
      .c-product-graph__inner ul li:last-child {
        margin-right: 0; }
      @media (--mobile) {
        .c-product-graph__inner ul li {
          position: relative;
          width: 100%;
          padding: 0 2rem;
          padding-bottom: 3rem;
          margin-bottom: 3rem; }
          .c-product-graph__inner ul li::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: calc(100% - 4rem);
            height: 0.1rem;
            content: "";
            background-color: #c1c1c1;
            transform: translateX(-50%); }
          .c-product-graph__inner ul li:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none; }
            .c-product-graph__inner ul li:last-child::after {
              content: none; } }

.c-product .l-lineup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .c-product .l-lineup__el {
    display: flex;
    width: calc(100% / 2 - 2rem);
    padding: 2.5rem 0;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2; }
    @media (--mobile) {
      .c-product .l-lineup__el {
        width: 100%; }
        .c-product .l-lineup__el:nth-of-type(n + 2) {
          margin-top: -1px; } }
    .c-product .l-lineup__el:nth-of-type(n + 3) {
      margin-top: -1px; }
  .c-product .l-lineup__img {
    width: 13rem;
    overflow: hidden; }
    @media (--mobile) {
      .c-product .l-lineup__img {
        width: 12rem; } }
    .c-product .l-lineup__img img {
      transition: 0.3s; }
  .c-product .l-lineup__info {
    width: calc(100% - 13rem);
    padding-left: 3rem; }
    @media (--mobile) {
      .c-product .l-lineup__info {
        width: calc(100% - 12rem);
        padding-left: 2rem; } }
    .c-product .l-lineup__info h5 {
      font-size: 2rem;
      line-height: 1.5; }
    .c-product .l-lineup__info p {
      font-size: 1.4rem; }
  .c-product .l-lineup__series {
    display: inline-block;
    color: #8491aa; }
  .c-product .l-lineup__el {
    position: relative; }
    .c-product .l-lineup__el::after, .c-product .l-lineup__el::before {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 1px;
      content: "";
      background-color: #1a2e56;
      opacity: 0;
      transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
      transition-duration: 0.6s;
      transition-property: opacity, transform;
      transform: scale3d(0.8, 1, 1);
      transform-origin: left center; }
    .c-product .l-lineup__el::after {
      top: 0;
      bottom: inherit; }
    @media (--large) {
      .c-product .l-lineup__el:hover img {
        transform: scale(1.05); }
      .c-product .l-lineup__el:hover::after, .c-product .l-lineup__el:hover::before {
        opacity: 1;
        transform: none; } }

.c-product-list ul {
  display: flex; }
  @media (--mobile) {
    .c-product-list ul {
      display: block; } }
  .c-product-list ul li {
    width: calc(100% / 2 - 4rem);
    margin-right: 4rem; }
    @media (--mobile) {
      .c-product-list ul li {
        width: 100%;
        margin-top: 3rem;
        margin-right: 0; }
        .c-product-list ul li:first-child {
          margin-top: 0; } }
    .c-product-list ul li:last-child {
      margin-right: 0; }
    .c-product-list ul li .title {
      margin-top: 2.4rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: #1a2e56; }
    .c-product-list ul li .text {
      margin-top: 1.2rem;
      font-size: 1.6rem; }

.c-s-search-inner {
  display: flex;
  align-items: center; }
  @media (--mobile) {
    .c-s-search-inner {
      flex-wrap: wrap; } }
  .c-s-search-inner__txt {
    margin-right: 2rem;
    color: #1a2e56; }
    @media (--mobile) {
      .c-s-search-inner__txt {
        width: 100%;
        font-size: 2rem; } }
  .c-s-search-inner__input {
    width: 26rem;
    height: 5rem;
    margin-right: 2rem; }
    @media (--mobile) {
      .c-s-search-inner__input {
        width: calc(100% - 14rem - 2rem); } }
    .c-s-search-inner__input input {
      width: 100%;
      height: 100%;
      padding: 1rem;
      font-size: 1.6rem;
      border: 0.1rem solid #c7ced3; }
      .c-s-search-inner__input input::placeholder {
        color: #b2b2b2; }
  .c-s-search-inner .c-btn01 a,
  .c-s-search-inner .c-btn01 button {
    width: 18.4rem;
    height: 5rem;
    padding: 0 2rem;
    font-size: 1.8rem; }
    @media (--mobile) {
      .c-s-search-inner .c-btn01 a,
      .c-s-search-inner .c-btn01 button {
        width: 14rem;
        font-size: 1.6rem; } }
  .c-s-search-inner .c-btn01 svg {
    right: 2rem;
    transform: translateY(-50%); }
    @media (--mobile) {
      .c-s-search-inner .c-btn01 svg {
        right: 1.5rem;
        width: 2rem; } }

.c-s-search__annotation {
  padding-left: 8.6rem;
  margin-top: 1rem;
  font-size: 1.4rem; }
  @media (--mobile) {
    .c-s-search__annotation {
      padding-left: 0;
      font-size: 1.2rem; } }
  .c-s-search__annotation span {
    display: inline-block;
    padding-left: 1em;
    color: #1a2e56;
    text-indent: -1em; }

.c-s-check {
  position: relative;
  font-size: 1.6rem; }
  .c-s-check label {
    position: relative;
    display: inline-block;
    padding-left: 3.5rem; }
    .c-s-check label input {
      position: absolute;
      top: 50%;
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
      transform: translateY(-50%); }
      .c-s-check label input::before, .c-s-check label input::after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        content: "";
        border: 1px solid #c7ced3; }
      .c-s-check label input::after {
        display: none;
        background: url(../images/common/ic_check.png) no-repeat center center/contain;
        border: none; }
      .c-s-check label input:checked::after {
        display: block; }

.c-s-select {
  position: relative; }
  .c-s-select label {
    position: relative;
    display: inline-block; }
    .c-s-select label::after {
      position: absolute;
      top: 50%;
      right: 1rem;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      border-right: 1px solid #1a2e56;
      border-bottom: 1px solid #1a2e56;
      transform: translateY(-50%) translateY(-25%) rotate(45deg); }
    .c-s-select label select {
      display: flex;
      align-items: center;
      min-width: 15rem;
      height: 4rem;
      padding: 0;
      padding: 0 3rem 0 2rem;
      font-size: 1.6rem;
      border: 1px solid #c7ced3; }

.c-separate {
  display: flex;
  flex-wrap: wrap; }
  .c-separate__left {
    width: 22rem;
    padding-right: 4rem; }
    @media (--mobile) {
      .c-separate__left {
        width: 100%;
        padding-right: 0;
        margin-bottom: 5rem; } }
  .c-separate__sticty {
    position: sticky;
    top: 0;
    left: 0; }
    @media (--large) {
      .c-separate__sticty {
        padding-top: 12rem;
        margin-top: -12rem; } }
  .c-separate__right {
    width: calc(100% - 22rem); }
    @media (--mobile) {
      .c-separate__right {
        width: 100%; } }

.js-form {
  position: relative;
  z-index: 1; }

.c-m-sort,
.c-s-sort {
  border: 1px solid #c7ced3;
  border-top: 1.3rem solid #1a2e56; }
  .c-m-sort h3,
  .c-s-sort h3 {
    position: relative;
    display: flex;
    align-items: center;
    height: 7rem;
    padding: 0 5.5rem;
    font-size: 2rem;
    color: #1a2e56;
    background: #edeff4; }
    @media (--mobile) {
      .c-m-sort h3,
      .c-s-sort h3 {
        height: 5rem;
        padding: 0.8rem  4.3rem 0.8rem 5.3rem;
        font-size: 1.8rem; } }
    .c-m-sort h3 svg:first-of-type,
    .c-s-sort h3 svg:first-of-type {
      position: absolute;
      top: 50%;
      left: 2.5rem;
      width: 2rem;
      height: 2rem;
      content: "";
      transform: translateY(-50%); }
      .c-m-sort h3 svg:first-of-type *,
      .c-s-sort h3 svg:first-of-type * {
        fill: #1a2e56; }
    .c-m-sort h3 svg:last-of-type,
    .c-s-sort h3 svg:last-of-type {
      position: absolute;
      top: 50%;
      right: 4rem;
      width: 1rem;
      height: 1.6rem;
      transition: 0.3s;
      transform: translateY(-50%) rotate(90deg); }
      @media (--mobile) {
        .c-m-sort h3 svg:last-of-type,
        .c-s-sort h3 svg:last-of-type {
          right: 2rem;
          width: 1.3rem;
          height: 2rem; } }
    .c-m-sort h3.is-open svg:last-of-type,
    .c-s-sort h3.is-open svg:last-of-type {
      transform: translateY(-50%) rotate(-90deg); }
  .c-m-sort .c-btn01 button,
  .c-s-sort .c-btn01 button {
    width: 28rem;
    height: 7rem;
    font-size: 1.8rem; }

@media (--mobile) {
  .c-m-sort h3 svg:first-of-type {
    left: 2rem; } }

.c-m-sort-wrap {
  padding: 1.5rem 5rem; }
  @media (--mobile) {
    .c-m-sort-wrap {
      padding: 2rem; } }
  .c-m-sort-wrap h4 {
    font-size: 2rem;
    color: #1a2e56; }
  .c-m-sort-wrap:nth-of-type(n + 2) {
    border-top: 1px solid #c7ced3; }

.c-m-sort-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem; }
  .c-m-sort-list div {
    width: calc(100% / 5); }
    @media (--mobile) {
      .c-m-sort-list div {
        width: auto;
        min-width: calc(100% / 2); } }
    @media (--large) {
      .c-m-sort-list div:nth-of-type(n + 6) {
        margin-top: 1rem; } }
    .c-m-sort-list div a,
    .c-m-sort-list div button {
      position: relative;
      padding: 0;
      padding-left: 1.8rem;
      font-size: 1.6rem;
      text-align: left; }
      .c-m-sort-list div a::after,
      .c-m-sort-list div button::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 0.8rem;
        height: 1.3rem;
        content: "";
        background: url(../images/common/ic_arrow01.svg) no-repeat center center/contain;
        transform: translateY(-50%); }

.c-s-sort {
  border-top: 0; }
  .c-s-sort h3 {
    font-size: 2rem;
    background: #fff; }
    @media (--mobile) {
      .c-s-sort h3 svg:first-of-type {
        left: 1.7rem; } }
  .c-s-sort-wrap {
    display: flex;
    padding: 0 1.5rem 1rem; }
    @media (--mobile) {
      .c-s-sort-wrap {
        display: block;
        padding-top: 0;
        padding-bottom: 2rem; } }
    .c-s-sort-wrap__ttl {
      position: relative;
      padding: 0.8rem 1.5rem;
      font-size: 1.8rem;
      background: #edeff4; }
      @media (--large) {
        .c-s-sort-wrap__ttl {
          margin-bottom: 2rem;
          pointer-events: none; } }
      @media (--mobile) {
        .c-s-sort-wrap__ttl {
          padding-right: 5rem; }
          .c-s-sort-wrap__ttl svg {
            position: absolute;
            top: 50%;
            right: 2rem;
            width: 1.3rem;
            height: 2rem;
            transition: 0.3s;
            transform: translateY(-50%) rotate(90deg); }
          .c-s-sort-wrap__ttl.is-open svg {
            transform: translateY(-50%) rotate(-90deg); } }
    .c-s-sort-wrap__el {
      width: calc(100% / 5);
      padding: 0 1.5rem; }
      @media (--mobile) {
        .c-s-sort-wrap__el {
          width: 100%;
          padding: 0; }
          .c-s-sort-wrap__el:nth-of-type(n + 2) {
            margin-top: 2rem; } }
      @media (--large) {
        .c-s-sort-wrap__el--wide {
          width: calc(100% / 5 * 2); } }
    @media (--large) {
      .c-s-sort-wrap__check {
        display: block !important; } }
    .c-s-sort-wrap__check-wrap {
      display: flex;
      flex-wrap: wrap; }
      @media (--mobile) {
        .c-s-sort-wrap__check-wrap {
          padding-top: 0.7rem; } }
    .c-s-sort-wrap__check .c-s-check {
      display: block; }
      @media (--large) {
        .c-s-sort-wrap__check .c-s-check {
          width: 100%; }
          .c-s-sort-wrap__check .c-s-check:nth-of-type(n + 2) {
            margin-top: 0.7rem; } }
      @media (--mobile) {
        .c-s-sort-wrap__check .c-s-check {
          min-width: 50%;
          margin-top: 0.7rem; } }
    @media (--large) {
      .c-s-sort-wrap__check--wide .c-s-check {
        width: 50%;
        margin-top: 0; }
        .c-s-sort-wrap__check--wide .c-s-check:nth-of-type(2) {
          margin-top: 0; }
        .c-s-sort-wrap__check--wide .c-s-check:nth-of-type(n + 3) {
          margin-top: 0.7rem; } }

.c-r-sort {
  margin-top: 3rem; }
  .c-r-sort h4 {
    margin-bottom: 1rem; }
  .c-r-sort div {
    display: flex;
    flex-wrap: wrap;
    width: 60%; }
    @media (--mobile) {
      .c-r-sort div {
        width: 100%; } }
    .c-r-sort div label {
      position: relative;
      width: 50%;
      padding-left: 3rem; }
      @media (--mobile) {
        .c-r-sort div label {
          width: 100%; }
          .c-r-sort div label:nth-of-type(n + 2) {
            margin-top: 1rem; } }
      @media (--large) {
        .c-r-sort div label:nth-of-type(n + 3) {
          margin-top: 0.5rem; } }
      .c-r-sort div label input {
        position: absolute;
        top: 50%;
        left: 0;
        width: 2rem;
        height: 2rem;
        transform: translateY(-50%); }
        .c-r-sort div label input::after, .c-r-sort div label input::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          content: "";
          border: 1px solid #ddd;
          border-radius: 9999px;
          transform: translate(-50%, -50%); }
        .c-r-sort div label input::before {
          width: 1rem;
          height: 1rem;
          background: #000;
          border-radius: 9999px;
          opacity: 0; }
        .c-r-sort div label input:checked::before {
          opacity: 1; }

.c-square-indent,
.c-point-indent {
  display: block;
  padding-left: 1.4em;
  font-size: 1.6rem;
  text-indent: -1.4em; }
  .en .c-square-indent, .en
  .c-point-indent {
    padding-left: 1rem;
    text-indent: -0.7em; }

.c-point-indent {
  padding-left: 0.7em;
  text-indent: -0.7em; }

.c-sup {
  position: relative;
  top: -0.5rem;
  display: inline-block !important;
  font-size: 0.1em; }

@media (--large) {
  .c-table01 {
    overflow: inherit !important; } }

@media (--mobile) {
  .c-table01 {
    overflow: auto; } }

.c-table01 table {
  width: 100%;
  font-size: 1.5rem;
  border-spacing: 0;
  border-collapse: collapse; }
  @media (--mobile) {
    .c-table01 table {
      width: 100rem;
      border-collapse: separate; } }
  .c-table01 table th {
    padding: 1.5rem 0;
    font-weight: bold;
    color: #1a2e56;
    background-color: #edeff4;
    border: 1px solid #c7ced3; }
  .c-table01 table td {
    padding: 1.5rem 0;
    text-align: center;
    background-color: #fff;
    border: 1px solid #c7ced3; }
    .c-table01 table td a {
      color: #0058a2;
      text-decoration: underline; }
  @media (--mobile) {
    .c-table01 table .fixed01 {
      position: sticky;
      left: 0; }
      .c-table01 table .fixed01::before {
        position: absolute;
        top: -1px;
        left: 1px;
        width: 100%;
        height: 100%;
        content: ""; } }

.c-table02 {
  border-right: 1px solid #c7ced3;
  border-left: 1px solid #c7ced3; }
  .c-table02 dl {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-top: 1px solid #c7ced3; }
    @media (--mobile) {
      .c-table02 dl {
        display: block; } }
    .c-table02 dl dt {
      width: 15%;
      padding: 2rem;
      font-size: 1.5rem;
      font-weight: bold;
      color: #1a2e56;
      background-color: #edeff4;
      border-right: 1px solid #c7ced3;
      border-bottom: 1px solid #c7ced3; }
      @media (--mobile) {
        .c-table02 dl dt {
          width: 100%;
          padding: 1rem 2rem; } }
    .c-table02 dl dd {
      width: 85%;
      padding: 2rem 3.5rem;
      font-size: 1.5rem;
      border-bottom: 1px solid #c7ced3; }
      @media (--mobile) {
        .c-table02 dl dd {
          width: 100%;
          padding: 1rem 2rem; } }

.c-table03 {
  overflow: hidden; }
  .c-table03 table {
    width: 100%;
    font-size: 1.5rem;
    border-spacing: 0;
    border-collapse: collapse; }
    @media (--mobile) {
      .c-table03 table {
        width: 80rem;
        border-collapse: separate; } }
    .c-table03 table th {
      padding: 1.5rem 0;
      color: #fff;
      background-color: #2c529f;
      border: 1px solid #c7ced3; }
      .c-table03 table th:first-child {
        background-color: #fff;
        border: none; }
    .c-table03 table td {
      padding: 1rem 2rem;
      border: 1px solid #c7ced3; }
      @media (--mobile) {
        .c-table03 table td {
          min-width: 9rem; } }
      .c-table03 table td.first {
        text-align: center;
        background-color: #edeff4; }
      .c-table03 table td.price {
        color: #2c529f; }
    @media (--mobile) {
      .c-table03 table .fixed01 {
        position: sticky;
        left: 0;
        width: 12rem; }
        .c-table03 table .fixed01::before {
          position: absolute;
          top: -1px;
          left: 1px;
          width: 100%;
          height: 100%;
          content: ""; } }

.c-ttl01 {
  font-size: 2.8rem;
  color: #1a2e56; }
  .c-ttl01 small {
    display: block;
    margin-top: 1rem;
    font-size: 1.4rem;
    color: #8491aa; }

.c-ttl02 {
  font-size: 2.8rem;
  color: #1a2e56; }
  .c-ttl02 small {
    display: block;
    margin-top: 1rem;
    font-size: 1.4rem;
    color: #8491aa; }

.c-ttl03 {
  padding: 0.5rem 1.5rem;
  font-size: 2.2rem;
  color: #fff;
  background: #1a2e56; }
  @media (--mobile) {
    .c-ttl03 {
      padding: 1rem 3rem;
      margin: 0 -3rem;
      font-size: 2rem; } }

.c-ttl04 {
  position: relative;
  padding-bottom: 1rem;
  font-size: 2rem;
  color: #1a2e56;
  border-bottom: 1px solid #c7ced3; }
  @media (--mobile) {
    .c-ttl04 {
      font-size: 2rem; } }
  .c-ttl04::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 10rem;
    height: 1px;
    content: "";
    background: #1a2e56; }
  .c-ttl04__wrap {
    position: relative; }
  .c-ttl04__required {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1.5;
    color: #fff;
    background: #1a2e56;
    transform: translateY(-50%) translateX(100%) translateX(1.5rem); }
    .en .c-ttl04__required {
      width: 10rem;
      height: 2.5rem; }

.c-ttl05 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #1a2e56; }
  .c-ttl05__wrap {
    position: relative; }
  .c-ttl05__required {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1.5;
    color: #fff;
    background: #1a2e56;
    transform: translateY(-50%) translateX(100%) translateX(1.5rem); }

.c-ttl06 {
  display: flex;
  align-items: center;
  height: calc(7rem + 1.3rem);
  padding-left: 2rem;
  font-size: 2.4rem;
  color: #1a2e56;
  background: #edeff4;
  border-top: 1.3rem solid #1a2e56; }
  .c-ttl06 svg {
    width: 2rem;
    margin-right: 1rem;
    transform: translateY(0.2rem); }
    .c-ttl06 svg * {
      fill: #1a2e56; }

.c-ttl07 {
  position: relative;
  padding: 1.7rem 1.5rem 1.7rem 6rem;
  font-size: 2.4rem;
  color: #1a2e56;
  background: #edeff4; }
  .c-ttl07::before {
    position: absolute;
    top: 50%;
    left: 2.3rem;
    width: 1.4rem;
    height: 1.4rem;
    content: "";
    border-right: 2px solid #1a2e56;
    border-bottom: 2px solid #1a2e56;
    transform: translateY(-75%) rotate(45deg); }

.c-wysiwyg {
  font-size: 1.6rem; }
  .c-wysiwyg img {
    margin-bottom: 1.6em; }
  .c-wysiwyg h2 {
    padding-bottom: 0.2em;
    margin-bottom: 2em;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.5;
    color: #1a2e56;
    border-bottom: 2px solid; }
  .c-wysiwyg h3 {
    position: relative;
    padding-left: 1em;
    margin-bottom: 1em;
    font-size: 1.125em;
    font-weight: 700; }
  .c-wysiwyg h3::before {
    position: absolute;
    top: 0.35em;
    left: 0;
    width: 4px;
    height: 1.11em;
    line-height: 1.5;
    content: "";
    background: #1a2e56;
    border-radius: 2px; }
  .c-wysiwyg h4 {
    padding: 0.2em 1em;
    margin-bottom: 2em;
    font-size: 1.125em;
    font-weight: 700;
    background: #eee;
    border-radius: 5px; }
  .c-wysiwyg h5 {
    margin-bottom: 2em;
    font-weight: 700;
    line-height: 1.5; }
  .c-wysiwyg p + p {
    margin-top: 1.75em; }
  .c-wysiwyg p {
    margin-bottom: 1.75em; }
  .c-wysiwyg a {
    color: #1a2e56;
    text-decoration: underline;
    transition: opacity 0.3s; }
  .c-wysiwyg a:hover,
  .c-wysiwyg a:active {
    opacity: 0.7; }
  .c-wysiwyg ul li {
    position: relative;
    padding-left: 1.05em;
    line-height: 1.5; }
  .c-wysiwyg ul li::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "・"; }
  .c-wysiwyg ul li + li {
    margin-top: 0.5em; }
  .c-wysiwyg ol {
    counter-reset: number 0; }
  .c-wysiwyg ol li + li {
    margin-top: 0.5em; }
  .c-wysiwyg ol li {
    position: relative;
    padding-left: 1.5em;
    line-height: 1.5; }
  .c-wysiwyg ol li::before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Baloo Bhaijaan 2", cursive;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.2;
    content: counter(number) ".";
    counter-increment: number 1; }
  .c-wysiwyg ol .pp-addr {
    padding-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 1.75em; }
  .c-wysiwyg ol .pp-addr span {
    display: inline-block; }
  .c-wysiwyg ol .pp-addr span:first-of-type {
    padding-right: 1em; }
  .c-wysiwyg h2 + h2,
  .c-wysiwyg h2 + h3,
  .c-wysiwyg h2 + h4,
  .c-wysiwyg h2 + h5,
  .c-wysiwyg h3 + h2,
  .c-wysiwyg h3 + h3,
  .c-wysiwyg h3 + h4,
  .c-wysiwyg h3 + h5,
  .c-wysiwyg h4 + h2,
  .c-wysiwyg h4 + h3,
  .c-wysiwyg h4 + h4,
  .c-wysiwyg h4 + h5,
  .c-wysiwyg h5 + h2,
  .c-wysiwyg h5 + h3,
  .c-wysiwyg h5 + h4,
  .c-wysiwyg h5 + h5 {
    margin-top: -0.9em; }
  .c-wysiwyg p + p,
  .c-wysiwyg p + img,
  .c-wysiwyg p + ul,
  .c-wysiwyg p + ol,
  .c-wysiwyg img + p,
  .c-wysiwyg img + ul,
  .c-wysiwyg img + ol,
  .c-wysiwyg ul + ol,
  .c-wysiwyg ul + p,
  .c-wysiwyg ul + img,
  .c-wysiwyg ol + p,
  .c-wysiwyg ol + img,
  .c-wysiwyg ol + ul {
    margin-top: 1.75em; }

.c-row {
  display: flex; }

.c-google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden; }

.c-google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.p-top .s-mv {
  position: relative; }
  @media (--mobile) {
    .p-top .s-mv {
      padding-bottom: 9rem; } }
  .p-top .s-mv__inner {
    position: relative;
    width: 100%;
    height: var(--firstViewHeight);
    overflow: hidden;
    background: linear-gradient(310deg, #191919 40%, #00012b); }
    @media (--mobile) {
      .p-top .s-mv__inner {
        height: calc(var(--firstViewHeight) + 2.2rem); } }
  .p-top .s-mv__circle-bottom {
    position: absolute;
    top: -20rem;
    left: 50%;
    width: 200rem;
    height: 200rem;
    opacity: 1;
    transform: translateX(-20rem) rotate(180deg); }
    @media (--mobile) {
      .p-top .s-mv__circle-bottom {
        top: -25rem;
        left: 50%;
        width: 100rem;
        height: 100rem;
        transform: translateX(-6rem) rotate(180deg); } }
    .p-top .s-mv__circle-bottom img {
      width: 100%; }
    .p-top .s-mv__circle-bottom.last {
      display: flex;
      align-items: center;
      justify-content: center; }
      .p-top .s-mv__circle-bottom.last img {
        width: 93%; }
        @media (--mobile) {
          .p-top .s-mv__circle-bottom.last img {
            width: 90%; } }
  .p-top .s-mv__circle-top {
    position: absolute;
    top: -27rem;
    left: 0;
    width: 50rem;
    height: 50rem;
    opacity: 1;
    transform: translateX(-20rem) rotate(50deg); }
    @media (--mobile) {
      .p-top .s-mv__circle-top {
        top: -29rem !important;
        transform: translateX(-25rem) rotate(50deg); } }
    .p-top .s-mv__circle-top img {
      width: 100%; }
    .p-top .s-mv__circle-top.last {
      display: flex;
      align-items: center;
      justify-content: center; }
      .p-top .s-mv__circle-top.last img {
        width: 80%; }
  .p-top .s-mv__spindle, .p-top .s-mv__mg {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 110rem;
    height: 50rem;
    transform: translateY(-50%) translateY(-30rem) translateX(108rem); }
    @media (--mobile) {
      .p-top .s-mv__spindle, .p-top .s-mv__mg {
        top: 50%;
        right: 50%;
        width: 60rem;
        height: 47rem;
        transform: translate(50%, -50%) translateX(13rem) translateY(-15rem); } }
    .p-top .s-mv__spindle img, .p-top .s-mv__mg img {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-120rem) translateX(120rem); }
      @media (--mobile) {
        .p-top .s-mv__spindle img, .p-top .s-mv__mg img {
          width: 100%;
          height: 100%;
          transform: translateY(-50rem) translateX(50rem);
          object-fit: contain; } }
    .p-top .s-mv__spindle-mov, .p-top .s-mv__mg-mov {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 100%;
      height: 100%;
      pointer-events: none;
      opacity: 0;
      transform: translate(-50%, -50%); }
      @media (--mobile) {
        .p-top .s-mv__spindle-mov, .p-top .s-mv__mg-mov {
          top: inherit;
          bottom: 27rem;
          left: inherit;
          height: 30rem;
          transform: inherit; } }
      .p-top .s-mv__spindle-mov video, .p-top .s-mv__mg-mov video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        object-position: center center; }
    .p-top .s-mv__spindle-txt, .p-top .s-mv__mg-txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) translateX(-38rem); }
      @media (--mobile) {
        .p-top .s-mv__spindle-txt, .p-top .s-mv__mg-txt {
          top: auto;
          bottom: 4rem;
          left: 0;
          padding-left: 3rem;
          transform: initial; } }
      .p-top .s-mv__spindle-txt *, .p-top .s-mv__mg-txt * {
        color: #fff;
        letter-spacing: 0.1em; }
        @media (--mobile) {
          .p-top .s-mv__spindle-txt *, .p-top .s-mv__mg-txt * {
            letter-spacing: 0.05em; } }
      .p-top .s-mv__spindle-txt-wrap div, .p-top .s-mv__mg-txt-wrap div {
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateX(-15rem); }
        @media (--mobile) {
          .p-top .s-mv__spindle-txt-wrap div, .p-top .s-mv__mg-txt-wrap div {
            transform: translateX(-8rem); } }
        .p-top .s-mv__spindle-txt-wrap div span, .p-top .s-mv__mg-txt-wrap div span {
          font-size: 2.2rem;
          opacity: 0.6; }
          @media (--mobile) {
            .p-top .s-mv__spindle-txt-wrap div span, .p-top .s-mv__mg-txt-wrap div span {
              font-size: 1.5rem; } }
        .p-top .s-mv__spindle-txt-wrap div small, .p-top .s-mv__mg-txt-wrap div small {
          font-size: 1.5rem;
          opacity: 0.6; }
          @media (--mobile) {
            .p-top .s-mv__spindle-txt-wrap div small, .p-top .s-mv__mg-txt-wrap div small {
              font-size: 1.2rem; } }
        .p-top .s-mv__spindle-txt-wrap div hr, .p-top .s-mv__mg-txt-wrap div hr {
          width: 20rem;
          height: 1px;
          margin: 0 1rem;
          background: #fff;
          border: none;
          opacity: 0.6; }
          @media (--mobile) {
            .p-top .s-mv__spindle-txt-wrap div hr, .p-top .s-mv__mg-txt-wrap div hr {
              width: 3rem; } }
    .p-top .s-mv__spindle-ttl, .p-top .s-mv__mg-ttl {
      margin-top: 4rem;
      font-family: Roboto, sans-serif;
      font-size: 10rem;
      font-style: italic;
      line-height: 1.1;
      opacity: 0;
      transform: translateX(-15rem); }
      @media (--mobile) {
        .p-top .s-mv__spindle-ttl, .p-top .s-mv__mg-ttl {
          margin-top: 2rem;
          font-size: 6.4rem;
          transform: translateX(-5rem); } }
  .p-top .s-mv__mg {
    top: 50% !important;
    right: 50% !important;
    width: 98rem !important;
    height: 55rem !important;
    transform: translate(50%, -50%) translateX(40rem) translateY(3rem) !important; }
    @media (--mobile) {
      .p-top .s-mv__mg {
        width: 48rem !important;
        height: 31rem !important;
        transform: translate(50%, -50%) translateY(-6rem) translateX(4rem) !important; } }
    .p-top .s-mv__mg img {
      opacity: 0;
      transform: translate(5rem, -5rem); }
      @media (--mobile) {
        .p-top .s-mv__mg img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center center;
          transform: translate(5rem, -5rem); } }
      .p-top .s-mv__mg img.last {
        position: relative;
        z-index: 1; }

/**
* wordpress
* WYSIWYG compatchble style
*/
.c-wp-post_article strong {
  font-weight: bold; }

.c-wp-post_article em {
  font-style: italic; }

.c-wp-post_article ul {
  margin-left: 1em;
  display: block;
  list-style-type: disc !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article ol {
  display: block;
  list-style-type: decimal !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article li {
  display: list-item;
  text-align: match-parent; }

.c-wp-post_article img {
  width: auto; }

.c-wp-post_article .alignleft {
  display: inline;
  float: left; }

.c-wp-post_article .alignright {
  display: inline;
  float: right; }

.c-wp-post_article .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-wp-post_article blockquote.alignleft,
.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article img.alignleft {
  margin: 0.4em 1.6em 1.6em 0; }

.c-wp-post_article blockquote.alignright,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article img.alignright {
  margin: 0.4em 0 1.6em 1.6em; }

.c-wp-post_article blockquote.aligncenter,
.c-wp-post_article .wp-caption.aligncenter,
.c-wp-post_article img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em; }

.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article .wp-caption.aligncenter {
  margin-bottom: 1.2em; }

/* ==========================================================================
 height
========================================================================== */
.u-h-0 {
  height: 0rem !important; }

.u-h-1 {
  height: 1rem !important; }

.u-h-2 {
  height: 2rem !important; }

.u-h-3 {
  height: 3rem !important; }

.u-h-4 {
  height: 4rem !important; }

.u-h-5 {
  height: 5rem !important; }

.u-h-6 {
  height: 6rem !important; }

.u-h-7 {
  height: 7rem !important; }

.u-h-8 {
  height: 8rem !important; }

.u-h-9 {
  height: 9rem !important; }

.u-h-10 {
  height: 10rem !important; }

.u-h-11 {
  height: 11rem !important; }

.u-h-12 {
  height: 12rem !important; }

.u-h-13 {
  height: 13rem !important; }

.u-h-14 {
  height: 14rem !important; }

.u-h-15 {
  height: 15rem !important; }

.u-h-16 {
  height: 16rem !important; }

.u-h-17 {
  height: 17rem !important; }

.u-h-18 {
  height: 18rem !important; }

.u-h-19 {
  height: 19rem !important; }

.u-h-20 {
  height: 20rem !important; }

.u-h-21 {
  height: 21rem !important; }

.u-h-22 {
  height: 22rem !important; }

.u-h-23 {
  height: 23rem !important; }

.u-h-24 {
  height: 24rem !important; }

.u-h-25 {
  height: 25rem !important; }

@media (--mobile) {
  .u-h-sp-0 {
    height: 0rem !important; }
  .u-h-sp-1 {
    height: 1rem !important; }
  .u-h-sp-2 {
    height: 2rem !important; }
  .u-h-sp-3 {
    height: 3rem !important; }
  .u-h-sp-4 {
    height: 4rem !important; }
  .u-h-sp-5 {
    height: 5rem !important; }
  .u-h-sp-6 {
    height: 6rem !important; }
  .u-h-sp-7 {
    height: 7rem !important; }
  .u-h-sp-8 {
    height: 8rem !important; }
  .u-h-sp-9 {
    height: 9rem !important; }
  .u-h-sp-10 {
    height: 10rem !important; }
  .u-h-sp-11 {
    height: 11rem !important; }
  .u-h-sp-12 {
    height: 12rem !important; }
  .u-h-sp-13 {
    height: 13rem !important; }
  .u-h-sp-14 {
    height: 14rem !important; }
  .u-h-sp-15 {
    height: 15rem !important; }
  .u-h-sp-16 {
    height: 16rem !important; }
  .u-h-sp-17 {
    height: 17rem !important; }
  .u-h-sp-18 {
    height: 18rem !important; }
  .u-h-sp-19 {
    height: 19rem !important; }
  .u-h-sp-20 {
    height: 20rem !important; }
  .u-h-sp-21 {
    height: 21rem !important; }
  .u-h-sp-22 {
    height: 22rem !important; }
  .u-h-sp-23 {
    height: 23rem !important; }
  .u-h-sp-24 {
    height: 24rem !important; }
  .u-h-sp-25 {
    height: 25rem !important; } }

/**
 * clearfix micro
 */
.clearfix:after {
  content: "";
  clear: both;
  display: block; }

/**
 * text ellipsis., text...
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-hide {
  display: none; }

.u-click {
  cursor: pointer; }

@media (--large) {
  .for-mobile {
    display: none; } }

@media (--mobile) {
  .for-large {
    display: none; } }

/* ==========================================================================
 margin
========================================================================== */
.u-m-c {
  margin-right: auto !important;
  margin-left: auto !important; }

.u-m-r {
  margin-right: auto !important;
  margin-left: 0 !important; }

/* Top margin */
.u-mt-0 {
  margin-top: 0rem !important; }

.u-mt-1 {
  margin-top: 1rem !important; }

.u-mt-2 {
  margin-top: 2rem !important; }

.u-mt-3 {
  margin-top: 3rem !important; }

.u-mt-4 {
  margin-top: 4rem !important; }

.u-mt-5 {
  margin-top: 5rem !important; }

.u-mt-6 {
  margin-top: 6rem !important; }

.u-mt-7 {
  margin-top: 7rem !important; }

.u-mt-8 {
  margin-top: 8rem !important; }

.u-mt-9 {
  margin-top: 9rem !important; }

.u-mt-10 {
  margin-top: 10rem !important; }

.u-mt-11 {
  margin-top: 11rem !important; }

.u-mt-12 {
  margin-top: 12rem !important; }

.u-mt-13 {
  margin-top: 13rem !important; }

.u-mt-14 {
  margin-top: 14rem !important; }

.u-mt-15 {
  margin-top: 15rem !important; }

.u-mt-16 {
  margin-top: 16rem !important; }

.u-mt-17 {
  margin-top: 17rem !important; }

.u-mt-18 {
  margin-top: 18rem !important; }

.u-mt-19 {
  margin-top: 19rem !important; }

.u-mt-20 {
  margin-top: 20rem !important; }

.u-mt-21 {
  margin-top: 21rem !important; }

.u-mt-22 {
  margin-top: 22rem !important; }

.u-mt-23 {
  margin-top: 23rem !important; }

.u-mt-24 {
  margin-top: 24rem !important; }

.u-mt-25 {
  margin-top: 25rem !important; }

.u-mt-26 {
  margin-top: 26rem !important; }

.u-mt-27 {
  margin-top: 27rem !important; }

.u-mt-28 {
  margin-top: 28rem !important; }

.u-mt-29 {
  margin-top: 29rem !important; }

.u-mt-30 {
  margin-top: 30rem !important; }

.u-mt-31 {
  margin-top: 31rem !important; }

.u-mt-32 {
  margin-top: 32rem !important; }

.u-mt-33 {
  margin-top: 33rem !important; }

.u-mt-34 {
  margin-top: 34rem !important; }

.u-mt-35 {
  margin-top: 35rem !important; }

.u-mt-36 {
  margin-top: 36rem !important; }

.u-mt-37 {
  margin-top: 37rem !important; }

.u-mt-38 {
  margin-top: 38rem !important; }

.u-mt-39 {
  margin-top: 39rem !important; }

.u-mt-40 {
  margin-top: 40rem !important; }

.u-mt-41 {
  margin-top: 41rem !important; }

.u-mt-42 {
  margin-top: 42rem !important; }

.u-mt-43 {
  margin-top: 43rem !important; }

.u-mt-44 {
  margin-top: 44rem !important; }

.u-mt-45 {
  margin-top: 45rem !important; }

.u-mt-46 {
  margin-top: 46rem !important; }

.u-mt-47 {
  margin-top: 47rem !important; }

.u-mt-48 {
  margin-top: 48rem !important; }

.u-mt-49 {
  margin-top: 49rem !important; }

/* Right margin */
.u-mr-0 {
  margin-right: 0rem !important; }

.u-mr-1 {
  margin-right: 1rem !important; }

.u-mr-2 {
  margin-right: 2rem !important; }

.u-mr-3 {
  margin-right: 3rem !important; }

.u-mr-4 {
  margin-right: 4rem !important; }

.u-mr-5 {
  margin-right: 5rem !important; }

.u-mr-6 {
  margin-right: 6rem !important; }

.u-mr-7 {
  margin-right: 7rem !important; }

.u-mr-8 {
  margin-right: 8rem !important; }

.u-mr-9 {
  margin-right: 9rem !important; }

.u-mr-10 {
  margin-right: 10rem !important; }

.u-mr-11 {
  margin-right: 11rem !important; }

.u-mr-12 {
  margin-right: 12rem !important; }

.u-mr-13 {
  margin-right: 13rem !important; }

.u-mr-14 {
  margin-right: 14rem !important; }

.u-mr-15 {
  margin-right: 15rem !important; }

.u-mr-16 {
  margin-right: 16rem !important; }

.u-mr-17 {
  margin-right: 17rem !important; }

.u-mr-18 {
  margin-right: 18rem !important; }

.u-mr-19 {
  margin-right: 19rem !important; }

.u-mr-20 {
  margin-right: 20rem !important; }

.u-mr-21 {
  margin-right: 21rem !important; }

.u-mr-22 {
  margin-right: 22rem !important; }

.u-mr-23 {
  margin-right: 23rem !important; }

.u-mr-24 {
  margin-right: 24rem !important; }

.u-mr-25 {
  margin-right: 25rem !important; }

.u-mr-26 {
  margin-right: 26rem !important; }

.u-mr-27 {
  margin-right: 27rem !important; }

.u-mr-28 {
  margin-right: 28rem !important; }

.u-mr-29 {
  margin-right: 29rem !important; }

.u-mr-30 {
  margin-right: 30rem !important; }

.u-mr-31 {
  margin-right: 31rem !important; }

.u-mr-32 {
  margin-right: 32rem !important; }

.u-mr-33 {
  margin-right: 33rem !important; }

.u-mr-34 {
  margin-right: 34rem !important; }

.u-mr-35 {
  margin-right: 35rem !important; }

.u-mr-36 {
  margin-right: 36rem !important; }

.u-mr-37 {
  margin-right: 37rem !important; }

.u-mr-38 {
  margin-right: 38rem !important; }

.u-mr-39 {
  margin-right: 39rem !important; }

.u-mr-40 {
  margin-right: 40rem !important; }

.u-mr-41 {
  margin-right: 41rem !important; }

.u-mr-42 {
  margin-right: 42rem !important; }

.u-mr-43 {
  margin-right: 43rem !important; }

.u-mr-44 {
  margin-right: 44rem !important; }

.u-mr-45 {
  margin-right: 45rem !important; }

.u-mr-46 {
  margin-right: 46rem !important; }

.u-mr-47 {
  margin-right: 47rem !important; }

.u-mr-48 {
  margin-right: 48rem !important; }

.u-mr-49 {
  margin-right: 49rem !important; }

/* Bottom margin */
.u-mb-0 {
  margin-bottom: 0rem !important; }

.u-mb-1 {
  margin-bottom: 1rem !important; }

.u-mb-2 {
  margin-bottom: 2rem !important; }

.u-mb-3 {
  margin-bottom: 3rem !important; }

.u-mb-4 {
  margin-bottom: 4rem !important; }

.u-mb-5 {
  margin-bottom: 5rem !important; }

.u-mb-6 {
  margin-bottom: 6rem !important; }

.u-mb-7 {
  margin-bottom: 7rem !important; }

.u-mb-8 {
  margin-bottom: 8rem !important; }

.u-mb-9 {
  margin-bottom: 9rem !important; }

.u-mb-10 {
  margin-bottom: 10rem !important; }

.u-mb-11 {
  margin-bottom: 11rem !important; }

.u-mb-12 {
  margin-bottom: 12rem !important; }

.u-mb-13 {
  margin-bottom: 13rem !important; }

.u-mb-14 {
  margin-bottom: 14rem !important; }

.u-mb-15 {
  margin-bottom: 15rem !important; }

.u-mb-16 {
  margin-bottom: 16rem !important; }

.u-mb-17 {
  margin-bottom: 17rem !important; }

.u-mb-18 {
  margin-bottom: 18rem !important; }

.u-mb-19 {
  margin-bottom: 19rem !important; }

.u-mb-20 {
  margin-bottom: 20rem !important; }

.u-mb-21 {
  margin-bottom: 21rem !important; }

.u-mb-22 {
  margin-bottom: 22rem !important; }

.u-mb-23 {
  margin-bottom: 23rem !important; }

.u-mb-24 {
  margin-bottom: 24rem !important; }

.u-mb-25 {
  margin-bottom: 25rem !important; }

.u-mb-26 {
  margin-bottom: 26rem !important; }

.u-mb-27 {
  margin-bottom: 27rem !important; }

.u-mb-28 {
  margin-bottom: 28rem !important; }

.u-mb-29 {
  margin-bottom: 29rem !important; }

.u-mb-30 {
  margin-bottom: 30rem !important; }

.u-mb-31 {
  margin-bottom: 31rem !important; }

.u-mb-32 {
  margin-bottom: 32rem !important; }

.u-mb-33 {
  margin-bottom: 33rem !important; }

.u-mb-34 {
  margin-bottom: 34rem !important; }

.u-mb-35 {
  margin-bottom: 35rem !important; }

.u-mb-36 {
  margin-bottom: 36rem !important; }

.u-mb-37 {
  margin-bottom: 37rem !important; }

.u-mb-38 {
  margin-bottom: 38rem !important; }

.u-mb-39 {
  margin-bottom: 39rem !important; }

.u-mb-40 {
  margin-bottom: 40rem !important; }

.u-mb-41 {
  margin-bottom: 41rem !important; }

.u-mb-42 {
  margin-bottom: 42rem !important; }

.u-mb-43 {
  margin-bottom: 43rem !important; }

.u-mb-44 {
  margin-bottom: 44rem !important; }

.u-mb-45 {
  margin-bottom: 45rem !important; }

.u-mb-46 {
  margin-bottom: 46rem !important; }

.u-mb-47 {
  margin-bottom: 47rem !important; }

.u-mb-48 {
  margin-bottom: 48rem !important; }

.u-mb-49 {
  margin-bottom: 49rem !important; }

/* Left margin */
.u-ml-0 {
  margin-left: 0rem !important; }

.u-ml-1 {
  margin-left: 1rem !important; }

.u-ml-2 {
  margin-left: 2rem !important; }

.u-ml-3 {
  margin-left: 3rem !important; }

.u-ml-4 {
  margin-left: 4rem !important; }

.u-ml-5 {
  margin-left: 5rem !important; }

.u-ml-6 {
  margin-left: 6rem !important; }

.u-ml-7 {
  margin-left: 7rem !important; }

.u-ml-8 {
  margin-left: 8rem !important; }

.u-ml-9 {
  margin-left: 9rem !important; }

.u-ml-10 {
  margin-left: 10rem !important; }

.u-ml-11 {
  margin-left: 11rem !important; }

.u-ml-12 {
  margin-left: 12rem !important; }

.u-ml-13 {
  margin-left: 13rem !important; }

.u-ml-14 {
  margin-left: 14rem !important; }

.u-ml-15 {
  margin-left: 15rem !important; }

.u-ml-16 {
  margin-left: 16rem !important; }

.u-ml-17 {
  margin-left: 17rem !important; }

.u-ml-18 {
  margin-left: 18rem !important; }

.u-ml-19 {
  margin-left: 19rem !important; }

.u-ml-20 {
  margin-left: 20rem !important; }

.u-ml-21 {
  margin-left: 21rem !important; }

.u-ml-22 {
  margin-left: 22rem !important; }

.u-ml-23 {
  margin-left: 23rem !important; }

.u-ml-24 {
  margin-left: 24rem !important; }

.u-ml-25 {
  margin-left: 25rem !important; }

.u-ml-26 {
  margin-left: 26rem !important; }

.u-ml-27 {
  margin-left: 27rem !important; }

.u-ml-28 {
  margin-left: 28rem !important; }

.u-ml-29 {
  margin-left: 29rem !important; }

.u-ml-30 {
  margin-left: 30rem !important; }

.u-ml-31 {
  margin-left: 31rem !important; }

.u-ml-32 {
  margin-left: 32rem !important; }

.u-ml-33 {
  margin-left: 33rem !important; }

.u-ml-34 {
  margin-left: 34rem !important; }

.u-ml-35 {
  margin-left: 35rem !important; }

.u-ml-36 {
  margin-left: 36rem !important; }

.u-ml-37 {
  margin-left: 37rem !important; }

.u-ml-38 {
  margin-left: 38rem !important; }

.u-ml-39 {
  margin-left: 39rem !important; }

.u-ml-40 {
  margin-left: 40rem !important; }

.u-ml-41 {
  margin-left: 41rem !important; }

.u-ml-42 {
  margin-left: 42rem !important; }

.u-ml-43 {
  margin-left: 43rem !important; }

.u-ml-44 {
  margin-left: 44rem !important; }

.u-ml-45 {
  margin-left: 45rem !important; }

.u-ml-46 {
  margin-left: 46rem !important; }

.u-ml-47 {
  margin-left: 47rem !important; }

.u-ml-48 {
  margin-left: 48rem !important; }

.u-ml-49 {
  margin-left: 49rem !important; }

@media (--mobile) {
  .u-m-sp-c {
    margin-right: auto !important;
    margin-left: auto !important; }
  .u-m-sp-r {
    margin-right: auto !important;
    margin-left: 0 !important; }
  /* Top margin */
  .u-mt-sp-0 {
    margin-top: 0rem !important; }
  .u-mt-sp-1 {
    margin-top: 1rem !important; }
  .u-mt-sp-2 {
    margin-top: 2rem !important; }
  .u-mt-sp-3 {
    margin-top: 3rem !important; }
  .u-mt-sp-4 {
    margin-top: 4rem !important; }
  .u-mt-sp-5 {
    margin-top: 5rem !important; }
  .u-mt-sp-6 {
    margin-top: 6rem !important; }
  .u-mt-sp-7 {
    margin-top: 7rem !important; }
  .u-mt-sp-8 {
    margin-top: 8rem !important; }
  .u-mt-sp-9 {
    margin-top: 9rem !important; }
  .u-mt-sp-10 {
    margin-top: 10rem !important; }
  .u-mt-sp-11 {
    margin-top: 11rem !important; }
  .u-mt-sp-12 {
    margin-top: 12rem !important; }
  .u-mt-sp-13 {
    margin-top: 13rem !important; }
  .u-mt-sp-14 {
    margin-top: 14rem !important; }
  .u-mt-sp-15 {
    margin-top: 15rem !important; }
  .u-mt-sp-16 {
    margin-top: 16rem !important; }
  .u-mt-sp-17 {
    margin-top: 17rem !important; }
  .u-mt-sp-18 {
    margin-top: 18rem !important; }
  .u-mt-sp-19 {
    margin-top: 19rem !important; }
  .u-mt-sp-20 {
    margin-top: 20rem !important; }
  .u-mt-sp-21 {
    margin-top: 21rem !important; }
  .u-mt-sp-22 {
    margin-top: 22rem !important; }
  .u-mt-sp-23 {
    margin-top: 23rem !important; }
  .u-mt-sp-24 {
    margin-top: 24rem !important; }
  .u-mt-sp-25 {
    margin-top: 25rem !important; }
  .u-mt-sp-26 {
    margin-top: 26rem !important; }
  .u-mt-sp-27 {
    margin-top: 27rem !important; }
  .u-mt-sp-28 {
    margin-top: 28rem !important; }
  .u-mt-sp-29 {
    margin-top: 29rem !important; }
  .u-mt-sp-30 {
    margin-top: 30rem !important; }
  .u-mt-sp-31 {
    margin-top: 31rem !important; }
  .u-mt-sp-32 {
    margin-top: 32rem !important; }
  .u-mt-sp-33 {
    margin-top: 33rem !important; }
  .u-mt-sp-34 {
    margin-top: 34rem !important; }
  .u-mt-sp-35 {
    margin-top: 35rem !important; }
  .u-mt-sp-36 {
    margin-top: 36rem !important; }
  .u-mt-sp-37 {
    margin-top: 37rem !important; }
  .u-mt-sp-38 {
    margin-top: 38rem !important; }
  .u-mt-sp-39 {
    margin-top: 39rem !important; }
  .u-mt-sp-40 {
    margin-top: 40rem !important; }
  .u-mt-sp-41 {
    margin-top: 41rem !important; }
  .u-mt-sp-42 {
    margin-top: 42rem !important; }
  .u-mt-sp-43 {
    margin-top: 43rem !important; }
  .u-mt-sp-44 {
    margin-top: 44rem !important; }
  .u-mt-sp-45 {
    margin-top: 45rem !important; }
  .u-mt-sp-46 {
    margin-top: 46rem !important; }
  .u-mt-sp-47 {
    margin-top: 47rem !important; }
  .u-mt-sp-48 {
    margin-top: 48rem !important; }
  .u-mt-sp-49 {
    margin-top: 49rem !important; }
  /* Right margin */
  .u-mr-sp-0 {
    margin-right: 0rem !important; }
  .u-mr-sp-1 {
    margin-right: 1rem !important; }
  .u-mr-sp-2 {
    margin-right: 2rem !important; }
  .u-mr-sp-3 {
    margin-right: 3rem !important; }
  .u-mr-sp-4 {
    margin-right: 4rem !important; }
  .u-mr-sp-5 {
    margin-right: 5rem !important; }
  .u-mr-sp-6 {
    margin-right: 6rem !important; }
  .u-mr-sp-7 {
    margin-right: 7rem !important; }
  .u-mr-sp-8 {
    margin-right: 8rem !important; }
  .u-mr-sp-9 {
    margin-right: 9rem !important; }
  .u-mr-sp-10 {
    margin-right: 10rem !important; }
  .u-mr-sp-11 {
    margin-right: 11rem !important; }
  .u-mr-sp-12 {
    margin-right: 12rem !important; }
  .u-mr-sp-13 {
    margin-right: 13rem !important; }
  .u-mr-sp-14 {
    margin-right: 14rem !important; }
  .u-mr-sp-15 {
    margin-right: 15rem !important; }
  .u-mr-sp-16 {
    margin-right: 16rem !important; }
  .u-mr-sp-17 {
    margin-right: 17rem !important; }
  .u-mr-sp-18 {
    margin-right: 18rem !important; }
  .u-mr-sp-19 {
    margin-right: 19rem !important; }
  .u-mr-sp-20 {
    margin-right: 20rem !important; }
  .u-mr-sp-21 {
    margin-right: 21rem !important; }
  .u-mr-sp-22 {
    margin-right: 22rem !important; }
  .u-mr-sp-23 {
    margin-right: 23rem !important; }
  .u-mr-sp-24 {
    margin-right: 24rem !important; }
  .u-mr-sp-25 {
    margin-right: 25rem !important; }
  .u-mr-sp-26 {
    margin-right: 26rem !important; }
  .u-mr-sp-27 {
    margin-right: 27rem !important; }
  .u-mr-sp-28 {
    margin-right: 28rem !important; }
  .u-mr-sp-29 {
    margin-right: 29rem !important; }
  .u-mr-sp-30 {
    margin-right: 30rem !important; }
  .u-mr-sp-31 {
    margin-right: 31rem !important; }
  .u-mr-sp-32 {
    margin-right: 32rem !important; }
  .u-mr-sp-33 {
    margin-right: 33rem !important; }
  .u-mr-sp-34 {
    margin-right: 34rem !important; }
  .u-mr-sp-35 {
    margin-right: 35rem !important; }
  .u-mr-sp-36 {
    margin-right: 36rem !important; }
  .u-mr-sp-37 {
    margin-right: 37rem !important; }
  .u-mr-sp-38 {
    margin-right: 38rem !important; }
  .u-mr-sp-39 {
    margin-right: 39rem !important; }
  .u-mr-sp-40 {
    margin-right: 40rem !important; }
  .u-mr-sp-41 {
    margin-right: 41rem !important; }
  .u-mr-sp-42 {
    margin-right: 42rem !important; }
  .u-mr-sp-43 {
    margin-right: 43rem !important; }
  .u-mr-sp-44 {
    margin-right: 44rem !important; }
  .u-mr-sp-45 {
    margin-right: 45rem !important; }
  .u-mr-sp-46 {
    margin-right: 46rem !important; }
  .u-mr-sp-47 {
    margin-right: 47rem !important; }
  .u-mr-sp-48 {
    margin-right: 48rem !important; }
  .u-mr-sp-49 {
    margin-right: 49rem !important; }
  /* Bottom margin */
  .u-mb-sp-0 {
    margin-bottom: 0rem !important; }
  .u-mb-sp-1 {
    margin-bottom: 1rem !important; }
  .u-mb-sp-2 {
    margin-bottom: 2rem !important; }
  .u-mb-sp-3 {
    margin-bottom: 3rem !important; }
  .u-mb-sp-4 {
    margin-bottom: 4rem !important; }
  .u-mb-sp-5 {
    margin-bottom: 5rem !important; }
  .u-mb-sp-6 {
    margin-bottom: 6rem !important; }
  .u-mb-sp-7 {
    margin-bottom: 7rem !important; }
  .u-mb-sp-8 {
    margin-bottom: 8rem !important; }
  .u-mb-sp-9 {
    margin-bottom: 9rem !important; }
  .u-mb-sp-10 {
    margin-bottom: 10rem !important; }
  .u-mb-sp-11 {
    margin-bottom: 11rem !important; }
  .u-mb-sp-12 {
    margin-bottom: 12rem !important; }
  .u-mb-sp-13 {
    margin-bottom: 13rem !important; }
  .u-mb-sp-14 {
    margin-bottom: 14rem !important; }
  .u-mb-sp-15 {
    margin-bottom: 15rem !important; }
  .u-mb-sp-16 {
    margin-bottom: 16rem !important; }
  .u-mb-sp-17 {
    margin-bottom: 17rem !important; }
  .u-mb-sp-18 {
    margin-bottom: 18rem !important; }
  .u-mb-sp-19 {
    margin-bottom: 19rem !important; }
  .u-mb-sp-20 {
    margin-bottom: 20rem !important; }
  .u-mb-sp-21 {
    margin-bottom: 21rem !important; }
  .u-mb-sp-22 {
    margin-bottom: 22rem !important; }
  .u-mb-sp-23 {
    margin-bottom: 23rem !important; }
  .u-mb-sp-24 {
    margin-bottom: 24rem !important; }
  .u-mb-sp-25 {
    margin-bottom: 25rem !important; }
  .u-mb-sp-26 {
    margin-bottom: 26rem !important; }
  .u-mb-sp-27 {
    margin-bottom: 27rem !important; }
  .u-mb-sp-28 {
    margin-bottom: 28rem !important; }
  .u-mb-sp-29 {
    margin-bottom: 29rem !important; }
  .u-mb-sp-30 {
    margin-bottom: 30rem !important; }
  .u-mb-sp-31 {
    margin-bottom: 31rem !important; }
  .u-mb-sp-32 {
    margin-bottom: 32rem !important; }
  .u-mb-sp-33 {
    margin-bottom: 33rem !important; }
  .u-mb-sp-34 {
    margin-bottom: 34rem !important; }
  .u-mb-sp-35 {
    margin-bottom: 35rem !important; }
  .u-mb-sp-36 {
    margin-bottom: 36rem !important; }
  .u-mb-sp-37 {
    margin-bottom: 37rem !important; }
  .u-mb-sp-38 {
    margin-bottom: 38rem !important; }
  .u-mb-sp-39 {
    margin-bottom: 39rem !important; }
  .u-mb-sp-40 {
    margin-bottom: 40rem !important; }
  .u-mb-sp-41 {
    margin-bottom: 41rem !important; }
  .u-mb-sp-42 {
    margin-bottom: 42rem !important; }
  .u-mb-sp-43 {
    margin-bottom: 43rem !important; }
  .u-mb-sp-44 {
    margin-bottom: 44rem !important; }
  .u-mb-sp-45 {
    margin-bottom: 45rem !important; }
  .u-mb-sp-46 {
    margin-bottom: 46rem !important; }
  .u-mb-sp-47 {
    margin-bottom: 47rem !important; }
  .u-mb-sp-48 {
    margin-bottom: 48rem !important; }
  .u-mb-sp-49 {
    margin-bottom: 49rem !important; }
  /* Left margin */
  .u-ml-sp-0 {
    margin-left: 0rem !important; }
  .u-ml-sp-1 {
    margin-left: 1rem !important; }
  .u-ml-sp-2 {
    margin-left: 2rem !important; }
  .u-ml-sp-3 {
    margin-left: 3rem !important; }
  .u-ml-sp-4 {
    margin-left: 4rem !important; }
  .u-ml-sp-5 {
    margin-left: 5rem !important; }
  .u-ml-sp-6 {
    margin-left: 6rem !important; }
  .u-ml-sp-7 {
    margin-left: 7rem !important; }
  .u-ml-sp-8 {
    margin-left: 8rem !important; }
  .u-ml-sp-9 {
    margin-left: 9rem !important; }
  .u-ml-sp-10 {
    margin-left: 10rem !important; }
  .u-ml-sp-11 {
    margin-left: 11rem !important; }
  .u-ml-sp-12 {
    margin-left: 12rem !important; }
  .u-ml-sp-13 {
    margin-left: 13rem !important; }
  .u-ml-sp-14 {
    margin-left: 14rem !important; }
  .u-ml-sp-15 {
    margin-left: 15rem !important; }
  .u-ml-sp-16 {
    margin-left: 16rem !important; }
  .u-ml-sp-17 {
    margin-left: 17rem !important; }
  .u-ml-sp-18 {
    margin-left: 18rem !important; }
  .u-ml-sp-19 {
    margin-left: 19rem !important; }
  .u-ml-sp-20 {
    margin-left: 20rem !important; }
  .u-ml-sp-21 {
    margin-left: 21rem !important; }
  .u-ml-sp-22 {
    margin-left: 22rem !important; }
  .u-ml-sp-23 {
    margin-left: 23rem !important; }
  .u-ml-sp-24 {
    margin-left: 24rem !important; }
  .u-ml-sp-25 {
    margin-left: 25rem !important; }
  .u-ml-sp-26 {
    margin-left: 26rem !important; }
  .u-ml-sp-27 {
    margin-left: 27rem !important; }
  .u-ml-sp-28 {
    margin-left: 28rem !important; }
  .u-ml-sp-29 {
    margin-left: 29rem !important; }
  .u-ml-sp-30 {
    margin-left: 30rem !important; }
  .u-ml-sp-31 {
    margin-left: 31rem !important; }
  .u-ml-sp-32 {
    margin-left: 32rem !important; }
  .u-ml-sp-33 {
    margin-left: 33rem !important; }
  .u-ml-sp-34 {
    margin-left: 34rem !important; }
  .u-ml-sp-35 {
    margin-left: 35rem !important; }
  .u-ml-sp-36 {
    margin-left: 36rem !important; }
  .u-ml-sp-37 {
    margin-left: 37rem !important; }
  .u-ml-sp-38 {
    margin-left: 38rem !important; }
  .u-ml-sp-39 {
    margin-left: 39rem !important; }
  .u-ml-sp-40 {
    margin-left: 40rem !important; }
  .u-ml-sp-41 {
    margin-left: 41rem !important; }
  .u-ml-sp-42 {
    margin-left: 42rem !important; }
  .u-ml-sp-43 {
    margin-left: 43rem !important; }
  .u-ml-sp-44 {
    margin-left: 44rem !important; }
  .u-ml-sp-45 {
    margin-left: 45rem !important; }
  .u-ml-sp-46 {
    margin-left: 46rem !important; }
  .u-ml-sp-47 {
    margin-left: 47rem !important; }
  .u-ml-sp-48 {
    margin-left: 48rem !important; }
  .u-ml-sp-49 {
    margin-left: 49rem !important; } }

/**
 * error message
 */
.mw_wp_form .error {
  display: block;
  font-size: 93%;
  color: #b70000; }

/**
 * error message of akismet
 */
.mw_wp_form .akismet_error {
  display: block; }

/**
 * vertical style of radio and checkbox
 */
.mw_wp_form .vertical-item {
  display: block; }

.mw_wp_form .vertical-item + .vertical-item {
  margin-top: 5px; }

/**
 * horizontal style of radio and checkbox
 */
.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 1rem; }

/**
 * radio and checkbox
 */
.mwform-checkbox-field label,
.mwform-radio-field label {
  margin-bottom: 0;
  font-weight: normal; }

.mwform-checkbox-field input,
.mwform-radio-field input {
  margin-right: 5px; }

/**
 * datepicker
 */
.mw_wp_form .ui-datepicker .ui-datepicker-title select.ui-datepicker-month,
.mw_wp_form .ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
  width: 40%;
  margin: 0 5px; }

/**
 * deprecated message
 */
.mw-wp-form-deprecated-message {
  padding: 1em;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.6;
  color: #af4040;
  background: #fdeeee;
  border: 1px solid #f7bdb8;
  border-radius: 5px; }

/**
 * file
 */
.mw_wp_form input[type="file"] {
  display: inline-block; }

/**
 * file delete button
 */
.mwform-file-delete {
  cursor: pointer;
  visibility: hidden; }

/**
 * tel and zip
 */
.mwform-tel-field input[type="text"],
.mwform-zip-field input[type="text"] {
  display: inline-block;
  width: auto; }

@media (--mobile) {
  .mwform-tel-field input[type="text"] {
    width: 28% !important; } }

/* ==========================================================================
 padding
========================================================================== */
/* Top padding */
.u-pt-0 {
  padding-top: 0rem !important; }

.u-pt-1 {
  padding-top: 1rem !important; }

.u-pt-2 {
  padding-top: 2rem !important; }

.u-pt-3 {
  padding-top: 3rem !important; }

.u-pt-4 {
  padding-top: 4rem !important; }

.u-pt-5 {
  padding-top: 5rem !important; }

.u-pt-6 {
  padding-top: 6rem !important; }

.u-pt-7 {
  padding-top: 7rem !important; }

.u-pt-8 {
  padding-top: 8rem !important; }

.u-pt-9 {
  padding-top: 9rem !important; }

.u-pt-10 {
  padding-top: 10rem !important; }

.u-pt-11 {
  padding-top: 11rem !important; }

.u-pt-12 {
  padding-top: 12rem !important; }

.u-pt-13 {
  padding-top: 13rem !important; }

.u-pt-14 {
  padding-top: 14rem !important; }

.u-pt-15 {
  padding-top: 15rem !important; }

.u-pt-16 {
  padding-top: 16rem !important; }

.u-pt-17 {
  padding-top: 17rem !important; }

.u-pt-18 {
  padding-top: 18rem !important; }

.u-pt-19 {
  padding-top: 19rem !important; }

.u-pt-20 {
  padding-top: 20rem !important; }

.u-pt-21 {
  padding-top: 21rem !important; }

.u-pt-22 {
  padding-top: 22rem !important; }

.u-pt-23 {
  padding-top: 23rem !important; }

.u-pt-24 {
  padding-top: 24rem !important; }

.u-pt-25 {
  padding-top: 25rem !important; }

.u-pt-26 {
  padding-top: 26rem !important; }

.u-pt-27 {
  padding-top: 27rem !important; }

.u-pt-28 {
  padding-top: 28rem !important; }

.u-pt-29 {
  padding-top: 29rem !important; }

.u-pt-30 {
  padding-top: 30rem !important; }

.u-pt-31 {
  padding-top: 31rem !important; }

.u-pt-32 {
  padding-top: 32rem !important; }

.u-pt-33 {
  padding-top: 33rem !important; }

.u-pt-34 {
  padding-top: 34rem !important; }

.u-pt-35 {
  padding-top: 35rem !important; }

.u-pt-36 {
  padding-top: 36rem !important; }

.u-pt-37 {
  padding-top: 37rem !important; }

.u-pt-38 {
  padding-top: 38rem !important; }

.u-pt-39 {
  padding-top: 39rem !important; }

.u-pt-40 {
  padding-top: 40rem !important; }

.u-pt-41 {
  padding-top: 41rem !important; }

.u-pt-42 {
  padding-top: 42rem !important; }

.u-pt-43 {
  padding-top: 43rem !important; }

.u-pt-44 {
  padding-top: 44rem !important; }

.u-pt-45 {
  padding-top: 45rem !important; }

.u-pt-46 {
  padding-top: 46rem !important; }

.u-pt-47 {
  padding-top: 47rem !important; }

.u-pt-48 {
  padding-top: 48rem !important; }

.u-pt-49 {
  padding-top: 49rem !important; }

/* Right padding */
.u-pr-0 {
  padding-right: 0rem !important; }

.u-pr-1 {
  padding-right: 1rem !important; }

.u-pr-2 {
  padding-right: 2rem !important; }

.u-pr-3 {
  padding-right: 3rem !important; }

.u-pr-4 {
  padding-right: 4rem !important; }

.u-pr-5 {
  padding-right: 5rem !important; }

.u-pr-6 {
  padding-right: 6rem !important; }

.u-pr-7 {
  padding-right: 7rem !important; }

.u-pr-8 {
  padding-right: 8rem !important; }

.u-pr-9 {
  padding-right: 9rem !important; }

.u-pr-10 {
  padding-right: 10rem !important; }

.u-pr-11 {
  padding-right: 11rem !important; }

.u-pr-12 {
  padding-right: 12rem !important; }

.u-pr-13 {
  padding-right: 13rem !important; }

.u-pr-14 {
  padding-right: 14rem !important; }

.u-pr-15 {
  padding-right: 15rem !important; }

.u-pr-16 {
  padding-right: 16rem !important; }

.u-pr-17 {
  padding-right: 17rem !important; }

.u-pr-18 {
  padding-right: 18rem !important; }

.u-pr-19 {
  padding-right: 19rem !important; }

.u-pr-20 {
  padding-right: 20rem !important; }

.u-pr-21 {
  padding-right: 21rem !important; }

.u-pr-22 {
  padding-right: 22rem !important; }

.u-pr-23 {
  padding-right: 23rem !important; }

.u-pr-24 {
  padding-right: 24rem !important; }

.u-pr-25 {
  padding-right: 25rem !important; }

.u-pr-26 {
  padding-right: 26rem !important; }

.u-pr-27 {
  padding-right: 27rem !important; }

.u-pr-28 {
  padding-right: 28rem !important; }

.u-pr-29 {
  padding-right: 29rem !important; }

.u-pr-30 {
  padding-right: 30rem !important; }

.u-pr-31 {
  padding-right: 31rem !important; }

.u-pr-32 {
  padding-right: 32rem !important; }

.u-pr-33 {
  padding-right: 33rem !important; }

.u-pr-34 {
  padding-right: 34rem !important; }

.u-pr-35 {
  padding-right: 35rem !important; }

.u-pr-36 {
  padding-right: 36rem !important; }

.u-pr-37 {
  padding-right: 37rem !important; }

.u-pr-38 {
  padding-right: 38rem !important; }

.u-pr-39 {
  padding-right: 39rem !important; }

.u-pr-40 {
  padding-right: 40rem !important; }

.u-pr-41 {
  padding-right: 41rem !important; }

.u-pr-42 {
  padding-right: 42rem !important; }

.u-pr-43 {
  padding-right: 43rem !important; }

.u-pr-44 {
  padding-right: 44rem !important; }

.u-pr-45 {
  padding-right: 45rem !important; }

.u-pr-46 {
  padding-right: 46rem !important; }

.u-pr-47 {
  padding-right: 47rem !important; }

.u-pr-48 {
  padding-right: 48rem !important; }

.u-pr-49 {
  padding-right: 49rem !important; }

/* Bottom padding */
.u-pb-0 {
  padding-bottom: 0rem !important; }

.u-pb-1 {
  padding-bottom: 1rem !important; }

.u-pb-2 {
  padding-bottom: 2rem !important; }

.u-pb-3 {
  padding-bottom: 3rem !important; }

.u-pb-4 {
  padding-bottom: 4rem !important; }

.u-pb-5 {
  padding-bottom: 5rem !important; }

.u-pb-6 {
  padding-bottom: 6rem !important; }

.u-pb-7 {
  padding-bottom: 7rem !important; }

.u-pb-8 {
  padding-bottom: 8rem !important; }

.u-pb-9 {
  padding-bottom: 9rem !important; }

.u-pb-10 {
  padding-bottom: 10rem !important; }

.u-pb-11 {
  padding-bottom: 11rem !important; }

.u-pb-12 {
  padding-bottom: 12rem !important; }

.u-pb-13 {
  padding-bottom: 13rem !important; }

.u-pb-14 {
  padding-bottom: 14rem !important; }

.u-pb-15 {
  padding-bottom: 15rem !important; }

.u-pb-16 {
  padding-bottom: 16rem !important; }

.u-pb-17 {
  padding-bottom: 17rem !important; }

.u-pb-18 {
  padding-bottom: 18rem !important; }

.u-pb-19 {
  padding-bottom: 19rem !important; }

.u-pb-20 {
  padding-bottom: 20rem !important; }

.u-pb-21 {
  padding-bottom: 21rem !important; }

.u-pb-22 {
  padding-bottom: 22rem !important; }

.u-pb-23 {
  padding-bottom: 23rem !important; }

.u-pb-24 {
  padding-bottom: 24rem !important; }

.u-pb-25 {
  padding-bottom: 25rem !important; }

.u-pb-26 {
  padding-bottom: 26rem !important; }

.u-pb-27 {
  padding-bottom: 27rem !important; }

.u-pb-28 {
  padding-bottom: 28rem !important; }

.u-pb-29 {
  padding-bottom: 29rem !important; }

.u-pb-30 {
  padding-bottom: 30rem !important; }

.u-pb-31 {
  padding-bottom: 31rem !important; }

.u-pb-32 {
  padding-bottom: 32rem !important; }

.u-pb-33 {
  padding-bottom: 33rem !important; }

.u-pb-34 {
  padding-bottom: 34rem !important; }

.u-pb-35 {
  padding-bottom: 35rem !important; }

.u-pb-36 {
  padding-bottom: 36rem !important; }

.u-pb-37 {
  padding-bottom: 37rem !important; }

.u-pb-38 {
  padding-bottom: 38rem !important; }

.u-pb-39 {
  padding-bottom: 39rem !important; }

.u-pb-40 {
  padding-bottom: 40rem !important; }

.u-pb-41 {
  padding-bottom: 41rem !important; }

.u-pb-42 {
  padding-bottom: 42rem !important; }

.u-pb-43 {
  padding-bottom: 43rem !important; }

.u-pb-44 {
  padding-bottom: 44rem !important; }

.u-pb-45 {
  padding-bottom: 45rem !important; }

.u-pb-46 {
  padding-bottom: 46rem !important; }

.u-pb-47 {
  padding-bottom: 47rem !important; }

.u-pb-48 {
  padding-bottom: 48rem !important; }

.u-pb-49 {
  padding-bottom: 49rem !important; }

/* Left padding */
.u-pl-0 {
  padding-left: 0rem !important; }

.u-pl-1 {
  padding-left: 1rem !important; }

.u-pl-2 {
  padding-left: 2rem !important; }

.u-pl-3 {
  padding-left: 3rem !important; }

.u-pl-4 {
  padding-left: 4rem !important; }

.u-pl-5 {
  padding-left: 5rem !important; }

.u-pl-6 {
  padding-left: 6rem !important; }

.u-pl-7 {
  padding-left: 7rem !important; }

.u-pl-8 {
  padding-left: 8rem !important; }

.u-pl-9 {
  padding-left: 9rem !important; }

.u-pl-10 {
  padding-left: 10rem !important; }

.u-pl-11 {
  padding-left: 11rem !important; }

.u-pl-12 {
  padding-left: 12rem !important; }

.u-pl-13 {
  padding-left: 13rem !important; }

.u-pl-14 {
  padding-left: 14rem !important; }

.u-pl-15 {
  padding-left: 15rem !important; }

.u-pl-16 {
  padding-left: 16rem !important; }

.u-pl-17 {
  padding-left: 17rem !important; }

.u-pl-18 {
  padding-left: 18rem !important; }

.u-pl-19 {
  padding-left: 19rem !important; }

.u-pl-20 {
  padding-left: 20rem !important; }

.u-pl-21 {
  padding-left: 21rem !important; }

.u-pl-22 {
  padding-left: 22rem !important; }

.u-pl-23 {
  padding-left: 23rem !important; }

.u-pl-24 {
  padding-left: 24rem !important; }

.u-pl-25 {
  padding-left: 25rem !important; }

.u-pl-26 {
  padding-left: 26rem !important; }

.u-pl-27 {
  padding-left: 27rem !important; }

.u-pl-28 {
  padding-left: 28rem !important; }

.u-pl-29 {
  padding-left: 29rem !important; }

.u-pl-30 {
  padding-left: 30rem !important; }

.u-pl-31 {
  padding-left: 31rem !important; }

.u-pl-32 {
  padding-left: 32rem !important; }

.u-pl-33 {
  padding-left: 33rem !important; }

.u-pl-34 {
  padding-left: 34rem !important; }

.u-pl-35 {
  padding-left: 35rem !important; }

.u-pl-36 {
  padding-left: 36rem !important; }

.u-pl-37 {
  padding-left: 37rem !important; }

.u-pl-38 {
  padding-left: 38rem !important; }

.u-pl-39 {
  padding-left: 39rem !important; }

.u-pl-40 {
  padding-left: 40rem !important; }

.u-pl-41 {
  padding-left: 41rem !important; }

.u-pl-42 {
  padding-left: 42rem !important; }

.u-pl-43 {
  padding-left: 43rem !important; }

.u-pl-44 {
  padding-left: 44rem !important; }

.u-pl-45 {
  padding-left: 45rem !important; }

.u-pl-46 {
  padding-left: 46rem !important; }

.u-pl-47 {
  padding-left: 47rem !important; }

.u-pl-48 {
  padding-left: 48rem !important; }

.u-pl-49 {
  padding-left: 49rem !important; }

@media (--mobile) {
  /* Top padding */
  .u-pt-sp-0 {
    padding-top: 0rem !important; }
  .u-pt-sp-1 {
    padding-top: 1rem !important; }
  .u-pt-sp-2 {
    padding-top: 2rem !important; }
  .u-pt-sp-3 {
    padding-top: 3rem !important; }
  .u-pt-sp-4 {
    padding-top: 4rem !important; }
  .u-pt-sp-5 {
    padding-top: 5rem !important; }
  .u-pt-sp-6 {
    padding-top: 6rem !important; }
  .u-pt-sp-7 {
    padding-top: 7rem !important; }
  .u-pt-sp-8 {
    padding-top: 8rem !important; }
  .u-pt-sp-9 {
    padding-top: 9rem !important; }
  .u-pt-sp-10 {
    padding-top: 10rem !important; }
  .u-pt-sp-11 {
    padding-top: 11rem !important; }
  .u-pt-sp-12 {
    padding-top: 12rem !important; }
  .u-pt-sp-13 {
    padding-top: 13rem !important; }
  .u-pt-sp-14 {
    padding-top: 14rem !important; }
  .u-pt-sp-15 {
    padding-top: 15rem !important; }
  .u-pt-sp-16 {
    padding-top: 16rem !important; }
  .u-pt-sp-17 {
    padding-top: 17rem !important; }
  .u-pt-sp-18 {
    padding-top: 18rem !important; }
  .u-pt-sp-19 {
    padding-top: 19rem !important; }
  .u-pt-sp-20 {
    padding-top: 20rem !important; }
  .u-pt-sp-21 {
    padding-top: 21rem !important; }
  .u-pt-sp-22 {
    padding-top: 22rem !important; }
  .u-pt-sp-23 {
    padding-top: 23rem !important; }
  .u-pt-sp-24 {
    padding-top: 24rem !important; }
  .u-pt-sp-25 {
    padding-top: 25rem !important; }
  .u-pt-sp-26 {
    padding-top: 26rem !important; }
  .u-pt-sp-27 {
    padding-top: 27rem !important; }
  .u-pt-sp-28 {
    padding-top: 28rem !important; }
  .u-pt-sp-29 {
    padding-top: 29rem !important; }
  .u-pt-sp-30 {
    padding-top: 30rem !important; }
  .u-pt-sp-31 {
    padding-top: 31rem !important; }
  .u-pt-sp-32 {
    padding-top: 32rem !important; }
  .u-pt-sp-33 {
    padding-top: 33rem !important; }
  .u-pt-sp-34 {
    padding-top: 34rem !important; }
  .u-pt-sp-35 {
    padding-top: 35rem !important; }
  .u-pt-sp-36 {
    padding-top: 36rem !important; }
  .u-pt-sp-37 {
    padding-top: 37rem !important; }
  .u-pt-sp-38 {
    padding-top: 38rem !important; }
  .u-pt-sp-39 {
    padding-top: 39rem !important; }
  .u-pt-sp-40 {
    padding-top: 40rem !important; }
  .u-pt-sp-41 {
    padding-top: 41rem !important; }
  .u-pt-sp-42 {
    padding-top: 42rem !important; }
  .u-pt-sp-43 {
    padding-top: 43rem !important; }
  .u-pt-sp-44 {
    padding-top: 44rem !important; }
  .u-pt-sp-45 {
    padding-top: 45rem !important; }
  .u-pt-sp-46 {
    padding-top: 46rem !important; }
  .u-pt-sp-47 {
    padding-top: 47rem !important; }
  .u-pt-sp-48 {
    padding-top: 48rem !important; }
  .u-pt-sp-49 {
    padding-top: 49rem !important; }
  /* Right padding */
  .u-pr-sp-0 {
    padding-right: 0rem !important; }
  .u-pr-sp-1 {
    padding-right: 1rem !important; }
  .u-pr-sp-2 {
    padding-right: 2rem !important; }
  .u-pr-sp-3 {
    padding-right: 3rem !important; }
  .u-pr-sp-4 {
    padding-right: 4rem !important; }
  .u-pr-sp-5 {
    padding-right: 5rem !important; }
  .u-pr-sp-6 {
    padding-right: 6rem !important; }
  .u-pr-sp-7 {
    padding-right: 7rem !important; }
  .u-pr-sp-8 {
    padding-right: 8rem !important; }
  .u-pr-sp-9 {
    padding-right: 9rem !important; }
  .u-pr-sp-10 {
    padding-right: 10rem !important; }
  .u-pr-sp-11 {
    padding-right: 11rem !important; }
  .u-pr-sp-12 {
    padding-right: 12rem !important; }
  .u-pr-sp-13 {
    padding-right: 13rem !important; }
  .u-pr-sp-14 {
    padding-right: 14rem !important; }
  .u-pr-sp-15 {
    padding-right: 15rem !important; }
  .u-pr-sp-16 {
    padding-right: 16rem !important; }
  .u-pr-sp-17 {
    padding-right: 17rem !important; }
  .u-pr-sp-18 {
    padding-right: 18rem !important; }
  .u-pr-sp-19 {
    padding-right: 19rem !important; }
  .u-pr-sp-20 {
    padding-right: 20rem !important; }
  .u-pr-sp-21 {
    padding-right: 21rem !important; }
  .u-pr-sp-22 {
    padding-right: 22rem !important; }
  .u-pr-sp-23 {
    padding-right: 23rem !important; }
  .u-pr-sp-24 {
    padding-right: 24rem !important; }
  .u-pr-sp-25 {
    padding-right: 25rem !important; }
  .u-pr-sp-26 {
    padding-right: 26rem !important; }
  .u-pr-sp-27 {
    padding-right: 27rem !important; }
  .u-pr-sp-28 {
    padding-right: 28rem !important; }
  .u-pr-sp-29 {
    padding-right: 29rem !important; }
  .u-pr-sp-30 {
    padding-right: 30rem !important; }
  .u-pr-sp-31 {
    padding-right: 31rem !important; }
  .u-pr-sp-32 {
    padding-right: 32rem !important; }
  .u-pr-sp-33 {
    padding-right: 33rem !important; }
  .u-pr-sp-34 {
    padding-right: 34rem !important; }
  .u-pr-sp-35 {
    padding-right: 35rem !important; }
  .u-pr-sp-36 {
    padding-right: 36rem !important; }
  .u-pr-sp-37 {
    padding-right: 37rem !important; }
  .u-pr-sp-38 {
    padding-right: 38rem !important; }
  .u-pr-sp-39 {
    padding-right: 39rem !important; }
  .u-pr-sp-40 {
    padding-right: 40rem !important; }
  .u-pr-sp-41 {
    padding-right: 41rem !important; }
  .u-pr-sp-42 {
    padding-right: 42rem !important; }
  .u-pr-sp-43 {
    padding-right: 43rem !important; }
  .u-pr-sp-44 {
    padding-right: 44rem !important; }
  .u-pr-sp-45 {
    padding-right: 45rem !important; }
  .u-pr-sp-46 {
    padding-right: 46rem !important; }
  .u-pr-sp-47 {
    padding-right: 47rem !important; }
  .u-pr-sp-48 {
    padding-right: 48rem !important; }
  .u-pr-sp-49 {
    padding-right: 49rem !important; }
  /* Bottom padding */
  .u-pb-sp-0 {
    padding-bottom: 0rem !important; }
  .u-pb-sp-1 {
    padding-bottom: 1rem !important; }
  .u-pb-sp-2 {
    padding-bottom: 2rem !important; }
  .u-pb-sp-3 {
    padding-bottom: 3rem !important; }
  .u-pb-sp-4 {
    padding-bottom: 4rem !important; }
  .u-pb-sp-5 {
    padding-bottom: 5rem !important; }
  .u-pb-sp-6 {
    padding-bottom: 6rem !important; }
  .u-pb-sp-7 {
    padding-bottom: 7rem !important; }
  .u-pb-sp-8 {
    padding-bottom: 8rem !important; }
  .u-pb-sp-9 {
    padding-bottom: 9rem !important; }
  .u-pb-sp-10 {
    padding-bottom: 10rem !important; }
  .u-pb-sp-11 {
    padding-bottom: 11rem !important; }
  .u-pb-sp-12 {
    padding-bottom: 12rem !important; }
  .u-pb-sp-13 {
    padding-bottom: 13rem !important; }
  .u-pb-sp-14 {
    padding-bottom: 14rem !important; }
  .u-pb-sp-15 {
    padding-bottom: 15rem !important; }
  .u-pb-sp-16 {
    padding-bottom: 16rem !important; }
  .u-pb-sp-17 {
    padding-bottom: 17rem !important; }
  .u-pb-sp-18 {
    padding-bottom: 18rem !important; }
  .u-pb-sp-19 {
    padding-bottom: 19rem !important; }
  .u-pb-sp-20 {
    padding-bottom: 20rem !important; }
  .u-pb-sp-21 {
    padding-bottom: 21rem !important; }
  .u-pb-sp-22 {
    padding-bottom: 22rem !important; }
  .u-pb-sp-23 {
    padding-bottom: 23rem !important; }
  .u-pb-sp-24 {
    padding-bottom: 24rem !important; }
  .u-pb-sp-25 {
    padding-bottom: 25rem !important; }
  .u-pb-sp-26 {
    padding-bottom: 26rem !important; }
  .u-pb-sp-27 {
    padding-bottom: 27rem !important; }
  .u-pb-sp-28 {
    padding-bottom: 28rem !important; }
  .u-pb-sp-29 {
    padding-bottom: 29rem !important; }
  .u-pb-sp-30 {
    padding-bottom: 30rem !important; }
  .u-pb-sp-31 {
    padding-bottom: 31rem !important; }
  .u-pb-sp-32 {
    padding-bottom: 32rem !important; }
  .u-pb-sp-33 {
    padding-bottom: 33rem !important; }
  .u-pb-sp-34 {
    padding-bottom: 34rem !important; }
  .u-pb-sp-35 {
    padding-bottom: 35rem !important; }
  .u-pb-sp-36 {
    padding-bottom: 36rem !important; }
  .u-pb-sp-37 {
    padding-bottom: 37rem !important; }
  .u-pb-sp-38 {
    padding-bottom: 38rem !important; }
  .u-pb-sp-39 {
    padding-bottom: 39rem !important; }
  .u-pb-sp-40 {
    padding-bottom: 40rem !important; }
  .u-pb-sp-41 {
    padding-bottom: 41rem !important; }
  .u-pb-sp-42 {
    padding-bottom: 42rem !important; }
  .u-pb-sp-43 {
    padding-bottom: 43rem !important; }
  .u-pb-sp-44 {
    padding-bottom: 44rem !important; }
  .u-pb-sp-45 {
    padding-bottom: 45rem !important; }
  .u-pb-sp-46 {
    padding-bottom: 46rem !important; }
  .u-pb-sp-47 {
    padding-bottom: 47rem !important; }
  .u-pb-sp-48 {
    padding-bottom: 48rem !important; }
  .u-pb-sp-49 {
    padding-bottom: 49rem !important; }
  /* Left padding */
  .u-pl-sp-0 {
    padding-left: 0rem !important; }
  .u-pl-sp-1 {
    padding-left: 1rem !important; }
  .u-pl-sp-2 {
    padding-left: 2rem !important; }
  .u-pl-sp-3 {
    padding-left: 3rem !important; }
  .u-pl-sp-4 {
    padding-left: 4rem !important; }
  .u-pl-sp-5 {
    padding-left: 5rem !important; }
  .u-pl-sp-6 {
    padding-left: 6rem !important; }
  .u-pl-sp-7 {
    padding-left: 7rem !important; }
  .u-pl-sp-8 {
    padding-left: 8rem !important; }
  .u-pl-sp-9 {
    padding-left: 9rem !important; }
  .u-pl-sp-10 {
    padding-left: 10rem !important; }
  .u-pl-sp-11 {
    padding-left: 11rem !important; }
  .u-pl-sp-12 {
    padding-left: 12rem !important; }
  .u-pl-sp-13 {
    padding-left: 13rem !important; }
  .u-pl-sp-14 {
    padding-left: 14rem !important; }
  .u-pl-sp-15 {
    padding-left: 15rem !important; }
  .u-pl-sp-16 {
    padding-left: 16rem !important; }
  .u-pl-sp-17 {
    padding-left: 17rem !important; }
  .u-pl-sp-18 {
    padding-left: 18rem !important; }
  .u-pl-sp-19 {
    padding-left: 19rem !important; }
  .u-pl-sp-20 {
    padding-left: 20rem !important; }
  .u-pl-sp-21 {
    padding-left: 21rem !important; }
  .u-pl-sp-22 {
    padding-left: 22rem !important; }
  .u-pl-sp-23 {
    padding-left: 23rem !important; }
  .u-pl-sp-24 {
    padding-left: 24rem !important; }
  .u-pl-sp-25 {
    padding-left: 25rem !important; }
  .u-pl-sp-26 {
    padding-left: 26rem !important; }
  .u-pl-sp-27 {
    padding-left: 27rem !important; }
  .u-pl-sp-28 {
    padding-left: 28rem !important; }
  .u-pl-sp-29 {
    padding-left: 29rem !important; }
  .u-pl-sp-30 {
    padding-left: 30rem !important; }
  .u-pl-sp-31 {
    padding-left: 31rem !important; }
  .u-pl-sp-32 {
    padding-left: 32rem !important; }
  .u-pl-sp-33 {
    padding-left: 33rem !important; }
  .u-pl-sp-34 {
    padding-left: 34rem !important; }
  .u-pl-sp-35 {
    padding-left: 35rem !important; }
  .u-pl-sp-36 {
    padding-left: 36rem !important; }
  .u-pl-sp-37 {
    padding-left: 37rem !important; }
  .u-pl-sp-38 {
    padding-left: 38rem !important; }
  .u-pl-sp-39 {
    padding-left: 39rem !important; }
  .u-pl-sp-40 {
    padding-left: 40rem !important; }
  .u-pl-sp-41 {
    padding-left: 41rem !important; }
  .u-pl-sp-42 {
    padding-left: 42rem !important; }
  .u-pl-sp-43 {
    padding-left: 43rem !important; }
  .u-pl-sp-44 {
    padding-left: 44rem !important; }
  .u-pl-sp-45 {
    padding-left: 45rem !important; }
  .u-pl-sp-46 {
    padding-left: 46rem !important; }
  .u-pl-sp-47 {
    padding-left: 47rem !important; }
  .u-pl-sp-48 {
    padding-left: 48rem !important; }
  .u-pl-sp-49 {
    padding-left: 49rem !important; } }

/* ==========================================================================
 text-align
========================================================================== */
.u-ta-l {
  text-align: left !important; }

.u-ta-c {
  text-align: center !important; }

.u-ta-r {
  text-align: right !important; }

@media (--mobile) {
  .u-ta-l-sp {
    text-align: left !important; }
  .u-ta-c-sp {
    text-align: center !important; }
  .u-ta-r-sp {
    text-align: right !important; } }

/* ==========================================================================
  fontsize
========================================================================== */
/* fontsize */
.u-fs-1 {
  font-size: 0.1rem !important; }

.u-fs-2 {
  font-size: 0.2rem !important; }

.u-fs-3 {
  font-size: 0.3rem !important; }

.u-fs-4 {
  font-size: 0.4rem !important; }

.u-fs-5 {
  font-size: 0.5rem !important; }

.u-fs-6 {
  font-size: 0.6rem !important; }

.u-fs-7 {
  font-size: 0.7rem !important; }

.u-fs-8 {
  font-size: 0.8rem !important; }

.u-fs-9 {
  font-size: 0.9rem !important; }

.u-fs-10 {
  font-size: 1rem !important; }

.u-fs-11 {
  font-size: 1.1rem !important; }

.u-fs-12 {
  font-size: 1.2rem !important; }

.u-fs-13 {
  font-size: 1.3rem !important; }

.u-fs-14 {
  font-size: 1.4rem !important; }

.u-fs-15 {
  font-size: 1.5rem !important; }

.u-fs-16 {
  font-size: 1.6rem !important; }

.u-fs-17 {
  font-size: 1.7rem !important; }

.u-fs-18 {
  font-size: 1.8rem !important; }

.u-fs-19 {
  font-size: 1.9rem !important; }

.u-fs-20 {
  font-size: 2rem !important; }

.u-fs-21 {
  font-size: 2.1rem !important; }

.u-fs-22 {
  font-size: 2.2rem !important; }

.u-fs-23 {
  font-size: 2.3rem !important; }

.u-fs-24 {
  font-size: 2.4rem !important; }

.u-fs-25 {
  font-size: 2.5rem !important; }

@media (--mobile) {
  /* fontsize */
  .u-fs-sp-1 {
    font-size: 0.1rem !important; }
  .u-fs-sp-2 {
    font-size: 0.2rem !important; }
  .u-fs-sp-3 {
    font-size: 0.3rem !important; }
  .u-fs-sp-4 {
    font-size: 0.4rem !important; }
  .u-fs-sp-5 {
    font-size: 0.5rem !important; }
  .u-fs-sp-6 {
    font-size: 0.6rem !important; }
  .u-fs-sp-7 {
    font-size: 0.7rem !important; }
  .u-fs-sp-8 {
    font-size: 0.8rem !important; }
  .u-fs-sp-9 {
    font-size: 0.9rem !important; }
  .u-fs-sp-10 {
    font-size: 1rem !important; }
  .u-fs-sp-11 {
    font-size: 1.1rem !important; }
  .u-fs-sp-12 {
    font-size: 1.2rem !important; }
  .u-fs-sp-13 {
    font-size: 1.3rem !important; }
  .u-fs-sp-14 {
    font-size: 1.4rem !important; }
  .u-fs-sp-15 {
    font-size: 1.5rem !important; }
  .u-fs-sp-16 {
    font-size: 1.6rem !important; }
  .u-fs-sp-17 {
    font-size: 1.7rem !important; }
  .u-fs-sp-18 {
    font-size: 1.8rem !important; }
  .u-fs-sp-19 {
    font-size: 1.9rem !important; }
  .u-fs-sp-20 {
    font-size: 2rem !important; }
  .u-fs-sp-21 {
    font-size: 2.1rem !important; }
  .u-fs-sp-22 {
    font-size: 2.2rem !important; }
  .u-fs-sp-23 {
    font-size: 2.3rem !important; }
  .u-fs-sp-24 {
    font-size: 2.4rem !important; }
  .u-fs-sp-25 {
    font-size: 2.5rem !important; } }

.u-objectFit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; }

.js-parallax {
  overflow: hidden; }
  .js-parallax img {
    transform: scale(1.1) translateY(20px); }
  .js-parallax--reverse img {
    transform: scale(1.1) translateY(-20px); }

/* ==========================================================================
 width
========================================================================== */
.u-w-all {
  width: 100% !important; }

.u-w-0 {
  width: 0rem !important; }

.u-w-1 {
  width: 1rem !important; }

.u-w-2 {
  width: 2rem !important; }

.u-w-3 {
  width: 3rem !important; }

.u-w-4 {
  width: 4rem !important; }

.u-w-5 {
  width: 5rem !important; }

.u-w-6 {
  width: 6rem !important; }

.u-w-7 {
  width: 7rem !important; }

.u-w-8 {
  width: 8rem !important; }

.u-w-9 {
  width: 9rem !important; }

.u-w-10 {
  width: 10rem !important; }

.u-w-11 {
  width: 11rem !important; }

.u-w-12 {
  width: 12rem !important; }

.u-w-13 {
  width: 13rem !important; }

.u-w-14 {
  width: 14rem !important; }

.u-w-15 {
  width: 15rem !important; }

.u-w-16 {
  width: 16rem !important; }

.u-w-17 {
  width: 17rem !important; }

.u-w-18 {
  width: 18rem !important; }

.u-w-19 {
  width: 19rem !important; }

.u-w-20 {
  width: 20rem !important; }

.u-w-21 {
  width: 21rem !important; }

.u-w-22 {
  width: 22rem !important; }

.u-w-23 {
  width: 23rem !important; }

.u-w-24 {
  width: 24rem !important; }

.u-w-25 {
  width: 25rem !important; }

.u-w-26 {
  width: 26rem !important; }

.u-w-27 {
  width: 27rem !important; }

.u-w-28 {
  width: 28rem !important; }

.u-w-29 {
  width: 29rem !important; }

.u-w-30 {
  width: 30rem !important; }

.u-w-31 {
  width: 31rem !important; }

.u-w-32 {
  width: 32rem !important; }

.u-w-33 {
  width: 33rem !important; }

.u-w-34 {
  width: 34rem !important; }

.u-w-35 {
  width: 35rem !important; }

.u-w-36 {
  width: 36rem !important; }

.u-w-37 {
  width: 37rem !important; }

.u-w-38 {
  width: 38rem !important; }

.u-w-39 {
  width: 39rem !important; }

.u-w-40 {
  width: 40rem !important; }

.u-w-41 {
  width: 41rem !important; }

.u-w-42 {
  width: 42rem !important; }

.u-w-43 {
  width: 43rem !important; }

.u-w-44 {
  width: 44rem !important; }

.u-w-45 {
  width: 45rem !important; }

.u-w-46 {
  width: 46rem !important; }

.u-w-47 {
  width: 47rem !important; }

.u-w-48 {
  width: 48rem !important; }

.u-w-49 {
  width: 49rem !important; }

@media (--mobile) {
  .u-w-sp-all {
    width: 100% !important; }
  .u-w-sp-0 {
    width: 0rem !important; }
  .u-w-sp-1 {
    width: 1rem !important; }
  .u-w-sp-2 {
    width: 2rem !important; }
  .u-w-sp-3 {
    width: 3rem !important; }
  .u-w-sp-4 {
    width: 4rem !important; }
  .u-w-sp-5 {
    width: 5rem !important; }
  .u-w-sp-6 {
    width: 6rem !important; }
  .u-w-sp-7 {
    width: 7rem !important; }
  .u-w-sp-8 {
    width: 8rem !important; }
  .u-w-sp-9 {
    width: 9rem !important; }
  .u-w-sp-10 {
    width: 10rem !important; }
  .u-w-sp-11 {
    width: 11rem !important; }
  .u-w-sp-12 {
    width: 12rem !important; }
  .u-w-sp-13 {
    width: 13rem !important; }
  .u-w-sp-14 {
    width: 14rem !important; }
  .u-w-sp-15 {
    width: 15rem !important; }
  .u-w-sp-16 {
    width: 16rem !important; }
  .u-w-sp-17 {
    width: 17rem !important; }
  .u-w-sp-18 {
    width: 18rem !important; }
  .u-w-sp-19 {
    width: 19rem !important; }
  .u-w-sp-20 {
    width: 20rem !important; }
  .u-w-sp-21 {
    width: 21rem !important; }
  .u-w-sp-22 {
    width: 22rem !important; }
  .u-w-sp-23 {
    width: 23rem !important; }
  .u-w-sp-24 {
    width: 24rem !important; }
  .u-w-sp-25 {
    width: 25rem !important; }
  .u-w-sp-26 {
    width: 26rem !important; }
  .u-w-sp-27 {
    width: 27rem !important; }
  .u-w-sp-28 {
    width: 28rem !important; }
  .u-w-sp-29 {
    width: 29rem !important; }
  .u-w-sp-30 {
    width: 30rem !important; }
  .u-w-sp-31 {
    width: 31rem !important; }
  .u-w-sp-32 {
    width: 32rem !important; }
  .u-w-sp-33 {
    width: 33rem !important; }
  .u-w-sp-34 {
    width: 34rem !important; }
  .u-w-sp-35 {
    width: 35rem !important; }
  .u-w-sp-36 {
    width: 36rem !important; }
  .u-w-sp-37 {
    width: 37rem !important; }
  .u-w-sp-38 {
    width: 38rem !important; }
  .u-w-sp-39 {
    width: 39rem !important; }
  .u-w-sp-40 {
    width: 40rem !important; }
  .u-w-sp-41 {
    width: 41rem !important; }
  .u-w-sp-42 {
    width: 42rem !important; }
  .u-w-sp-43 {
    width: 43rem !important; }
  .u-w-sp-44 {
    width: 44rem !important; }
  .u-w-sp-45 {
    width: 45rem !important; }
  .u-w-sp-46 {
    width: 46rem !important; }
  .u-w-sp-47 {
    width: 47rem !important; }
  .u-w-sp-48 {
    width: 48rem !important; }
  .u-w-sp-49 {
    width: 49rem !important; } }

/**
 * BrowserSync
 */
#__bs_notify__ {
  display: none !important;
  max-height: 50px;
  font-size: 8px !important;
  opacity: 0.25; }

.__debug_mihon__ {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  pointer-events: none;
  cursor: none;
  animation: __debug_mihon__ 1s infinite; }

.__debug_mihon__ {
  padding-top: 0;
  margin-top: 0; }

@keyframes __debug_mihon__ {
  0% {
    opacity: 0; }
  60% {
    opacity: 0.5; }
  100% {
    opacity: 0.5; } }

/*
@example
 <div class="mihon">
  <img src="./images/sample1.png" alt="" class="type_pc">
  <img src="./images/sample2.png" alt="" class="type_sp">
</div>
*/
.p-error {
  margin-bottom: 14rem; }
  @media (--mobile) {
    .p-error {
      margin-bottom: 7rem; } }
  .p-error p {
    text-align: center; }
    @media (--mobile) {
      .p-error p {
        text-align: left; } }
  .p-error .c-btn03 {
    margin-top: 5rem; }

.p-abt .c-product-mv {
  padding-bottom: 6rem; }
  @media (--mobile) {
    .en .p-abt .c-product-mv-btn .c-btn09 a {
      width: 23rem; } }

.p-abt .c-product-spec {
  padding-top: 9rem; }
  @media (--mobile) {
    .p-abt .c-product-spec {
      padding-top: 4rem; } }

.p-abt .c-product-spec-img {
  width: 94.9rem; }
  @media (--mobile) {
    .p-abt .c-product-spec-img {
      width: 100%; } }

.p-airspeed .c-product-spec-img {
  width: 58.2rem; }
  @media (--mobile) {
    .p-airspeed .c-product-spec-img {
      width: 100%; } }

.p-aCase .c-mv__top {
  display: flex;
  align-items: center; }
  .p-aCase .c-mv__top span {
    font-size: 1.8rem;
    color: #969faf; }
    @media (--mobile) {
      .p-aCase .c-mv__top span {
        font-size: 1.4rem; } }
  .p-aCase .c-mv__top em {
    padding: 0 1.3rem;
    margin-left: 1rem;
    font-size: 1.2rem;
    font-style: inherit;
    color: #969faf;
    border: 1px solid #969faf;
    border-radius: 9999px; }

.p-aCase .c-mv__middle {
  padding-top: 0.8rem;
  font-size: 4rem;
  line-height: 1.2;
  color: #969faf;
  letter-spacing: 0.1em; }
  @media (--mobile) {
    .p-aCase .c-mv__middle {
      padding-top: 0.8rem;
      font-size: 3rem; } }

.p-aCase .c-mv__bottom {
  padding-top: 1rem;
  font-size: 3.2rem;
  line-height: 1.2; }
  @media (--mobile) {
    .p-aCase .c-mv__bottom {
      padding-top: 1.2rem;
      font-size: 3.3rem; } }

@media (--mobile) {
  .p-aCase .c-mv__h {
    height: 22rem; } }

@media (--large) {
  .p-aCase .c-product-example-el h3 {
    margin-top: 1.8rem;
    font-size: 1.6rem; }
  .p-aCase .c-product-example-el h4 {
    font-size: 1.6rem; }
  .p-aCase .c-product-example-el:nth-of-type(n + 4) {
    margin-top: 5.5rem; }
  .p-aCase .c-pagenav .wp-pagenavi * {
    margin: 0 0.6rem; } }

.p-aCase__mv {
  position: relative; }
  @media (--mobile) {
    .p-aCase__mv {
      height: 20rem; } }
  .p-aCase__mv .c-i-inner {
    padding: 0 0 0 3rem; }

.p-aCase__kind {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  margin-bottom: 1.8rem;
  font-size: 1.8rem;
  font-weight: bold;
  border: 1px solid #fff; }
  @media (--mobile) {
    .p-aCase__kind {
      padding: 0.1rem 0.4rem;
      margin-bottom: 0;
      font-size: 1.4rem; } }

.p-aCase__type {
  display: block;
  font-size: 2rem; }

.p-exhibition-inner {
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
  margin-bottom: 14rem; }
  @media (--mobile) {
    .p-exhibition-inner {
      display: block;
      margin-top: 3rem;
      margin-bottom: 7rem; } }
  .p-exhibition-inner__side {
    width: 15rem; }
    @media (--mobile) {
      .p-exhibition-inner__side {
        width: 100%; } }
    .p-exhibition-inner__side ul li a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      font-size: 1.5rem;
      font-weight: bold;
      border: 0.1rem solid #1a2e56; }
    .p-exhibition-inner__side ul li:nth-child(n + 2) {
      margin-top: 2rem; }
    .p-exhibition-inner__side ul li.active a {
      color: #fff;
      pointer-events: none;
      background-color: #1a2e56; }
  .p-exhibition-inner__main {
    width: 100%; }
    @media (--mobile) {
      .p-exhibition-inner__main {
        width: 100%;
        margin-top: 3rem; } }
    .p-exhibition-inner__main .text {
      margin-top: 3rem;
      font-size: 1.6rem; }
  .p-exhibition-inner__list .p-exhibition-inner__cell:first-of-type {
    margin-top: 3rem; }
  .p-exhibition-inner__list .p-exhibition-inner__cell:nth-of-type(n + 2) {
    margin-top: 6rem; }
  .p-exhibition-inner__cell .content {
    display: flex;
    margin-top: 2rem; }
    @media (--mobile) {
      .p-exhibition-inner__cell .content {
        display: block; } }
  .p-exhibition-inner__cell .left-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29rem;
    min-height: 11rem;
    margin-right: 2rem;
    overflow: hidden; }
    @media (--mobile) {
      .p-exhibition-inner__cell .left-img {
        width: 100%;
        min-height: 13rem; } }
    .p-exhibition-inner__cell .left-img img {
      display: block;
      width: 100%;
      min-width: 20rem;
      height: 100%;
      margin: 0 auto;
      object-fit: cover; }
  .p-exhibition-inner__cell .right-content {
    position: relative;
    width: calc(100% - 29rem - 2rem); }
    @media (--mobile) {
      .p-exhibition-inner__cell .right-content {
        width: 100%; } }
    .p-exhibition-inner__cell .right-content dl {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      font-size: 1.6rem; }
      @media (--large) {
        .p-exhibition-inner__cell .right-content dl {
          padding-right: 19rem; } }
      @media (--mobile) {
        .p-exhibition-inner__cell .right-content dl {
          margin-top: 2rem; } }
      .p-exhibition-inner__cell .right-content dl dt {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9rem;
        background-color: #edeff4; }
        .p-exhibition-inner__cell .right-content dl dt:nth-of-type(n + 2) {
          margin-top: 1rem; }
        @media (--mobile) {
          .p-exhibition-inner__cell .right-content dl dt {
            width: calc(100% - 70% - 1.5rem); } }
      .p-exhibition-inner__cell .right-content dl dd {
        width: calc(100% - 9rem);
        padding-left: 1.5rem;
        letter-spacing: -0.01em; }
        .p-exhibition-inner__cell .right-content dl dd:nth-of-type(n + 2) {
          margin-top: 1rem; }
        @media (--mobile) {
          .p-exhibition-inner__cell .right-content dl dd {
            width: 70%; } }
    .p-exhibition-inner__cell .right-content .btn {
      position: absolute;
      right: 0;
      bottom: 0; }
      @media (--mobile) {
        .p-exhibition-inner__cell .right-content .btn {
          position: inherit;
          margin-top: 3rem; }
          .p-exhibition-inner__cell .right-content .btn .c-btn09 a {
            height: 6rem !important;
            padding: 0 !important; } }

@media (--large) {
  .p-aManual .s-btn_wrap {
    display: flex;
    align-items: center; } }

@media (--mobile) {
  .p-aManual .s-btn_wrap a {
    height: 5rem; } }

.p-aManual .c-s-sort-wrap {
  padding: 3rem 0 !important;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }
  @media (--mobile) {
    .p-aManual .c-s-sort-wrap .c-s-check {
      width: inherit !important;
      min-width: calc(100% / 2); }
      .p-aManual .c-s-sort-wrap .c-s-check label {
        white-space: nowrap; } }

@media (--mobile) {
  .p-aManual .c-ttl07 {
    position: relative;
    padding: 0.5rem 1.5rem 0.5rem 6rem;
    margin: 0 -3rem;
    font-size: 2.2rem; } }

.p-aManual .s-range {
  display: flex;
  flex-wrap: wrap; }
  @media (--mobile) {
    .p-aManual .s-range {
      display: block !important; } }
  .p-aManual .s-range > div {
    display: flex;
    align-items: center;
    width: 50%; }
    @media (--mobile) {
      .p-aManual .s-range > div {
        flex-wrap: wrap;
        width: 100%; } }
    @media (--large) {
      .p-aManual .s-range > div:nth-of-type(n + 3) {
        margin-top: 2rem; } }
    @media (--mobile) {
      .p-aManual .s-range > div:nth-of-type(n + 2) {
        margin-top: 1rem; } }
    .p-aManual .s-range > div .ttl {
      min-width: 8.5rem;
      margin-right: 2.5rem;
      text-align: right;
      white-space: nowrap; }
      @media (--mobile) {
        .p-aManual .s-range > div .ttl {
          width: 100%;
          min-width: auto;
          margin-right: 0;
          margin-bottom: 0.8rem;
          font-size: 1.8rem;
          text-align: left; } }
    .p-aManual .s-range > div .delimiter {
      margin: 0 1rem; }
    .p-aManual .s-range > div:nth-of-type(odd) .ttl {
      min-width: auto; }

.p-aManual .s-side {
  display: flex; }
  .p-aManual .s-side__img {
    flex-shrink: 0;
    width: 30rem;
    padding-left: 7rem; }
    .p-aManual .s-side__img div {
      position: relative;
      position: sticky;
      top: 12rem; }
      .p-aManual .s-side__img div img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        opacity: 0; }

.p-aManual .js-scrollable .scroll-hint-icon {
  top: 8rem !important; }

.p-aManual .c-btn03 a {
  width: 30rem;
  height: 8rem; }

@media (--large) {
  .p-aManual .c-s-sort-wrap__vertical {
    flex-direction: column;
    width: 100%; }
    .p-aManual .c-s-sort-wrap__vertical .c-s-check {
      margin: 0.4rem 0 !important; }
  .p-aManual .c-s-sort-wrap__type01 {
    height: 21rem; }
  .p-aManual .c-s-sort-wrap__type02 {
    height: 62rem; } }

@media (--large) {
  .p-aManual .c-r-sort div label {
    margin-top: 0.5rem; }
    .p-aManual .c-r-sort div label:nth-of-type(1) {
      order: 1; }
    .p-aManual .c-r-sort div label:nth-of-type(2) {
      order: 3; }
    .p-aManual .c-r-sort div label:nth-of-type(3) {
      order: 5; }
    .p-aManual .c-r-sort div label:nth-of-type(4) {
      order: 2; }
    .p-aManual .c-r-sort div label:nth-of-type(5) {
      order: 4; } }

.p-aProduct .c-mv__top {
  display: flex;
  align-items: center; }
  .p-aProduct .c-mv__top span {
    font-size: 1.8rem;
    color: #969faf; }
    @media (--mobile) {
      .p-aProduct .c-mv__top span {
        font-size: 1.4rem; } }
  .p-aProduct .c-mv__top em {
    padding: 0 1.3rem;
    margin-left: 1rem;
    font-size: 1.2rem;
    font-style: inherit;
    color: #969faf;
    border: 1px solid #969faf;
    border-radius: 9999px; }

.p-aProduct .c-mv__middle {
  padding-top: 0.8rem;
  font-size: 4rem;
  line-height: 1.2;
  color: #969faf;
  letter-spacing: 0.1em; }
  @media (--mobile) {
    .p-aProduct .c-mv__middle {
      padding-top: 0.8rem;
      font-size: 3rem; } }

.p-aProduct .c-mv__bottom {
  padding-top: 1.5rem;
  font-size: 3.2rem;
  line-height: 1.2; }
  @media (--mobile) {
    .p-aProduct .c-mv__bottom {
      padding-top: 1.2rem;
      font-size: 3.3rem; } }

@media (--mobile) {
  .p-aProduct .c-mv__h {
    height: 22rem; } }

.p-aProduct .c-s-sort h4 {
  padding: 0 2.5rem 2rem; }
  @media (--mobile) {
    .p-aProduct .c-s-sort h4 {
      padding: 0 1.5rem 2rem; } }
  .p-aProduct .c-s-sort h4 span {
    position: relative;
    display: block;
    padding: 0.8rem 1.5rem;
    font-size: 1.8rem;
    background: #edeff4; }

.p-aProduct__kind {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  margin-bottom: 1.8rem;
  font-size: 1.8rem;
  font-weight: bold;
  border: 1px solid #fff; }

.p-aProduct .l-sort:nth-of-type(n + 2) {
  margin-top: 5.5rem; }
  @media (--mobile) {
    .p-aProduct .l-sort:nth-of-type(n + 2) {
      margin-top: 3rem; } }

.p-aProduct .l-sort h4 {
  padding-bottom: 1rem;
  font-weight: bold;
  color: #1a2e56;
  border-bottom: 1px solid #c7ced3; }
  .p-aProduct .l-sort h4 span {
    position: relative;
    display: block;
    font-size: 1.3rem;
    font-weight: 500; }
    @media (--mobile) {
      .p-aProduct .l-sort h4 span {
        font-size: 2rem; } }
    .p-aProduct .l-sort h4 span svg {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1.5rem;
      height: 1.1rem;
      transition: 0.3s;
      transform: translateY(-50%) rotate(90deg); }
      @media (--mobile) {
        .p-aProduct .l-sort h4 span svg {
          right: 1rem;
          width: 1.4rem;
          height: 1.8rem; } }
  .p-aProduct .l-sort h4.is-open svg {
    transform: translateY(-50%) rotate(-90deg); }

.p-aProduct .l-sort__inner {
  padding: 2.4rem 1rem 0; }
  @media (--mobile) {
    .p-aProduct .l-sort__inner {
      padding-top: 2rem; } }
  .p-aProduct .l-sort__inner div a,
  .p-aProduct .l-sort__inner div span {
    display: inline-block;
    font-size: 1.3rem;
    cursor: pointer; }
    @media (--mobile) {
      .p-aProduct .l-sort__inner div a,
      .p-aProduct .l-sort__inner div span {
        font-size: 1.6rem; } }
    .p-aProduct .l-sort__inner div a.is-active,
    .p-aProduct .l-sort__inner div span.is-active {
      font-weight: bold;
      pointer-events: none; }

.p-aProduct .l-lineup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .p-aProduct .l-lineup__el {
    display: flex;
    width: calc(100% / 2 - 2rem);
    padding: 2.5rem 0;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2; }
    @media (--mobile) {
      .p-aProduct .l-lineup__el {
        width: 100%; }
        .p-aProduct .l-lineup__el:nth-of-type(n + 2) {
          margin-top: -1px; } }
    .p-aProduct .l-lineup__el:nth-of-type(n + 3) {
      margin-top: -1px; }
  .p-aProduct .l-lineup__img {
    width: 13rem;
    overflow: hidden; }
    @media (--mobile) {
      .p-aProduct .l-lineup__img {
        width: 12rem; } }
    .p-aProduct .l-lineup__img img {
      transition: 0.3s; }
  .p-aProduct .l-lineup__info {
    width: calc(100% - 13rem);
    padding-left: 3rem; }
    @media (--mobile) {
      .p-aProduct .l-lineup__info {
        width: calc(100% - 12rem);
        padding-left: 2rem; } }
    .p-aProduct .l-lineup__info h5 {
      font-size: 2rem;
      line-height: 1.5; }
    .p-aProduct .l-lineup__info p {
      font-size: 1.4rem; }
  .p-aProduct .l-lineup__series {
    display: inline-block;
    color: #8491aa; }
  .p-aProduct .l-lineup__el {
    position: relative; }
    .p-aProduct .l-lineup__el::after, .p-aProduct .l-lineup__el::before {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 1px;
      content: "";
      background-color: #1a2e56;
      opacity: 0;
      transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
      transition-duration: 0.6s;
      transition-property: opacity, transform;
      transform: scale3d(0.8, 1, 1);
      transform-origin: left center; }
    .p-aProduct .l-lineup__el::after {
      top: 0;
      bottom: inherit; }
    @media (--large) {
      .p-aProduct .l-lineup__el:hover img {
        transform: scale(1.05); }
      .p-aProduct .l-lineup__el:hover::after, .p-aProduct .l-lineup__el:hover::before {
        opacity: 1;
        transform: none; } }

.p-aProduct .c-s-sort {
  margin-top: -1px;
  border-top: 1px solid #c7ced3; }
  .p-aProduct .c-s-sort-wrap {
    flex-wrap: wrap;
    padding: 0 2.5rem 3rem; }
    @media (--mobile) {
      .p-aProduct .c-s-sort-wrap {
        display: flex;
        padding: 0 1.5rem 3rem; } }
    .p-aProduct .c-s-sort-wrap .c-s-check {
      width: calc(100% / 4); }
      @media (--mobile) {
        .p-aProduct .c-s-sort-wrap .c-s-check {
          width: calc(100% / 2); } }
      @media (--large) {
        .p-aProduct .c-s-sort-wrap .c-s-check:nth-of-type(n + 5) {
          margin-top: 1rem; } }
      @media (--mobile) {
        .p-aProduct .c-s-sort-wrap .c-s-check:nth-of-type(n + 3) {
          margin-top: 1rem; } }

.p-beginner {
  margin-bottom: 16rem; }
  .p-beginner .about figure {
    width: 100%;
    height: 34rem;
    margin-top: 4rem; }
    @media (--mobile) {
      .p-beginner .about figure {
        height: 22rem; } }
    .p-beginner .about figure img {
      width: 100%;
      height: 100%; }
  .p-beginner .about p {
    letter-spacing: 0.1em; }
  .p-beginner .feature .c-product-side {
    display: flex;
    height: 27rem;
    background: linear-gradient(to right, #00012b, #000); }
    @media (--mobile) {
      .p-beginner .feature .c-product-side {
        display: block;
        width: 100vw;
        height: 100%;
        margin: 0 calc(50% - 50vw);
        margin-bottom: 0.2rem; } }
    @media (--large) {
      .en .p-beginner .feature .c-product-side {
        position: relative;
        display: block; } }
    .p-beginner .feature .c-product-side__left {
      color: #fff; }
      @media (--mobile) {
        .p-beginner .feature .c-product-side__left {
          width: 33rem;
          padding-top: 5rem;
          margin: 0 auto; } }
      @media (--large) {
        .p-beginner .feature .c-product-side__left {
          display: flex;
          align-items: center;
          width: 50%;
          height: 23rem;
          padding-left: 8rem; }
          .en .p-beginner .feature .c-product-side__left {
            position: absolute;
            top: 50%;
            left: 4rem;
            display: block;
            width: 70%;
            height: auto;
            padding-left: 0;
            transform: translateY(-50%); } }
      .p-beginner .feature .c-product-side__left h4 {
        font-size: 3.2rem;
        font-weight: 500;
        color: #fff;
        letter-spacing: 0.1em; }
        @media (--mobile) {
          .p-beginner .feature .c-product-side__left h4 {
            font-size: 2.6rem; } }
      .p-beginner .feature .c-product-side__left ul li {
        position: relative;
        padding-left: 1rem; }
        .p-beginner .feature .c-product-side__left ul li::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "・"; }
      .p-beginner .feature .c-product-side__left p {
        line-height: 1.8; }
      @media (--mobile) {
        .p-beginner .feature .c-product-side__left .c-btn09 {
          margin-bottom: 2rem; } }
      .p-beginner .feature .c-product-side__left .c-btn09 a {
        width: 20rem;
        height: 5rem;
        border: 1px solid #1a2e56; }
        @media (--large) {
          .p-beginner .feature .c-product-side__left .c-btn09 a {
            padding-right: 0; } }
        .p-beginner .feature .c-product-side__left .c-btn09 a span::after {
          right: -3rem; }
    @media (--large) {
      .p-beginner .feature .c-product-side__right {
        width: 50%; }
        .en .p-beginner .feature .c-product-side__right {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 40%; } }
    @media (--mobile) {
      .p-beginner .feature .c-product-side__right {
        margin-top: 4rem; } }
    @media (--mobile) {
      .p-beginner .feature .c-product-side__right picture {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%; } }
    .p-beginner .feature .c-product-side__right picture img {
      width: 100%;
      height: 100%; }
  .p-beginner-container {
    display: flex;
    justify-content: space-between; }
    @media (--mobile) {
      .p-beginner-container {
        display: block;
        margin-top: 3rem; } }
  @media (--mobile) {
    .p-beginner-content {
      width: 100%; } }
  .p-beginner-side ul li:nth-of-type(n + 2) {
    margin-top: 2rem; }
  .p-beginner-side ul li a {
    position: relative;
    display: block;
    font-size: 1.2rem; }
    .p-beginner-side ul li a::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 0.8rem;
      height: 0.8rem;
      content: "";
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      transform: translateY(-50%) rotate(45deg); }
  .p-beginner-flex {
    display: flex; }
    @media (--mobile) {
      .p-beginner-flex {
        display: block; } }
    .p-beginner-flex .left-content {
      width: calc(100% - 28rem - 6rem);
      margin-right: 6rem; }
      @media (--mobile) {
        .p-beginner-flex .left-content {
          width: 100%;
          margin-right: 0; } }
      .p-beginner-flex .left-content p {
        font-size: 1.6rem; }
        .p-beginner-flex .left-content p:nth-child(n + 2) {
          margin-top: 3rem; }
          @media (--mobile) {
            .p-beginner-flex .left-content p:nth-child(n + 2) {
              margin-top: 1.5rem; } }
    .p-beginner-flex .right-img {
      width: 28rem; }
      @media (--mobile) {
        .p-beginner-flex .right-img {
          width: 100%;
          margin-top: 4rem; } }
      .p-beginner-flex .right-img .img:nth-child(n + 2) {
        margin-top: 4rem; }
  .p-beginner-inner .img-content {
    width: 65rem;
    margin: 0 auto; }
    @media (--mobile) {
      .p-beginner-inner .img-content {
        width: 100%; } }
  .p-beginner-inner__text p {
    font-size: 1.6rem; }
    .p-beginner-inner__text p:first-of-type {
      margin-top: 5rem; }
      @media (--mobile) {
        .p-beginner-inner__text p:first-of-type {
          margin-top: 2rem; } }
    .p-beginner-inner__text p:nth-child(n + 2) {
      margin-top: 3rem; }
      @media (--mobile) {
        .p-beginner-inner__text p:nth-child(n + 2) {
          margin-top: 1.5rem; } }
  .p-beginner-inner__content {
    display: flex; }
    @media (--mobile) {
      .p-beginner-inner__content {
        display: block; } }
    .p-beginner-inner__content .left-content {
      width: calc(100% - 38rem - 3rem);
      margin-right: 3rem; }
      @media (--mobile) {
        .p-beginner-inner__content .left-content {
          width: 100%;
          margin-right: 0; } }
      .p-beginner-inner__content .left-content p {
        font-size: 1.6rem; }
    .p-beginner-inner__content .right-img {
      width: 38rem; }
      @media (--large) {
        .p-beginner-inner__content .right-img {
          padding-left: 4rem; } }
      @media (--mobile) {
        .p-beginner-inner__content .right-img {
          width: 100%;
          margin-top: 4rem; } }
      @media (--mobile) {
        .p-beginner-inner__content .right-img .img {
          text-align: center; } }
      .p-beginner-inner__content .right-img .text {
        margin-top: 1rem;
        font-size: 1.6rem;
        text-align: right; }
  .p-beginner-inner ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5rem; }
    .p-beginner-inner ul li {
      width: 48%; }
      @media (--mobile) {
        .p-beginner-inner ul li {
          width: 100%; } }
      @media (--large) {
        .p-beginner-inner ul li:nth-child(n + 3) {
          margin-top: 8rem; } }
      @media (--mobile) {
        .p-beginner-inner ul li:nth-child(n + 2) {
          margin-top: 4rem; } }
      .p-beginner-inner ul li .text {
        margin-top: 1.4rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #1a2e56; }
        @media (--mobile) {
          .p-beginner-inner ul li .text {
            margin-top: 1rem; } }

.p-catalogue .all-ele {
  position: relative;
  display: block;
  padding: 1.5rem 1.5rem 1rem; }

.p-catalogue .all-ele__link {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: -1.5rem -1.5rem -1rem !important; }
  .p-catalogue .all-ele__link:hover {
    color: #8491aa; }

.p-catalogue__inner .caption {
  margin-bottom: 3rem;
  font-size: 1.6rem; }
  .p-catalogue__inner .caption a {
    text-decoration: underline; }

.p-catalogue__heading {
  display: flex;
  align-items: center; }
  .p-catalogue__heading:nth-child(1) .u-m-c {
    color: #fff;
    background-color: #1a2e56; }
  .p-catalogue__heading:nth-child(1) svg {
    width: 1.1rem; }
    .p-catalogue__heading:nth-child(1) svg path {
      stroke: #fff; }
  @media (--mobile) {
    .p-catalogue__heading {
      flex-direction: column-reverse;
      align-items: flex-start; } }
  @media (--mobile) {
    .p-catalogue__heading .heading-text {
      margin-left: 0 !important; } }
  @media (--mobile) {
    .p-catalogue__heading .u-m-c {
      height: 5.6rem;
      margin-top: 0;
      margin-right: auto;
      margin-left: auto; } }
  @media (--mobile) {
    .p-catalogue__heading .c-btn08 {
      width: 100%;
      margin-top: 2rem; } }

.p-catalogue__search .search-content {
  display: flex; }
  .p-catalogue__search .search-content input {
    width: 26rem;
    padding: 0 1rem;
    background-color: #fff;
    border: 1px solid #c7ced3; }
  .p-catalogue__search .search-content .c-btn09 a {
    background-color: #1a2e56; }
    .p-catalogue__search .search-content .c-btn09 a span {
      color: #fff; }
      .p-catalogue__search .search-content .c-btn09 a span::after {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff; }

.p-catalogue__item:nth-child(n + 2) {
  margin-top: 8rem; }

.p-catalogue__item .item-ttl {
  margin-bottom: 3rem; }

.p-catalogue__item .item-subttl {
  margin-bottom: 3rem; }

.p-catalogue__item .item-list {
  display: flex;
  flex-wrap: wrap; }
  @media (--mobile) {
    .p-catalogue__item .item-list {
      justify-content: space-between; } }

.p-catalogue__item .item-this {
  position: relative;
  width: 27.7rem;
  border: 1px solid #1a2e56;
  transition: 0.3s; }
  @media (--large) {
    .p-catalogue__item .item-this:hover {
      background-color: #1a2e56; }
      .p-catalogue__item .item-this:hover .this-name p {
        color: #8491aa; }
      .p-catalogue__item .item-this:hover .light {
        color: #8491aa; }
        .p-catalogue__item .item-this:hover .light a {
          color: #8491aa; } }
  .p-catalogue__item .item-this a {
    display: block;
    height: 100%;
    padding: 1.5rem 1.5rem 1rem; }
  .p-catalogue__item .item-this .ic_pdf {
    position: absolute;
    top: 1rem;
    right: 1.7rem;
    width: 2.9rem;
    height: 2.9rem; }
    @media (--mobile) {
      .p-catalogue__item .item-this .ic_pdf {
        top: 0.5rem;
        right: 0.7rem;
        width: 2rem;
        height: 2rem; } }
  @media (--mobile) {
    .p-catalogue__item .item-this {
      width: 48%; }
      .p-catalogue__item .item-this:nth-child(n + 3) {
        margin-top: 3rem !important; } }
  .p-catalogue__item .item-this:not(:nth-child(4n)) {
    margin-right: 3rem; }
    @media (--mobile) {
      .p-catalogue__item .item-this:not(:nth-child(4n)) {
        margin-right: 0; } }
  .p-catalogue__item .item-this:nth-child(n + 5) {
    margin-top: 3rem; }
    @media (--mobile) {
      .p-catalogue__item .item-this:nth-child(n + 5) {
        margin-top: 0; } }
  .p-catalogue__item .item-this.item01 {
    min-height: 10rem; }
  .p-catalogue__item .item-this.item02 .this-name {
    line-height: 1.4; }
    .p-catalogue__item .item-this.item02 .this-name p {
      display: flex;
      align-items: center; }
  .p-catalogue__item .item-this .this-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.2rem;
    font-size: 1.8rem;
    letter-spacing: 0.1em;
    letter-spacing: 0;
    border-bottom: 1px solid #c7ced3; }
    @media (--mobile) {
      .p-catalogue__item .item-this .this-name {
        flex-direction: column-reverse;
        align-items: flex-start;
        letter-spacing: 0; } }
    .p-catalogue__item .item-this .this-name p {
      font-weight: bold; }
      @media (--mobile) {
        .p-catalogue__item .item-this .this-name p {
          margin-top: 1rem;
          font-size: 1.4rem;
          line-height: 1.4; } }
    .p-catalogue__item .item-this .this-name span {
      display: block;
      font-size: 1.3rem;
      font-weight: 500; }
  .p-catalogue__item .item-this .this-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.8rem; }
    @media (--mobile) {
      .p-catalogue__item .item-this .this-text {
        flex-direction: column-reverse;
        align-items: flex-start; } }
    .p-catalogue__item .item-this .this-text p {
      font-size: 1.3rem;
      letter-spacing: 0; }
    .p-catalogue__item .item-this .this-text time {
      font-size: 1rem;
      color: #8491aa;
      letter-spacing: 0; }
      .p-catalogue__item .item-this .this-text time.new {
        color: #c41818; }

.p-catalogue.detail .this-text {
  justify-content: right; }
  .p-catalogue.detail .this-text.desc {
    justify-content: space-between; }
    .p-catalogue.detail .this-text.desc .light {
      font-size: 1.3rem; }
      .p-catalogue.detail .this-text.desc .light a {
        position: relative;
        z-index: 2;
        padding: 0; }

.p-company-overview {
  margin-top: 6rem; }
  @media (--mobile) {
    .p-company-overview {
      margin-top: 3rem; } }
  .p-company-overview__img {
    width: 86rem;
    margin: 5rem auto 0; }
    @media (--mobile) {
      .p-company-overview__img {
        width: 100%;
        margin-top: 3rem; } }
  .p-company-overview dl {
    margin-top: 5rem; }
    @media (--mobile) {
      .p-company-overview dl {
        margin-top: 3rem; } }
    .en .p-company-overview dl dt {
      width: 25%; }
    .en .p-company-overview dl dd {
      width: 75%; }
  .p-company-overview__list {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem; }
    @media (--mobile) {
      .p-company-overview__list {
        display: block;
        margin-top: 0; } }
  .p-company-overview__content {
    width: 50rem; }
    @media (--mobile) {
      .p-company-overview__content {
        width: 100%;
        margin-top: 3rem; } }
    .p-company-overview__content .text {
      margin-top: 3rem;
      font-size: 1.6rem; }
    .p-company-overview__content .img {
      margin-top: 2rem;
      overflow: hidden; }
    .p-company-overview__content .btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 3rem;
      cursor: pointer; }
      .p-company-overview__content .btn .prev01,
      .p-company-overview__content .btn .prev02 {
        width: 3rem;
        margin-right: 2rem; }
      .p-company-overview__content .btn .next01,
      .p-company-overview__content .btn .next02 {
        width: 3rem; }

.p-company-area {
  margin-top: 11rem;
  margin-bottom: 16rem; }
  @media (--mobile) {
    .p-company-area {
      margin-top: 5rem;
      margin-bottom: 8rem; } }
  .p-company-area__content {
    display: flex;
    margin-top: 3rem; }
    @media (--mobile) {
      .p-company-area__content {
        display: block; } }
    .p-company-area__content .left-content {
      width: 48rem;
      margin-right: 7rem; }
      @media (--mobile) {
        .p-company-area__content .left-content {
          width: 100%;
          margin-right: 0; } }
      .p-company-area__content .left-content .text {
        font-size: 1.6rem; }
      .p-company-area__content .left-content .btn a {
        width: 24rem;
        height: 6.2rem;
        margin-top: 3rem;
        font-size: 1.6rem; }
    @media (--mobile) {
      .p-company-area__content .right-img {
        margin-top: 3rem; } }
    .p-company-area__content .right-img .btn {
      display: flex;
      justify-content: flex-end; }
      .p-company-area__content .right-img .btn .prev03,
      .p-company-area__content .right-img .btn .prev04,
      .p-company-area__content .right-img .btn .prev05 {
        width: 3rem;
        margin-right: 2rem; }
      .p-company-area__content .right-img .btn .next03,
      .p-company-area__content .right-img .btn .next04,
      .p-company-area__content .right-img .btn .next05 {
        width: 3rem; }
    .p-company-area__content .right-img .img {
      width: 55rem;
      overflow: hidden; }
      @media (--mobile) {
        .p-company-area__content .right-img .img {
          width: 100%; } }

@media (--mobile) {
  .p-e2000 .c-product-mv {
    display: block;
    padding-top: 4rem; }
  .p-e2000 .c-product-mv__pImg {
    margin-bottom: 3rem; } }

.p-e2000 .c-product-spec-img {
  width: 100rem; }
  @media (--mobile) {
    .p-e2000 .c-product-spec-img {
      width: 100%; } }

.p-e2000-list ul {
  display: flex; }
  @media (--mobile) {
    .p-e2000-list ul {
      display: block; } }
  .p-e2000-list ul li {
    margin-right: 4rem; }
    @media (--mobile) {
      .p-e2000-list ul li {
        margin-top: 3rem;
        margin-right: 0; }
        .p-e2000-list ul li:first-child {
          margin-top: 0; } }
    .p-e2000-list ul li:last-child {
      margin-right: 0; }
    .p-e2000-list ul li .title {
      margin-top: 2.4rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: #1a2e56; }
    .p-e2000-list ul li .text {
      margin-top: 1.2rem;
      font-size: 1.6rem; }

@media (--large) {
  .p-e3000 .c-product-spec-img {
    width: 64.1rem; }
  .en .p-e3000 .c-product-list > ul li:nth-child(2) .c-link01 a {
    font-size: 1.5rem !important; } }

.p-e3000-list ul {
  display: flex; }
  @media (--mobile) {
    .p-e3000-list ul {
      display: block; } }
  .p-e3000-list ul li {
    margin-right: 4rem; }
    @media (--mobile) {
      .p-e3000-list ul li {
        margin-top: 3rem;
        margin-right: 0; }
        .p-e3000-list ul li:first-child {
          margin-top: 0; } }
    .p-e3000-list ul li:last-child {
      margin-right: 0; }
    .p-e3000-list ul li .title {
      margin-top: 2.4rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: #1a2e56; }
    .p-e3000-list ul li .text {
      margin-top: 1.2rem;
      font-size: 1.6rem; }

.c-modal.short {
  width: 40rem; }
  @media (--mobile) {
    .c-modal.short {
      width: 90%; } }

.c-modal-inner.long {
  height: 500px;
  overflow-y: scroll; }
  @media (--large) {
    .en .c-modal-inner.long.vertical ul {
      flex-direction: column;
      margin: 2.4rem 0 0; }
      .en .c-modal-inner.long.vertical ul li {
        display: flex;
        width: 100%;
        min-width: 80rem;
        margin: 0; }
        .en .c-modal-inner.long.vertical ul li:nth-child(n + 2) {
          margin-top: 2rem; }
        .en .c-modal-inner.long.vertical ul li > .img {
          width: 40%;
          margin-right: 2rem; }
        .en .c-modal-inner.long.vertical ul li > div {
          width: calc(100% - 40% - 2rem); } }

.p-emax_evolution__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3rem; }
  @media (--mobile) {
    .p-emax_evolution__content {
      display: block; } }

.p-emax_evolution__left {
  width: 51.7rem; }
  @media (--mobile) {
    .p-emax_evolution__left {
      width: 100%; } }

.p-emax_evolution__right {
  margin-left: 6rem; }
  @media (--mobile) {
    .p-emax_evolution__right {
      margin-top: 2rem;
      margin-left: 0; } }
  .p-emax_evolution__right p {
    font-size: 1.6rem; }
  .p-emax_evolution__right span {
    display: block;
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: bold;
    color: #1a2e56; }
  .p-emax_evolution__right .img {
    width: 35.1rem;
    margin-top: 2rem; }
    @media (--mobile) {
      .p-emax_evolution__right .img {
        width: 100%; } }

.p-emax_evolution__list ul {
  display: flex; }
  @media (--mobile) {
    .p-emax_evolution__list ul {
      display: block; } }
  .p-emax_evolution__list ul li:first-child {
    margin-right: 4rem; }
    @media (--mobile) {
      .p-emax_evolution__list ul li:first-child {
        margin-right: 0;
        margin-bottom: 2rem; } }
  .p-emax_evolution__list ul li .pic {
    margin-bottom: 2rem; }
    @media (--mobile) {
      .p-emax_evolution__list ul li .pic {
        margin-bottom: 1rem; } }
  .p-emax_evolution__list ul li .text {
    font-size: 1.6rem;
    font-weight: bold;
    color: #1a2e56; }

.p-emax_evolution .c-ttl04 span {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  border: 0.1rem solid #1a2e56; }
  @media (--mobile) {
    .p-emax_evolution .c-ttl04 span {
      margin-bottom: 0.5rem; } }

@media (--large) {
  .en .p-emax_evolution .no-break {
    white-space: nowrap; } }

.p-espert500 .c-product-spec-img {
  width: 74.1rem;
  max-width: 100%; }

.p-espert500__list ul {
  display: flex; }
  @media (--mobile) {
    .p-espert500__list ul {
      display: block; } }
  .p-espert500__list ul li:first-child {
    margin-right: 4rem; }
    @media (--mobile) {
      .p-espert500__list ul li:first-child {
        margin-right: 0;
        margin-bottom: 2rem; } }
  .p-espert500__list ul li .pic {
    margin-bottom: 2rem; }
    @media (--mobile) {
      .p-espert500__list ul li .pic {
        margin-bottom: 1rem; } }
  .p-espert500__list ul li .text {
    font-size: 1.6rem;
    font-weight: bold;
    color: #1a2e56; }

.p-espert500 .c-ttl04 span {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  border: 0.1rem solid #1a2e56; }
  @media (--mobile) {
    .p-espert500 .c-ttl04 span {
      margin-bottom: 0.5rem; } }

.p-faq {
  font-size: 1.6rem; }
  .p-faq-side ul li:nth-of-type(n + 2) {
    margin-top: 2rem; }
  .p-faq-side ul li a {
    position: relative;
    display: block;
    font-size: 1.2rem; }
    .p-faq-side ul li a::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 0.8rem;
      height: 0.8rem;
      content: "";
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      transform: translateY(-50%) rotate(45deg); }
  .p-faq .c-mv__top {
    display: flex;
    align-items: center; }
    .p-faq .c-mv__top span {
      font-size: 1.8rem;
      color: #969faf; }
      @media (--mobile) {
        .p-faq .c-mv__top span {
          font-size: 1.4rem; } }
    .p-faq .c-mv__top em {
      padding: 0 1.3rem;
      margin-left: 1rem;
      font-size: 1.2rem;
      font-style: inherit;
      color: #969faf;
      border: 1px solid #969faf;
      border-radius: 9999px; }
  .p-faq .c-mv__middle {
    padding-top: 0.8rem;
    font-size: 4rem;
    line-height: 1.2;
    color: #969faf;
    letter-spacing: 0.1em; }
    @media (--mobile) {
      .p-faq .c-mv__middle {
        padding-top: 0.8rem;
        font-size: 3rem; } }
  .p-faq .c-mv__bottom {
    padding-top: 1rem;
    font-size: 3.2rem;
    line-height: 1.2; }
    @media (--mobile) {
      .p-faq .c-mv__bottom {
        padding-top: 1.2rem;
        font-size: 3.3rem; } }
  @media (--mobile) {
    .p-faq .c-mv__h {
      height: 22rem; } }
  .p-faq__ttl .ttl-it {
    padding: 1.2rem 0.9rem;
    background-color: #1a2e56; }
    .p-faq__ttl .ttl-it h2 {
      font-size: 2.2rem;
      color: #fff; }
  .p-faq__ttl .ttl-text {
    margin-top: 3rem; }
  .p-faq__content {
    margin-top: 3rem;
    margin-bottom: 10rem; }
  .p-faq__inner {
    display: flex; }
    @media (--mobile) {
      .p-faq__inner {
        flex-direction: column; } }
  .p-faq__menu {
    width: 21rem;
    color: #1a2e56; }
    @media (--mobile) {
      .p-faq__menu {
        width: 100%;
        margin-bottom: 5rem; } }
    .p-faq__menu .menu-inner {
      width: 15rem; }
      @media (--mobile) {
        .p-faq__menu .menu-inner {
          width: 100%; } }
    .p-faq__menu .menu-select {
      position: relative; }
      .p-faq__menu .menu-select select {
        width: 100%;
        padding: 1.2rem 3.2rem 1.2rem 1.2rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: #1a2e56;
        letter-spacing: 0.03em;
        white-space: normal;
        border: 1px solid #1a2e56; }
      .p-faq__menu .menu-select svg {
        position: absolute;
        top: 50%;
        right: 1rem;
        width: 1.4rem;
        height: 1.4rem;
        transform: translateY(-50%); }
    .p-faq__menu .menu-accoInner {
      margin-top: 1.7rem; }
    .p-faq__menu .menu-acco:nth-child(n + 2) {
      margin-top: 0.7rem; }
    .p-faq__menu .menu-acco__ttl {
      position: relative;
      padding: 1.3rem 0;
      font-size: 1.3rem;
      font-weight: bold;
      border-bottom: 1px solid #c7ced3; }
      .p-faq__menu .menu-acco__ttl svg {
        position: absolute;
        top: 50%;
        right: 1rem;
        width: 1.4rem;
        height: 1.4rem;
        transform: translateY(-50%); }
    .p-faq__menu .menu-acco__list {
      font-size: 1.3rem; }
      @media (--mobile) {
        .p-faq__menu .menu-acco__list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between; } }
    .p-faq__menu .menu-acco__item {
      margin-top: 1.4rem;
      text-align: center; }
      .p-faq__menu .menu-acco__item:first-child {
        margin-top: 2rem; }
        @media (--mobile) {
          .p-faq__menu .menu-acco__item:first-child {
            margin-top: 1.4rem; } }
      @media (--mobile) {
        .p-faq__menu .menu-acco__item {
          width: 50%; } }
  .p-faq__list,
  .p-faq .c-faq {
    flex: 1; }
    .p-faq__list .list-ttl,
    .p-faq .c-faq .list-ttl {
      padding: 0.9rem 1.2rem;
      background-color: #1a2e56; }
      .p-faq__list .list-ttl h3,
      .p-faq .c-faq .list-ttl h3 {
        font-size: 2.2rem;
        color: #fff; }
    .p-faq__list .list-content,
    .p-faq .c-faq .list-content {
      margin-top: 3rem; }
    .p-faq__list .list-item,
    .p-faq .c-faq .list-item {
      border-bottom: 1px solid #8491aa; }
      .p-faq__list .list-item:first-child,
      .p-faq .c-faq .list-item:first-child {
        border-top: 1px solid #8491aa; }
    .p-faq__list .list-question, .p-faq__list .list-answer,
    .p-faq .c-faq .list-question,
    .p-faq .c-faq .list-answer {
      display: flex;
      align-items: flex-start;
      padding: 3.2rem 6rem 3.2rem 2rem; }
      @media (--mobile) {
        .p-faq__list .list-question, .p-faq__list .list-answer,
        .p-faq .c-faq .list-question,
        .p-faq .c-faq .list-answer {
          padding: 1.8rem 3.5rem 1.8rem 0.5rem; } }
      .p-faq__list .list-question p, .p-faq__list .list-answer p,
      .p-faq .c-faq .list-question p,
      .p-faq .c-faq .list-answer p {
        flex: 1; }
      .p-faq__list .list-question .list-icon, .p-faq__list .list-answer .list-icon,
      .p-faq .c-faq .list-question .list-icon,
      .p-faq .c-faq .list-answer .list-icon {
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 2rem; }
        @media (--mobile) {
          .p-faq__list .list-question .list-icon, .p-faq__list .list-answer .list-icon,
          .p-faq .c-faq .list-question .list-icon,
          .p-faq .c-faq .list-answer .list-icon {
            margin-right: 1rem; } }
    .p-faq__list .list-question,
    .p-faq .c-faq .list-question {
      position: relative;
      transition: 0.3s; }
      .p-faq__list .list-question p,
      .p-faq .c-faq .list-question p {
        margin-top: 0.2rem; }
      .p-faq__list .list-question .arrow-bottom,
      .p-faq .c-faq .list-question .arrow-bottom {
        position: absolute;
        top: 50%;
        right: 2rem;
        width: 1.4rem;
        height: 1.4rem;
        transform: translateY(-50%); }
        @media (--mobile) {
          .p-faq__list .list-question .arrow-bottom,
          .p-faq .c-faq .list-question .arrow-bottom {
            right: 1rem; } }
      @media (--large) {
        .p-faq__list .list-question:hover,
        .p-faq .c-faq .list-question:hover {
          opacity: 0.5; } }
    .p-faq__list .list-answer,
    .p-faq .c-faq .list-answer {
      margin-top: -3rem; }
      @media (--mobile) {
        .p-faq__list .list-answer,
        .p-faq .c-faq .list-answer {
          padding: 1.8rem 3.5rem 1.8rem 0.5rem;
          margin-top: -1rem; } }
      .p-faq__list .list-answer__content,
      .p-faq .c-faq .list-answer__content {
        flex: 1;
        margin-top: 0.2rem; }
      .p-faq__list .list-answer .caption,
      .p-faq .c-faq .list-answer .caption {
        width: 71.6rem;
        font-size: 1.4rem;
        text-align: right; }
        @media (--mobile) {
          .p-faq__list .list-answer .caption,
          .p-faq .c-faq .list-answer .caption {
            width: 100%; } }
      .p-faq__list .list-answer__img,
      .p-faq .c-faq .list-answer__img {
        height: 100%;
        margin-top: 3rem; }
        .p-faq__list .list-answer__img.img01,
        .p-faq .c-faq .list-answer__img.img01 {
          width: 30rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img01,
            .p-faq .c-faq .list-answer__img.img01 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img02,
        .p-faq .c-faq .list-answer__img.img02 {
          width: 30rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img02,
            .p-faq .c-faq .list-answer__img.img02 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img03,
        .p-faq .c-faq .list-answer__img.img03 {
          width: 43rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img03,
            .p-faq .c-faq .list-answer__img.img03 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img04,
        .p-faq .c-faq .list-answer__img.img04 {
          width: 43rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img04,
            .p-faq .c-faq .list-answer__img.img04 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img05,
        .p-faq .c-faq .list-answer__img.img05 {
          width: 77rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img05,
            .p-faq .c-faq .list-answer__img.img05 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img06,
        .p-faq .c-faq .list-answer__img.img06 {
          width: 44rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img06,
            .p-faq .c-faq .list-answer__img.img06 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img07,
        .p-faq .c-faq .list-answer__img.img07 {
          width: 63rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img07,
            .p-faq .c-faq .list-answer__img.img07 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img08,
        .p-faq .c-faq .list-answer__img.img08 {
          width: 59rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img08,
            .p-faq .c-faq .list-answer__img.img08 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img09,
        .p-faq .c-faq .list-answer__img.img09 {
          width: 59rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img09,
            .p-faq .c-faq .list-answer__img.img09 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img10,
        .p-faq .c-faq .list-answer__img.img10 {
          width: 73.6rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img10,
            .p-faq .c-faq .list-answer__img.img10 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img11,
        .p-faq .c-faq .list-answer__img.img11 {
          width: 73.6rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img11,
            .p-faq .c-faq .list-answer__img.img11 {
              width: 100%; } }
        .p-faq__list .list-answer__img.img12,
        .p-faq .c-faq .list-answer__img.img12 {
          width: 73.6rem; }
          @media (--mobile) {
            .p-faq__list .list-answer__img.img12,
            .p-faq .c-faq .list-answer__img.img12 {
              width: 100%; } }
        .p-faq__list .list-answer__img img,
        .p-faq .c-faq .list-answer__img img {
          width: 100%;
          height: 100%; }
      .p-faq__list .list-answer__link,
      .p-faq .c-faq .list-answer__link {
        display: flex;
        margin-top: 3rem; }
        .p-faq__list .list-answer__link a,
        .p-faq .c-faq .list-answer__link a {
          text-decoration: underline; }
          .p-faq__list .list-answer__link a:nth-child(n + 2),
          .p-faq .c-faq .list-answer__link a:nth-child(n + 2) {
            margin-left: 3rem; }

.p-hes .c-product-spec-img {
  width: 52.3rem; }
  @media (--mobile) {
    .p-hes .c-product-spec-img {
      width: 100%; } }

.p-hts .c-product-mv {
  padding-bottom: 6rem; }

.p-hts .c-product-spec {
  padding-top: 9rem; }
  @media (--mobile) {
    .p-hts .c-product-spec {
      padding-top: 4rem; } }

.p-hts .c-product-spec-img {
  width: 72.6rem; }
  @media (--mobile) {
    .p-hts .c-product-spec-img {
      width: 100%; } }

.p-impulse .c-product-mv {
  padding-bottom: 6rem; }

@media (--mobile) {
  .p-impulse .c-product-spec-ttl {
    font-size: 2.3rem; } }

.p-impulse .c-product-spec {
  padding-top: 9rem; }
  @media (--mobile) {
    .p-impulse .c-product-spec {
      padding-top: 4rem; } }

@media (--mobile) {
  .p-impulse .c-product-mv-ttl02 {
    font-size: 4rem; }
    .p-impulse .c-product-mv-ttl02 span {
      font-size: 3rem; } }

.p-impulse .c-product-spec-img {
  width: 71.6rem; }
  @media (--mobile) {
    .p-impulse .c-product-spec-img {
      width: 100%; } }

.p-inquiry {
  margin-bottom: 14rem; }
  @media (--mobile) {
    .p-inquiry {
      margin-bottom: 7rem; } }
  .p-inquiry-box {
    width: 100%;
    padding: 5rem 9rem;
    margin-top: 6rem;
    border: 0.1rem solid #1a2e56; }
    @media (--mobile) {
      .p-inquiry-box {
        padding: 2rem 3rem;
        margin-top: 3rem; } }
    .p-inquiry-box__title {
      font-size: 2.4rem;
      text-align: center; }
    .p-inquiry-box__inner {
      margin-top: 2rem; }
      @media (--mobile) {
        .p-inquiry-box__inner {
          margin-top: 2rem; } }
    .p-inquiry-box__content .img {
      text-align: center; }
      .p-inquiry-box__content .img img {
        width: 8.2rem; }
    .p-inquiry-box__content .btn {
      margin-top: 2rem; }
      @media (--mobile) {
        .p-inquiry-box__content .btn {
          margin-top: 4rem; } }
  .p-inquiry-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4rem; }
    @media (--mobile) {
      .p-inquiry-block {
        display: block;
        margin-top: 3rem; } }
    .p-inquiry-block__cell {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5rem 0;
      border: 0.1rem solid #1a2e56; }
      @media (--mobile) {
        .p-inquiry-block__cell {
          padding: 3rem 0; } }
      .p-inquiry-block__cell:first-of-type {
        width: 58rem; }
        @media (--mobile) {
          .p-inquiry-block__cell:first-of-type {
            width: 100%;
            margin-right: 0; } }
      .p-inquiry-block__cell:nth-of-type(2) {
        width: 58rem; }
        @media (--mobile) {
          .p-inquiry-block__cell:nth-of-type(2) {
            width: 100%;
            margin-top: 2rem; } }
      .p-inquiry-block__cell:nth-of-type(n + 3) {
        width: 58rem;
        margin-top: 4rem; }
        @media (--mobile) {
          .p-inquiry-block__cell:nth-of-type(n + 3) {
            width: 100%;
            margin-top: 2rem; } }
      .p-inquiry-block__cell:last-of-type {
        margin-right: 0; }
      .p-inquiry-block__cell .title {
        font-size: 2.4rem;
        color: #1a2e56; }
        @media (--mobile) {
          .p-inquiry-block__cell .title {
            font-size: 2rem; } }
      .p-inquiry-block__cell .img {
        margin-top: 4rem; }
        @media (--mobile) {
          .p-inquiry-block__cell .img {
            margin-top: 2rem; } }
        .p-inquiry-block__cell .img img {
          width: 8.2rem; }
      .p-inquiry-block__cell .btn-content {
        display: flex;
        margin-top: 2rem; }
        @media (--mobile) {
          .p-inquiry-block__cell .btn-content {
            display: block;
            margin-top: 0; } }
        .p-inquiry-block__cell .btn-content .btn:first-of-type {
          margin-right: 3rem; }
          @media (--mobile) {
            .p-inquiry-block__cell .btn-content .btn:first-of-type {
              margin-right: 0; } }
      .p-inquiry-block__cell .btn {
        margin-top: 4rem; }
        @media (--mobile) {
          .p-inquiry-block__cell .btn {
            margin-top: 2rem; }
            .p-inquiry-block__cell .btn:first-of-type {
              margin-top: 4rem; } }
  @media (--mobile) {
    .p-inquiry .c-btn07 a {
      width: 23rem;
      height: 5rem; } }
  @media (--mobile) {
    .p-inquiry .u-m-c {
      font-size: 1.4rem; } }

.p-instructions__item {
  display: flex; }
  .p-instructions__item:nth-child(n + 2) {
    margin-top: 4rem; }
  .p-instructions__item .item-num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    background-color: #1a2e56;
    border-radius: 50%;
    margin-right: 2rem;
    color: #fff; }
  .p-instructions__item .item-text {
    flex: 1; }

.p-instructions__btn {
  display: flex;
  width: calc(960 / 1100 * 100%);
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between; }
  @media (--mobile) {
    .p-instructions__btn {
      width: 100%; } }
  @media (--mobile) {
    .p-instructions__btn {
      flex-direction: column; } }
  .p-instructions__btn .c-btn04,
  .p-instructions__btn .c-btn05 {
    width: 46rem; }
    @media (--mobile) {
      .p-instructions__btn .c-btn04,
      .p-instructions__btn .c-btn05 {
        width: 100%; } }
    .p-instructions__btn .c-btn04 a,
    .p-instructions__btn .c-btn05 a {
      width: 100% !important;
      font-size: 1.9rem; }
  @media (--mobile) {
    .p-instructions__btn .c-btn04 {
      margin-top: 2rem; } }
  .p-instructions__btn .c-btn04 a {
    justify-content: space-between;
    padding-left: 4.5rem;
    padding-right: 3.3rem; }
    @media (--mobile) {
      .p-instructions__btn .c-btn04 a {
        font-size: 1.5rem; } }
  .p-instructions__btn .c-btn05 a {
    background-color: #fff;
    border: 1px solid #1a2e56;
    color: #1a2e56;
    transition: 0.3s;
    justify-content: left;
    padding: 0 3.3rem; }
    @media (--mobile) {
      .p-instructions__btn .c-btn05 a {
        justify-content: center; } }
    @media (--large) {
      .p-instructions__btn .c-btn05 a:hover {
        color: #fff; }
        .p-instructions__btn .c-btn05 a:hover svg path {
          stroke: #fff; } }
    .p-instructions__btn .c-btn05 a svg {
      margin-right: 4rem; }
      .p-instructions__btn .c-btn05 a svg path {
        transition: 0.3s;
        stroke: #1a2e56; }

.p-ispeed3 .c-product-spec-img {
  width: 57rem; }
  @media (--mobile) {
    .p-ispeed3 .c-product-spec-img {
      width: 100%; } }

@media (--mobile) {
  .p-micro-grinder .c-product-mv {
    padding: 7rem 0; }
    .p-micro-grinder .c-product-mv__pImg {
      display: block;
      width: 37rem;
      margin: 0 auto 5rem; } }

.p-micro-grinder .c-product-mv-mTxt {
  font-size: 1.6rem; }

.p-micro-grinder .c-product-mv-sTxt {
  font-size: 1.6rem; }

.p-micro-grinder-list01 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; }
  @media (--mobile) {
    .p-micro-grinder-list01 {
      display: block;
      margin: 0; } }
  .p-micro-grinder-list01-el {
    position: relative;
    width: calc(100% / 2 - 2rem);
    min-height: 27rem;
    margin: 0 1rem;
    background: linear-gradient(to left, #000, #00012b); }
    @media (--large) {
      .en .p-micro-grinder-list01-el:nth-child(2) h4 {
        margin-top: 4.4rem; } }
    .en .p-micro-grinder-list01-el.single-type h4 {
      margin-top: 3rem !important; }
    @media (--large) {
      .en .p-micro-grinder-list01-el.single-type h5 {
        margin-top: 2.4rem; } }
    @media (--mobile) {
      .p-micro-grinder-list01-el {
        width: 100%;
        height: 100%;
        min-height: 18rem;
        margin: 0 0 2rem; } }
    .p-micro-grinder-list01-el a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 2rem; }
      .p-micro-grinder-list01-el a * {
        position: relative;
        z-index: 2;
        padding-left: 0.8rem;
        color: #fff; }
      .p-micro-grinder-list01-el a img {
        position: absolute;
        z-index: 1;
        padding-left: 0; }
        .p-micro-grinder-list01-el a img.evolution {
          right: 4.5rem;
          bottom: 1.7rem;
          width: 25rem;
          height: auto; }
          @media (--mobile) {
            .p-micro-grinder-list01-el a img.evolution {
              right: 2rem;
              bottom: 1.7rem;
              width: 15rem;
              height: auto; } }
        .p-micro-grinder-list01-el a img.espert {
          right: 2.9rem;
          bottom: 3.1rem;
          width: 27rem; }
          @media (--mobile) {
            .p-micro-grinder-list01-el a img.espert {
              right: 1.2rem;
              bottom: 2.1rem;
              width: 18rem; } }
        .p-micro-grinder-list01-el a img.sheenus {
          right: 3rem;
          bottom: 2.4rem;
          width: 24rem; }
          @media (--large) {
            .en .p-micro-grinder-list01-el a img.sheenus {
              top: 5.5rem;
              right: 2.5rem;
              width: 22rem; } }
          @media (--mobile) {
            .p-micro-grinder-list01-el a img.sheenus {
              right: 2rem;
              width: 15.4rem; }
              .en .p-micro-grinder-list01-el a img.sheenus {
                bottom: 4rem;
                width: 13.5rem; } }
        .p-micro-grinder-list01-el a img.soniccutter {
          top: 0.8rem;
          right: 3.8rem;
          width: 23rem; }
          @media (--large) {
            .en .p-micro-grinder-list01-el a img.soniccutter {
              top: 1.5rem;
              right: 2.5rem;
              width: 20rem; } }
          @media (--mobile) {
            .p-micro-grinder-list01-el a img.soniccutter {
              right: 2rem;
              width: 14.8rem; }
              .en .p-micro-grinder-list01-el a img.soniccutter {
                top: 1.5rem;
                width: 13rem; } }
      .p-micro-grinder-list01-el a span {
        display: inline-block;
        padding: 0 1rem;
        font-size: 1.1rem;
        color: #c7ced3;
        border: 1px solid #c7ced3;
        border-radius: 9999px; }
      .p-micro-grinder-list01-el a h3 {
        font-size: 3.6rem;
        font-weight: normal;
        color: #fff; }
        @media (--mobile) {
          .p-micro-grinder-list01-el a h3 {
            font-size: 2.6rem; } }
      .p-micro-grinder-list01-el a h4 {
        margin-top: 3rem;
        font-size: 1.8rem;
        font-weight: lighter;
        opacity: 0.9; }
        @media (--mobile) {
          .p-micro-grinder-list01-el a h4 {
            font-size: 1.6rem; } }
      .p-micro-grinder-list01-el a h5 {
        margin-top: 0.4rem;
        font-size: 1.4rem;
        font-weight: lighter;
        opacity: 0.9; }
        @media (--mobile) {
          .p-micro-grinder-list01-el a h5 {
            font-size: 1.2rem; } }
      .p-micro-grinder-list01-el a p {
        margin-top: 3rem;
        margin-bottom: -1.5rem;
        font-size: 3rem;
        line-height: 1.3; }
        @media (--mobile) {
          .p-micro-grinder-list01-el a p {
            margin-top: 1rem;
            font-size: 1.8rem; } }
    .p-micro-grinder-list01-el:nth-of-type(n + 3) {
      width: calc(100% / 3 - 2rem);
      min-height: 22rem;
      margin-top: 2rem; }
      @media (--mobile) {
        .p-micro-grinder-list01-el:nth-of-type(n + 3) {
          width: 100%;
          margin-bottom: 2rem; } }
      .p-micro-grinder-list01-el:nth-of-type(n + 3) a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 2rem; }
      .p-micro-grinder-list01-el:nth-of-type(n + 3) h3 {
        margin-top: 0.5rem;
        font-size: 2.6rem; }
        @media (--mobile) {
          .p-micro-grinder-list01-el:nth-of-type(n + 3) h3 {
            font-size: 2.8rem; } }
      .p-micro-grinder-list01-el:nth-of-type(n + 3) h4 {
        margin-top: 0;
        font-size: 1.6rem;
        font-weight: lighter;
        opacity: 0.9; }
      .p-micro-grinder-list01-el:nth-of-type(n + 3) h5 {
        margin-top: 0.4rem;
        font-size: 1.2rem;
        font-weight: lighter;
        opacity: 0.9; }
      .p-micro-grinder-list01-el:nth-of-type(n + 3) p {
        margin-top: 0.5rem;
        font-size: 1.1rem;
        opacity: 0.7; }

.p-micro-grinder-list02 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; }
  @media (--mobile) {
    .p-micro-grinder-list02 {
      margin: 0; } }
  .p-micro-grinder-list02-el {
    position: relative;
    width: calc(100% / 3 - 2rem);
    min-height: 18rem;
    margin: 0 1rem;
    background: linear-gradient(to left, #000, #00012b); }
    @media (--mobile) {
      .p-micro-grinder-list02-el {
        width: 100%;
        height: 100%;
        min-height: inherit;
        margin: 0 0 2rem; } }
    .p-micro-grinder-list02-el a {
      display: block;
      width: 100%;
      height: 100%; }
      @media (--large) {
        .p-micro-grinder-list02-el a {
          padding-top: 4.5rem !important;
          padding-left: 1.6rem !important; } }
      @media (--mobile) {
        .p-micro-grinder-list02-el a {
          padding: 3rem 1.5rem; } }
      .p-micro-grinder-list02-el a * {
        position: relative;
        z-index: 2;
        padding-left: 0.8rem;
        color: #fff; }
      .p-micro-grinder-list02-el a img {
        position: absolute;
        z-index: 1;
        padding-left: 0; }
        .p-micro-grinder-list02-el a img.lotas {
          right: 0.9rem;
          bottom: 2.1rem;
          width: 16rem; }
          @media (--mobile) {
            .p-micro-grinder-list02-el a img.lotas {
              bottom: 0.9rem; } }
        .p-micro-grinder-list02-el a img.impulse {
          right: 0.9rem;
          bottom: 0;
          width: 17.2rem; }
        .p-micro-grinder-list02-el a img.presto {
          right: 1.4rem;
          bottom: 2.3rem;
          width: 22.5rem; }
          @media (--large) {
            .en .p-micro-grinder-list02-el a img.presto {
              right: 0.4rem;
              bottom: 3.3rem;
              width: 20.5rem; } }
          @media (--mobile) {
            .p-micro-grinder-list02-el a img.presto {
              right: 0;
              width: 17.6rem; } }
      .p-micro-grinder-list02-el a span {
        display: inline-block;
        padding: 0 1rem;
        font-size: 1.1rem;
        color: #c7ced3;
        border: 1px solid #c7ced3;
        border-radius: 9999px; }
      .p-micro-grinder-list02-el a h3 {
        font-size: 2.6rem;
        font-weight: normal;
        line-height: 1.5;
        color: #fff; }
      @media (--large) {
        .p-micro-grinder-list02-el a {
          padding-top: 2.5rem; } }
      .p-micro-grinder-list02-el a h5 {
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: lighter;
        opacity: 0.9; }
        @media (--mobile) {
          .p-micro-grinder-list02-el a h5 {
            font-size: 1.6rem; } }
      .p-micro-grinder-list02-el a p {
        margin-top: 1.2rem;
        font-size: 1.1rem;
        letter-spacing: 0;
        opacity: 0.7; }
    .p-micro-grinder-list02-el:nth-of-type(n + 4) {
      margin-top: 2rem; }
      @media (--mobile) {
        .p-micro-grinder-list02-el:nth-of-type(n + 4) {
          margin-top: 0; } }

.p-micro-grinder .about figure {
  width: 100%;
  height: 34rem;
  margin-top: 4rem; }
  @media (--mobile) {
    .p-micro-grinder .about figure {
      height: 22rem; } }
  .p-micro-grinder .about figure img {
    width: 100%;
    height: 100%; }

.p-micro-grinder .about p {
  letter-spacing: 0.1em; }

.p-micro-grinder .feature .c-product-side {
  display: flex; }
  @media (--mobile) {
    .p-micro-grinder .feature .c-product-side {
      display: block;
      margin-top: 10rem; } }
  .p-micro-grinder .feature .c-product-side__left h4 {
    font-size: 2.4rem;
    font-weight: 500;
    color: #1a2e56;
    letter-spacing: 0.1em; }
  .p-micro-grinder .feature .c-product-side__left p {
    line-height: 1.8; }
  @media (--mobile) {
    .p-micro-grinder .feature .c-product-side__left .c-btn09 {
      margin-bottom: 2rem; } }
  .p-micro-grinder .feature .c-product-side__left .c-btn09 a {
    width: 20rem;
    height: 5rem;
    border: 1px solid #1a2e56; }
    @media (--large) {
      .p-micro-grinder .feature .c-product-side__left .c-btn09 a {
        padding-right: 0; } }
    .p-micro-grinder .feature .c-product-side__left .c-btn09 a span::after {
      right: -3rem; }
  .p-micro-grinder .feature .c-product-side__right figure {
    width: 55rem;
    height: 32rem; }
    @media (--mobile) {
      .p-micro-grinder .feature .c-product-side__right figure {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%; } }
    .p-micro-grinder .feature .c-product-side__right figure img {
      width: 100%;
      height: 100%; }

.p-micro-grinder .comparison .c-table03 table {
  width: 100%;
  font-size: 1.5rem;
  border-spacing: 0;
  border-collapse: collapse; }
  @media (--mobile) {
    .p-micro-grinder .comparison .c-table03 table {
      width: 80rem;
      border-collapse: separate; } }
  .p-micro-grinder .comparison .c-table03 table th {
    padding: 1.5rem 0;
    color: #fff;
    background-color: #2c529f;
    border: 1px solid #c7ced3; }
    .p-micro-grinder .comparison .c-table03 table th:first-child {
      background-color: #fff;
      border: none; }
  .p-micro-grinder .comparison .c-table03 table td {
    padding: 1rem 2rem;
    border: 1px solid #c7ced3; }
    @media (--large) {
      .en .p-micro-grinder .comparison .c-table03 table td.nonbreaking {
        white-space: nowrap; } }
    @media (--mobile) {
      .p-micro-grinder .comparison .c-table03 table td {
        min-width: 9rem; } }
    .p-micro-grinder .comparison .c-table03 table td.first {
      text-align: center;
      background-color: #edeff4; }
    .p-micro-grinder .comparison .c-table03 table td.price {
      color: #2c529f; }
  .p-micro-grinder .comparison .c-table03 table .p-2 {
    padding: 2.5rem 2rem; }
    @media (--mobile) {
      .p-micro-grinder .comparison .c-table03 table .p-2 {
        padding: 0.5rem 2rem; } }
  @media (--mobile) {
    .p-micro-grinder .comparison .c-table03 table .fixed01 {
      position: sticky;
      left: 0;
      width: 12rem; }
      .p-micro-grinder .comparison .c-table03 table .fixed01::before {
        position: absolute;
        top: -1px;
        left: 1px;
        width: 100%;
        height: 100%;
        content: ""; } }

.p-micro-grinder .model__menu {
  display: flex;
  justify-content: space-between; }
  .p-micro-grinder .model__menu li {
    position: relative;
    width: 54rem;
    text-align: center;
    border-bottom: 3px solid #b2b2b2; }
    @media (--mobile) {
      .p-micro-grinder .model__menu li {
        width: 16.5rem; } }
    .p-micro-grinder .model__menu li.is-active {
      border-bottom: 3px solid #1a2e56; }
      .p-micro-grinder .model__menu li.is-active div {
        color: #1a2e56; }
      .p-micro-grinder .model__menu li.is-active svg * {
        fill: #1a2e56; }
    .p-micro-grinder .model__menu li div {
      display: block;
      width: 100%;
      padding: 0.8rem 0;
      color: #b2b2b2;
      text-align: center;
      cursor: pointer; }
      @media (--mobile) {
        .p-micro-grinder .model__menu li div {
          padding: 0.8rem 0.5rem 0.8rem 2.3rem; } }
    .p-micro-grinder .model__menu li svg {
      position: absolute;
      bottom: -1.7rem;
      left: 50%;
      width: 1.5rem;
      height: 1rem;
      transform: translateY(-50%); }
      .p-micro-grinder .model__menu li svg * {
        fill: #b2b2b2; }
      @media (--mobile) {
        .p-micro-grinder .model__menu li svg {
          bottom: -1.6rem; } }

@media (--large) {
  .p-micro-grinder .model .c-table03 {
    display: none; }
    .p-micro-grinder .model .c-table03.is-active {
      display: block; }
    .p-micro-grinder .model .c-table03 th {
      color: #fff; }
    .p-micro-grinder .model .c-table03 td {
      min-width: 9rem;
      padding: 2rem;
      border: 1px solid #c7ced3; }
      .p-micro-grinder .model .c-table03 td figure {
        display: flex;
        align-items: center;
        justify-content: center; }
        .p-micro-grinder .model .c-table03 td figure img {
          margin: 0 auto; }
          .p-micro-grinder .model .c-table03 td figure img.model_basic {
            width: 15.4rem; }
          .p-micro-grinder .model .c-table03 td figure img.model_highend {
            width: 20.8rem; }
          .p-micro-grinder .model .c-table03 td figure img.model_sheenus {
            width: 18.2rem; }
          .p-micro-grinder .model .c-table03 td figure img.model_sonic {
            width: 18.5rem; }
          .p-micro-grinder .model .c-table03 td figure img.model_detail01 {
            width: 28.3rem; }
          .p-micro-grinder .model .c-table03 td figure img.model_detail02 {
            width: 30.4rem; }
          .p-micro-grinder .model .c-table03 td figure img.enk-410s {
            width: 14.5rem; }
          .p-micro-grinder .model .c-table03 td figure img.enk-250t {
            width: 14.5rem; }
          .p-micro-grinder .model .c-table03 td figure img.enk-500c {
            width: 14.5rem; }
          .p-micro-grinder .model .c-table03 td figure img.enk-500t {
            width: 14.5rem; }
      .p-micro-grinder .model .c-table03 td span {
        position: relative; }
        .p-micro-grinder .model .c-table03 td span figure {
          position: absolute;
          top: -0.2rem;
          left: -2.5rem;
          width: 2rem;
          height: 3rem; }
        .p-micro-grinder .model .c-table03 td span.item {
          display: block;
          padding-left: 2rem; }
          .en .p-micro-grinder .model .c-table03 td span.item {
            padding-left: 1.5rem; }
          .p-micro-grinder .model .c-table03 td span.item::before {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1.6rem;
            color: #1a2e56;
            content: "■"; }
            .en .p-micro-grinder .model .c-table03 td span.item::before {
              content: "•"; }
    .p-micro-grinder .model .c-table03 .p-2 {
      padding: 1.5rem 3rem; }
    .p-micro-grinder .model .c-table03 .first {
      width: 16rem;
      padding: 1.8rem 1rem 1.8rem 2rem;
      text-align: left; }
      .en .p-micro-grinder .model .c-table03 .first.el_02 {
        width: 23rem; }
    .p-micro-grinder .model .c-table03 .p-l-5 {
      padding-left: 5rem; }
    .p-micro-grinder .model .c-table03 .w-50 {
      width: 50rem; } }

.p-micro-grinder .model .c-table03-sp {
  display: none; }
  .p-micro-grinder .model .c-table03-sp.is-active {
    display: block; }
  .p-micro-grinder .model .c-table03-sp table {
    width: 100%;
    margin: 6rem auto;
    font-size: 1.5rem;
    border-spacing: 0;
    border-collapse: separate; }
  .p-micro-grinder .model .c-table03-sp th {
    width: 100%;
    color: #fff;
    background-color: #2c529f;
    border: 1px solid #c7ced3; }
    .p-micro-grinder .model .c-table03-sp th:first-child {
      background-color: #fff;
      border: none; }
  .p-micro-grinder .model .c-table03-sp .c-f {
    color: #fff;
    background-color: #2c529f; }
  .p-micro-grinder .model .c-table03-sp td {
    min-width: 9rem;
    padding: 2rem;
    text-align: center;
    border: 1px solid #c7ced3; }
    .p-micro-grinder .model .c-table03-sp td figure {
      display: flex;
      align-items: center;
      justify-content: center; }
      .p-micro-grinder .model .c-table03-sp td figure img {
        margin: 0 auto; }
        .p-micro-grinder .model .c-table03-sp td figure img.model_basic {
          width: 15.4rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.model_highend {
          width: 20.8rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.model_sheenus {
          width: 18.2rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.model_sonic {
          width: 18.5rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.model_detail01 {
          width: 28.3rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.model_detail02 {
          width: 30.4rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.enk-410s {
          width: 14.5rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.enk-250t {
          width: 14.5rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.enk-500c {
          width: 14.5rem; }
        .p-micro-grinder .model .c-table03-sp td figure img.enk-500t {
          width: 14.5rem; }
    .p-micro-grinder .model .c-table03-sp td.price {
      color: #2c529f; }
    .p-micro-grinder .model .c-table03-sp td span {
      position: relative; }
      .p-micro-grinder .model .c-table03-sp td span figure {
        position: absolute;
        top: -0.2rem;
        left: -2.5rem;
        width: 2rem;
        height: 3rem; }
      .p-micro-grinder .model .c-table03-sp td span.item {
        display: block;
        padding-left: 2rem; }
        .p-micro-grinder .model .c-table03-sp td span.item::before {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 1.6rem;
          color: #1a2e56;
          content: "■"; }
          .en .p-micro-grinder .model .c-table03-sp td span.item::before {
            content: "●"; }
  .p-micro-grinder .model .c-table03-sp .p-2 {
    padding: 1.5rem 3rem; }
  .p-micro-grinder .model .c-table03-sp .first {
    width: 100%;
    padding: 1.1rem 1rem 0.6rem 2rem;
    text-align: center;
    background-color: #edeff4; }
  .p-micro-grinder .model .c-table03-sp .p-l-5 {
    padding-left: 4rem;
    text-align: left; }
  .p-micro-grinder .model .c-table03-sp .w-50 {
    width: 50rem; }

.p-micro-grinder .model .model-btn {
  display: flex;
  width: 70rem;
  margin: 5rem auto 0; }
  @media (--mobile) {
    .p-micro-grinder .model .model-btn {
      display: block;
      width: 100%;
      margin: 3rem auto 0; } }
  .p-micro-grinder .model .model-btn .u-m-c {
    width: 32rem;
    height: 7rem;
    font-size: 1.6rem; }
    @media (--mobile) {
      .p-micro-grinder .model .model-btn .u-m-c {
        width: 100%;
        margin: 2rem auto 0; } }

.p-micro-grinder .model .c-btn12 a,
.p-micro-grinder .model .c-btn12 button {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  width: 38rem;
  height: 10rem;
  padding: 0 4rem 0 2rem;
  margin-right: 2rem;
  font-size: 2.2rem; }
  @media (--mobile) {
    .p-micro-grinder .model .c-btn12 a,
    .p-micro-grinder .model .c-btn12 button {
      width: 80%;
      height: 8rem;
      padding: 2rem;
      font-size: 2rem; } }
  .p-micro-grinder .model .c-btn12 a svg,
  .p-micro-grinder .model .c-btn12 button svg {
    right: 2rem; }
    @media (--mobile) {
      .p-micro-grinder .model .c-btn12 a svg,
      .p-micro-grinder .model .c-btn12 button svg {
        right: 2rem; } }

.p-micro-grinder .model .c-btn12 .c-btn-hover04 a,
.p-micro-grinder .model .c-btn12 .c-btn-hover04 button {
  overflow: hidden; }
  .p-micro-grinder .model .c-btn12 .c-btn-hover04 a::before,
  .p-micro-grinder .model .c-btn12 .c-btn-hover04 button::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    color: #fff;
    content: "";
    background-color: #0058a2;
    opacity: 0;
    transition: 0.3s; }
  .p-micro-grinder .model .c-btn12 .c-btn-hover04 a svg,
  .p-micro-grinder .model .c-btn12 .c-btn-hover04 button svg {
    transition: 0.3s all; }
  @media (--large) {
    .p-micro-grinder .model .c-btn12 .c-btn-hover04 a:hover svg,
    .p-micro-grinder .model .c-btn12 .c-btn-hover04 button:hover svg {
      right: 2rem; } }
  @media (--large) {
    .p-micro-grinder .model .c-btn12 .c-btn-hover04 a:hover::before,
    .p-micro-grinder .model .c-btn12 .c-btn-hover04 button:hover::before {
      left: 0;
      opacity: 1; } }

.p-micro-grinder .price {
  color: #1a2e56 !important; }

@media (--mobile) {
  .p-moter_spindle .c-product-mv {
    padding-bottom: 7rem; } }

.p-moter_spindle .c-product-mv__left {
  width: 56rem; }
  @media (--mobile) {
    .p-moter_spindle .c-product-mv__left {
      width: inherit; } }

.p-moter_spindle .c-product-mv-mTxt {
  font-size: 1.6rem; }

.p-moter_spindle .c-product-mv-sTxt {
  font-size: 1.6rem; }

@media (--large) {
  .p-moter_spindle .c-product-mv__pImg {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    transform: translateY(-50%); } }

.p-moter_spindle-list01 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; }
  @media (--mobile) {
    .p-moter_spindle-list01 {
      display: block;
      margin: 0; } }
  .p-moter_spindle-list01-el {
    position: relative;
    width: calc(100% / 2 - 2rem);
    min-height: 27rem;
    margin: 0 1rem;
    background: linear-gradient(to left, #000, #00012b); }
    @media (--mobile) {
      .p-moter_spindle-list01-el {
        width: 100%;
        height: 19rem;
        min-height: initial;
        margin: 0 0 2rem; } }
    .p-moter_spindle-list01-el a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 2rem; }
      .p-moter_spindle-list01-el a * {
        position: relative;
        z-index: 2;
        padding-left: 0.8rem;
        color: #fff; }
        @media (--mobile) {
          .p-moter_spindle-list01-el a * {
            padding-left: 0; } }
      .p-moter_spindle-list01-el a img {
        position: absolute;
        z-index: 1;
        padding-left: 0; }
        .p-moter_spindle-list01-el a img.e4000 {
          top: 2.3rem;
          right: -2.2rem;
          width: 26rem;
          transform: rotate(-90deg); }
          @media (--mobile) {
            .p-moter_spindle-list01-el a img.e4000 {
              top: 1.5rem;
              right: -1.4rem;
              width: 16rem; } }
        .p-moter_spindle-list01-el a img.e3000 {
          top: 2.9rem;
          right: -2.9rem;
          width: 26rem;
          transform: rotate(-90deg); }
          @media (--mobile) {
            .p-moter_spindle-list01-el a img.e3000 {
              top: 1.8rem;
              right: -1.7rem;
              width: 16rem; } }
        .p-moter_spindle-list01-el a img.e2000 {
          top: 0;
          right: 1rem;
          width: 14.5rem;
          transform: rotate(180deg); }
          @media (--mobile) {
            .p-moter_spindle-list01-el a img.e2000 {
              width: 12.6rem; } }
        .p-moter_spindle-list01-el a img.ispeed3 {
          top: 2.8rem;
          right: -1rem;
          width: 18rem;
          transform: rotate(-90deg); }
          @media (--mobile) {
            .p-moter_spindle-list01-el a img.ispeed3 {
              top: 2rem;
              right: -0.4rem;
              width: 14rem; } }
        .p-moter_spindle-list01-el a img.hes {
          top: 3.1rem;
          right: 0;
          width: 17rem;
          transform: rotate(-90deg); }
          @media (--large) {
            .en .p-moter_spindle-list01-el a img.hes {
              right: -2rem; } }
          @media (--mobile) {
            .p-moter_spindle-list01-el a img.hes {
              top: 2.8rem;
              width: 15rem; } }
      .p-moter_spindle-list01-el a > span {
        display: inline-block;
        padding: 0 1rem;
        font-size: 1.1rem;
        color: #c7ced3;
        border: 1px solid #c7ced3;
        border-radius: 9999px; }
        .en .p-moter_spindle-list01-el a > span {
          padding: 0.5rem 1rem;
          line-height: 1.2; }
        @media (--mobile) {
          .p-moter_spindle-list01-el a > span {
            top: -0.8rem;
            width: 61%;
            font-size: 1rem; } }
      .p-moter_spindle-list01-el a h3 {
        margin-top: 1rem;
        font-size: 3.6rem;
        font-weight: normal;
        color: #fff; }
        @media (--mobile) {
          .p-moter_spindle-list01-el a h3 {
            margin-top: -1rem;
            font-size: 2.8rem; } }
      .p-moter_spindle-list01-el a h4 {
        margin-top: 0.5rem;
        font-size: 1.6rem;
        font-weight: lighter;
        opacity: 0.9; }
        @media (--mobile) {
          .p-moter_spindle-list01-el a h4 {
            margin-top: -0.5rem; } }
      .p-moter_spindle-list01-el a h5 {
        margin-top: 0.4rem;
        font-size: 1.2rem;
        font-weight: lighter;
        opacity: 0.9; }
        @media (--mobile) {
          .p-moter_spindle-list01-el a h5 {
            margin-top: 0; } }
      .p-moter_spindle-list01-el a p {
        margin-top: 1.6rem;
        font-size: 1.1rem;
        letter-spacing: 0;
        opacity: 0.7; }
        @media (--mobile) {
          .p-moter_spindle-list01-el a p {
            margin-top: 0;
            font-size: 1rem; } }
        .p-moter_spindle-list01-el a p > span {
          padding-left: 0;
          font-size: 70%;
          vertical-align: top; }
    .p-moter_spindle-list01-el:nth-of-type(n + 3) {
      width: calc(100% / 3 - 2rem);
      min-height: 23rem;
      margin-top: 2rem; }
      @media (--mobile) {
        .p-moter_spindle-list01-el:nth-of-type(n + 3) {
          width: 100%;
          height: 18rem;
          min-height: initial;
          margin-bottom: 2rem; } }
      .en .p-moter_spindle-list01-el:nth-of-type(n + 3) {
        min-height: 24rem; }
        .en .p-moter_spindle-list01-el:nth-of-type(n + 3) span {
          width: 55%; }
          @media (--mobile) {
            .en .p-moter_spindle-list01-el:nth-of-type(n + 3) span {
              width: 61%; } }
      .p-moter_spindle-list01-el:nth-of-type(n + 3) h3 {
        margin-top: 0.5rem;
        font-size: 2.6rem; }
        @media (--mobile) {
          .p-moter_spindle-list01-el:nth-of-type(n + 3) h3 {
            margin-top: -1rem; } }
      .p-moter_spindle-list01-el:nth-of-type(n + 3) h4 {
        margin-top: 0;
        font-size: 1.6rem;
        font-weight: lighter;
        opacity: 0.9; }
        @media (--mobile) {
          .p-moter_spindle-list01-el:nth-of-type(n + 3) h4 {
            margin-top: -0.5rem; } }
        .en .p-moter_spindle-list01-el:nth-of-type(n + 3) h4 {
          width: 65%;
          line-height: 1.3; }
      .p-moter_spindle-list01-el:nth-of-type(n + 3) h5 {
        margin-top: 0.4rem;
        font-size: 1.2rem;
        font-weight: lighter;
        opacity: 0.9; }
        @media (--mobile) {
          .p-moter_spindle-list01-el:nth-of-type(n + 3) h5 {
            margin-top: 0; } }
        .en .p-moter_spindle-list01-el:nth-of-type(n + 3) h5 {
          width: 65%;
          line-height: 1.3; }
        @media (--large) {
          .en .p-moter_spindle-list01-el:nth-of-type(n + 3) h5.hes {
            width: 70%; } }
      .p-moter_spindle-list01-el:nth-of-type(n + 3) p {
        margin-top: 0.5rem;
        font-size: 1.1rem;
        opacity: 0.7; }
        @media (--mobile) {
          .p-moter_spindle-list01-el:nth-of-type(n + 3) p {
            margin-top: 0;
            font-size: 1rem; } }
    .en .p-moter_spindle-list01-el:nth-of-type(4) span {
      width: 41%; }
      @media (--mobile) {
        .en .p-moter_spindle-list01-el:nth-of-type(4) span {
          width: 46%; } }

@media (--mobile) {
  .p-moter_spindle .h-16 {
    height: 100%;
    min-height: 16rem; } }

@media (--mobile) {
  .p-moter_spindle .h-20 {
    height: 100%;
    min-height: 20rem; } }

.p-moter_spindle-list02 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; }
  @media (--mobile) {
    .p-moter_spindle-list02 {
      margin: 0; } }
  .p-moter_spindle-list02-el {
    position: relative;
    width: calc(100% / 3 - 2rem);
    min-height: 18rem;
    margin: 0 1rem;
    background: linear-gradient(to left, #000, #00012b); }
    @media (--mobile) {
      .p-moter_spindle-list02-el {
        width: 100%;
        margin: 0 0 2rem; } }
    .p-moter_spindle-list02-el a {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      padding: 2rem; }
      .p-moter_spindle-list02-el a * {
        position: relative;
        z-index: 2;
        padding-left: 0.8rem;
        color: #fff; }
      .p-moter_spindle-list02-el a img {
        position: absolute;
        z-index: 1;
        padding-left: 0; }
        .p-moter_spindle-list02-el a img.air-speed {
          top: 0;
          right: 0;
          width: 14rem;
          transform: rotate(-180deg); }
          @media (--mobile) {
            .p-moter_spindle-list02-el a img.air-speed {
              right: 1.5rem;
              width: 12rem; } }
        .p-moter_spindle-list02-el a img.xpeed {
          top: 0;
          right: 2.4rem;
          width: 9.8rem;
          transform: rotate(-180deg); }
          @media (--mobile) {
            .p-moter_spindle-list02-el a img.xpeed {
              top: 0;
              right: 3.2rem;
              width: 8.8rem; } }
        .p-moter_spindle-list02-el a img.abt {
          top: 0;
          right: 2.8rem;
          width: 7.5rem;
          transform: rotate(-180deg); }
          @media (--mobile) {
            .p-moter_spindle-list02-el a img.abt {
              top: 0;
              right: 4rem;
              width: 6.8rem; } }
        .p-moter_spindle-list02-el a img.hts {
          top: 0;
          right: 2rem;
          width: 10.7rem;
          transform: rotate(-180deg); }
          .en .p-moter_spindle-list02-el a img.hts {
            width: 9.7rem; }
          @media (--mobile) {
            .p-moter_spindle-list02-el a img.hts {
              top: 0;
              right: 2.5rem;
              width: 9.7rem; } }
        .p-moter_spindle-list02-el a img.planet {
          top: 0;
          right: 1.5rem;
          width: 10.4rem;
          transform: rotate(-180deg); }
          .en .p-moter_spindle-list02-el a img.planet {
            width: 9.4rem; }
          @media (--mobile) {
            .p-moter_spindle-list02-el a img.planet {
              top: 0;
              right: 2.4rem;
              width: 8.4rem; } }
      .p-moter_spindle-list02-el a > span {
        display: inline-block;
        padding: 0 1rem;
        font-size: 1.1rem;
        color: #c7ced3;
        border: 1px solid #c7ced3;
        border-radius: 9999px; }
        .en .p-moter_spindle-list02-el a > span {
          width: 55%;
          padding: 0.5rem 1rem;
          line-height: 1.2; }
          @media (--mobile) {
            .en .p-moter_spindle-list02-el a > span {
              position: absolute;
              top: 1.5rem; } }
      .p-moter_spindle-list02-el a h3 {
        position: absolute;
        top: 50%;
        font-size: 2.6rem;
        font-weight: normal;
        line-height: 1.5;
        color: #fff;
        transform: translateY(-50%) translateY(-1rem); }
      .p-moter_spindle-list02-el a h5 {
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: lighter;
        opacity: 0.9; }
        @media (--mobile) {
          .p-moter_spindle-list02-el a h5 {
            font-size: 1.6rem; } }
      .p-moter_spindle-list02-el a p {
        margin-top: 1.2rem;
        font-size: 1.1rem;
        letter-spacing: 0;
        opacity: 0.7; }
        .p-moter_spindle-list02-el a p.desc {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateY(3rem); }
          .p-moter_spindle-list02-el a p.desc > span {
            padding-left: 0;
            font-size: 70%;
            vertical-align: top; }
    .p-moter_spindle-list02-el:nth-of-type(n + 4) {
      margin-top: 2rem; }
      @media (--mobile) {
        .p-moter_spindle-list02-el:nth-of-type(n + 4) {
          margin-top: 0; } }
      .en .p-moter_spindle-list02-el:nth-of-type(n + 4) span {
        width: 70%; }
        @media (--mobile) {
          .en .p-moter_spindle-list02-el:nth-of-type(n + 4) span {
            width: 55%; } }
    .en .p-moter_spindle-list02-el:nth-of-type(2) a span, .en .p-moter_spindle-list02-el:nth-of-type(3) a span {
      width: 25rem; }
      @media (--mobile) {
        .en .p-moter_spindle-list02-el:nth-of-type(2) a span, .en .p-moter_spindle-list02-el:nth-of-type(3) a span {
          width: 52%; } }
    .en .p-moter_spindle-list02-el:nth-of-type(5) a span {
      width: 21rem; }
      @media (--mobile) {
        .en .p-moter_spindle-list02-el:nth-of-type(5) a span {
          width: 60%; } }

.p-moter_spindle .about figure {
  width: 100%;
  height: 34rem;
  margin-top: 4rem; }
  @media (--mobile) {
    .p-moter_spindle .about figure {
      height: 22rem; } }
  .p-moter_spindle .about figure img {
    width: 100%;
    height: 100%; }

.p-moter_spindle .about p {
  letter-spacing: 0.1em; }

.p-moter_spindle .feature .c-product-side {
  display: flex;
  height: 27rem;
  background: linear-gradient(to right, #00012b, #000); }
  @media (--mobile) {
    .p-moter_spindle .feature .c-product-side {
      display: block;
      width: 100vw;
      height: 100%;
      margin: 0 calc(50% - 50vw);
      margin-bottom: 0.2rem; } }
  @media (--large) {
    .en .p-moter_spindle .feature .c-product-side {
      position: relative;
      display: block; } }
  .p-moter_spindle .feature .c-product-side__left {
    color: #fff; }
    @media (--mobile) {
      .p-moter_spindle .feature .c-product-side__left {
        width: 33rem;
        padding-top: 5rem;
        margin: 0 auto; } }
    @media (--large) {
      .p-moter_spindle .feature .c-product-side__left {
        display: flex;
        align-items: center;
        width: 50%;
        height: 23rem;
        padding-left: 8rem; }
        .en .p-moter_spindle .feature .c-product-side__left {
          position: absolute;
          top: 50%;
          left: 4rem;
          display: block;
          width: 70%;
          height: auto;
          padding-left: 0;
          transform: translateY(-50%); } }
    .p-moter_spindle .feature .c-product-side__left h4 {
      font-size: 3.2rem;
      font-weight: 500;
      color: #fff;
      letter-spacing: 0.1em; }
      @media (--mobile) {
        .p-moter_spindle .feature .c-product-side__left h4 {
          font-size: 2.6rem; } }
    .p-moter_spindle .feature .c-product-side__left ul li {
      position: relative;
      padding-left: 1rem; }
      .p-moter_spindle .feature .c-product-side__left ul li::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "・"; }
    .p-moter_spindle .feature .c-product-side__left p {
      line-height: 1.8; }
    @media (--mobile) {
      .p-moter_spindle .feature .c-product-side__left .c-btn09 {
        margin-bottom: 2rem; } }
    .p-moter_spindle .feature .c-product-side__left .c-btn09 a {
      width: 20rem;
      height: 5rem;
      border: 1px solid #1a2e56; }
      @media (--large) {
        .p-moter_spindle .feature .c-product-side__left .c-btn09 a {
          padding-right: 0; } }
      .p-moter_spindle .feature .c-product-side__left .c-btn09 a span::after {
        right: -3rem; }
  @media (--large) {
    .p-moter_spindle .feature .c-product-side__right {
      width: 50%; }
      .en .p-moter_spindle .feature .c-product-side__right {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 40%; } }
  @media (--mobile) {
    .p-moter_spindle .feature .c-product-side__right {
      margin-top: 4rem; } }
  @media (--mobile) {
    .p-moter_spindle .feature .c-product-side__right picture {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%; } }
  .p-moter_spindle .feature .c-product-side__right picture img {
    width: 100%;
    height: 100%; }

@media (--mobile) {
  .p-moter_spindle .comparison .c-table03 table {
    width: 60rem; } }

.p-moter_spindle .comparison .c-table03 table tr {
  width: 80%;
  color: #fff; }
  @media (--mobile) {
    .p-moter_spindle .comparison .c-table03 table tr {
      width: 100%; } }

.p-moter_spindle .comparison .c-table03 table th {
  font-size: 1.5rem;
  font-weight: 700; }

.p-moter_spindle .comparison .c-table03 table td {
  position: relative;
  padding: 2.7rem 1.6rem;
  font-size: 1.3rem;
  color: #333;
  text-align: left; }
  @media (--tablet) {
    .p-moter_spindle .comparison .c-table03 table td {
      padding: 2.7rem 1.4rem; } }
  @media (--mobile) {
    .p-moter_spindle .comparison .c-table03 table td {
      padding: 2rem 1rem 1.7rem; } }
  .p-moter_spindle .comparison .c-table03 table td span {
    position: absolute;
    top: 50%;
    left: 8%;
    width: 3.8rem;
    height: 3.3rem;
    transform: translateY(-50%); }
    @media (--mobile) {
      .p-moter_spindle .comparison .c-table03 table td span {
        left: 5%;
        width: 3.8rem;
        height: 3.3rem; } }
    .p-moter_spindle .comparison .c-table03 table td span img {
      width: 100%;
      height: 100%; }
  .p-moter_spindle .comparison .c-table03 table td .cross {
    left: 6%;
    width: 5rem;
    height: 5rem; }
    @media (--mobile) {
      .p-moter_spindle .comparison .c-table03 table td .cross {
        left: 3%; } }

.p-moter_spindle .comparison .c-table03 table .fixed01 {
  width: 10%; }

.p-moter_spindle .comparison .c-table03 table .first {
  position: sticky;
  z-index: 1;
  width: 16rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a2e56; }
  @media (--mobile) {
    .p-moter_spindle .comparison .c-table03 table .first {
      width: 10%; } }

.p-moter_spindle .comparison .c-table03 table .left,
.p-moter_spindle .comparison .c-table03 table .right {
  width: 47rem; }

.p-moter_spindle .comparison .c-table03 table .left {
  position: relative; }
  @media (--mobile) {
    .p-moter_spindle .comparison .c-table03 table .left {
      width: 10rem; } }
  .p-moter_spindle .comparison .c-table03 table .left::after {
    position: absolute;
    top: 50%;
    right: 8%;
    display: inline-block;
    width: 3.8rem;
    height: 3.8rem;
    vertical-align: middle;
    content: "";
    background-image: url(../images/page/moter_spindle/ms-circle.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateY(-50%); }

.p-moter_spindle .comparison .c-table03 table .right {
  position: relative;
  padding: 2.7rem 6rem 2.7rem 9.5rem; }
  @media (--mobile) {
    .p-moter_spindle .comparison .c-table03 table .right {
      width: 10rem;
      padding: 1rem 2rem 1rem 6.5rem; } }

.p-moter_spindle .comparison .c-table03 table .p-2 {
  padding: 2rem 8rem 2rem 2.8rem; }
  @media (--mobile) {
    .p-moter_spindle .comparison .c-table03 table .p-2 {
      padding: 1rem 6.5rem 1rem 1rem; } }

.p-moter_spindle .comparison .c-table03 table .p-3 {
  padding: 2rem 7rem 2rem 9.5rem; }
  @media (--mobile) {
    .p-moter_spindle .comparison .c-table03 table .p-3 {
      padding: 1rem 2rem 1rem 6.5rem; } }

.c-modal.moter_spindle {
  width: 100rem; }
  @media (--mobile) {
    .c-modal.moter_spindle {
      width: 90%; } }

@media (--mobile) {
  .p-network {
    overflow: hidden; } }

.p-network__map {
  position: relative;
  margin-top: 9rem;
  margin-bottom: 6.5rem; }
  .en .p-network__map {
    margin-top: 4rem; }
  .p-network__map .map-c {
    position: absolute; }
    @media (--mobile) {
      .p-network__map .map-c {
        width: 9rem;
        height: 2rem;
        transform: scale(0.7); }
        .p-network__map .map-c p {
          width: 100%;
          height: 100%;
          font-size: 1rem; } }
  .p-network__map .map-link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
    .p-network__map .map-link p {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12.8rem;
      font-size: 1.6rem;
      color: #fff;
      background-color: #1a2e56;
      border-radius: 3rem; }
    @media (--large) {
      .p-network__map .map-link:hover .ja-img,
      .p-network__map .map-link:hover .east-img,
      .p-network__map .map-link:hover .es-img,
      .p-network__map .map-link:hover .oceania-img,
      .p-network__map .map-link:hover .africa-img,
      .p-network__map .map-link:hover .europa-img,
      .p-network__map .map-link:hover .usa-img {
        opacity: 1; } }
  .p-network__map .map01 {
    top: 44%;
    right: 3rem;
    z-index: 20;
    width: 16rem;
    height: 8.4rem;
    transform: translateY(-50%); }
    @media (--mobile) {
      .p-network__map .map01 {
        right: 0; } }
    .p-network__map .map01 p {
      right: 0;
      bottom: 0; }
    .p-network__map .map01 .ja-img {
      position: absolute;
      top: -0.4rem;
      left: -1.5rem;
      width: 6.8rem;
      opacity: 0;
      transition: 0.3s; }
  .p-network__map .map02 {
    top: 0;
    right: 0;
    width: 46rem;
    height: 25rem; }
    @media (--mobile) {
      .p-network__map .map02 {
        top: -2rem;
        right: -3rem; } }
    .p-network__map .map02 p {
      top: 0;
      right: 10rem; }
    .p-network__map .map02 .east-img {
      position: absolute;
      top: 1.7rem;
      right: -0.4rem;
      width: 50.9rem;
      opacity: 0;
      transition: 0.3s; }
  .p-network__map .map03 {
    right: 0;
    bottom: 1rem;
    width: 28rem;
    height: 13rem; }
    @media (--mobile) {
      .p-network__map .map03 {
        right: 5rem;
        bottom: -1rem; } }
    .p-network__map .map03 p {
      bottom: 0;
      left: 0; }
    .p-network__map .map03 .oceania-img {
      position: absolute;
      top: -6.5rem;
      right: 4rem;
      width: 22.2rem;
      opacity: 0;
      transition: 0.3s; }
  .p-network__map .map04 {
    right: 18.4rem;
    bottom: 14.4rem;
    width: 24rem;
    height: 16rem; }
    @media (--mobile) {
      .p-network__map .map04 {
        right: 10rem; } }
    .p-network__map .map04 p {
      top: 14.5rem;
      left: 0.5rem; }
    .p-network__map .map04 .es-img {
      position: absolute;
      top: -2.6rem;
      right: 1.5rem;
      width: 22.2rem;
      opacity: 0;
      transition: 0.3s; }
  .p-network__map .map05 {
    bottom: 8.9rem;
    left: 51.1%;
    width: 30rem;
    height: 25rem;
    transform: translateX(-50%); }
    @media (--mobile) {
      .p-network__map .map05 {
        bottom: 1rem;
        left: 33%; } }
    .p-network__map .map05 p {
      bottom: 0;
      left: 0.5rem; }
    .p-network__map .map05 .africa-img {
      position: absolute;
      top: -2.7rem;
      right: -1.1rem;
      width: 27.1rem;
      opacity: 0;
      transition: 0.3s; }
  .p-network__map .map06 {
    top: 14rem;
    left: 29%;
    z-index: 20;
    width: 34rem;
    height: 24rem;
    transform: translateY(-50%); }
    @media (--mobile) {
      .p-network__map .map06 {
        top: 6rem; } }
    .p-network__map .map06 p {
      bottom: 0;
      left: 0.5rem; }
    .p-network__map .map06 .europa-img {
      position: absolute;
      top: 0.2rem;
      right: -0.6rem;
      width: 17.2rem;
      opacity: 0;
      transition: 0.3s; }
  .p-network__map .map07 {
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 50rem; }
    @media (--mobile) {
      .p-network__map .map07 {
        top: -12rem;
        bottom: inherit;
        left: -2.5rem; } }
    .p-network__map .map07 p {
      top: 30rem;
      left: 6rem; }
    .p-network__map .map07 .usa-img {
      position: absolute;
      top: 0.2rem;
      right: -1.6rem;
      width: 51.5rem;
      opacity: 0;
      transition: 0.3s; }

.p-network__inner {
  margin-bottom: 15rem; }

.p-network__continent:nth-child(n + 2) {
  margin-top: 8rem; }

@media (--large) {
  .en .p-network__continent dt {
    width: 22% !important; } }

.p-network__country {
  display: flex;
  flex-direction: column; }
  @media (--large) {
    .en .p-network__country {
      width: 22% !important; } }
  .p-network__country.noflex-sp {
    display: block; }
  .p-network__country span {
    display: block; }
    @media (--mobile) {
      .p-network__country span {
        display: inline-block; } }
  @media (--mobile) {
    .p-network__country.country-row__sp {
      flex-direction: row;
      flex-wrap: wrap; } }

.p-network__base01 {
  display: flex;
  justify-content: space-between; }
  @media (--large) {
    .en .p-network__base01 {
      width: 78% !important; } }
  @media (--mobile) {
    .p-network__base01 {
      flex-direction: column; } }

.p-network__base02 {
  display: flex;
  flex-direction: column;
  padding: 2rem 0 !important; }
  @media (--large) {
    .en .p-network__base02 {
      width: 78% !important; } }

.p-network__item {
  display: flex;
  justify-content: space-between;
  padding: 0 3.5rem; }
  @media (--mobile) {
    .p-network__item {
      display: block;
      padding: 0 2rem; } }
  .p-network__item:nth-child(n + 2) {
    border-top: 1px solid #c7ced3; }

.p-network__addr02 {
  display: flex;
  flex-direction: column; }
  .p-network__addr02 span.ttl {
    font-weight: bold; }
  .p-network__addr02 span.att {
    color: #2c529f; }

@media (--mobile) {
  .p-network__tel01 {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem; } }

.p-network__tel01 span:nth-child(n + 2) {
  margin-left: 2.5rem; }
  @media (--mobile) {
    .p-network__tel01 span:nth-child(n + 2) {
      margin-left: 0; } }

.p-network__tel02 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center; }
  @media (--mobile) {
    .p-network__tel02 {
      align-items: flex-start;
      margin-top: 1.5rem; } }

.p-network__sns {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem; }
  @media (--mobile) {
    .p-network__sns {
      flex-direction: column;
      padding-bottom: 1rem; } }
  .p-network__sns ul {
    display: flex;
    height: 2.4rem;
    padding-top: 0.5rem; }
    .p-network__sns ul li {
      height: 2.4rem; }
      .p-network__sns ul li:nth-child(n + 2) {
        margin-left: 1rem; }
      .p-network__sns ul li a {
        transition: 0.3s all; }
        .p-network__sns ul li a:hover {
          opacity: 0.6; }
  @media (--mobile) {
    .p-network__sns .link-cell {
      display: flex; } }
  .p-network__sns .link {
    margin-left: 2rem; }
    @media (--mobile) {
      .p-network__sns .link {
        margin-top: 2rem;
        margin-left: 0; } }
    .p-network__sns .link:nth-of-type(n + 2) {
      margin-top: 1rem; }
      @media (--mobile) {
        .p-network__sns .link:nth-of-type(n + 2) {
          margin-top: 2rem;
          margin-left: 1rem; } }
    .p-network__sns .link a {
      padding-bottom: 0.5rem;
      color: #384eaa;
      border-bottom: 0.1rem solid #384eaa;
      transition: 0.3s all; }
      .p-network__sns .link a:hover {
        opacity: 0.6; }

.p-planet .c-product-mv {
  padding-bottom: 6rem; }

.p-planet .c-product-spec {
  padding-top: 9rem; }
  @media (--mobile) {
    .p-planet .c-product-spec {
      padding-top: 4rem; } }

.p-planet .c-product-spec-img {
  width: 42.9rem; }
  @media (--mobile) {
    .p-planet .c-product-spec-img {
      width: 100%; } }

.p-presto2 .c-product-mv {
  padding-bottom: 6rem; }

.p-presto2 .c-product-spec {
  padding-top: 9rem; }

.p-presto2 .c-product-spec-img {
  width: 83.8rem; }
  @media (--mobile) {
    .p-presto2 .c-product-spec-img {
      width: 100%; } }

.p-privacy {
  margin-bottom: 14rem; }
  @media (--mobile) {
    .p-privacy {
      margin-bottom: 7rem; } }

.p-sProduct .c-article li a span {
  position: relative; }
  .p-sProduct .c-article li a span.is-movie::after {
    position: absolute;
    top: 50%;
    right: -4rem;
    width: 2.5rem;
    height: 1.9rem;
    content: "";
    background: url(../images/common/ic_movie.png);
    background-size: cover;
    transform: translateY(-50%); }
    @media (--mobile) {
      .p-sProduct .c-article li a span.is-movie::after {
        top: inherit;
        bottom: -1rem; } }

@media (--large) {
  .p-rental-user .l-btnWrap {
    display: flex;
    align-items: flex-end; } }

.p-rental-user .l-btnWrap .c-btn12 a {
  width: 40rem;
  height: 7rem; }
  @media (--mobile) {
    .p-rental-user .l-btnWrap .c-btn12 a {
      width: 100%;
      height: 6rem;
      font-size: 1.8rem; } }

.p-rental-user .l-btnWrap p {
  margin-left: 2rem;
  font-size: 1.5rem;
  color: #8491aa; }
  @media (--mobile) {
    .p-rental-user .l-btnWrap p {
      margin-top: 1rem;
      margin-left: 0; } }

@media (--large) {
  .p-rental-user .l-btnWrap02 {
    display: flex; } }

@media (--large) {
  .p-rental-user .l-btnWrap02 .c-btn12 a {
    width: 35rem;
    height: 6rem; } }

@media (--mobile) {
  .p-rental-user .l-btnWrap02 .c-btn12 a {
    width: 100%;
    height: 6rem;
    font-size: 1.8rem; } }

@media (--large) {
  .p-rental-user .l-btnWrap02 .c-btn12:nth-of-type(n + 2) {
    margin-left: 3rem; } }

@media (--mobile) {
  .p-rental-user .l-btnWrap02 .c-btn12:nth-of-type(n + 2) {
    margin-top: 2rem; } }

.p-rental-user .l-product dd {
  width: 27rem; }
  @media (--mobile) {
    .p-rental-user .l-product dd {
      width: 100%; } }
  .p-rental-user .l-product dd input {
    width: 100%; }

.p-rental-user .l-product--separate {
  display: flex;
  flex-wrap: wrap; }
  @media (--mobile) {
    .p-rental-user .l-product--separate {
      justify-content: space-between; } }
  @media (--large) {
    .p-rental-user .l-product--separate {
      margin: 0 -2.2rem; }
      .p-rental-user .l-product--separate .c-f-txt01 {
        margin: 0 2.2rem; }
        .p-rental-user .l-product--separate .c-f-txt01:nth-of-type(n + 4) {
          margin-top: 3rem; } }
  @media (--mobile) {
    .p-rental-user .l-product--separate .c-f-txt01 {
      width: 46%; }
      .p-rental-user .l-product--separate .c-f-txt01:nth-of-type(n + 3) {
        margin-top: 2rem; } }

.p-rental-user .c-btn01 button,
.p-rental-user .c-btn01 a {
  position: relative;
  height: 8rem;
  font-size: 1.8rem; }
  .p-rental-user .c-btn01 button::after,
  .p-rental-user .c-btn01 a::after {
    position: absolute;
    top: 50%;
    right: 4rem;
    width: 4rem;
    height: 1.3rem;
    content: "";
    background: url(../images/common/ic_arrow02.svg) no-repeat center center/contain;
    transition: 0.3s;
    transform: translateY(-50%) translateY(-0.2rem); }
  @media (--large) {
    .p-rental-user .c-btn01 button:hover::after,
    .p-rental-user .c-btn01 a:hover::after {
      right: 2.5rem; } }

.p-rotus .c-product-mv {
  padding-bottom: 6rem; }

.p-rotus .c-product-spec {
  padding-top: 9rem;
  padding-bottom: 0; }
  @media (--mobile) {
    .p-rotus .c-product-spec {
      padding-top: 4rem; } }

.p-rotus .c-product-spec-img {
  width: 73.2rem; }
  @media (--mobile) {
    .p-rotus .c-product-spec-img {
      width: 100%; } }

.p-sample-shop .l-sample__flex {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 0 !important; }
  @media (--mobile) {
    .p-sample-shop .l-sample__flex {
      flex-direction: column;
      align-items: flex-start; } }
  .p-sample-shop .l-sample__flex .error {
    position: absolute;
    bottom: -2.4rem; }
    @media (--mobile) {
      .p-sample-shop .l-sample__flex .error {
        right: 0; } }

.p-sample-shop .l-sample__content {
  justify-content: space-between; }
  .p-sample-shop .l-sample__content:nth-child(n + 2) {
    margin-top: 2.4rem !important; }
    @media (--mobile) {
      .p-sample-shop .l-sample__content:nth-child(n + 2) {
        margin-top: 3rem !important; } }
  .p-sample-shop .l-sample__content.is-hidden {
    margin-top: 0 !important; }
    @media (--mobile) {
      .p-sample-shop .l-sample__content.is-hidden {
        margin-top: 0 !important; } }

@media (--mobile) {
  .p-sample-shop .l-sample__code, .p-sample-shop .l-sample__item {
    margin-top: 0.5rem !important; }
    .p-sample-shop .l-sample__code input, .p-sample-shop .l-sample__item input {
      height: 4rem; } }

.p-sample-shop .l-sample__code span, .p-sample-shop .l-sample__item span {
  margin-top: 0 !important;
  margin-right: 2rem; }

.p-sample-shop .l-sample__code input {
  width: 19rem !important; }
  @media (--mobile) {
    .p-sample-shop .l-sample__code input {
      width: 100% !important; } }

.p-sample-shop .l-sample__item {
  margin-left: 4rem; }
  @media (--mobile) {
    .p-sample-shop .l-sample__item {
      margin-left: 0; } }
  .p-sample-shop .l-sample__item input {
    width: 35rem !important; }

.p-sample-shop .l-sample__add {
  margin-top: 5rem; }
  .p-sample-shop .l-sample__add .c-btn10 .sample-add__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33rem;
    height: 6rem;
    font-size: 2.2rem;
    color: #1a2e56;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #1a2e56;
    transition: 0.3s; }
    .p-sample-shop .l-sample__add .c-btn10 .sample-add__btn.u-m-c {
      justify-content: center !important;
      padding: 0 !important; }
    .p-sample-shop .l-sample__add .c-btn10 .sample-add__btn svg {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%); }
      .p-sample-shop .l-sample__add .c-btn10 .sample-add__btn svg path {
        transition: 0.3s; }

.p-sample-shop .c-btn-hover04 .sample-add__btn {
  position: relative;
  z-index: 10;
  overflow: hidden; }
  .p-sample-shop .c-btn-hover04 .sample-add__btn::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #0058a2;
    opacity: 0;
    transition: 0.3s; }
  .p-sample-shop .c-btn-hover04 .sample-add__btn svg {
    transition: 0.3s all; }
  @media (--large) {
    .p-sample-shop .c-btn-hover04 .sample-add__btn:hover {
      color: #fff; }
      .p-sample-shop .c-btn-hover04 .sample-add__btn:hover span {
        color: #fff; }
        .p-sample-shop .c-btn-hover04 .sample-add__btn:hover span::after {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff; }
      .p-sample-shop .c-btn-hover04 .sample-add__btn:hover svg * {
        stroke: #fff; } }
  @media (--large) {
    .p-sample-shop .c-btn-hover04 .sample-add__btn:hover::before {
      left: 0;
      opacity: 1; }
    .p-sample-shop .c-btn-hover04 .sample-add__btn:hover::after {
      border-top: 2px solid #fff;
      border-right: 2px solid #fff; } }

.js-sample .is-hidden {
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s; }
  .js-sample .is-hidden.js-appear {
    display: flex;
    height: inherit;
    margin-top: 2.4rem !important;
    visibility: visible;
    opacity: 1; }

.mw_wp_form_confirm .c-f-cation {
  display: none; }

.mw_wp_form_confirm .c-caption {
  display: none; }

.mw_wp_form_confirm .l-sample__add {
  display: none; }

@media (--mobile) {
  .mw_wp_form_confirm .l-sample__code, .mw_wp_form_confirm .l-sample__item {
    flex-direction: row; }
    .mw_wp_form_confirm .l-sample__code span, .mw_wp_form_confirm .l-sample__item span {
      width: 8rem; } }

@media (--mobile) {
  .mw_wp_form_confirm .l-sample__flex {
    width: 100%; } }

.p-sds .s-btn_wrap {
  margin-top: 4rem; }
  @media (--large) {
    .p-sds .s-btn_wrap {
      display: flex;
      justify-content: center; } }
  @media (--large) {
    .p-sds .s-btn_wrap a {
      width: 31rem;
      padding-top: 0.5rem; }
      .en .p-sds .s-btn_wrap a {
        width: 43rem; } }
  @media (--mobile) {
    .en .p-sds .s-btn_wrap a {
      width: 100%; } }
  @media (--large) {
    .p-sds .s-btn_wrap .c-btn01:nth-of-type(n + 2) {
      margin-left: 4rem; } }
  @media (--mobile) {
    .p-sds .s-btn_wrap .c-btn01:nth-of-type(n + 2) {
      margin-top: 2rem; } }
  .p-sds .s-btn_wrap .c-btn01 span {
    line-height: 1.5; }
  .p-sds .s-btn_wrap .c-hidden {
    pointer-events: none; }
    .p-sds .s-btn_wrap .c-hidden a {
      background: #b2b2b2; }

.p-search #error {
  display: none; }

@media screen and (min-width: 768px), print {
  .p-search .c-s-search-inner__input {
    width: 58rem; } }

@media (--mobile) {
  .p-search .c-table01 table {
    width: 100rem; } }

.p-search .c-table01 table th {
  padding: 0.5rem;
  line-height: 1.2; }

.p-search .c-table01 table td {
  padding: 1rem; }

.p-sCase .s-ttlArea__top {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (--mobile) {
    .p-sCase .s-ttlArea__top {
      display: block; } }

.p-sCase .s-ttlArea__left {
  display: flex;
  align-items: center; }

.p-sCase .s-ttlArea__tag {
  padding: 0.1rem 1rem;
  font-size: 1.4rem;
  color: #fff;
  background: #1a2e56; }

.p-sCase .s-ttlArea__cat {
  margin-left: 1rem;
  font-size: 1.5rem; }

.p-sCase .s-ttlArea__right {
  margin: 0 -0.5rem; }
  @media (--mobile) {
    .p-sCase .s-ttlArea__right {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem; } }
  .p-sCase .s-ttlArea__right span {
    padding: 0.2rem 1rem;
    margin: 0.3rem 0.5rem;
    font-size: 1.4rem;
    border: 1px solid #1a2e56;
    border-radius: 9999px; }
    @media (--mobile) {
      .p-sCase .s-ttlArea__right span {
        padding: 0.2rem 0.5rem;
        font-size: 1.2rem; } }

.p-sCase .s-ttlArea__ttl {
  margin-top: 2.4rem;
  font-size: 3rem;
  font-weight: normal;
  color: #1a2e56;
  border-bottom: 0.3rem solid #1a2e56; }
  @media (--mobile) {
    .p-sCase .s-ttlArea__ttl {
      padding-bottom: 0.8rem;
      margin-top: 1rem;
      font-size: 2.6rem;
      line-height: 1.5; } }

.p-sCase .s-ttlArea__other {
  display: flex;
  align-items: center;
  margin-top: 2rem; }
  @media (--mobile) {
    .p-sCase .s-ttlArea__other {
      display: block;
      margin-top: 1rem; } }
  .p-sCase .s-ttlArea__other p {
    color: #1a2e56; }
    @media (--large) {
      .p-sCase .s-ttlArea__other p:nth-of-type(n + 2) {
        margin-left: 2.6rem; } }

.p-sCase .s-mv {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  text-align: center; }
  @media (--mobile) {
    .p-sCase .s-mv {
      margin: 5rem -3rem 0; } }
  .p-sCase .s-mv iframe,
  .p-sCase .s-mv img,
  .p-sCase .s-mv video {
    width: 85rem;
    height: 47rem;
    object-fit: cover; }
    @media (--mobile) {
      .p-sCase .s-mv iframe,
      .p-sCase .s-mv img,
      .p-sCase .s-mv video {
        width: 100%;
        height: 55vw; } }

@media (--large) {
  .p-sCase .c-table02 dl dt {
    width: 17%; }
  .p-sCase .c-table02 dl dd {
    width: 83%; } }

@media (--mobile) {
  .p-sCase .c-table03 table {
    width: 50rem; } }

.p-sCase .c-table03 table th {
  font-size: 2rem; }
  .p-sCase .c-table03 table th:nth-of-type(n + 2) {
    width: 44.7rem; }
  @media (--mobile) {
    .p-sCase .c-table03 table th {
      padding: 1rem;
      font-size: 1.8rem;
      line-height: 1.5; }
      .p-sCase .c-table03 table th:first-of-type {
        position: sticky;
        left: 0;
        width: 12rem; }
        .p-sCase .c-table03 table th:first-of-type::after {
          position: absolute;
          top: -1px;
          left: 1px;
          width: 100%;
          height: 100%;
          content: ""; } }
  .p-sCase .c-table03 table th:last-of-type {
    background: #384eaa; }

.p-sCase .c-table03 table tr td:first-of-type {
  width: 20.5rem;
  height: 7.8rem;
  font-weight: bold;
  background: #edeff4; }
  @media (--mobile) {
    .p-sCase .c-table03 table tr td:first-of-type {
      position: sticky;
      left: 0;
      height: auto;
      min-height: 7.8rem; }
      .p-sCase .c-table03 table tr td:first-of-type::before {
        position: absolute;
        top: -1px;
        left: 1px;
        width: 100%;
        height: 100%;
        content: ""; } }

.p-sCase .c-table03 table tr td:nth-of-type(n + 2) {
  text-align: center; }

@media (--mobile) {
  .p-sCase .c-table03--2row {
    overflow: hidden !important; }
    .p-sCase .c-table03--2row table {
      width: 100%; }
      .p-sCase .c-table03--2row table tr td:first-of-type {
        width: 11.5rem; } }

.p-sCase .l-btnArea01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (--mobile) {
    .p-sCase .l-btnArea01 {
      display: block; } }
  @media (--large) {
    .p-sCase .l-btnArea01 .c-btn12:nth-of-type(n + 3) {
      margin-top: 5rem; } }
  .p-sCase .l-btnArea01 a {
    width: 57rem;
    font-size: 2.4rem; }
    @media (--mobile) {
      .p-sCase .l-btnArea01 a {
        width: 100%; } }

.p-sCase .l-btnArea02 {
  display: flex; }
  @media (--mobile) {
    .p-sCase .l-btnArea02 {
      display: block; } }
  .p-sCase .l-btnArea02 div:nth-of-type(n + 2) {
    margin-left: 4rem; }
    @media (--mobile) {
      .p-sCase .l-btnArea02 div:nth-of-type(n + 2) {
        margin-top: 2rem;
        margin-left: 0; } }
  .p-sCase .l-btnArea02 div a {
    width: auto;
    height: 7rem;
    padding: 0 3rem;
    padding-left: 7rem;
    font-size: 1.6rem; }
    @media (--mobile) {
      .p-sCase .l-btnArea02 div a {
        font-size: 1.8rem; } }
    .p-sCase .l-btnArea02 div a svg {
      left: 2rem; }

@media (--large) {
  .p-sExhibition .l-inner {
    width: 90rem;
    margin-right: auto;
    margin-left: auto; } }

.p-sExhibition .s-ttlArea {
  padding-bottom: 1rem;
  border-bottom: 1px solid #8491aa; }
  .p-sExhibition .s-ttlArea__date {
    font-size: 1.6rem; }
  .p-sExhibition .s-ttlArea__ttl {
    margin-top: 1rem;
    font-size: 3rem; }
    @media (--mobile) {
      .p-sExhibition .s-ttlArea__ttl {
        margin-top: 0.5rem;
        font-size: 2.4rem; } }

.p-sExhibition .s-source__inner {
  display: flex; }
  @media (--mobile) {
    .p-sExhibition .s-source__inner {
      display: block; } }

.p-sExhibition .s-source__img {
  padding-right: 2rem; }
  @media (--mobile) {
    .p-sExhibition .s-source__img {
      width: 100%;
      padding-right: 0; } }
  .p-sExhibition .s-source__img .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40rem;
    min-height: 14rem; }
    @media (--mobile) {
      .p-sExhibition .s-source__img .img {
        width: 100%; } }
  .p-sExhibition .s-source__img figure img {
    width: 100%;
    min-width: 20rem;
    height: 100%;
    object-fit: cover; }
  .p-sExhibition .s-source__img .caption {
    margin-top: 1rem;
    font-size: 1.4rem; }

.p-sExhibition .s-source__right {
  width: calc(100% - 40rem); }
  @media (--mobile) {
    .p-sExhibition .s-source__right {
      width: 100%;
      margin-top: 2rem; } }
  .p-sExhibition .s-source__right .title-img {
    margin-bottom: 2rem; }
  .p-sExhibition .s-source__right dl {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6rem; }
    @media (--mobile) {
      .p-sExhibition .s-source__right dl {
        margin-top: 2rem; } }
    .p-sExhibition .s-source__right dl dt {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9rem;
      font-weight: bold;
      color: #1a2e56;
      background-color: #edeff4; }
      .p-sExhibition .s-source__right dl dt:nth-of-type(n + 2) {
        margin-top: 1rem; }
      @media (--mobile) {
        .p-sExhibition .s-source__right dl dt {
          width: calc(100% - 70% - 1.5rem); } }
    .p-sExhibition .s-source__right dl dd {
      width: calc(100% - 9rem);
      padding-left: 1.5rem;
      letter-spacing: -0.01em; }
      .p-sExhibition .s-source__right dl dd:nth-of-type(n + 2) {
        margin-top: 1rem; }
      @media (--mobile) {
        .p-sExhibition .s-source__right dl dd {
          width: 70%; } }
  .p-sExhibition .s-source__right .link {
    margin-top: 1rem; }
  .p-sExhibition .s-source__right .captionTxt {
    margin-top: 2rem; }

.p-sExhibition .s-btn {
  border-top: 1px solid #8491aa; }
  .p-sExhibition .s-btn .c-btn03 a {
    width: 26rem;
    height: 7rem;
    font-size: 1.6rem; }
  .en .p-sExhibition .s-btn .c-btn03 a {
    width: 35rem; }

.p-sManual .s-wrap {
  display: flex;
  justify-content: space-between; }
  @media (--mobile) {
    .p-sManual .s-wrap {
      display: block; } }
  .p-sManual .s-wrap__left {
    width: 100%; }
    .p-sManual .s-wrap__left dl {
      display: flex;
      flex-wrap: wrap; }
      .p-sManual .s-wrap__left dl *:nth-child(n + 3) {
        margin-top: 1rem; }
      .p-sManual .s-wrap__left dl dt {
        width: 12rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #1a2e56; }
      .p-sManual .s-wrap__left dl dd {
        width: calc(100% - 12rem); }
  .p-sManual .s-wrap__right {
    flex-shrink: 0;
    width: calc(28rem + 5.5rem);
    padding-left: 5.5rem; }
    @media (--mobile) {
      .p-sManual .s-wrap__right {
        width: 100%;
        padding-left: 0;
        margin-top: 3rem; } }
    .p-sManual .s-wrap__right figure {
      border: 1px solid #dedede; }
      .p-sManual .s-wrap__right figure img {
        width: 100%;
        height: 100%; }

.p-sManual .s-btn {
  display: flex; }
  @media (--mobile) {
    .p-sManual .s-btn {
      justify-content: space-between; } }
  @media (--mobile) {
    .p-sManual .s-btn .c-btn02,
    .p-sManual .s-btn .c-btn03 {
      width: 48%; } }
  .p-sManual .s-btn .c-btn02 a,
  .p-sManual .s-btn .c-btn03 a {
    width: 20rem;
    height: 6rem;
    padding: 0 2rem;
    font-size: 1.6rem; }
    @media (--mobile) {
      .p-sManual .s-btn .c-btn02 a,
      .p-sManual .s-btn .c-btn03 a {
        width: 100%;
        padding: 0; } }
    .p-sManual .s-btn .c-btn02 a svg,
    .p-sManual .s-btn .c-btn03 a svg {
      left: 2rem; }
  @media (--mobile) {
    .p-sManual .s-btn .c-btn02 a {
      padding-right: 1.5rem !important; }
      .p-sManual .s-btn .c-btn02 a svg {
        width: 2.4rem; } }

.p-sManual .s-contact {
  padding-top: 5.6rem;
  border-top: 1px solid #8491aa; }
  @media (--mobile) {
    .p-sManual .s-contact {
      padding-top: 4rem; } }
  .p-sManual .s-contact-ttl {
    text-align: center; }
  .p-sManual .s-contact-wrap {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (--mobile) {
      .p-sManual .s-contact-wrap {
        display: block; } }
  .p-sManual .s-contact-left {
    padding-right: 4.7rem;
    border-right: 1px solid #c7ced3; }
    @media (--mobile) {
      .p-sManual .s-contact-left {
        padding-right: 0;
        background-repeat: 0; } }
    .p-sManual .s-contact-left .c-btn10 a {
      width: 33rem;
      font-size: 2rem;
      font-weight: 500; }
      @media (--mobile) {
        .p-sManual .s-contact-left .c-btn10 a {
          width: 100%;
          height: 6rem;
          padding: 1.5rem 0 1.5rem 7rem; } }
      .p-sManual .s-contact-left .c-btn10 a svg {
        width: 2.8rem; }
      .p-sManual .s-contact-left .c-btn10 a:hover svg * {
        fill: #fff;
        stroke: none; }
  .p-sManual .s-contact-right {
    padding-left: 4.7rem; }
    @media (--mobile) {
      .p-sManual .s-contact-right {
        padding-left: 0; } }
    .p-sManual .s-contact-right h4 {
      display: flex;
      align-items: center;
      font-size: 2.2rem;
      font-weight: bold;
      color: #1a2e56; }
      @media (--mobile) {
        .p-sManual .s-contact-right h4 {
          justify-content: center;
          padding-right: 1rem; } }
      .p-sManual .s-contact-right h4 svg {
        width: 4rem;
        margin-right: 1rem; }
    .p-sManual .s-contact-right__tel {
      display: flex;
      align-items: center; }
      @media (--mobile) {
        .p-sManual .s-contact-right__tel {
          display: block; } }
      .p-sManual .s-contact-right__tel > div {
        display: flex;
        align-items: center; }
        @media (--large) {
          .p-sManual .s-contact-right__tel > div:nth-of-type(n + 2) {
            margin-left: 2.4rem; } }
        @media (--mobile) {
          .p-sManual .s-contact-right__tel > div {
            justify-content: center; } }
        .p-sManual .s-contact-right__tel > div span {
          flex-shrink: 0;
          padding: 0 0.8rem;
          margin-right: 1rem;
          font-size: 1.6rem;
          color: #fff;
          background: #1a2e56; }
        .p-sManual .s-contact-right__tel > div b,
        .p-sManual .s-contact-right__tel > div a {
          font-size: 2.2rem;
          color: #222; }

.p-sProduct .l-top {
  display: flex; }
  @media (--large) {
    .p-sProduct .l-top {
      justify-content: space-between; } }
  @media (--mobile) {
    .p-sProduct .l-top {
      display: block; } }
  .p-sProduct .l-top__left {
    width: 54rem; }
    @media (--mobile) {
      .p-sProduct .l-top__left {
        width: 100%; } }
  .p-sProduct .l-top__right {
    width: calc(100% - 54rem);
    padding-left: 6rem; }
    @media (--mobile) {
      .p-sProduct .l-top__right {
        width: 100%;
        padding-left: 0; } }
    .p-sProduct .l-top__right img {
      width: 100%; }
  .p-sProduct .l-top__tag {
    display: flex; }
    .p-sProduct .l-top__tag span {
      padding: 0 0.8rem;
      font-size: 1.4rem;
      color: #fff;
      background: #1a2e56; }
    .p-sProduct .l-top__tag a {
      margin-left: 1rem;
      font-size: 1.4rem;
      font-weight: bold; }
  .p-sProduct .l-top__ttl {
    padding-bottom: 1.5rem;
    margin-top: 0.2rem;
    border-bottom: 3px solid #1a2e56; }
    @media (--mobile) {
      .p-sProduct .l-top__ttl {
        border-bottom-width: 1px; } }
    .p-sProduct .l-top__ttl * {
      display: block; }
    .p-sProduct .l-top__ttl b {
      font-size: 4rem;
      line-height: 1.5; }
      @media (--mobile) {
        .p-sProduct .l-top__ttl b {
          font-size: 3.2rem; } }
    .p-sProduct .l-top__ttl small {
      font-size: 2rem;
      font-weight: 500; }
      @media (--mobile) {
        .p-sProduct .l-top__ttl small {
          font-size: 1.6rem; } }
  .p-sProduct .l-top__code {
    margin-top: 2.5rem; }
    .p-sProduct .l-top__code-inner {
      display: flex; }
      @media (--mobile) {
        .p-sProduct .l-top__code-inner {
          flex-direction: column; } }
    @media (--mobile) {
      .p-sProduct .l-top__code {
        margin-top: 1.5rem; } }
    .p-sProduct .l-top__code * {
      display: block; }
    .p-sProduct .l-top__code small {
      font-size: 1.2rem; }
    .p-sProduct .l-top__code b {
      font-size: 2.8rem;
      line-height: 1.5; }

.p-sProduct .l-anchor a {
  position: relative;
  display: block;
  font-size: 1.3rem;
  font-weight: bold; }
  .p-sProduct .l-anchor a svg {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0.7rem;
    height: 1.4rem;
    transform: translateY(-50%); }
  .p-sProduct .l-anchor a:nth-of-type(n + 2) {
    margin-top: 3rem; }

.p-sProduct .l-btnArea {
  display: flex;
  flex-wrap: wrap; }
  @media (--large) {
    .p-sProduct .l-btnArea {
      margin: 0 -1.5rem; } }
  @media (--mobile) {
    .p-sProduct .l-btnArea {
      display: block; } }
  .p-sProduct .l-btnArea .c-btn08 {
    width: calc(100% / 3 - 3rem);
    margin: 0 1.5rem; }
    @media (--mobile) {
      .p-sProduct .l-btnArea .c-btn08 {
        width: 100%;
        margin: 0; }
        .p-sProduct .l-btnArea .c-btn08:nth-of-type(n + 2) {
          margin-top: 3rem; } }
    @media (--large) {
      .p-sProduct .l-btnArea .c-btn08:nth-of-type(n + 4) {
        margin-top: 3rem; } }
    .p-sProduct .l-btnArea .c-btn08 a {
      width: 100%; }
      @media (--mobile) {
        .p-sProduct .l-btnArea .c-btn08 a {
          height: 6rem; } }

@media (--large) {
  .p-sProduct .l-btHalf {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (--large) {
  .p-sProduct .l-btHalf .c-btn12 {
    width: calc(100% / 2 - 1.5rem) !important; } }

.p-sProduct .l-btHalf .c-btn12,
.p-sProduct .l-btHalf .c-btn12 a {
  width: 100%;
  font-size: 2.4rem; }
  @media (--large) {
    .p-sProduct .l-btHalf .c-btn12,
    .p-sProduct .l-btHalf .c-btn12 a {
      height: 8rem; } }
  @media (--mobile) {
    .p-sProduct .l-btHalf .c-btn12,
    .p-sProduct .l-btHalf .c-btn12 a {
      height: 6rem; } }

@media (--mobile) {
  .p-sProduct .l-btHalf .c-btn12 a {
    padding: 2rem;
    padding-left: 3.4rem !important;
    font-size: 2rem;
    font-weight: bold; } }

.p-sProduct .l-btnAll .c-btn12,
.p-sProduct .l-btnAll .c-btn12 a {
  width: 100%;
  font-size: 2.4rem; }

@media (--large) {
  .p-sProduct .c-table02 dl dt {
    width: 25%; }
  .p-sProduct .c-table02 dd {
    width: 75%; } }

@media (--mobile) {
  .p-sProduct .c-table01 table {
    width: 50rem; } }

.leaflet-container {
  z-index: 2 !important;
  outline: none !important; }

.p-sitemap {
  margin-bottom: 14rem; }
  @media (--mobile) {
    .p-sitemap {
      margin-bottom: 7rem; } }
  .p-sitemap-inner {
    width: 117rem;
    margin: 0 auto; }
    @media (--mobile) {
      .p-sitemap-inner {
        width: 100%;
        padding: 0 3rem; } }
  .p-sitemap-list {
    display: flex;
    margin-top: 6rem; }
    @media (--mobile) {
      .p-sitemap-list {
        display: block;
        margin-top: 3rem; } }
    .p-sitemap-list__content {
      width: calc(100% / 3);
      margin-right: 6rem; }
      @media (--mobile) {
        .p-sitemap-list__content {
          width: 100%;
          margin-right: 0; }
          .p-sitemap-list__content:nth-of-type(n + 2) {
            margin-top: 3rem; } }
      .p-sitemap-list__content ul li {
        margin-top: 2rem; }
        .p-sitemap-list__content ul li a {
          position: relative;
          padding-left: 2rem;
          font-size: 1.6rem; }
          .p-sitemap-list__content ul li a::after {
            position: absolute;
            top: 50%;
            left: 0;
            width: 0.9rem;
            height: 0.9rem;
            content: "";
            border-top: 2px solid #1a2e56;
            border-right: 2px solid #1a2e56;
            transform: translateY(-50%) rotate(45deg); }
          .en .p-sitemap-list__content ul li a {
            white-space: nowrap; }
            .en .p-sitemap-list__content ul li a::after {
              top: 0.8rem;
              transform: rotate(45deg); }
  .p-sitemap-middle {
    margin-top: 11rem; }
    @media (--mobile) {
      .p-sitemap-middle {
        margin-top: 5rem; } }
    .p-sitemap-middle__menu {
      margin-top: 4rem;
      font-size: 2.4rem;
      font-weight: bold; }
      @media (--mobile) {
        .p-sitemap-middle__menu {
          margin-top: 2rem; } }
      .p-sitemap-middle__menu a {
        position: relative; }
        .p-sitemap-middle__menu a::after {
          position: absolute;
          top: 50%;
          right: -2rem;
          width: 0.9rem;
          height: 0.9rem;
          content: "";
          border-top: 2px solid #1a2e56;
          border-right: 2px solid #1a2e56;
          transform: translateY(-50%) rotate(45deg); }
      .p-sitemap-middle__menu.btn {
        padding-bottom: 1rem;
        border-bottom: 0.1rem solid #8491aa; }
    .p-sitemap-middle__flex {
      display: flex;
      justify-content: space-between; }
      .p-sitemap-middle__flex.wrap {
        flex-wrap: wrap; }
      @media (--mobile) {
        .p-sitemap-middle__flex {
          display: block; } }
    .p-sitemap-middle__content {
      width: 44rem;
      margin-right: 6rem; }
      @media (--mobile) {
        .p-sitemap-middle__content {
          width: 100%;
          margin-right: 0; } }
      .p-sitemap-middle__content:nth-of-type(2) {
        width: calc(100% - 44rem - 6rem);
        margin-right: 0; }
        @media (--mobile) {
          .p-sitemap-middle__content:nth-of-type(2) {
            width: 100%; } }
    .p-sitemap-middle__wrap {
      display: flex;
      flex-wrap: wrap; }
      @media (--mobile) {
        .p-sitemap-middle__wrap {
          flex-direction: column; } }
    .p-sitemap-middle__list {
      margin-top: 2rem; }
      .p-sitemap-middle__list .title {
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        font-size: 2rem;
        font-weight: bold;
        color: #1a2e56;
        background-color: #edeff4; }
        .en .p-sitemap-middle__list .title {
          padding-right: 1.5rem; }
        .p-sitemap-middle__list .title span {
          font-size: 1.4rem; }
          @media (--large) {
            .en .p-sitemap-middle__list .title span {
              margin-top: 0.4rem; } }
        @media (--mobile) {
          .p-sitemap-middle__list .title {
            padding: 0 1rem; } }
      .p-sitemap-middle__list ul {
        width: 21rem; }
        @media (--mobile) {
          .p-sitemap-middle__list ul {
            width: 100%; } }
        .p-sitemap-middle__list ul li {
          margin-top: 1rem; }
          .en .p-sitemap-middle__list ul li {
            white-space: nowrap; }
          .p-sitemap-middle__list ul li a {
            position: relative;
            display: inline-block;
            padding-left: 2rem; }
            .p-sitemap-middle__list ul li a::after {
              position: absolute;
              top: 50%;
              left: 0;
              width: 0.8rem;
              height: 0.8rem;
              content: "";
              border-top: 2px solid #1a2e56;
              border-right: 2px solid #1a2e56;
              transform: translateY(-50%) rotate(45deg); }
      .p-sitemap-middle__list:nth-of-type(1) {
        margin-right: 1.5rem; }
        @media (--mobile) {
          .p-sitemap-middle__list:nth-of-type(1) {
            margin-right: 0; } }
      .p-sitemap-middle__list:nth-of-type(n + 2) {
        margin-right: 1.5rem; }
        @media (--mobile) {
          .p-sitemap-middle__list:nth-of-type(n + 2) {
            margin-right: 0; } }
      .p-sitemap-middle__list:nth-of-type(3) {
        margin-right: 0; }
      .p-sitemap-middle__list:last-of-type {
        margin-right: 0; }
  .p-sitemap-bottom {
    display: flex;
    margin-top: 6rem; }
    @media (--mobile) {
      .p-sitemap-bottom {
        display: block;
        margin-top: 3rem; } }
    .p-sitemap-bottom__wrap ul {
      margin-top: 2rem; }
      .p-sitemap-bottom__wrap ul li a {
        position: relative;
        display: inline-block;
        padding-left: 2rem;
        font-size: 1.6rem; }
        .p-sitemap-bottom__wrap ul li a::after {
          position: absolute;
          top: 50%;
          left: 0;
          width: 0.8rem;
          height: 0.8rem;
          content: "";
          border-top: 2px solid #1a2e56;
          border-right: 2px solid #1a2e56;
          transform: translateY(-50%) rotate(45deg); }
    .p-sitemap-bottom__wrap:nth-of-type(1) {
      width: 56rem;
      margin-right: 6rem; }
      @media (--mobile) {
        .p-sitemap-bottom__wrap:nth-of-type(1) {
          width: 100%;
          margin-right: 0; } }
    .p-sitemap-bottom__wrap:nth-of-type(n + 2) {
      width: 24rem;
      margin-right: 6rem; }
      @media (--mobile) {
        .p-sitemap-bottom__wrap:nth-of-type(n + 2) {
          width: 100%;
          margin-top: 3rem;
          margin-right: 0; } }
    .p-sitemap-bottom__wrap:last-of-type {
      margin-right: 0; }
    .p-sitemap-bottom__wrap .title {
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      margin-top: 4rem;
      font-size: 2rem;
      font-weight: bold;
      color: #1a2e56;
      background-color: #edeff4; }
      .p-sitemap-bottom__wrap .title span {
        font-size: 1.4rem; }
    .p-sitemap-bottom__content {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem; }
      .en .p-sitemap-bottom__content {
        margin-top: 0; }
      @media (--mobile) {
        .p-sitemap-bottom__content {
          display: block; } }
      .p-sitemap-bottom__content ul {
        margin-top: 0; }
      .p-sitemap-bottom__content .subtitle {
        font-size: 1.6rem;
        font-weight: bold;
        color: #333; }
    @media (--mobile) {
      .p-sitemap-bottom__list:nth-of-type(n + 2) {
        margin-top: 2rem; } }

@media (--mobile) {
  .p-size .c-table01 table {
    width: 50rem; } }

@media (--large) {
  .p-soniccutter-zero .c-product-fontSize span {
    font-size: 4.2rem; } }

@media (--mobile) {
  .p-soniccutter-zero .c-product-fontSize span {
    font-size: 3rem; } }

.p-soniccutter-zero .c-product-spec-img {
  position: relative;
  left: 6rem;
  width: 55.4rem; }
  @media (--mobile) {
    .p-soniccutter-zero .c-product-spec-img {
      position: relative;
      left: 4rem;
      width: 33rem; } }

.s-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff; }
  .s-loading__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 1rem;
    background: #0058a2;
    transition: 0.5s; }
  .s-loading svg {
    width: 20rem; }
    .s-loading svg .second {
      opacity: 0;
      transform: translate(-1rem, -1rem); }
    .s-loading svg .first {
      opacity: 0;
      transform: translate(1rem, 1rem); }
    .s-loading svg .third {
      opacity: 0;
      transform: translate(1rem, -1rem); }

.p-top {
  margin-top: -10rem; }
  .p-top .c-topTtl {
    text-align: center; }
    .p-top .c-topTtl strong {
      display: block;
      font-size: 2.8rem;
      font-weight: bold;
      color: #1a2e56;
      letter-spacing: 0.1em; }
    .p-top .c-topTtl small {
      font-family: "Roboto", sans-serif;
      font-size: 1.8rem; }
  .p-top .s-mv-news {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: #edeff4;
    transition: 0.3s background; }
    @media (--mobile) {
      .p-top .s-mv-news {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 9rem; } }
    .p-top .s-mv-news a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      padding: 1.4rem 2.7rem; }
      .p-top .s-mv-news a::after {
        position: absolute;
        top: 50%;
        right: 2.7rem;
        width: 0.9rem;
        height: 0.9rem;
        content: "";
        border-top: 0.2rem solid #1a2e56;
        border-right: 0.2rem solid #1a2e56;
        transform: translateY(-50%) rotate(45deg); }
        @media (--mobile) {
          .p-top .s-mv-news a::after {
            right: 2rem; } }
      @media (--mobile) {
        .p-top .s-mv-news a {
          padding: 1rem 2rem; } }
    .p-top .s-mv-news__ttl {
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      color: #384eaa;
      transition: 0.3s; }
    .p-top .s-mv-news__b {
      display: flex;
      align-items: center; }
    .p-top .s-mv-news__year {
      margin-right: 0.8rem;
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem;
      line-height: 1;
      letter-spacing: 0;
      writing-mode: tb;
      transition: 0.3s; }
      @media (--mobile) {
        .p-top .s-mv-news__year {
          width: 1.2rem; } }
    .p-top .s-mv-news__day {
      margin-left: 1.5rem;
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem;
      font-weight: bold;
      color: #8491aa;
      letter-spacing: normal;
      transition: 0.3s; }
      @media (--mobile) {
        .p-top .s-mv-news__day {
          width: 11rem; } }
    .p-top .s-mv-news__txt {
      position: relative;
      width: 38rem;
      margin-right: 2rem;
      overflow: hidden;
      font-size: 1.5rem;
      line-height: 1.5;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: 0.3s; }
      @media (--mobile) {
        .p-top .s-mv-news__txt {
          width: 95%; } }
      .p-top .s-mv-news__txt svg {
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.9rem;
        transform: translateY(-50%); }
        .p-top .s-mv-news__txt svg * {
          transition: 0.3s; }
    @media (--large) {
      .p-top .s-mv-news:hover {
        background: #1a2e56; }
        .p-top .s-mv-news:hover * {
          color: #fff; }
        .p-top .s-mv-news:hover a::after {
          border-color: #fff; }
        .p-top .s-mv-news:hover svg * {
          stroke: #fff; } }
  .p-top .s-products {
    padding-top: 16.5rem;
    padding-bottom: 8rem;
    overflow: hidden;
    background: #edeff4; }
    @media (--mobile) {
      .p-top .s-products {
        padding: 0 3rem 5rem;
        padding-top: 9rem;
        overflow: hidden; } }
    .p-top .s-products__ttl {
      position: relative;
      width: calc(142rem - 6rem * 2);
      margin-right: auto;
      margin-left: auto; }
      .p-top .s-products__ttl::after {
        position: absolute;
        top: -5rem;
        left: -7rem;
        width: 116rem;
        height: 53rem;
        pointer-events: none;
        content: "";
        background: url(../images/page/top/products_txt.png) no-repeat center center/contain; }
        @media (--mobile) {
          .p-top .s-products__ttl::after {
            top: -6rem;
            left: -6rem;
            width: 83rem;
            height: 38rem; } }
      .p-top .s-products__ttl * {
        color: #1a2e56; }
      .p-top .s-products__ttl strong {
        display: block;
        font-family: "Roboto", sans-serif;
        font-size: 15.2rem;
        line-height: 1; }
        @media (--mobile) {
          .p-top .s-products__ttl strong {
            font-size: 6.5rem; } }
      .p-top .s-products__ttl small {
        display: inline-block;
        font-size: 3.6rem; }
        @media (--mobile) {
          .p-top .s-products__ttl small {
            font-size: 3rem; } }
  .p-top .s-moter {
    width: 120rem;
    margin-top: 6.3rem;
    margin-right: auto;
    margin-left: auto; }
    @media (--mobile) {
      .p-top .s-moter {
        position: relative;
        width: 100%; } }
    .p-top .s-moter__wrap {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between; }
      @media (--mobile) {
        .p-top .s-moter__wrap {
          display: block; } }
    .p-top .s-moter__ttl * {
      color: #1a2e56; }
    .p-top .s-moter__ttl strong {
      display: block;
      font-family: "Roboto", sans-serif;
      font-size: 10.6rem;
      line-height: 1; }
      @media (--mobile) {
        .p-top .s-moter__ttl strong {
          font-size: 4.7rem;
          line-height: 1.1; } }
    .p-top .s-moter__ttl small {
      font-size: 4.2rem; }
      @media (--mobile) {
        .p-top .s-moter__ttl small {
          font-size: 2.2rem; } }
    .p-top .s-moter__right {
      width: 52.8rem;
      padding-top: 5rem; }
      @media (--mobile) {
        .p-top .s-moter__right {
          position: relative;
          z-index: 1;
          width: 100%;
          padding-top: 28rem; } }
    .p-top .s-moter__txt {
      font-size: 2rem; }
      @media (--mobile) {
        .p-top .s-moter__txt {
          font-size: 1.6rem; } }
    .p-top .s-moter__btnWrap {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem; }
      @media (--mobile) {
        .p-top .s-moter__btnWrap {
          justify-content: center; } }
      @media (--mobile) {
        .p-top .s-moter__btnWrap .c-btn01 {
          width: 70%; } }
      .p-top .s-moter__btnWrap a {
        width: 25.5rem;
        height: 7rem;
        padding-left: 2rem;
        font-size: 1.6rem; }
        .en .p-top .s-moter__btnWrap a {
          width: 28.5rem; }
        @media (--mobile) {
          .p-top .s-moter__btnWrap a {
            width: 100%;
            height: 6rem;
            padding-left: 1.5rem; } }
        @media (--mobile) {
          .p-top .s-moter__btnWrap a svg {
            right: 1rem;
            width: 2.5rem;
            transform: translateY(-50%) translateY(-0.1rem); } }
    .p-top .s-moter__slider {
      width: 98rem;
      margin: 0 0 0 auto;
      pointer-events: none; }
      @media (--large) {
        .p-top .s-moter__slider {
          width: 85rem; } }
      @media (--mobile) {
        .p-top .s-moter__slider {
          position: absolute;
          top: -3rem;
          z-index: 2;
          width: calc(100% + 4rem);
          padding-top: 0;
          margin: 0 -2rem; } }
      .p-top .s-moter__slider-box {
        position: relative;
        width: 100rem;
        height: 60rem; }
        @media (--large) {
          .p-top .s-moter__slider-box {
            left: -3rem;
            width: 81rem;
            height: 46rem;
            margin: 0 0 0 auto; } }
        @media (--mobile) {
          .p-top .s-moter__slider-box {
            width: 100%; } }
        .p-top .s-moter__slider-box::after {
          position: absolute;
          bottom: 9rem;
          left: 50%;
          width: calc(100% - 8rem);
          height: calc(100% - 4rem);
          content: "";
          background: linear-gradient(135deg, #e4e6ec, #c5c7d0);
          transform: translateX(-50%); }
          @media (--mobile) {
            .p-top .s-moter__slider-box::after {
              top: 50%;
              bottom: inherit;
              width: 85%;
              height: 19rem;
              transform: translateX(-50%) translateY(-50%) translateY(-1rem); } }
        .p-top .s-moter__slider-box img {
          position: absolute;
          top: -9rem;
          left: 0;
          z-index: 2;
          width: 100%;
          object-fit: contain;
          opacity: 0;
          transform: translate(15rem, -15rem); }
          @media (--large) {
            .p-top .s-moter__slider-box img {
              top: -8rem;
              left: 3rem;
              width: 93%; } }
          @media (--mobile) {
            .p-top .s-moter__slider-box img {
              top: 16rem;
              transform: translate(5rem, -5rem); } }
        .p-top .s-moter__slider-box--mg img {
          top: -1rem; }
          @media (--large) {
            .p-top .s-moter__slider-box--mg img {
              top: 0; } }
      .p-top .s-moter__slider-num {
        position: absolute;
        right: 3rem;
        bottom: 4rem;
        display: flex;
        align-items: center;
        font-family: "Roboto", sans-serif;
        font-size: 2rem; }
        @media (--large) {
          .p-top .s-moter__slider-num {
            right: 7rem; } }
        @media (--mobile) {
          .p-top .s-moter__slider-num {
            bottom: 17rem; } }
        .p-top .s-moter__slider-num span {
          position: relative;
          width: 2.4rem;
          height: 3.6rem; }
          .p-top .s-moter__slider-num span:nth-of-type(2) {
            width: 3rem;
            height: 1px;
            margin: 0 0.8rem;
            background: #1a2e56; }
          .p-top .s-moter__slider-num span em {
            position: absolute;
            top: 0;
            left: 0;
            font-style: inherit;
            opacity: 0; }
  .p-top .s-lineUp {
    position: relative; }
    .p-top .s-lineUp__wrap {
      position: relative;
      display: flex;
      margin-top: 8rem;
      margin-left: calc(50vw - 144rem / 2 + 5rem); }
      .en .p-top .s-lineUp__wrap {
        justify-content: flex-end;
        margin-top: 3rem;
        margin-left: calc(50vw - 144rem / 2); }
        @media (--mobile) {
          .en .p-top .s-lineUp__wrap {
            margin: 0 -3rem;
            margin-top: 3rem; } }
      @media (--mobile) {
        .p-top .s-lineUp__wrap {
          display: block;
          margin: 0 -3rem;
          margin-top: 7rem; } }
    @media (--large) {
      .p-top .s-lineUp__pagenation {
        position: absolute;
        bottom: 6rem;
        left: 0;
        display: inline-block;
        width: auto; }
        .en .p-top .s-lineUp__pagenation {
          position: relative;
          bottom: inherit;
          left: inherit; }
        .p-top .s-lineUp__pagenation span {
          font-family: "Roboto", sans-serif;
          line-height: 0; }
          .p-top .s-lineUp__pagenation span:nth-of-type(1) {
            position: absolute;
            top: -3rem;
            left: -2rem;
            width: 4rem;
            font-size: 3.6rem;
            font-weight: bold;
            text-align: right; }
          .p-top .s-lineUp__pagenation span:nth-of-type(2) {
            position: absolute;
            top: -0.7rem;
            left: 0.4rem;
            width: 3.5rem;
            height: 1px;
            background: #8491aa;
            transform: rotate(-40deg); }
          .p-top .s-lineUp__pagenation span:nth-of-type(3) {
            position: absolute;
            top: 1rem;
            left: 1.8rem;
            font-size: 2.3rem; } }
    @media (--mobile) {
      .p-top .s-lineUp__pagenation {
        display: none; } }
    .p-top .s-lineUp__next, .p-top .s-lineUp__prev {
      position: absolute;
      top: 50%;
      left: 10rem;
      z-index: 2;
      width: 8.8rem;
      height: 8.8rem;
      cursor: pointer;
      background: rgb(255 255 255 / 90%);
      border-radius: 9999px;
      outline: none;
      transition: 0.3s;
      transform: translateY(-50%) translateX(-50%); }
      @media (--large) {
        .p-top .s-lineUp__next:hover, .p-top .s-lineUp__prev:hover {
          transform: translateY(-50%) translateX(-50%) scale(1.1); } }
      @media (--mobile) {
        .p-top .s-lineUp__next, .p-top .s-lineUp__prev {
          top: 0;
          right: 9.6rem;
          left: inherit;
          width: 4.6rem;
          height: 4.6rem;
          transform: translateY(-0.6rem); }
          .en .p-top .s-lineUp__next, .en .p-top .s-lineUp__prev {
            top: -6.5rem; } }
      .p-top .s-lineUp__next::after, .p-top .s-lineUp__prev::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.9rem;
        height: 1.8rem;
        content: "";
        background: url(../images/common/ic_arrow01.svg) no-repeat center center/contain;
        transform: translate(-50%, -50%) rotate(180deg); }
    @media (--large) {
      .p-top .s-lineUp__prev {
        display: none; } }
    @media (--mobile) {
      .p-top .s-lineUp__prev {
        right: 3rem;
        transform: translateY(-0.6rem) rotate(180deg); } }
    .p-top .s-lineUp__ttl {
      display: flex;
      align-items: flex-end;
      width: 10rem;
      font-family: "Roboto", sans-serif;
      font-size: 3.6rem;
      line-height: 1;
      color: #1a2e56;
      writing-mode: tb; }
      @media (--mobile) {
        .p-top .s-lineUp__ttl {
          width: auto;
          padding-left: 3rem;
          writing-mode: lr-tb; } }
    .p-top .s-lineUp__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 120rem;
      margin-top: 8rem;
      margin-left: calc(50vw - 144rem / 2 + 10rem); }
      .p-top .s-lineUp__title > h3 {
        font-family: "Roboto", sans-serif;
        font-size: 3.6rem;
        line-height: 1;
        color: #1a2e56; }
      @media (--mobile) {
        .p-top .s-lineUp__title {
          width: 100%;
          margin: 0 auto;
          margin-top: 7rem; } }
    .p-top .s-lineUp__slider {
      position: relative;
      display: flex;
      width: calc(100% - 10rem);
      padding-bottom: 4rem;
      overflow: hidden; }
      .p-top .s-lineUp__slider::after, .p-top .s-lineUp__slider::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 116rem;
        height: 2px;
        content: "";
        background: #c7ced3; }
        @media (--mobile) {
          .p-top .s-lineUp__slider::after, .p-top .s-lineUp__slider::before {
            width: calc(100vw - 6rem); } }
      .p-top .s-lineUp__slider::before {
        z-index: 2;
        width: 56rem;
        background: #1a2e56; }
        @media (--mobile) {
          .p-top .s-lineUp__slider::before {
            width: 12rem; } }
      @media (--mobile) {
        .p-top .s-lineUp__slider {
          width: calc(100% - 3rem);
          margin: 0 0 0 auto;
          margin-top: 2.3rem; } }
      .p-top .s-lineUp__slider > div {
        width: 30rem;
        height: 45rem; }
        @media (--mobile) {
          .p-top .s-lineUp__slider > div {
            width: inherit;
            height: 25.2rem; } }
        .p-top .s-lineUp__slider > div a {
          position: relative;
          display: inline-block;
          width: 100%;
          height: 100%;
          padding-top: 27rem;
          background: linear-gradient(315deg, #000, #00012b);
          transition: 0.3s; }
          @media (--mobile) {
            .p-top .s-lineUp__slider > div a {
              padding-top: 14rem; } }
          @media (--large) {
            .p-top .s-lineUp__slider > div a:hover h3 {
              transform: scale(1.05); } }
          .p-top .s-lineUp__slider > div a > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 13.8rem;
            text-align: center; }
            @media (--mobile) {
              .p-top .s-lineUp__slider > div a > div {
                height: 8.8rem; } }
          .p-top .s-lineUp__slider > div a span {
            display: inline-block;
            padding: 0 0.8rem;
            margin-top: 2rem;
            font-size: 1.4rem;
            color: #c7ced3;
            border: 1px solid #c7ced3;
            border-radius: 9999px; }
            @media (--mobile) {
              .p-top .s-lineUp__slider > div a span {
                margin-top: 1rem;
                font-size: 1.2rem; }
                .en .p-top .s-lineUp__slider > div a span {
                  padding: 0.6rem 1.5rem;
                  line-height: 1.5; } }
          .p-top .s-lineUp__slider > div a h3 {
            line-height: 1.7;
            text-align: center;
            transition: 0.3s; }
            .p-top .s-lineUp__slider > div a h3 * {
              color: #fff; }
            .p-top .s-lineUp__slider > div a h3 strong {
              display: block;
              font-size: 3.2rem;
              font-weight: 500; }
              @media (--mobile) {
                .p-top .s-lineUp__slider > div a h3 strong {
                  font-size: 1.8rem; } }
            .p-top .s-lineUp__slider > div a h3 small {
              display: block;
              font-size: 1.8rem;
              color: #c7ced3; }
              @media (--mobile) {
                .p-top .s-lineUp__slider > div a h3 small {
                  font-size: 1.4rem; } }
          .p-top .s-lineUp__slider > div a figure {
            position: absolute;
            top: 0;
            left: 50%;
            width: 30rem;
            transform: translateX(-50%); }
            @media (--mobile) {
              .p-top .s-lineUp__slider > div a figure {
                width: 15rem; } }
  .p-top .s-dl {
    margin-top: 7rem; }
    @media (--mobile) {
      .p-top .s-dl {
        padding: 0; } }
    .p-top .s-dl__btnWrap {
      display: flex;
      justify-content: space-between;
      margin-top: 4.6rem; }
      @media (--mobile) {
        .p-top .s-dl__btnWrap {
          display: block;
          margin-top: 2rem; } }
      @media (--large) {
        .p-top .s-dl__btnWrap .c-btn08 {
          width: calc(100% / 3 - 2rem); } }
      @media (--mobile) {
        .p-top .s-dl__btnWrap .c-btn08:nth-of-type(n + 2) {
          margin-top: 2rem; } }
      .p-top .s-dl__btnWrap .c-btn08 a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 8rem;
        font-size: 1.6rem;
        font-weight: bold;
        background: none;
        border: 1px solid #1a2e56; }
        @media (--mobile) {
          .p-top .s-dl__btnWrap .c-btn08 a {
            width: 100%; }
            .p-top .s-dl__btnWrap .c-btn08 a:nth-of-type(n + 2) {
              margin-top: 1.8rem; } }
        .p-top .s-dl__btnWrap .c-btn08 a svg {
          position: absolute;
          top: 50%;
          right: 3rem;
          width: 2.1rem;
          transform: translateY(-50%); }
  .p-top .s-support {
    padding-top: 8rem;
    padding-bottom: 8rem; }
    @media (--mobile) {
      .p-top .s-support {
        padding-top: 5rem;
        padding-bottom: 5rem; } }
    .p-top .s-support__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
      margin-top: 3.1rem; }
      .p-top .s-support__list li {
        position: relative;
        width: calc(100% / 4); }
        @media (--mobile) {
          .p-top .s-support__list li {
            width: calc(100% / 3); } }
        @media (--large) {
          .p-top .s-support__list li::after {
            position: absolute;
            top: 50%;
            width: 1px;
            height: calc(100% - 1.3rem * 2);
            content: "";
            background: #c7ced3;
            transform: translateY(-50%); } }
        .p-top .s-support__list li:nth-of-type(1)::after, .p-top .s-support__list li:nth-of-type(5)::after {
          display: none; }
        .p-top .s-support__list li:nth-of-type(2) svg {
          width: 4.5rem; }
        .p-top .s-support__list li:nth-of-type(7) svg {
          width: 4.4rem; }
        .p-top .s-support__list li:nth-of-type(8) svg {
          width: 3.2rem; }
          @media (--mobile) {
            .p-top .s-support__list li:nth-of-type(8) svg {
              width: 4rem; } }
        @media (--large) {
          .p-top .s-support__list li:nth-of-type(n + 5) {
            border-top: 1px solid #c7ced3; } }
        .p-top .s-support__list li a {
          position: relative;
          display: block;
          padding: 5rem 0;
          font-size: 1.6rem;
          text-align: center; }
          @media (--mobile) {
            .p-top .s-support__list li a {
              padding: 0.5rem 0 2rem; } }
          .p-top .s-support__list li a figure {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5.3rem;
            height: 4.1rem;
            margin-right: auto;
            margin-bottom: 2rem;
            margin-left: auto; }
            @media (--mobile) {
              .p-top .s-support__list li a figure {
                margin-bottom: 1rem; } }
            .p-top .s-support__list li a figure svg {
              max-width: 100%;
              max-height: 100%; }
          @media (--mobile) {
            .p-top .s-support__list li a span {
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 5rem;
              line-height: 1.5;
              text-align: center; } }
          @media (--large) {
            .p-top .s-support__list li a::before {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: -1;
              width: 0;
              height: 0;
              content: "";
              background: #edeff4;
              opacity: 0;
              transition: 0.3s;
              transform: translate(-50%, -50%); }
            .p-top .s-support__list li a:hover::before {
              width: calc(100% - 1.3rem * 2);
              height: calc(100% - 1.3rem * 2);
              opacity: 1; } }
    .p-top .s-support__btn {
      display: flex;
      justify-content: space-between;
      margin-top: 5rem; }
      @media (--mobile) {
        .p-top .s-support__btn {
          display: block;
          margin-top: 2rem; } }
      @media (--large) {
        .p-top .s-support__btn .c-btn10 {
          width: calc(100% / 2 - 2rem); } }
      @media (--mobile) {
        .p-top .s-support__btn .c-btn10:nth-of-type(n + 2) {
          margin-top: 2rem; } }
      .p-top .s-support__btn .c-btn10 a {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 11rem;
        padding-left: 10.8rem;
        font-size: 2.2rem;
        font-weight: 500;
        letter-spacing: 0.1em;
        border: 1px solid #1a2e56; }
        @media (--mobile) {
          .p-top .s-support__btn .c-btn10 a {
            width: 100%;
            height: 8rem;
            padding-left: 8rem;
            font-size: 2rem; } }
        .p-top .s-support__btn .c-btn10 a svg {
          position: absolute;
          top: 50%;
          left: 4rem;
          width: 3.6rem;
          transform: translateY(-50%); }
          @media (--mobile) {
            .p-top .s-support__btn .c-btn10 a svg {
              left: 2.5rem;
              width: 3.5rem; } }
          .p-top .s-support__btn .c-btn10 a svg * {
            stroke: none; }
        @media (--large) {
          .p-top .s-support__btn .c-btn10 a:hover svg * {
            fill: #fff; } }
        .p-top .s-support__btn .c-btn10 a::after {
          right: 3rem;
          width: 1.5rem;
          height: 1.5rem;
          content: ""; }
          @media (--mobile) {
            .p-top .s-support__btn .c-btn10 a::after {
              right: 2.5rem; } }
  .p-top .s-sideWrap {
    display: flex;
    justify-content: space-between;
    padding-top: 8rem;
    padding-bottom: 8rem;
    border-top: 1px solid #c7ced3; }
    @media (--mobile) {
      .p-top .s-sideWrap {
        display: block;
        padding-top: 5rem;
        padding-bottom: 5rem; } }
    .p-top .s-sideWrap > section {
      width: calc(100% / 2 - 3rem); }
      @media (--mobile) {
        .p-top .s-sideWrap > section {
          width: 100%; } }
  .p-top .s-news__list {
    margin-top: 4rem; }
  .p-top .s-news__el {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c7ced3; }
    @media (--large) {
      .p-top .s-news__el {
        height: 9.6rem; } }
    .p-top .s-news__el:first-of-type {
      border-top: 1px solid #c7ced3; }
    .p-top .s-news__el:nth-of-type(n + 2) {
      margin-top: -1px; }
  .p-top .s-news__mass {
    width: 100%; }
    @media (--mobile) {
      .p-top .s-news__mass {
        padding: 1.5rem 0; } }
  .p-top .s-news__link {
    position: absolute;
    width: 100%;
    height: 100%; }
    .p-top .s-news__link.nolink {
      pointer-events: none; }
  .p-top .s-news__top {
    display: flex;
    align-items: center; }
  .p-top .s-news__tag {
    min-width: 12rem;
    font-size: 1.3rem;
    color: #fff;
    text-align: center;
    background: #384eaa; }
  .p-top .s-news__date {
    margin-left: 1rem;
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem; }
  .p-top .s-news__txt {
    max-width: 100%;
    margin-top: 0.5rem;
    font-size: 1.5rem; }
    @media (--large) {
      .p-top .s-news__txt {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
  .p-top .s-news__more {
    position: relative;
    margin-top: 2.4rem;
    font-size: 1.5rem;
    text-align: right; }
    @media (--mobile) {
      .p-top .s-news__more {
        font-size: 1.8rem; } }
    .p-top .s-news__more a {
      padding-right: 2rem; }
      .p-top .s-news__more a svg {
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.8rem;
        transform: translateY(-50%); }
  @media (--mobile) {
    .p-top .s-exhibition {
      padding-top: 6rem; } }
  .p-top .s-exhibition__list {
    margin-top: 4rem; }
  .p-top .s-exhibition__el {
    border-bottom: 1px solid #c7ced3; }
    .p-top .s-exhibition__el:first-of-type {
      border-top: 1px solid #c7ced3; }
    .p-top .s-exhibition__el:nth-of-type(n + 2) {
      margin-top: -1px; }
  .p-top .s-exhibition__link {
    display: flex;
    align-items: center;
    padding: 2.4rem 0; }
    @media (--mobile) {
      .p-top .s-exhibition__link {
        position: relative;
        display: block; } }
  .p-top .s-exhibition__thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22rem;
    height: 10rem;
    overflow: hidden; }
    @media (--mobile) {
      .p-top .s-exhibition__thumb {
        position: absolute;
        top: 2.9rem;
        left: 0;
        width: 12.6rem;
        height: 5.5rem; } }
    .p-top .s-exhibition__thumb img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .p-top .s-exhibition__mass {
    width: calc(100% - 22rem);
    padding-left: 2.4rem; }
    @media (--mobile) {
      .p-top .s-exhibition__mass {
        width: 100%;
        padding-left: 0; } }
  .p-top .s-exhibition__ttl {
    font-size: 1.7rem; }
    @media (--large) {
      .p-top .s-exhibition__ttl {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
    @media (--mobile) {
      .p-top .s-exhibition__ttl {
        min-height: 7rem;
        padding-left: 14.4rem; } }
  .p-top .s-exhibition__info {
    display: flex;
    align-items: center;
    margin-top: 0.5rem; }
    .p-top .s-exhibition__info span:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.5rem;
      height: 2.4rem;
      font-size: 1.4rem;
      border: 1px solid #1a2e56; }
    .p-top .s-exhibition__info span:last-of-type {
      width: calc(100% - 7.5rem);
      padding-left: 1rem;
      font-size: 1.4rem; }
  .p-top .s-exhibition__more {
    position: relative;
    margin-top: 2.4rem;
    font-size: 1.5rem;
    text-align: right; }
    .p-top .s-exhibition__more a {
      padding-right: 2rem; }
      .p-top .s-exhibition__more a svg {
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.8rem;
        transform: translateY(-50%); }
  .p-top .s-column {
    padding-top: 8rem;
    padding-bottom: 8rem;
    background: #edeff4; }
    @media (--mobile) {
      .p-top .s-column {
        padding-top: 6rem;
        padding-bottom: 6rem; } }
    @media (--large) {
      .p-top .s-column__el:hover .s-column__thumb img {
        transform: scale(1.05); } }
    .p-top .s-column__thumb {
      overflow: hidden; }
      .p-top .s-column__thumb img {
        transition: 0.3s; }
    .p-top .s-column__txt {
      margin-top: 3rem;
      font-size: 1.6rem;
      text-align: center; }
      @media (--mobile) {
        .p-top .s-column__txt {
          margin-top: 2rem; } }
    .p-top .s-column__sliderWrap {
      position: relative;
      padding: 0 4.5rem;
      margin: 0 -4.5rem;
      margin-top: 4.6rem; }
      @media (--mobile) {
        .p-top .s-column__sliderWrap {
          padding: 0;
          margin: 0;
          margin-top: 3rem; } }
    @media (--mobile) {
      .p-top .s-column__list {
        display: block; }
      .p-top .s-column__el {
        display: flex; }
        .p-top .s-column__el:nth-of-type(n + 2) {
          margin-top: 2.5rem; }
      .p-top .s-column__thumb {
        width: 12rem; }
        .p-top .s-column__thumb img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .p-top .s-column__spRight {
        width: calc(100% - 12rem);
        padding-left: 2rem; } }
    .p-top .s-column__slider {
      overflow: hidden; }
      .p-top .s-column__slider--next, .p-top .s-column__slider--prev {
        position: absolute;
        top: 4.4rem;
        left: 0;
        z-index: 2;
        width: 8.8rem;
        height: 8.8rem;
        cursor: pointer;
        background: #fff;
        border-radius: 9999px;
        outline: none;
        opacity: 0.9;
        transition: 0.3s; }
        @media (--large) {
          .p-top .s-column__slider--next:hover, .p-top .s-column__slider--prev:hover {
            transform: scale(1.1); } }
        @media (--mobile) {
          .p-top .s-column__slider--next, .p-top .s-column__slider--prev {
            display: none; } }
        .p-top .s-column__slider--next::after, .p-top .s-column__slider--prev::after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0.9rem;
          height: 1.8rem;
          content: "";
          background: url(../images/common/ic_arrow01.svg) no-repeat center center/contain;
          transform: translate(-50%, -50%); }
      .p-top .s-column__slider--next::after {
        transform: translate(-50%, -50%) rotate(180deg); }
      .p-top .s-column__slider--prev {
        right: 0;
        left: inherit; }
    .p-top .s-column__ttl {
      margin-top: 2.2rem;
      font-size: 1.6rem;
      line-height: 1.5;
      color: #1a2e56; }
      @media (--mobile) {
        .p-top .s-column__ttl {
          margin-top: 0; } }
    .p-top .s-column__client {
      margin-top: 0.7rem;
      line-height: 1.5;
      color: #8491aa; }
  .p-top .s-mg {
    position: relative; }
    .p-top .s-mg::after {
      position: absolute;
      top: -13rem;
      left: -12rem;
      width: 116rem;
      height: 53rem;
      pointer-events: none;
      content: "";
      background: url(../images/page/top/products_txt.png) no-repeat center center/contain; }
      @media (--mobile) {
        .p-top .s-mg::after {
          display: none; } }

.p-xpeed .c-product-mv {
  padding-bottom: 6rem; }

.p-xpeed .c-product-spec {
  padding-top: 9rem; }
  @media (--mobile) {
    .p-xpeed .c-product-spec {
      padding-top: 4rem; } }

.p-xpeed .c-product-spec-img {
  width: 74.8rem; }
  @media (--mobile) {
    .p-xpeed .c-product-spec-img {
      width: 100%; } }

@media (--large) {
  .p-zero .c-product-fontSize span {
    font-size: 4.2rem; } }

@media (--large) {
  .p-zero .c-product-spec-img {
    position: relative;
    left: 11rem; } }
