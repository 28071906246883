.p-instructions {
  &__inner{

  }

  &__content{

  }

  &__caution{

  }

  &__list{

  }

  &__item {
    display: flex;

    &:nth-child(n + 2){
      margin-top: 4rem;
    }

    .item {
      &-num {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        background-color: #1a2e56;
        border-radius: 50%;
        margin-right: 2rem;
        color: #fff;
      }

      &-text{
        flex: 1;
      }
    }
  }

  &__btn{
    display: flex;
    width: calc(960 / 1100 * 100%);
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;

    @media (--mobile) {
      width: 100%;
    }

    @media (--mobile) {
      flex-direction: column;
    }

    .c-btn04,
    .c-btn05{
      width: 46rem;
      @media (--mobile) {
        width: 100%;
      }
      a{
        width: 100% !important;
        font-size: 1.9rem;
      }
    }

    .c-btn04{
      @media (--mobile) {
        margin-top: 2rem;
      }

      a{
        justify-content: space-between;
        padding-left: 4.5rem;
        padding-right: 3.3rem;
        @media (--mobile) {
          font-size: 1.5rem;
        }
      }

    }

    .c-btn05{
      a{
        background-color: #fff;
        border: 1px solid #1a2e56;
        color: #1a2e56;
        transition: 0.3s;
        justify-content: left;
        padding: 0 3.3rem;
        @media (--mobile) {
          justify-content: center;
        }
        @media (--large) {
          &:hover{
            color: #fff;
            svg{
              path{
                stroke: #fff;
              }
            }
          }
        }
        svg{
          margin-right: 4rem;
          path{
            transition: 0.3s;
            stroke: #1a2e56;
          }
        }
      }
    }

  }

}
