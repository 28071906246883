.c-breadcrumb {
  display: flex;
  align-items: center;
  height: 4rem;
  padding-right: 3rem;
  background: #1a2e56;

  @media (--mobile) {
    overflow: auto;

    .c-i-inner {
      width: auto;
      min-width: 100%;

      @media (--mobile) {
        padding-right: 0;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__el {
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (--mobile) {
      text-overflow: inherit;
    }

    &,
    * {
      font-size: 1.3rem;
    }

    &:nth-of-type(n + 2) {
      padding-left: 1.5rem;
      margin-left: 0.5rem;

      &::before {
        position: absolute;
        top: 50%;
        left: 0.5rem;
        width: 0.1rem;
        height: 60%;
        content: "";
        background: #fff;
        transform: translateY(-50%) rotate(14deg);
      }
    }

    &,
    * {
      font-weight: lighter;
      color: #fff;
    }
  }
}
