.p-sds {
  .s-btn_wrap {
    @media (--large) {
      display: flex;
      justify-content: center;
    }

    margin-top: 4rem;

    a {
      @media (--large) {
        width: 31rem;
        padding-top: 0.5rem;

        @at-root .en & {
          width: 43rem;
        }
      }

      @media (--mobile) {
        @at-root .en & {
          width: 100%;
        }
      }
    }

    .c-btn01 {
      &:nth-of-type(n + 2) {
        @media (--large) {
          margin-left: 4rem;
        }

        @media (--mobile) {
          margin-top: 2rem;
        }
      }

      span {
        line-height: 1.5;
      }
    }

    .c-hidden {
      pointer-events: none;

      a {
        background: #b2b2b2;
      }
    }
  }
}
