.p-soniccutter-zero {
  @media (--large) {
    .c-product-fontSize {
      span {
        font-size: 4.2rem;
      }
    }
  }

  @media (--mobile) {
    .c-product-fontSize {
      span {
        font-size: 3rem;
      }
    }
  }

  .c-product-spec-img {
    position: relative;
    left: 6rem;
    width: 55.4rem;

    @media (--mobile) {
      position: relative;
      left: 4rem;
      width: 33rem;
    }
  }
}
