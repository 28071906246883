.p-abt {
  .c-product-mv {
    padding-bottom: 6rem;

    @media (--mobile) {
      @at-root .en & {
        &-btn {
          .c-btn09 {
            a {
              width: 23rem;
            }
          }
        }
      }
    }
  }

  .c-product-spec {
    padding-top: 9rem;

    @media (--mobile) {
      padding-top: 4rem;
    }
  }

  .c-product-spec-img {
    width: 94.9rem;

    @media (--mobile) {
      width: 100%;
    }
  }
}
