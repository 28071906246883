.p-network {
  @media (--mobile) {
    overflow: hidden;
  }

  &__map {
    position: relative;
    margin-top: 9rem;
    margin-bottom: 6.5rem;

    @at-root .en & {
      margin-top: 4rem;
    }

    .map-c {
      position: absolute;

      @media (--mobile) {
        width: 9rem;
        height: 2rem;
        transform: scale(0.7);

        p {
          width: 100%;
          height: 100%;
          font-size: 1rem;
        }
      }

      a {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // width: 12.8rem;
        // font-size: 1.6rem;
        // color: #fff;
        // background-color: #1a2e56;
        // border-radius: 3rem;
        // position: absolute;
      }
    }

    .map-link {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      p {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12.8rem;
        font-size: 1.6rem;
        color: #fff;
        background-color: #1a2e56;
        border-radius: 3rem;
      }

      @media (--large) {
        &:hover {
          .ja-img,
          .east-img,
          .es-img,
          .oceania-img,
          .africa-img,
          .europa-img,
          .usa-img {
            opacity: 1;
          }
        }
      }
    }

    .map01 {
      top: 44%;
      right: 3rem;
      z-index: 20;
      width: 16rem;
      height: 8.4rem;
      transform: translateY(-50%);

      @media (--mobile) {
        right: 0;
      }

      p {
        right: 0;
        bottom: 0;
      }

      .ja-img {
        position: absolute;
        top: -0.4rem;
        left: -1.5rem;
        width: 6.8rem;
        opacity: 0;
        transition: 0.3s;
      }
    }

    .map02 {
      top: 0;
      right: 0;
      width: 46rem;
      height: 25rem;

      @media (--mobile) {
        top: -2rem;
        right: -3rem;
      }

      p {
        top: 0;
        right: 10rem;
      }

      .east-img {
        position: absolute;
        top: 1.7rem;
        right: -0.4rem;
        width: 50.9rem;
        opacity: 0;
        transition: 0.3s;
      }
    }

    .map03 {
      right: 0;
      bottom: 1rem;
      width: 28rem;
      height: 13rem;

      @media (--mobile) {
        right: 5rem;
        bottom: -1rem;
      }

      p {
        bottom: 0;
        left: 0;
      }

      .oceania-img {
        position: absolute;
        top: -6.5rem;
        right: 4rem;
        width: 22.2rem;
        opacity: 0;
        transition: 0.3s;
      }
    }

    .map04 {
      right: 18.4rem;
      bottom: 14.4rem;
      width: 24rem;
      height: 16rem;

      @media (--mobile) {
        right: 10rem;
      }

      p {
        top: 14.5rem;
        left: 0.5rem;
      }

      .es-img {
        position: absolute;
        top: -2.6rem;
        right: 1.5rem;
        width: 22.2rem;
        opacity: 0;
        transition: 0.3s;
      }
    }

    .map05 {
      bottom: 8.9rem;
      left: 51.1%;
      width: 30rem;
      height: 25rem;
      transform: translateX(-50%);

      @media (--mobile) {
        bottom: 1rem;
        left: 33%;
      }

      p {
        bottom: 0;
        left: 0.5rem;
      }

      .africa-img {
        position: absolute;
        top: -2.7rem;
        right: -1.1rem;
        width: 27.1rem;
        opacity: 0;
        transition: 0.3s;
      }
    }

    .map06 {
      top: 14rem;
      left: 29%;
      z-index: 20;
      width: 34rem;
      height: 24rem;
      transform: translateY(-50%);

      @media (--mobile) {
        top: 6rem;
      }

      p {
        bottom: 0;
        left: 0.5rem;
      }

      .europa-img {
        position: absolute;
        top: 0.2rem;
        right: -0.6rem;
        width: 17.2rem;
        opacity: 0;
        transition: 0.3s;
      }
    }

    .map07 {
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 50rem;

      @media (--mobile) {
        top: -12rem;
        bottom: inherit;
        left: -2.5rem;
      }

      // height: 24rem;

      p {
        top: 30rem;
        left: 6rem;
      }

      .usa-img {
        position: absolute;
        top: 0.2rem;
        right: -1.6rem;
        width: 51.5rem;
        opacity: 0;
        transition: 0.3s;
      }
    }
  }

  &__inner {
    margin-bottom: 15rem;
  }

  &__continent {
    &:nth-child(n + 2) {
      margin-top: 8rem;
    }

    @at-root .en & {
      dt {
        @media (--large) {
          width: 22% !important;
        }
      }
    }
  }

  &__country {
    display: flex;
    flex-direction: column;

    @at-root .en & {
      @media (--large) {
        width: 22% !important;
      }
    }

    &.noflex-sp {
      display: block;
    }

    span {
      display: block;

      @media (--mobile) {
        display: inline-block;
      }
    }

    &.country-row__sp {
      @media (--mobile) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  &__base01 {
    display: flex;
    justify-content: space-between;

    @at-root .en & {
      @media (--large) {
        width: 78% !important;
      }
    }

    @media (--mobile) {
      flex-direction: column;
    }
  }

  &__base02 {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 !important;

    @at-root .en & {
      @media (--large) {
        width: 78% !important;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 0 3.5rem;

    @media (--mobile) {
      display: block;
      padding: 0 2rem;
    }

    &:nth-child(n + 2) {
      border-top: 1px solid #c7ced3;
    }
  }

  &__addr02 {
    display: flex;
    flex-direction: column;

    span {
      // &:first-child {
      //   font-weight: bold;
      // }

      &.ttl {
        font-weight: bold;
      }

      &.att {
        color: #2c529f;
      }
    }
  }

  &__tel01 {
    @media (--mobile) {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;
    }

    span {
      &:nth-child(n + 2) {
        margin-left: 2.5rem;

        @media (--mobile) {
          margin-left: 0;
        }
      }
    }
  }

  &__tel02 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    @media (--mobile) {
      align-items: flex-start;
      margin-top: 1.5rem;
    }
  }

  &__sns {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    @media (--mobile) {
      flex-direction: column;
      padding-bottom: 1rem;
    }

    ul {
      display: flex;
      height: 2.4rem;
      padding-top: 0.5rem;

      li {
        height: 2.4rem;

        &:nth-child(n + 2) {
          margin-left: 1rem;
        }

        a {
          transition: 0.3s all;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    .link-cell {
      @media (--mobile) {
        display: flex;
      }
    }

    .link {
      margin-left: 2rem;

      @media (--mobile) {
        margin-top: 2rem;
        margin-left: 0;
      }

      &:nth-of-type(n + 2) {
        margin-top: 1rem;

        @media (--mobile) {
          margin-top: 2rem;
          margin-left: 1rem;
        }
      }

      a {
        padding-bottom: 0.5rem;
        color: #384eaa;
        border-bottom: 0.1rem solid #384eaa;
        transition: 0.3s all;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
