.c-link01 {
  a {
    position: relative;
    display: inline-block;
    padding-right: 1rem;
    color: #384eaa;
    border-bottom: 1px solid #384eaa;
    transition: 0.3s opacity;

    @media (--large) {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__arrow {
    a {
      padding-right: 1rem;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.7rem;
        height: 0.7rem;
        content: "";
        border-top: 2px solid #384eaa;
        border-right: 2px solid #384eaa;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}

.c-txtLink {
  color: #384eaa;
  text-decoration: underline;
  transition: 0.3s opacity;

  @media (--large) {
    &:hover {
      opacity: 0.6;
    }
  }
}
