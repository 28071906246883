.p-rotus {
  .c-product-mv {
    padding-bottom: 6rem;
  }

  .c-product-spec {
    padding-top: 9rem;
    padding-bottom: 0;

    @media (--mobile) {
      padding-top: 4rem;
    }
  }

  .c-product-spec-img {
    width: 73.2rem;

    @media (--mobile) {
      width: 100%;
    }
  }
}
