.c-product-map {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: #edeff4;

  @media (--mobile) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .c-i-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (--mobile) {
      display: block;
    }
  }

  &__left,
  &__right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 48%;

    @media (--mobile) {
      width: 100%;
    }
  }

  &__right {
    @media (--mobile) {
      margin-top: 3rem;
    }

    & > nav {
      width: 31%;

      @media (--mobile) {
        width: 100%;
      }

      @media (--large) {
        &:nth-of-type(n + 4) {
          margin-top: 3.7rem;
        }
      }

      @media (--mobile) {
        &:nth-of-type(n + 2) {
          margin-top: 0;
        }
      }
    }
  }

  &__ttl {
    width: 100%;
    margin-bottom: 3rem;

    @media (--mobile) {
      margin-bottom: 2rem;
    }

    a {
      position: relative;
      display: inline-block;
      padding-right: 2.5rem;
      font-size: 2.2rem;
      font-weight: bold;
      color: #1a2e56;

      svg {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1rem;
        transform: translateY(-50%);
      }
    }
  }

  &__nav {
    width: 48%;

    @media (--mobile) {
      width: 100%;
    }
  }

  &__sTtl {
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #1a2e56;
    border-bottom: 1px solid #8491aa;

    @media (--large) {
      pointer-events: none;

      @at-root .en & {
        display: flex;
        align-items: flex-end;
        min-height: 7rem;
      }
    }

    @media (--mobile) {
      font-size: 1.8rem;

      &.is-active {
        .c-product-map__trigger {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }
  }

  @media (--mobile) {
    &__trigger {
      position: absolute;
      top: 50%;
      right: 0;
      display: inline-block;
      width: 3rem;
      height: 3rem;
      transition: 0.3s transform;
      transform: translateY(-50%);

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1rem;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  @media (--mobile) {
    &__list {
      display: none;
      padding-bottom: 2rem;
    }
  }

  &__el {
    &:nth-of-type(n + 2) {
      margin-top: 0.5rem;
    }

    @media (--mobile) {
      font-size: 1.6rem;
    }
  }
}
