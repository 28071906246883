.c-s-search {
  &-inner {
    display: flex;
    align-items: center;

    @media (--mobile) {
      flex-wrap: wrap;
    }

    &__txt {
      margin-right: 2rem;
      color: #1a2e56;

      @media (--mobile) {
        width: 100%;
        font-size: 2rem;
      }
    }

    &__input {
      width: 26rem;
      height: 5rem;

      @media (--mobile) {
        width: calc(100% - 14rem - 2rem);
      }

      input {
        width: 100%;
        height: 100%;
        padding: 1rem;
        font-size: 1.6rem;
        border: 0.1rem solid #c7ced3;

        &::placeholder {
          color: #b2b2b2;
        }
      }

      margin-right: 2rem;
    }

    .c-btn01 {
      a,
      button {
        width: 18.4rem;
        height: 5rem;
        padding: 0 2rem;
        font-size: 1.8rem;

        @media (--mobile) {
          width: 14rem;
          font-size: 1.6rem;
        }
      }

      svg {
        right: 2rem;
        transform: translateY(-50%);

        @media (--mobile) {
          right: 1.5rem;
          width: 2rem;
        }
      }
    }
  }

  &__annotation {
    padding-left: 8.6rem;
    margin-top: 1rem;
    font-size: 1.4rem;

    @media (--mobile) {
      padding-left: 0;
      font-size: 1.2rem;
    }

    span {
      display: inline-block;
      padding-left: 1em;
      color: #1a2e56;
      text-indent: -1em;
    }
  }
}

.c-s-check {
  position: relative;
  font-size: 1.6rem;

  label {
    position: relative;
    display: inline-block;
    padding-left: 3.5rem;

    input {
      position: absolute;
      top: 50%;
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
      transform: translateY(-50%);

      &::before,
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        content: "";
        border: 1px solid #c7ced3;
      }

      &::after {
        display: none;
        background: url(../images/common/ic_check.png) no-repeat center center / contain;
        border: none;
      }

      &:checked {
        &::after {
          display: block;
        }
      }
    }
  }
}

.c-s-select {
  position: relative;

  label {
    position: relative;
    display: inline-block;

    &::after {
      position: absolute;
      top: 50%;
      right: 1rem;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      border-right: 1px solid #1a2e56;
      border-bottom: 1px solid #1a2e56;
      transform: translateY(-50%) translateY(-25%) rotate(45deg);
    }

    select {
      display: flex;
      align-items: center;
      min-width: 15rem;
      height: 4rem;
      padding: 0;
      padding: 0 3rem 0 2rem;
      font-size: 1.6rem;
      border: 1px solid #c7ced3;
    }
  }
}
