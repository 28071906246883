.c-square-indent,
.c-point-indent {
  display: block;
  padding-left: 1.4em;
  font-size: 1.6rem;
  text-indent: -1.4em;

  @at-root .en & {
    padding-left: 1rem;
    text-indent: -0.7em;
  }
}

.c-point-indent {
  padding-left: 0.7em;
  text-indent: -0.7em;
}
