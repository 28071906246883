.c-ttl01 {
  font-size: 2.8rem;
  color: #1a2e56;

  small {
    display: block;
    margin-top: 1rem;
    font-size: 1.4rem;
    color: #8491aa;
  }
}

.c-ttl02 {
  font-size: 2.8rem;
  color: #1a2e56;

  small {
    display: block;
    margin-top: 1rem;
    font-size: 1.4rem;
    color: #8491aa;
  }
}

.c-ttl03 {
  padding: 0.5rem 1.5rem;
  font-size: 2.2rem;
  color: #fff;
  background: #1a2e56;

  @media (--mobile) {
    padding: 1rem 3rem;
    margin: 0 -3rem;
    font-size: 2rem;
  }
}

.c-ttl04 {
  position: relative;
  padding-bottom: 1rem;
  font-size: 2rem;
  color: #1a2e56;
  border-bottom: 1px solid #c7ced3;

  @media (--mobile) {
    font-size: 2rem;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 10rem;
    height: 1px;
    content: "";
    background: #1a2e56;
  }

  &__wrap {
    position: relative;
  }

  &__required {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1.5;
    color: #fff;
    background: #1a2e56;
    transform: translateY(-50%) translateX(100%) translateX(1.5rem);

    @at-root .en & {
      width: 10rem;
      height: 2.5rem;
    }
  }
}

.c-ttl05 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #1a2e56;

  &__wrap {
    position: relative;
  }

  &__required {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1.5;
    color: #fff;
    background: #1a2e56;
    transform: translateY(-50%) translateX(100%) translateX(1.5rem);
  }
}

.c-ttl06 {
  display: flex;
  align-items: center;
  height: calc(7rem + 1.3rem);
  padding-left: 2rem;
  font-size: 2.4rem;
  color: #1a2e56;
  background: #edeff4;
  border-top: 1.3rem solid #1a2e56;

  svg {
    width: 2rem;
    margin-right: 1rem;
    transform: translateY(0.2rem);

    * {
      fill: #1a2e56;
    }
  }
}

.c-ttl07 {
  position: relative;
  padding: 1.7rem 1.5rem 1.7rem 6rem;
  font-size: 2.4rem;
  color: #1a2e56;
  background: #edeff4;

  &::before {
    position: absolute;
    top: 50%;
    left: 2.3rem;
    width: 1.4rem;
    height: 1.4rem;
    content: "";
    border-right: 2px solid #1a2e56;
    border-bottom: 2px solid #1a2e56;
    transform: translateY(-75%) rotate(45deg);
  }
}
