.p-search {
  #error {
    display: none;
  }

  .c-s-search {
    &-inner {
      &__input {
        @media screen and (min-width: 768px), print {
          width: 58rem;
        }
      }
    }
  }

  .c-table01 {
    table {
      @media (--mobile) {
        width: 100rem;
      }

      th {
        padding: 0.5rem;
        line-height: 1.2;
      }

      td {
        padding: 1rem;
      }
    }
  }
}
