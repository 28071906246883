.c-articleDetail {
  @media (--large) {
    width: 90rem;
    margin-right: auto;
    margin-left: auto;
  }

  &-ttl {
    &__h1 {
      padding-bottom: 1rem;
      margin-bottom: 2rem;
      font-size: 3rem;
      border-bottom: 1px solid #8491aa;

      @media (--mobile) {
        font-size: 2.2rem;
      }
    }

    &__btm {
      display: flex;
      align-items: center;

      &--date {
        font-weight: bold;
        color: #1a2e56;
        letter-spacing: 0;
      }

      &--tag {
        display: flex;
        justify-content: center;
        width: 12rem;
        padding: 0.2rem 1rem;
        margin-right: 2.4rem;
        margin-left: 2rem;
        overflow: hidden;
        font-size: 1.3rem;
        color: #fff;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #1a2e56;
      }
    }
  }

  &-btn {
    display: flex;
    justify-content: center;

    // justify-content: space-between;
    padding-top: 6rem;
    margin-top: 8rem;
    border-top: 1px solid #8491aa;

    @media (--mobile) {
      padding-top: 3rem;
      margin-top: 5rem;
    }

    &__wrap {
      @media (--large) {
        width: 12rem;
      }

      @media (--mobile) {
        width: 10.5rem;
      }
    }

    @media (--mobile) {
      & > div {
        &:nth-of-type(1),
        &:nth-of-type(3) {
          width: 10.5rem;
        }

        &:nth-of-type(1) {
          a {
            justify-content: flex-end !important;

            svg {
              position: absolute;
              top: 50%;
              left: 1rem;
              transform: translateY(-50%);
            }
          }
        }

        &:nth-of-type(3) {
          a {
            justify-content: flex-start !important;

            svg {
              position: absolute;
              top: 50%;
              right: 1rem;
              transform: translateY(-50%);
            }
          }
        }

        &:nth-of-type(2) {
          a {
            width: 12rem !important;
          }
        }
      }
    }

    a {
      height: 7rem !important;
      font-size: 1.6rem !important;

      @media (--mobile) {
        width: 100% !important;
        height: 6rem !important;
        padding: 1rem !important;
      }
    }

    .c-btn03 {
      a {
        width: 26rem;

        @media (--mobile) {
          width: 13rem;
        }
      }
    }
  }
}
