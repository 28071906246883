.p-sCase {
  .s-ttlArea {
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (--mobile) {
        display: block;
      }
    }

    &__left {
      display: flex;
      align-items: center;
    }

    &__tag {
      padding: 0.1rem 1rem;
      font-size: 1.4rem;
      color: #fff;
      background: #1a2e56;
    }

    &__cat {
      margin-left: 1rem;
      font-size: 1.5rem;
    }

    &__right {
      margin: 0 -0.5rem;

      @media (--mobile) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
      }

      span {
        padding: 0.2rem 1rem;
        margin: 0.3rem 0.5rem;
        font-size: 1.4rem;
        border: 1px solid #1a2e56;
        border-radius: 9999px;

        @media (--mobile) {
          padding: 0.2rem 0.5rem;
          font-size: 1.2rem;
        }
      }
    }

    &__ttl {
      margin-top: 2.4rem;
      font-size: 3rem;
      font-weight: normal;
      color: #1a2e56;
      border-bottom: 0.3rem solid #1a2e56;

      @media (--mobile) {
        padding-bottom: 0.8rem;
        margin-top: 1rem;
        font-size: 2.6rem;
        line-height: 1.5;
      }
    }

    &__other {
      display: flex;
      align-items: center;
      margin-top: 2rem;

      @media (--mobile) {
        display: block;
        margin-top: 1rem;
      }

      p {
        color: #1a2e56;

        &:nth-of-type(n + 2) {
          @media (--large) {
            margin-left: 2.6rem;
          }
        }
      }
    }
  }

  .s-mv {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    text-align: center;

    @media (--mobile) {
      margin: 5rem -3rem 0;
    }

    iframe,
    img,
    video {
      width: 85rem;
      height: 47rem;
      object-fit: cover;

      @media (--mobile) {
        width: 100%;
        height: 55vw;
      }
    }
  }

  @media (--large) {
    .c-table02 {
      dl {
        dt {
          width: 17%;
        }

        dd {
          width: 83%;
        }
      }
    }
  }

  .c-table03 {
    table {
      @media (--mobile) {
        width: 50rem;
      }

      th {
        font-size: 2rem;

        &:nth-of-type(n + 2) {
          width: 44.7rem;
        }

        @media (--mobile) {
          padding: 1rem;
          font-size: 1.8rem;
          line-height: 1.5;

          &:first-of-type {
            position: sticky;
            left: 0;
            width: 12rem;

            &::after {
              position: absolute;
              top: -1px;
              left: 1px;
              width: 100%;
              height: 100%;
              content: "";
            }
          }
        }

        &:last-of-type {
          background: #384eaa;
        }
      }

      tr {
        td {
          &:first-of-type {
            width: 20.5rem;
            height: 7.8rem;
            font-weight: bold;
            background: #edeff4;

            @media (--mobile) {
              position: sticky;
              left: 0;
              height: auto;
              min-height: 7.8rem;

              &::before {
                position: absolute;
                top: -1px;
                left: 1px;
                width: 100%;
                height: 100%;
                content: "";
              }
            }
          }

          &:nth-of-type(n + 2) {
            text-align: center;
          }

          // &:last-of-type {
          //   font-size: 1.8rem;
          // }
        }
      }

      // th {
      //   &:not(.fixed01) {
      //     width: 44.7rem;
      //     font-size: 2rem;
      //     letter-spacing: 0.1em;

      //     @media (--mobile) {
      //       width: 15rem;
      //       padding: 1rem 0;
      //       font-size: 1.8rem;
      //       line-height: 1.5;
      //     }
      //   }
      // }

      // td {
      //   height: 7.8rem;
      //   font-size: 1.3rem;
      //   text-align: center;

      //   @media (--mobile) {
      //     font-size: 1.6rem;
      //   }

      //   strong {
      //     font-size: 1.8rem;
      //     font-weight: bold;

      //     @media (--mobile) {
      //       font-size: 2rem;
      //     }
      //   }
      // }

      // .first {
      //   width: 20.5rem;
      //   font-size: 1.5rem;
      //   font-weight: bold;
      //   text-align: left;

      //   @media (--mobile) {
      //     width: 3rem;
      //   }
      // }
    }

    @media (--mobile) {
      &--2row {
        overflow: hidden !important;

        table {
          width: 100%;

          tr td:first-of-type {
            width: 11.5rem;
          }
        }
      }
    }
  }

  .l-btnArea01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (--mobile) {
      display: block;
    }

    @media (--large) {
      .c-btn12 {
        &:nth-of-type(n + 3) {
          margin-top: 5rem;
        }
      }
    }

    a {
      width: 57rem;
      font-size: 2.4rem;

      @media (--mobile) {
        width: 100%;
      }
    }
  }

  .l-btnArea02 {
    display: flex;

    @media (--mobile) {
      display: block;
    }

    div {
      &:nth-of-type(n + 2) {
        margin-left: 4rem;

        @media (--mobile) {
          margin-top: 2rem;
          margin-left: 0;
        }
      }

      a {
        width: auto;
        height: 7rem;
        padding: 0 3rem;
        padding-left: 7rem;
        font-size: 1.6rem;

        @media (--mobile) {
          font-size: 1.8rem;
        }

        svg {
          left: 2rem;
        }
      }
    }
  }
}
