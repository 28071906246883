// .e2000 {
//   width: 47.5rem !important;
// }

.p-e2000 {
  @media (--mobile) {
    .c-product-mv {
      display: block;
      padding-top: 4rem;
    }

    .c-product-mv__pImg {
      margin-bottom: 3rem;
    }
  }

  .c-product-spec-img {
    width: 100rem;

    @media (--mobile) {
      width: 100%;
    }
  }

  &-list {
    ul {
      display: flex;

      @media (--mobile) {
        display: block;
      }

      li {
        margin-right: 4rem;

        @media (--mobile) {
          margin-top: 3rem;
          margin-right: 0;

          &:first-child {
            margin-top: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .title {
          margin-top: 2.4rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56;
        }

        .text {
          margin-top: 1.2rem;
          font-size: 1.6rem;
        }
      }
    }
  }
}
