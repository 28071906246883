.cursor {
  position: fixed;
  top: -4rem;
  left: -4rem;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  font-size: 1.3rem;
  pointer-events: none;
  background: unquote("rgb(247 247 247 / 0%)");
  border-radius: 50%;
  transition: 0.3s top, 0.3s left, 0.3s width, 0.3s height, 0.3s background;

  &.is-active {
    top: -6.5rem;
    left: -6.5rem;
    width: 13rem;
    height: 13rem;
    background: unquote("rgb(247 247 247 / 80%)");

    // transform: translate(50rem, 50rem) !important;
  }

  &_wrap {
    overflow: hidden;

    span {
      display: inline-block;
      opacity: 0;
      transition: 0.3s;
      transform: translateY(100%);

      @for $i from 1 through 6 {
        &:nth-of-type(#{$i}) {
          transition-delay: #{$i / 30}s;
        }
      }
    }
  }

  &.is-active {
    span {
      opacity: 1 !important;
      transform: translateY(0%);
    }

    &.black {
      background: unquote("rgb(0 0 0 / 70%)");

      span {
        color: #fff;
      }
    }
  }
}
