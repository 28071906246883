.p-presto2 {
  .c-product-mv {
    padding-bottom: 6rem;
  }

  .c-product-spec {
    padding-top: 9rem;
  }

  .c-product-spec-img {
    width: 83.8rem;

    @media (--mobile) {
      width: 100%;
    }
  }
}
