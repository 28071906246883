.c-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;

  // width: 65rem;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
  transform: translate(-50%, -50%);

  @media (--mobile) {
    width: 90%;
  }

  .img-inner {
    overflow-y: scroll;

    @media (--large) {
      height: 100%;
      overflow: hidden;
    }

    @media (--mobile) {
      height: 60%;
    }
  }

  &.is-active {
    pointer-events: inherit;
    opacity: 1;
  }

  &-inner {
    width: 100%;
    padding: 2rem 4rem 4rem;
    background-color: #fff;

    @media (--mobile) {
      padding: 2rem;

      &.long {
        height: 80vh;
        overflow: auto;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.5rem;
      margin: 0 auto;
      font-size: 2rem;
      color: #fff;
      background-color: #384eaa;

      @at-root .en & {
        &.long {
          font-size: 1.8rem;
        }
      }

      @media (--mobile) {
        &.long {
          height: auto;
          padding: 1rem;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.5rem;
      margin-top: 2.4rem;

      @media (--mobile) {
        flex-direction: column;
        margin: 2.4rem 0 0;
      }

      li {
        width: calc(100% / 3 - 3rem);
        margin: 0 1.5rem;

        @media (--mobile) {
          width: 100%;
          margin: 0;
        }

        &:nth-of-type(3) {
          margin-right: 0;
        }

        &:nth-of-type(n + 4) {
          margin-top: 5rem;
        }

        @media (--mobile) {
          &:nth-of-type(n + 2) {
            margin-top: 4rem;
          }
        }

        .title {
          margin-top: 2.4rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: #1a2e56;

          @media (--mobile) {
            margin-top: 1rem;
          }
        }

        .text {
          margin-top: 1.2rem;
          font-size: 1.6rem;
        }
      }
    }

    &__list {
      display: flex;
      margin-top: 2.4rem;

      @media (--mobile) {
        flex-direction: column;
      }

      & > div {
        width: calc(100% / 2);

        @media (--mobile) {
          width: 100%;

          &:nth-of-type(n + 2) {
            margin-top: 4rem;
          }
        }

        &:first-of-type {
          margin-right: 4rem;

          @media (--mobile) {
            margin-right: 0;
          }
        }
      }

      .img {

      }

      .title {
        margin-top: 2.4rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #1a2e56;

        @media (--mobile) {
          margin-top: 1rem;
        }
      }

      .text {
        margin-top: 1.2rem;
        font-size: 1.6rem;

        &.long {
          @at-root .en & {
            white-space: nowrap;
          }
        }
      }
    }

    &__block {
      margin-top: 2.4rem;

      .img {

      }

      .title {
        margin-top: 2.4rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #1a2e56;

        @media (--mobile) {
          margin-top: 1rem;
        }
      }

      .text {
        margin-top: 1.2rem;
        font-size: 1.6rem;
      }
    }
  }

  &__inner {
    position: absolute;
  }

  &__close {
    position: absolute;
    top: -1rem;
    right: 0;
    padding-left: 1.5rem;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
    transform: translateY(-100%);

    span {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      transform: translateY(-50%) translateX(-0.5rem);

      &::after,
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 1px;
        content: "";
        background: #fff;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: unquote("rgb(26 46 86 / 90%)");
    opacity: 0;
    transition: 0.3s;

    &.is-active {
      pointer-events: inherit;
      opacity: 1;
    }
  }
}
