.js-form {
  position: relative;
  z-index: 1;
}

.c-m-sort,
.c-s-sort {
  border: 1px solid #c7ced3;
  border-top: 1.3rem solid #1a2e56;

  h3 {
    position: relative;
    display: flex;
    align-items: center;
    height: 7rem;
    padding: 0 5.5rem;
    font-size: 2rem;
    color: #1a2e56;
    background: #edeff4;

    @media (--mobile) {
      height: 5rem;
      padding: 0.8rem  4.3rem 0.8rem 5.3rem;
      font-size: 1.8rem;
    }

    svg {
      &:first-of-type {
        position: absolute;
        top: 50%;
        left: 2.5rem;
        width: 2rem;
        height: 2rem;
        content: "";
        transform: translateY(-50%);

        * {
          fill: #1a2e56;
        }
      }

      &:last-of-type {
        position: absolute;
        top: 50%;
        right: 4rem;
        width: 1rem;
        height: 1.6rem;
        transition: 0.3s;
        transform: translateY(-50%) rotate(90deg);

        @media (--mobile) {
          right: 2rem;
          width: 1.3rem;
          height: 2rem;
        }
      }
    }

    &.is-open {
      svg:last-of-type {
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }

  .c-btn01 {
    button {
      width: 28rem;
      height: 7rem;
      font-size: 1.8rem;
    }
  }
}

.c-m-sort {
  @media (--mobile) {
    h3 {
      svg {
        &:first-of-type {
          left: 2rem;
        }
      }
    }
  }

  &-wrap {
    padding: 1.5rem 5rem;

    @media (--mobile) {
      padding: 2rem;
    }

    h4 {
      font-size: 2rem;
      color: #1a2e56;
    }

    &:nth-of-type(n + 2) {
      border-top: 1px solid #c7ced3;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    div {
      width: calc(100% / 5);

      @media (--mobile) {
        width: auto;
        min-width: calc(100% / 2);
      }

      @media (--large) {
        &:nth-of-type(n + 6) {
          margin-top: 1rem;
        }
      }

      a,
      button {
        position: relative;
        padding: 0;
        padding-left: 1.8rem;
        font-size: 1.6rem;
        text-align: left;

        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          width: 0.8rem;
          height: 1.3rem;
          content: "";
          background: url(../images/common/ic_arrow01.svg) no-repeat center center / contain;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.c-s-sort {
  border-top: 0;

  h3 {
    font-size: 2rem;
    background: #fff;

    @media (--mobile) {
      svg {
        &:first-of-type {
          left: 1.7rem;
        }
      }
    }
  }

  &-wrap {
    display: flex;
    padding: 0 1.5rem 1rem;

    @media (--mobile) {
      display: block;
      padding-top: 0;
      padding-bottom: 2rem;
    }

    &__ttl {
      position: relative;
      padding: 0.8rem 1.5rem;
      font-size: 1.8rem;
      background: #edeff4;

      @media (--large) {
        margin-bottom: 2rem;
        pointer-events: none;
      }

      @media (--mobile) {
        padding-right: 5rem;

        svg {
          position: absolute;
          top: 50%;
          right: 2rem;
          width: 1.3rem;
          height: 2rem;
          transition: 0.3s;
          transform: translateY(-50%) rotate(90deg);
        }

        &.is-open {
          svg {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
      }
    }

    &__el {
      width: calc(100% / 5);
      padding: 0 1.5rem;

      @media (--mobile) {
        width: 100%;
        padding: 0;

        &:nth-of-type(n + 2) {
          margin-top: 2rem;
        }
      }

      @media (--large) {
        &--wide {
          width: calc(100% / 5 * 2);
        }
      }
    }

    &__check {
      @media (--large) {
        display: block !important;
      }

      &-wrap {
        display: flex;
        flex-wrap: wrap;

        @media (--mobile) {
          padding-top: 0.7rem;
        }
      }

      .c-s-check {
        display: block;

        @media (--large) {
          width: 100%;

          &:nth-of-type(n + 2) {
            margin-top: 0.7rem;
          }
        }

        @media (--mobile) {
          min-width: 50%;
          margin-top: 0.7rem;
        }
      }

      @media (--large) {
        &--wide {
          .c-s-check {
            width: 50%;
            margin-top: 0;

            &:nth-of-type(2) {
              margin-top: 0;
            }

            &:nth-of-type(n + 3) {
              margin-top: 0.7rem;
            }
          }
        }
      }
    }
  }
}

.c-r-sort {
  margin-top: 3rem;

  h4 {
    margin-bottom: 1rem;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    width: 60%;

    @media (--mobile) {
      width: 100%;
    }

    label {
      position: relative;
      width: 50%;
      padding-left: 3rem;

      @media (--mobile) {
        width: 100%;

        &:nth-of-type(n + 2) {
          margin-top: 1rem;
        }
      }

      @media (--large) {
        &:nth-of-type(n + 3) {
          margin-top: 0.5rem;
        }
      }

      input {
        position: absolute;
        top: 50%;
        left: 0;
        width: 2rem;
        height: 2rem;
        transform: translateY(-50%);

        &::after,
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          content: "";
          border: 1px solid #ddd;
          border-radius: 9999px;
          transform: translate(-50%, -50%);
        }

        &::before {
          width: 1rem;
          height: 1rem;
          background: #000;
          border-radius: 9999px;
          opacity: 0;
        }

        &:checked {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
