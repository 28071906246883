.p-aManual {
  .s-btn_wrap {
    @media (--large) {
      display: flex;
      align-items: center;
    }

    @media (--mobile) {
      a {
        height: 5rem;
      }
    }
  }

  .c-s-sort-wrap {
    padding: 3rem 0 !important;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    @media (--mobile) {
      .c-s-check {
        width: inherit !important;
        min-width: calc(100% / 2);

        label {
          white-space: nowrap;
        }
      }
    }
  }

  @media (--mobile) {
    .c-ttl07 {
      position: relative;
      padding: 0.5rem 1.5rem 0.5rem 6rem;
      margin: 0 -3rem;
      font-size: 2.2rem;
    }
  }

  .s-range {
    display: flex;
    flex-wrap: wrap;

    @media (--mobile) {
      display: block !important;
    }

    & > div {
      display: flex;
      align-items: center;
      width: 50%;

      @media (--mobile) {
        flex-wrap: wrap;
        width: 100%;
      }

      @media (--large) {
        &:nth-of-type(n + 3) {
          margin-top: 2rem;
        }
      }

      @media (--mobile) {
        &:nth-of-type(n + 2) {
          margin-top: 1rem;
        }
      }

      .ttl {
        min-width: 8.5rem;
        margin-right: 2.5rem;
        text-align: right;
        white-space: nowrap;

        @media (--mobile) {
          width: 100%;
          min-width: auto;
          margin-right: 0;
          margin-bottom: 0.8rem;
          font-size: 1.8rem;
          text-align: left;
        }
      }

      .delimiter {
        margin: 0 1rem;
      }

      &:nth-of-type(odd) {
        .ttl {
          min-width: auto;
        }
      }
    }
  }

  .s-side {
    display: flex;

    &__img {
      flex-shrink: 0;
      width: 30rem;
      padding-left: 7rem;

      div {
        position: relative;
        position: sticky;
        top: 12rem;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
          opacity: 0;
        }
      }
    }
  }

  .js-scrollable .scroll-hint-icon {
    top: 8rem !important;
  }

  .c-btn03 {
    a {
      width: 30rem;
      height: 8rem;
    }
  }

  @media (--large) {
    .c-s-sort-wrap {
      &__vertical {
        flex-direction: column;
        width: 100%;

        .c-s-check {
          // width: auto !important;
          margin: 0.4rem 0 !important;
        }
      }

      &__type01 {
        height: 21rem;
      }

      &__type02 {
        height: 62rem;
      }
    }
  }

  @media (--large) {
    .c-r-sort {
      div {
        label {
          margin-top: 0.5rem;

          &:nth-of-type(1) {
            order: 1;
          }

          &:nth-of-type(2) {
            order: 3;
          }

          &:nth-of-type(3) {
            order: 5;
          }

          &:nth-of-type(4) {
            order: 2;
          }

          &:nth-of-type(5) {
            order: 4;
          }
        }
      }
    }
  }
}
