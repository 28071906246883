.p-error {
  margin-bottom: 14rem;

  @media (--mobile) {
    margin-bottom: 7rem;
  }

  p {
    text-align: center;

    @media (--mobile) {
      text-align: left;
    }
  }

  .c-btn03 {
    margin-top: 5rem;
  }
}
