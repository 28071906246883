.c-wysiwyg {
  font-size: 1.6rem;

  img {
    margin-bottom: 1.6em;
  }

  h2 {
    padding-bottom: 0.2em;
    margin-bottom: 2em;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.5;
    color: #1a2e56;
    border-bottom: 2px solid;
  }

  h3 {
    position: relative;
    padding-left: 1em;
    margin-bottom: 1em;
    font-size: 1.125em;
    font-weight: 700;
  }

  h3::before {
    position: absolute;
    top: 0.35em;
    left: 0;
    width: 4px;
    height: 1.11em;
    line-height: 1.5;
    content: "";
    background: #1a2e56;
    border-radius: 2px;
  }

  h4 {
    padding: 0.2em 1em;
    margin-bottom: 2em;
    font-size: 1.125em;
    font-weight: 700;
    background: #eee;
    border-radius: 5px;
  }

  h5 {
    margin-bottom: 2em;
    font-weight: 700;
    line-height: 1.5;
  }

  p + p {
    margin-top: 1.75em;
  }

  p {
    margin-bottom: 1.75em;
  }

  a {
    color: #1a2e56;
    text-decoration: underline;
    transition: opacity 0.3s;
  }

  a:hover,
  a:active {
    opacity: 0.7;
  }

  ul li {
    position: relative;
    padding-left: 1.05em;
    line-height: 1.5;
  }

  ul li::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "・";
  }

  ul li + li {
    margin-top: 0.5em;
  }

  ol {
    counter-reset: number 0;
  }

  ol li + li {
    margin-top: 0.5em;
  }

  ol li {
    position: relative;
    padding-left: 1.5em;
    line-height: 1.5;
  }

  ol li::before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Baloo Bhaijaan 2", cursive;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.2;
    content: counter(number) ".";
    counter-increment: number 1;
  }

  ol .pp-addr {
    padding-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 1.75em;
  }

  ol .pp-addr span {
    display: inline-block;
  }

  ol .pp-addr span:first-of-type {
    padding-right: 1em;
  }

  h2 + h2,
  h2 + h3,
  h2 + h4,
  h2 + h5,
  h3 + h2,
  h3 + h3,
  h3 + h4,
  h3 + h5,
  h4 + h2,
  h4 + h3,
  h4 + h4,
  h4 + h5,
  h5 + h2,
  h5 + h3,
  h5 + h4,
  h5 + h5 {
    margin-top: -0.9em;
  }

  p + p,
  p + img,
  p + ul,
  p + ol,
  img + p,
  img + ul,
  img + ol,
  ul + ol,
  ul + p,
  ul + img,
  ol + p,
  ol + img,
  ol + ul {
    margin-top: 1.75em;
  }
}
